import * as React from "react";
import { List as MaterialList, ListItem } from "material-ui";
import { Section } from "../Section/Section";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import ListInternalLink from "components/Navigation/InternalLink/ListInternalLink";
const styles = require("./style.less");
import { getNavigationUrl } from "../PagingBaseComponent/PagingBaseComponent";
import Divider from "components/Divider/Divider";
import NumberedPagingBar from "../PagingBaseComponent/NumberedPagingBar";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import { NoResults } from "components/NoResults/NoResults";

interface SimpleListProps<R> {
    empty?: React.ReactNode;
    match?: any;
    items: R[];
    filterHintText?: string;
    autoFocusOnFilterSearch?: boolean;
    disableListItem?: boolean;
    pageSize?: number;
    onRow(item: R): React.ReactNode;
    onRowRedirectUrl?(item: R): string;
    onFilter?(filter: string, item: R): boolean;
}

interface SimpleListState {
    redirectTo?: string;
    filter?: string;
    currentPageIndex: number;
}

export abstract class SimpleList<R> extends React.Component<SimpleListProps<R>, SimpleListState> {
    constructor(props: SimpleListProps<R>) {
        super(props);
        this.state = { currentPageIndex: 0 };
    }

    componentWillReceiveProps(nextProps: SimpleListProps<R>) {
        if (nextProps.pageSize) {
            const newLastPageIndex = nextProps.items.length === 0 ? 1 : Math.ceil(nextProps.items.length / nextProps.pageSize);
            if (newLastPageIndex < this.state.currentPageIndex) {
                this.setState({ currentPageIndex: newLastPageIndex });
            }
        }
    }

    onFilter(item: R): boolean {
        const filter = this.state.filter;
        if (!filter || filter === "" || !this.props.onFilter) {
            return true;
        }
        return this.props.onFilter(this.state.filter, item);
    }

    renderEmpty() {
        return this.props.empty ? <Section>{this.props.empty}</Section> : <NoResults />;
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }

        if (!this.props.items) {
            return null;
        }

        let filteredList = this.props.items
            .filter((item) => this.onFilter(item));

        if (this.props.pageSize) {
            const firstItemIndex = this.props.pageSize * this.state.currentPageIndex;
            filteredList = filteredList.slice(firstItemIndex, firstItemIndex + this.props.pageSize);
        }

        return <TransitionAnimation>
            <MaterialList>
                {this.props.onFilter && this.renderFilterSearchComponents()}
                {(filteredList.length === 0) && <div className={styles.emptyList}>{this.renderEmpty()}</div>}
                {filteredList.map((item, index) => this.buildItem(item, index))}
                {
                    this.props.pageSize &&
                    <NumberedPagingBar currentPageIndex={this.state.currentPageIndex}
                        totalItems={this.props.items.length}
                        pageSize={this.props.pageSize}
                        onPageSelected={(skip, currentPageIndex) => this.setState({ currentPageIndex })}
                    />
                }
            </MaterialList>
        </TransitionAnimation>;
    }

    private buildItem(item: R, index: number) {
        const redirectUrl = getNavigationUrl(this.props, item);
        let nodeRow = this.props.onRow(item);
        if (redirectUrl) {
            // Make these actual href links, so people can still right-click > open new window (this is expected usability for things you can click).
            nodeRow = <ListInternalLink to={redirectUrl}>{nodeRow}</ListInternalLink>;
        }
        const redirectTo = getNavigationUrl(this.props, item);
        const nodes = [
            <ListItem key={0} className={styles.listItem}
                onClick={e => this.navigate(item)}
                disabled={this.props.disableListItem || !redirectTo}>
                {nodeRow}
            </ListItem>
        ];

        if (index < (this.props.items.length - 1)) {
            nodes.push(<Divider />);
        }

        return (
            React.Children.toArray(nodes)
        );
    }

    private navigate(item: R) {
        const redirectTo = getNavigationUrl(this.props, item);
        if (!redirectTo) {
            return;
        }
        this.setState({ redirectTo });
    }

    private renderFilterSearchComponents() {
        return (<Section>
            <FilterSearchBox
                hintText="Filter..."
                onChange={(filter) => this.setState({ filter })}
                autoFocus={this.props.autoFocusOnFilterSearch}
            />
        </Section>);
    }
}