import { reducers } from "../reducers";
import configureStore from "./configureStore";
import { throttle } from "lodash";
import { loadState, saveState } from "./persistence";

const persistedState = loadState();

const store = configureStore(persistedState)(reducers);

store.subscribe(throttle(() => {
    const state = store.getState();
    saveState({
        drawerArea: state.drawerArea
    });
}, 250));

export default store;
