import * as React from "react";
import { CustomWizardDialogLayout, WizardStepper } from "components/DialogLayout/Custom";
import ExternalLink from "components/Navigation/ExternalLink";
import { WizardDialogActions } from "components/DialogLayout/Custom/CustomDialogActions";
import * as cn from "classnames";
import IconButton from "material-ui/IconButton";
import ClearIcon from "material-ui/svg-icons/content/clear";
import ImageWithPlaceholder from "../ImageWithPlaceholder/ImageWithPlaceholder";

const styles = require("./FundamentalsGuideDialogLayout.less");

interface FundamentalsWizardPageProps {
    image: string;
    heading: React.ReactNode;
}

const isIE11 = !!(window as any).MSInputMethodContext && !!(document as any).documentMode;

const slide1 = require(`../WelcomeSlides/slide1-welcomeoctopus${isIE11 ? "-ie11" : ""}.svg`);
const slide2 = require(`../WelcomeSlides/slide2-environments${isIE11 ? "-ie11" : ""}.svg`);
const slide3 = require(`../WelcomeSlides/slide3-package${isIE11 ? "-ie11" : ""}.svg`);
const slide4 = require(`../WelcomeSlides/slide4-steps${isIE11 ? "-ie11" : ""}.svg`);
const slide5 = require(`../WelcomeSlides/slide5-release${isIE11 ? "-ie11" : ""}.svg`);

const FundamentalsWizardPage: React.FC<FundamentalsWizardPageProps> = ({ image, heading, children }) => (
    <div className={styles.centre}>
        <ImageWithPlaceholder className={styles.image} src={image} minHeight="26.5rem" />
        <div className={styles.heading}>{heading}</div>
        <div className={cn(styles.content, styles.centre)}>
            {children}
        </div>
    </div>
);

interface FundamentalsGuideDialogLayoutProps {
    open: boolean;
    close: () => void;
}

export class FundamentalsGuideDialogLayout extends React.Component<FundamentalsGuideDialogLayoutProps> {

    constructor(props: FundamentalsGuideDialogLayoutProps) {
        super(props);
    }

    render() {
        return <CustomWizardDialogLayout
            open={this.props.open}
            close={this.props.close}
            renderPage={(props) => (
                <div className={styles.container}>
                    {props.content}
                    <div className={styles.closeButton}>
                        <IconButton
                            tooltip="Close"
                            onClick={this.props.close}>
                            <ClearIcon />
                        </IconButton>
                    </div>
                    <WizardDialogActions {...props} stepper={<WizardStepper
                        pageCount={props.pageCount}
                        pageNumber={props.currentPageIndex}
                        selectPage={props.selectPage}
                    />} />
                </div>
            )}
        >
            <FundamentalsWizardPage
                image={slide1}
                heading="Welcome to Octopus">
                We'll take you through the fundamentals of Octopus to help deploy your first application easily.
            </FundamentalsWizardPage>

            <FundamentalsWizardPage
                image={slide2}
                heading="Where are we deploying?">
                Octopus organizes your deployment targets (the machines and services you deploy software to) into groups called environments.
                For your first deployment, try creating one environment and one target.
            </FundamentalsWizardPage>

            <FundamentalsWizardPage
                image={slide3}
                heading="What are we deploying?">
                Upload your <ExternalLink href="packageids">Packages</ExternalLink> containing all the files needed to run your application when deployed.
                They require a package ID, version number and be in one of many common formats.  Alternatively, you can write scripts directly in Octopus.
            </FundamentalsWizardPage>

            <FundamentalsWizardPage
                image={slide4}
                heading="How would you like to deploy it?">
                Projects are used to define your deployment process.
                This is where you can add steps that configure your application on a deployment target.  You can add built-in steps, community contributed steps or custom scripts.  Add as many or as little as you need.
            </FundamentalsWizardPage>

            <FundamentalsWizardPage
                image={slide5}
                heading="Ready to deploy your release?">
                Create a release for your project and deploy it to your first environment.
                Once it's been tested, you can promote it through your other environments until you get to production!
            </FundamentalsWizardPage>

        </CustomWizardDialogLayout>;
    }
}