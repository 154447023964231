import * as React from "react";
import CodeGuideEntry from "./CodeGuideEntry";
import ExternalLink from "../Navigation/ExternalLink/ExternalLink";
import Callout, { CalloutType } from "components/Callout/Callout";
import PageTitleHelper from "utils/PageTitleHelper";
const styles = require("./style.less");
import PaperLayout from "components/PaperLayout/PaperLayout";
import AreaTitle from "components/AreaTitle";
import routeLinks from "../../routeLinks";
import ScrollToTop from "components/ScrollToTop";
import DrawerWrapperLayout from "components/Drawer/DrawerWrapperLayout";

interface CodeGuideState {
    busyIndicator?: Promise<any>;
    model: Model;
    cleanModel: Model;
}

interface Model {
    text: string;
}

export default class CodeGuide extends React.Component<any, CodeGuideState> {
    constructor(props: any) {
        super(props);
        PageTitleHelper.setRootPageTitle();
        const model = {
            text: "text",
        };
    }

    render() {
        const entries = this.getEntries()
            .sort((entry1, entry2) => {
                if (entry1.props.heading < entry2.props.heading) {
                    return -1;
                }
                if (entry1.props.heading > entry2.props.heading) {
                    return 1;
                }
                return 0;
            })
            .map(entry => React.cloneElement(entry, { ...entry.props, key: entry.props.heading }));

        return <div>
            <AreaTitle
                link={routeLinks.codeGuide}
                title="Code Guide"
            />
            <DrawerWrapperLayout>
                <div className={styles.styleGuide}>
                    <ul>
                        <h2>Guide</h2>
                        {entries.map((entry, i) => <li key={i}>
                            <a href="#" onClick={e => this.navigateToEntry(e, entry.props.heading)}>{entry.props.heading}</a>
                        </li>)}
                    </ul>
                    <PaperLayout title="Demos" fullWidth={true}>
                        <ul className={styles.components}>
                            {entries}
                        </ul>
                    </PaperLayout>
                    <ScrollToTop />
                </div>
            </DrawerWrapperLayout>
        </div>;
    }

    private getEntries() {
        return [
            <CodeGuideEntry heading="Redux" context="Why we use Redux and where you may find it useful.">
                <p>
                    Redux is "a predictable state container for Javascript apps". It's a lightweight implementation of Flux.
                    Flux is a data-flow architecture, where you can think of the data-flow as moving one-way. Have a good read
                    of <ExternalLink
                        href="http://www.youhavetolearncomputers.com/blog/2015/9/15/a-conceptual-overview-of-redux-or-how-i-fell-in-love-with-a-javascript-state-container"
                        showIcon={true}> this article </ExternalLink> for some great explanations of why this is an amazing architecture.
                </p>
                <Callout type={CalloutType.Success} title={"The TLDR; of Redux"}>
                    It can often help you solve data-flow problems (ie. when you need to pass state
                    between various components). Instead of passing your state change directly from ComponentA to ComponentB and/or through to
                    ComponentC, you can simply dispatch an action to say "hey app, this state has changed" and Redux will flow that state-
                    change through as props to any components that are listening...
                </Callout>
                <p>
                    We use Redux only where it makes sense to use Redux. ie. When we hit complexity regarding state management between components.
                </p>
                <h2>Real world example: Our 'Projects' side menu</h2>
                <ul>
                    <li>Our app is wrapped in a Redux store (see ./index.tsx).</li>
                    <li>
                        This "store" allows us to store global state about our app (see GlobalState.tsx), part of which is our "currentProject" (via
                        the ProjectsAreaState).
                    </li>
                    <li>
                        We know users might update the project's name or description via the project settings page (see ProjectSettings.tsx), so this
                        class dispatches a "projectSaved" action (whenever the project settings are saved) to let the Redux store know that some
                        global state (our "currentProject") has changed.
                    </li>
                    <li>
                        This "projectSaved" action is then processed by a "reducer" (search for "projectSaved" and you will find the reducer), which
                        figures out what state changes need to happen. In this case, it returns a new "currentProject", which Redux will then push onto
                        our Redux store's state tree.
                    </li>
                    <li>
                        Our project sidebar component (see ProjectLayout.tsx) is listening for any changes to the GlobalState and (thanks to Redux) will
                        automatically receive new props when our "currentProject" changes in our Redux store. As this component receives new props, it causes the
                        component to re-render and display our updated project name/description etc.
                    </li>
                </ul>
                <p>
                    This illustrates the one-way data-flow approach of Redux to help solve an otherwise difficult problem of communicating to the sidebar
                    component that the current project's name/description has been updated. Components anywhere in the app can "dispatch" actions, and components
                    anywhere in the app can receive new props in response to those state changes.
                </p>
                <h2>The Warning Signs</h2>
                <p>
                    If you find you are having to pass props between various levels of components in order to get things to re-render or behave as you expect,
                    take a step back and think about whether your problem could be more easily solved with a simple Redux action/reducer pattern.
                </p>
            </CodeGuideEntry>,

            <CodeGuideEntry
                heading="Cancelling continuations / invalidating older requests"
                context={"Useful for situations where you need the last request to have precendence and you want your component to " +
                    "ignore the responses of previous requests that may still be in-flight."}>
                <p>
                    In places where we need to invalidate older requests / cancel continuations, we have a `RequestRaceConditioner` class that can help.
                </p>
                <p>
                    For example, in some situations where users can apply filters in quick succession (filters that each cause requests to the API with
                    different parameters), the earlier requests may take longer to respond than the most current request, so the user could end up looking
                    at stale data.
                </p>
                <p>
                    In cases like these, your component can opt-in to safeguard itself by wrapping its request(s) in a call to `RequestRaceConditioner`:
                </p>
                <pre>
                    <code>{`import RequestRaceConditioner from "utils/RequestRaceConditioner";

...

class MyComponent extends DataBaseComponent<MyComponentProps, MyComponentState> {

    // Create an instance of our race conditioner...
    private requestRaceConditioner = new RequestRaceConditioner();

    // Some method that gets called whenever filters are changed...
    private async reloadBasedOnFilters() {
        const filter = this.state.filter;
        const args = {
            foo1: filter.foo1,
            foo2: filter.foo2,
            foo3: filter.foo3,
        };

        // Typically, we would just do something like this:
        //const response = await repository.Foo.endpointThatSupportsFiltering(args);
        //this.setState({
        //    myData: response,
        //});

        // But instead, we'll wrap it with our race conditioner, as it helps us ignore any responses that do not belong to the most current request.
        await this.requestRaceConditioner.avoidStaleResponsesForRequest(repository.Foo.endpointThatSupportsFiltering(args), (response) => {
            this.setState({
                myData: response,
            });
        });
    }

    ...
}`}</code>
                </pre>
                <Callout type={CalloutType.Warning} title="Important bits">
                    <p>
                        This pattern does add complexity, so we only want to apply it where necessary. From our testing, users discovered this problem
                        in areas that supported complex filtering, as well as when searching in our lists, so those are the places we've added this protection to,
                        but if you come across any others, please try and re-use this existing pattern or let us know if you have a better approach that we
                        can investigate/apply.
                    </p>
                </Callout>
            </CodeGuideEntry>,

            <CodeGuideEntry heading="Code guide 001: Create a resource" context="">
                <Callout type={CalloutType.Success} title={"Writing new features"}>
                    If you're thinking of writing a new feature that has an API and needs list and CRUD screens, this guide can show you
                    how to get started and will introduce you to the patterns you should be following to stay on the straight and narrow :)
                </Callout>
                <p>Let's say you have a new "Thing" resource that's exposed by the Octopus API...</p>
                <h3>How to create a new 'Thing' resource</h3>
                <ul>
                    <li>Head over to the /app/client/resources and create a new resource file 'thingResource.ts'</li>
                    <li>Create your resource class including any enums this resource may need (an example template is included below that you can copy and refactor accordingly</li>
                    <li>Create a new entry in /app/client/resources/index.ts for your new resource</li>
                </ul>
                <br />
                <pre>
                    <code>{`import NamedResource from "./namedResource";

export interface ThingResource extends NamedResource {
    Type: ThingType;
    IsDisabled: boolean;
    // Add other properties here
}

export enum ThingType {
    Unknown = "Unknown",
    Rick = "Rick",
    Morty = "Morty",
}`}</code>
                </pre>
                <Callout type={CalloutType.Warning} title="Important bits">
                    <p>
                        If you're coming from our previous Angular world, you'll notice that everything is <strong>typed</strong> (yay).
                    </p>
                    <p>
                        ...and we have <strong>enums</strong> that map to the same API/server-side enums, that our components can use to address values properly (woohoo).
                    </p>
                    <p>
                        Please avoid using the <code>any</code> keyword where possible, as this is like <code>dynamic</code> keyword in C# which
                    should be reserved for very specific use cases.
                    </p>
                </Callout>
                <p>
                    If you're adding a new 'Thing' resource, it will most likely have a corresponding API. So next, we need to create a repository to help access this API.
                </p>
            </CodeGuideEntry>,

            <CodeGuideEntry heading="Code guide 002: Create a repository" context="">
                <h3>Create your 'Thing' repository</h3>
                <ul>
                    <li>Head over to the /app/client/repository and create a new repository (Note: it inherits from BasicRepository)</li>
                    <li>If your repository exposes custom endpoints (other than the usual POST/PUT/GET/DELETE), have a look around other repositories for an example you can copy,
                        chances are someone's already done something similar to what you need)</li>
                </ul>
                <br />
                <pre>
                    <code>
                        {`import BasicRepository from "./basicRepository";
    import Client from "../client";

    class ThingRepository extends BasicRepository<any> {
        constructor(client: Client) {
            super("Things", client);
        }
    }

    export default ThingRepository;`}
                    </code>
                </pre>
                <p>Now you have a resource and a repository to help run API operations for that resource. Now you can start writing screens!</p>
            </CodeGuideEntry>,

            <CodeGuideEntry heading="Code guide 003: Create a list" context="">
                <h3>List template</h3>
                <ul>
                    <li>Create a folder for your lists screen layout. E.g. /app/areas/[your area]/components/ThingsLayout</li>
                    <li>Create a corresponding index.tsx (to allow you to import this component more easily)</li>
                    <li>Create a corresponding style.less file (for any styles this component may need)</li>
                </ul>
                <br />
                <pre>
                    <code>
                        {`import * as React from "react";
import List from "areas/shared/List";
import { ResourceCollection } from "client/resources";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import {ThingResource} from "client/resources";
import {repository} from "clientInstance";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { NavigationButton, NavigationButtonType } from "components/Button";
import {RouteComponentProps} from "react-router";
import InfrastructureLayout from "../InfrastructureLayout";
import ListTitle from "areas/shared/ListTitle/ListTitle";
const styles = require("./style.less");

class ThingList extends List<ThingResource> {}

interface ThingLayoutState extends DataBaseComponentState {
    thingsResponse: ResourceCollection<ThingResource>;
}

export default class ThingsLayout extends DataBaseComponent<RouteComponentProps<void>, ThingLayoutState> {
    constructor(props: any) {
        super(props);
        this.state = ({
            thingsResponse: null,
        });
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const thingsResponse = await repository.Things.list();
            this.setState({ thingsResponse });
        });
    }

    render() {
        const addButton = <NavigationButton type={NavigationButtonType.Primary} label="Add Thing" href={\`$\{this.props.match.url}/create\`} />;

        return <InfrastructureLayout {...this.props}>
            <PaperLayout busy={this.state.busy}
                errors={this.state.errors}
                title="Things"
                sectionControl={addButton}>
                <p>Some description about this thing!</p>
                {this.state.thingsResponse && <ThingList
                    initialData={this.state.thingsResponse}
                    onRow={item => this.buildThingRow(item)}
                    onFilter={this.Filter}
                    filterSearchEnabled={true}
                    apiSearchParams={["partialName"]}
                    match={this.props.match}
                    filterHintText="Filter by name..." />}
            </PaperLayout>
        </InfrastructureLayout>;
    }

    private Filter(filter: string, resource: ThingResource) {
        return !filter || filter.length === 0 || !resource
            || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }

    private buildThingRow(thing: ThingResource) {
        return (
            <ListTitle>{thing.Name}</ListTitle>
        );
    }
}
`}
                    </code>
                </pre>
                <Callout type={CalloutType.Warning} title="Important bits">
                    <p>
                        We wrap all asynchronous work in <code>await this.doBusyTask</code>.
                    </p>
                    <p>
                        Our <code>PaperLayout</code> includes both <code>busy</code> and <code>errors</code> properties. These
                        allow the layout to know when to display busy indicators and error messages (all managed for us because
                        this component extends/inherits from <code>DataBaseComponent</code>).
                    </p>
                </Callout>
                <p>Now we have a list screen, we need to create a CRUD screen so we can create/read/update/delete this Thing!</p>
            </CodeGuideEntry>,

            <CodeGuideEntry heading="Code guide 004: Create an add/edit screen" context="">
                <h3>CRUD template</h3>
                <ul>
                    <li>Create a folder for your screen layout. E.g. /app/areas/[your area]/components/ThingLayout</li>
                    <li>Create a corresponding index.tsx (to allow you to import this component more easily)</li>
                    <li>Create a corresponding style.less file (for any styles this component may need)</li>
                </ul>
                <br />
                <pre>
                    <code>{`import * as React from "react";
import {ThingResource, ThingType} from "client/resources";
import {repository} from "clientInstance";
import {Redirect} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import FormBaseComponent, { FormBaseComponentState } from "components/FormBaseComponent";
import {cloneDeep} from "lodash";
import {
    Text,
    Expander,
    Summary,
    required,
    Sensitive
} from "components/form";
import InfrastructureLayout from "../InfrastructureLayout/InfrastructureLayout";
import ParseHelper from "utils/ParseHelper";
import {Callout, CalloutType} from "components/Callout";
import OverflowMenu from "components/Menu/OverflowMenu";
const styles = require("./style.less");

interface ThingProps extends RouteComponentProps<ThingParams> {
    create?: boolean;
}

interface ThingParams {
    thingId: string;
}

interface ThingState extends FormBaseComponentState<ThingResource> {
    deleted: boolean;
    newId: string;
}

class ThingLayout extends FormBaseComponent<ThingProps, ThingState, ThingResource> {
    constructor(props: any) {
        super(props);
        this.state = {
            deleted: false,
            newId: null
        };
    }

    async componentDidMount() {
        let thing: ThingResource = null;
        if (this.props.create) {
            thing = {
                Id: null as string,
                Name: "",
                Type: ThingType.Unknown,
                Links: null
            };
        } else {
            const thingPromise = repository.Things.get(this.props.match.params.thingId);
            this.setState({ busy: thingPromise });
            thing = await thingPromise;
        }

        this.setState({
            model: thing,
            cleanModel: cloneDeep(thing)
        });
    }

    render() {
        const title = this.props.create
            ? "Create thing"
            : this.state.model
                ? this.state.model.Name
                : StringHelper.ellipsis;

        const overFlowActions = [];
        if (!this.props.create) {
            overFlowActions.push([OverflowMenu.deleteItemDefault("thing", this.handleDeleteConfirm)]);
        }

        const saveText: string = this.state.newId
                ? "Thing created"
                : "Thing details updated";

        return <InfrastructureLayout {...this.props}>
            <FormPaperLayout
                title={title}
                busy={this.state.busy}
                errors={this.state.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                onSaveClick={this.handleSaveClick}
                saveText={saveText}
                expandAllOnMount={this.props.create}
                overFlowActions={overFlowActions}
            >
                {this.state.deleted && <InternalRedirect to={routeLinks.infrastructure.things}/>}
                {this.state.newId && <InternalRedirect to={routeLinks.infrastructure.thing(this.state.newId)}/>}
                {this.state.model && <div>

                <Expander
                    errorKey="Name"
                    title="Name"
                    summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your thing")}
                    help="A short, memorable, unique name for this Thing.">
                    <Text
                        value={this.state.model.Name}
                        onChange={Name => this.setModelState({Name})}
                        label="Thing name"
                        validate={required("Please enter a thing name")}
                    />
                </Expander>
                    </div>}
            </FormPaperLayout>
        </InfrastructureLayout>;
    }

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const isNew = this.state.model.Id == null;
            const result = await repository.Things.save(this.state.model);
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
                newId: isNew ? result.Id : null
            });
        });
    }

    private handleDeleteConfirm = async () => {
        await this.doBusyTask(async () => {
            const result = await repository.Things.del(this.state.model);
            this.setState(state => {
                return {
                    model: null,
                    cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                    deleted: true
                };
            });
        });
    }
}

export default ThingLayout;
`}
                    </code>
                </pre>
                <p>Now we have a list and CRUD screen, we need to expose these layouts (ie. our new screens) to our navigation/routing system!</p>
            </CodeGuideEntry>,

            <CodeGuideEntry heading="Code guide 005: Creating a route" context="">
                <h3>Adding a route</h3>
                <ul>
                    <li>Let's say this 'Thing' belongs to our infrastructure section...</li>
                    <li>Open /app/areas/infrastructure/components/infrastructureRoutes/infrastructureRoutes.tsx</li>
                    <li>Add entries for your Thing's navigation routes</li>
                </ul>
                <br />
                <pre>
                    <code>{`<ReloadableRoute path={\`$\{match.url}/things/create\`} render={(props: any) => <ThingLayout create {...props}/>} />
<ReloadableRoute path={\`\${match.url}/things/:thingId\`} component={ThingLayout} />
<ReloadableRoute path={\`$\{match.url}/things\`} component={ThingsLayout} />`}
                    </code>
                </pre>
                <p>Finally, we need to create links in our area's sidebar so people can access our screens!</p>
            </CodeGuideEntry>,

            <CodeGuideEntry heading="Code guide 006: Creating navigation" context="">
                <h3>Adding our nav links</h3>
                <ul>
                    <li>Again, let's say this 'Thing' belongs to our infrastructure section...</li>
                    <li>Open /app/areas/infrastructure/components/infrastructureLayout/infrastructureLayout.tsx</li>
                    <li>Add an entry to your list screen so people can access 'Things'</li>
                </ul>
                <br />
                <pre>
                    <code>{`OverflowMenu.navItem("Things", {routeLinks.infrastructure.things}`}
                    </code>
                </pre>
            </CodeGuideEntry>,

        ];
    }

    private navigateToEntry(e: any, heading: any) {
        e.preventDefault();
        //# did not work
        document.getElementById(heading).scrollIntoView();

        const scrolledY = window.scrollY;
        if (scrolledY) {
            window.scroll(0, scrolledY - 144);
        }
    }

    private link(heading: string) {
        return `${location.href}#${heading}`;
    }

    private startDoing = async (e: any) => {
        e.preventDefault();
        const busyIndicator = this.wait();
        this.setState({ busyIndicator });
        await busyIndicator;
    }

    private wait() {
        return new Promise(resolve => setTimeout(resolve, 1000));
    }

    private handleChange = (model: Model) => {
        this.setState({
            model
        });
    }
}