import * as React from "react";
import { Provider } from "react-redux";
import store from "./store";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import Theme from "components/Theme";
import { FocusContextProvider } from "components/FocusContext";

export const AppContext: React.FC<{}> = ({children}) => (
    <ErrorBoundary>
        <Provider store={store}>
            <Theme>
                <FocusContextProvider value={{current: null}}>
                    {children}
                </FocusContextProvider>
            </Theme>
        </Provider>
    </ErrorBoundary>
);

export default AppContext;