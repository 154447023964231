import * as React from "react";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import MaterialDialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { SFC } from "react";
import { dialogClass } from "uiTestClasses";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "theme";
import * as cn from "classnames";

interface DialogProps {
    open?: boolean;
    wide?: boolean;
    repositionOnUpdate?: boolean;
    onRequestClose?: () => void;
    fullScreen?: boolean;
    title?: React.ReactNode;
    actions?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
    paper: ({wide}: DialogProps) => ({
        maxWidth: wide ? "1000px" : "600px",
        width: "100%",
    })
}));

const DialogRaw: SFC<DialogProps> = (props) => {
    const { open, children, fullScreen, title, actions } = props;
    const classes = useStyles(props);

    return (
            <MaterialDialog
                classes={{ paperWidthSm: classes.paper }}
                className={cn(dialogClass)}
                open={open || false}
                fullScreen={fullScreen}
            >
                {title && <DialogTitle>{title}</DialogTitle>}
                {children}
                {actions && <DialogActions>{actions}</DialogActions>}
            </MaterialDialog>
    );
};

const Dialog = withMobileDialog<DialogProps>()(DialogRaw);
Dialog.displayName = "Dialog";

export default Dialog;