import { PackagingRegistration } from "../../Registry/packagingRegistry";

import BuildServerPackagingInstruction from "./BuildServerPackagingInstruction";
import ManualPackagingInstruction from "./ManualPackagingInstruction";
import CommandLinePackagingInstruction from "./CommandLinePackagingInstruction";

export const JavaPackagingRegistration: PackagingRegistration = {
    displayOrder: 400,
    instructions: [
        BuildServerPackagingInstruction,
        ManualPackagingInstruction,
        CommandLinePackagingInstruction
    ],
    name: "Java",
    id: "Java"
};

export default JavaPackagingRegistration;