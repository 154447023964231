import * as React from "react";
const styles = require("./style.less");
import { CloseButton } from "components/Button";
import smoothscroll from "./smoothscroll";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import Note from "components/form/Note/Note";
import { octopusErrorClass } from "uiTestClasses";
import { ActionButton, ActionButtonType } from "components/Button";
import { client } from "../../clientInstance";
import { KnownErrorCodes } from "components/UnhandledError";

interface ErrorPanelProps {
    message: string;
    statusCode?: number;
    details?: string[];
    detailLinks?: string[];
    helpText?: string;
    helpLink?: string;
    fullException?: string;
    fullWidth?: boolean;
    canClose?: boolean;
    onErrorClose?: () => void;
    scrollToPanel?: boolean;
}

interface ErrorPanelState {
    showFullException: boolean;
}

class ErrorPanel extends React.Component<ErrorPanelProps, ErrorPanelState> {
    private static defaultProps: Partial<ErrorPanelProps> = {
        fullWidth: true,
        scrollToPanel: true
    };

    panel: HTMLDivElement;

    constructor(props: ErrorPanelProps) {
        super(props);
        this.state = ({
            showFullException: false
        });
    }

    componentDidMount() {
        if (this.props.scrollToPanel && this.panel) {
            smoothscroll.scrollTo(this.panel);
        }
    }

    render() {
        if (this.props.statusCode === KnownErrorCodes.NetworkError) {
            // We are handling network errors globally via SystemMessagesBanner
            return null;
        }

        const serverInfo = client.tryGetServerInformation();
        return <div ref={ref => this.panel = ref}
            className={styles.error}
            style={{
                display: this.props.fullWidth ? "block" : "inline-block",
                width: this.props.fullWidth ? "100%" : "auto"
            }}>
            <div className={styles.titleContainer}>
                <span className={styles.title}>{this.props.message}</span>
                <div>
                    {this.props.canClose && <CloseButton size="1.5rem" onClose={this.props.onErrorClose} />}
                </div>
            </div>
            {this.props.details && this.props.details.length > 0 &&
                <ul>
                    {this.props.details.map((e, index) => this.detailFor(e, index))}
                </ul>
            }
            {this.props.helpText && <Note>{this.props.helpText}</Note>}
            {this.props.helpLink && <ExternalLink href={this.props.helpLink}>More information...</ExternalLink>}
            {this.props.fullException &&
                <div>
                    <ActionButton type={ActionButtonType.Ternary}
                        label={this.state.showFullException ? "hide full exception" : "show full exception"}
                        onClick={() => this.setState({ showFullException: !this.state.showFullException })}
                    />
                    {this.state.showFullException &&
                        <pre>
                            Octopus v{serverInfo ? serverInfo.version : undefined}
                            <br /><br />
                            {this.props.fullException}
                        </pre>}
                </div>}
        </div>;
    }

    private detailFor(error: string, index: number) {
        const detailLinks = this.props.detailLinks;
        const detailLink = detailLinks && detailLinks.length > index && detailLinks[index];
        return <li key={index} className={octopusErrorClass}>
            {error}
            {detailLink && <div className={styles.detailLink}>
                <ExternalLink href={detailLink}>More information...</ExternalLink>
            </div>}
        </li>;
    }
}

export default ErrorPanel;
