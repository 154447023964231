import * as React from "react";
import ProjectMultiSelect from "components/MultiSelect/ProjectMultiSelect";
import { repository } from "clientInstance";
import { ActionButton, ActionButtonType } from "components/Button";
import EnvironmentMultiSelect from "components/MultiSelect/EnvironmentMultiSelect";
import {cloneDeep} from "lodash";
import { ProjectResource, EnvironmentResource, TenantedDeploymentMode, Permission } from "client/resources";
import PermissionCheck, { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import SaveDialogLayout from "../../../components/DialogLayout/SaveDialogLayout";
import {TenantResource} from "../../../client/resources/tenantResource";
import {Callout, CalloutType} from "../../../components/Callout/Callout";
import Select from "components/form/Select/Select";
import routeLinks from "routeLinks";
import InternalLink from "components/Navigation/InternalLink";

interface AddProjectsToTenantDialogProps {
    existingProjectLink?: { projectId: string, environmentIds: string[] };
    excludedProjects: string[];
    tenant: TenantResource;
    onUpdated(tenant: TenantResource): void;
}

interface AddProjectsToTenantDialogState extends DataBaseComponentState {
    project?: ProjectResource;
    environmentIds: string[];
    projects?: ProjectResource[];
    availableEnvironments?: EnvironmentResource[];
    isLoaded: boolean;
}

export default class AddProjectsToTenantDialog extends DataBaseComponent<AddProjectsToTenantDialogProps, AddProjectsToTenantDialogState> {
    constructor(props: AddProjectsToTenantDialogProps) {
        super(props);
        this.state = {
            environmentIds: null,
            isLoaded: false
        };
    }

    componentWillReceiveProps(nextProps: AddProjectsToTenantDialogProps) {
        this.setExistingProject(nextProps);
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const projects = await repository.Projects.all();
            this.setState({ projects }, () => this.setExistingProject(this.props));
        });
    }

    setExistingProject(props: AddProjectsToTenantDialogProps) {
        const { projectId = null, environmentIds = [] } = (props.existingProjectLink || {});
        const project = projectId && this.state.projects ? this.state.projects.find((p) => p.Id === projectId) : null;
        this.setState({
            environmentIds,
            project,
            availableEnvironments: [],
            isLoaded: !project
        }, async () => {
            if (project) {
                await this.doBusyTask(async () => {
                    const progression = await repository.Progression.getProgression(project);
                    this.setState({
                        availableEnvironments: progression.Environments as EnvironmentResource[],
                        isLoaded: true
                    });
                });
            }
        });
    }

    enableTenantedProject = async (project: ProjectResource) => {
        project.TenantedDeploymentMode = TenantedDeploymentMode.TenantedOrUntenanted;
        await this.doBusyTask(async () => {
            await repository.Projects.modify(project);
        });
    }

    handleProjectSelected = async (projectId: string) => {
        const project = projectId ? this.state.projects.find((p) => p.Id === projectId) : null;
        this.setState({ project, environmentIds: [], availableEnvironments: [] }, async () => {
            if (project) {
                await this.doBusyTask(async () => {
                    const progression = await repository.Progression.getProgression(project);
                    this.setState({ availableEnvironments: progression.Environments as EnvironmentResource[] });
                });
            }
        });
    }

    handleEnvironmentsSelected = async (environmentIds: string[]) => {
        this.setState({ environmentIds });
    }

    renderProjectUntenanted() {
        const project = this.state.project;
        return <Callout title="To connect, a project must have tenanted deployments enabled." type={CalloutType.Warning}>
            Do you want to enable tenanted deployments for <b>{project.Name}</b>?
            <PermissionCheck permission={Permission.ProjectEdit} project={project.Id} projectGroup={project.ProjectGroupId} tenant="*">
                <div style={{marginTop: "1rem"}}>
                    <ActionButton label={`Enable tenanted deployments`}
                    type={ActionButtonType.Primary} onClick={() => this.enableTenantedProject(project)} />
                </div>
            </PermissionCheck>
        </Callout>;
    }

    renderEnvironmentMultiSelect() {
        return this.state.isLoaded && [<EnvironmentMultiSelect
            key="select"
            onChange={this.handleEnvironmentsSelected}
            value={this.state.environmentIds ? this.state.environmentIds : []}
            items={this.state.availableEnvironments}
            autoFocus />,
            this.state.environmentIds && this.state.environmentIds.length === 0 ? <Callout
                key="callout"
                title="No Environments Selected"
                type={CalloutType.Information}>
                A tenant needs to be linked to an environment of a project before deployments can take place.
            </Callout> : null
        ];
    }

    renderNewProjectLink() {
        if (!this.state.projects) {
            return;
        }

        const projects = this.state.projects
                                .filter(p => !this.props.excludedProjects.includes(p.Id))
                                .map(p => ({value: p.Id, text: p.Name}));

        return <div>
            {projects && projects.length === 0
                ? <Callout type={CalloutType.Information} title="No Projects">
                    Create your first <InternalLink to={routeLinks.projects.root}>project</InternalLink> now.
                  </Callout>
                  :
                  [
                    <Select
                        onChange={this.handleProjectSelected}
                        label="Select a project"
                        value={this.state.project ? this.state.project.Id : null}
                        items={projects}
                        allowFilter={true}
                        autoFocus />,
                    this.state.project && (this.canProjectDeployTenanted()
                                           ? this.renderEnvironmentMultiSelect()
                                           : this.renderProjectUntenanted())
                  ]
            }
        </div>;
    }

    canProjectDeployTenanted() {
        return this.state.project && this.state.project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted;
    }

    save =  async  () => {
        return this.doBusyTask(async () => {
            let tenant = cloneDeep(this.props.tenant);
            tenant.ProjectEnvironments[this.state.project.Id] = this.state.environmentIds ? this.state.environmentIds : [];
            tenant = await repository.Tenants.save(tenant);
            setImmediate(() => this.props.onUpdated(tenant));
            return true;
        });
    }

    render() {
        const isExistingProjectLink = !!(this.props.existingProjectLink && this.props.existingProjectLink.projectId);
        const title = isExistingProjectLink && this.state.project ?
            `Change Connection to ${this.state.project.Name}` :
            "Connect to a Project";
        const updateButton = isExistingProjectLink ?
            "Update Connection" :
            "Add Connection";

        return <SaveDialogLayout title={title}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={this.save}
                                 savePermission={{permission: Permission.TenantEdit, tenant: this.props.tenant.Id}}
                                 saveButtonDisabled={!this.canProjectDeployTenanted()}
                                 saveButtonLabel={updateButton}>
            {isExistingProjectLink ? this.renderEnvironmentMultiSelect() : this.renderNewProjectLink()}
        </SaveDialogLayout>;
    }
}