import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonConfigurationTeamOverview, CommonConfigurationTeamTopics } from "../../CommonComponents/CommonConfigurationTeamViews";

export const ConfigurationTeamsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.teams.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationTeamOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationTeamTopics />;
    }
};
