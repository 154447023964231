import * as React from "react";
import { repository } from "clientInstance";
import { Text, required } from "components/form";
import {SpaceResource, UserResource, TeamResource} from "client/resources";
import Note from "../../../../components/form/Note/Note";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import UserMultiSelect from "../../../../components/MultiSelect/UserMultiSelect";
import TeamMultiSelect from "../../../../components/MultiSelect/TeamMultiSelect";
import ToolTip from "components/ToolTip/index";

interface AddSpaceDialogProps {
    onSpaceCreated(space: SpaceResource): void;
}

interface AddSpaceDialogState extends DataBaseComponentState {
    name: string;
    spaceManagersTeams: string[];
    usersToBeAddedToSpaceManagersTeam: string[];
    users: UserResource[];
    teams: TeamResource[];
}

export default class AddSpaceDialog extends DataBaseComponent<AddSpaceDialogProps, AddSpaceDialogState> {

    constructor(props: AddSpaceDialogProps) {
        super(props);
        this.state = {
            name: "",
            spaceManagersTeams: [],
            usersToBeAddedToSpaceManagersTeam: [],
            teams: [],
            users: [],
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const users = repository.Users.all();
            const teams =  repository.forSystem().Teams.all();

            this.setState({users: await users, teams: await teams});
        });
    }

    save = async () => {
        await this.doBusyTask(async () => {
            const space = await repository.Spaces.create({
                Name: this.state.name,
                SpaceManagersTeams: this.state.spaceManagersTeams,
                SpaceManagersTeamMembers: this.state.usersToBeAddedToSpaceManagersTeam,
                IsDefault: false,
                TaskQueueStopped: false
            });

            this.props.onSpaceCreated(space);
        });

        return false;
    }

    render() {
        return <SaveDialogLayout
            title="Add New Space"
            busy={this.state.busy}
            errors={this.state.errors}
            onSaveClick={this.save}>
            <div>
                <Text
                    value={this.state.name}
                    onChange={name => this.setState({name})}
                    label="New space name"
                    validate={required("Please enter a space name")}
                    autoFocus={true}
                />
                <h4>Select members and teams to be managers of this space</h4>
                <p>The members and teams selected will be added to the Space Manager team and will have
                    management permissions and access to everything in this space. System level concerns of Octopus are outside of this space.</p>
                <UserMultiSelect
                    label={"Select space managers (members)"}
                    items={this.state.users}
                    onChange={usersToBeAddedToSpaceManagersTeam => this.setState({usersToBeAddedToSpaceManagersTeam})}
                    value={this.state.usersToBeAddedToSpaceManagersTeam} />

                <TeamMultiSelect
                    label={"Select space managers (teams)"}
                    items={this.state.teams}
                    onChange={spaceManagersTeams => this.setState({spaceManagersTeams})}
                    value={this.state.spaceManagersTeams}/>
            </div>
        </SaveDialogLayout>;
    }
}
