export default class StringHelper {
    public static ellipsis = "...";

    public static capitalizeFirstLetter(value: string) {
        return value === null || typeof value === "undefined" || value.length === 0
        ? value
        : value.charAt(0).toUpperCase() + value.slice(1);
    }

    public static isNullOrWhiteSpace(value: string | null): boolean {
        return value === null || typeof value === "undefined" || value.trim().length === 0;
    }

    public static join(values: string[], separator: string) {
        return values.reduce((prev, cur) => prev + separator + cur);
    }

    public static formatDiff(diff: string) {
        if (!diff) {
            return diff;
        }
        const replaced = diff.replace(new RegExp("\r&para;", "g"), "");
        return replaced;
    }

    public static toOctopusStringBoolean(value: string | boolean) {
        const converted = this.parseBoolean(value);
        return converted ? "True" : "False";
    }

    public static parseBoolean(value: string | null | boolean): boolean {
        if (typeof value === "boolean") {
            return value;
        } else if (!value) {
            return false;
        }

        switch (value.trim().toLocaleLowerCase()) {
            case "true": return true;
            case "false": return false;
        }

        return false;
    }

    public static isBooleanString(value: string): boolean {
        return /[\s]*(true|false)[\s]*/gi.test(value);
    }
}