import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText, TermsText } from "./CommonViews";
import { LearnMoreResources, TopicsContainer } from "./CommonViews";
import Note from "../../../../form/Note/Note";
const HelpImageTenants = require("../../Images/Tenant.svg");
import ImageWithPlaceholder from "components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import routeLinks from "../../../../../routeLinks";
import InternalLink from "../../../../Navigation/InternalLink/InternalLink";

export const CommonTenantOverviewViews: React.StatelessComponent<{}> = props => {
    return <OverviewContainer>
        <OverviewHighlightText>
            <p>Use <ExternalLink href="MultiTenantDeployments">tenants</ExternalLink> to deploy your projects to multiple end-customers, with the software tailored to each specific customer.</p>
            <ImageWithPlaceholder src={HelpImageTenants} alt={"Tenants"} /> {/*//TODO - Jess - I think we need a better Tenant definition here.*/}
        </OverviewHighlightText>
        <TermsText>
            <Note heading={"Connecting Projects"}>
                By connecting tenants to projects you can control which projects will be deployed into which environments. Projects will need to be enabled for multi-tenancy and linked to environments.
            </Note>
            <Note heading={"Tenant Tag Sets"}>
                Tagging lets you deal with tenants in groups instead of as individuals. You might have a tenant tag set called `Tenant importance` with tags `VIP`, `Standard` and `Trial`.
                At deployment time, each of these groups will get a different version of your software. Modify your tag sets in the <InternalLink to={routeLinks.library.tagSets.root}>library</InternalLink>.
            </Note>
            <Note heading={"Tenant Variables"}>Tenants inherit Common Variables, values that already exist per project, and Project Variable that need values defined for each tenant.</Note>
        </TermsText>
    </OverviewContainer>;
};

export const CommonTenantTopics: React.StatelessComponent<{}> = props => {
    return <TopicsContainer>
        <LearnMoreResources>
            <div><ExternalLink href="MultiTenantDeployments">Multi-tenant Deployments</ExternalLink></div>
        </LearnMoreResources>
    </TopicsContainer>;
};