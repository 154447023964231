import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
const image = require("../../Images/Triggers.svg");
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
import { TopicsContainer, LearnMoreResources } from "./CommonViews";
import ImageWithPlaceholder from "components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";

export const CommonProjectTriggerOverview: React.StatelessComponent<{}> = props => {
    return <OverviewContainer>
        <OverviewHighlightText>
            <p>
                <ExternalLink href="ProjectTriggers">Triggers</ExternalLink> allow you to automate your deployments
                by responding to events such as changes in your deployment targets or time-based schedules.
            </p>
            <p>
                Choose from a subset of events that can occur in Octopus Deploy, apply a filter to those events, and decide on an action you want performed once the trigger fires.
            </p>
            <ImageWithPlaceholder src={image} alt={"Deployment process"} />
        </OverviewHighlightText>
    </OverviewContainer>;
};

export const CommonProjectTriggerTopics: React.StatelessComponent<{}> = props => {
    return <TopicsContainer>
        <LearnMoreResources>
            <div><ExternalLink href="ProjectTriggers">Project Triggers</ExternalLink></div>
        </LearnMoreResources>
    </TopicsContainer>;
};