import * as React from "react";
import Text from "components/form/Text/Text";
import { Section } from "components/Section/Section";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import CopyToClipboard from "components/CopyToClipboardButton";
import CustomSaveDialogLayout, { CustomSaveDialogTitleBar } from "components/DialogLayout/Custom/CustomSaveDialogLayout";
import { RenderProps } from "components/Dialog/CustomDialog";
import { CustomDialogActions, CustomFlexDialogContent, CustomSaveDialogActions, SmallDialogFrame } from "components/DialogLayout/Custom";

interface AddApiKeyState extends DataBaseComponentState {
    purpose?: string;
}

interface AddApiKeyProps extends RenderProps {
    apiKey?: string;
    onAdd(purpose: string): Promise<any>;
    onClose(): void;
}

class AddApiKeyDialogLayout extends DataBaseComponent<AddApiKeyProps, AddApiKeyState> {
    constructor(props: AddApiKeyProps) {
        super(props);
        this.state = {
        };
    }

    render() {
        return <CustomSaveDialogLayout
            {...this.props}
            frame={SmallDialogFrame}
            close={this.props.close}
            open={this.props.open}
            renderTitle={() => <CustomSaveDialogTitleBar title="Generate New API Key" />}
            busy={this.state.busy}
            errors={this.state.errors}
            onSaveClick={() => this.saveAndShowKey()}
            renderActions={(renderProps) => (
                <CustomDialogActions actions={
                    <CustomSaveDialogActions
                        close={renderProps.close}
                        saveButtonLabel={this.props.apiKey ? "Close" : "Generate New"}
                        hideCancel={!!this.props.apiKey}
                        onSaveClick={renderProps.onSaveClick}
                        savePermission={renderProps.savePermission} />}
                />
            )}
            renderContent={(renderProps) => (
                <CustomFlexDialogContent>
                    {this.props.apiKey ? this.renderResult() : this.renderSetupApiKey()}
                </CustomFlexDialogContent>
            )}
        />;
    }

    private saveAndShowKey = async () => {
        if (this.props.apiKey) {
            this.props.onClose();
            return true;
        }

        await this.props.onAdd(this.state.purpose);
        return false;
    }

    private renderResult() {
        return <div>
            <Section>
                Your new API key is:
            </Section>
            <Section>
                <strong>{this.props.apiKey}</strong><CopyToClipboard value={this.props.apiKey} />
            </Section>
            <Section>
                API keys cannot be retrieved once they are created. Make sure you save this key in a safe place like a password management tool.
            </Section>
        </div>;
    }

    private renderSetupApiKey() {
        return <div>
            <Section>
                API keys can be used to authenticate with the Octopus Deploy REST API in place of a username and password.
                Using API keys lets you keep your username and password secret, but the API key itself is still sensitive
                information that needs to be protected.
            </Section>
            <Section>
                <Text
                    value={this.state.purpose}
                    onChange={(purpose) => this.setState({ purpose })}
                    autoFocus={true}
                    label="Purpose" />
            </Section>
            <Section>
                Record the purpose of this key so that you can revoke it when no longer required.
            </Section>
        </div>;
    }
}

export default AddApiKeyDialogLayout;