import * as React from "react";
import { repository } from "clientInstance";
import * as tenantTagsets from "components/tenantTagsets";
import SidebarLayout from "components/SidebarLayout/SidebarLayout";
import { TenantResource, TagSetResource, Permission } from "client/resources";
import ProjectEnvironmentSelection from "./AddProjectsToTenantDialog";
import { Section, SectionHeadingType } from "components/Section/Section";
import { Callout, CalloutType } from "components/Callout";
import { tenantsActions } from "../tenantsArea";
import { connect } from "react-redux";
import { TenantMissingVariableResource } from "../../../client/resources/tenantMissingVariablesResource";
import { ActionButtonType } from "components/Button";
import InternalLink from "components/Navigation/InternalLink";
import routeLinks from "../../../routeLinks";
import PaperLayout from "../../../components/PaperLayout/PaperLayout";
import OpenDialogButton from "../../../components/Dialog/OpenDialogButton";
import { SimpleList } from "../../../components/List/SimpleList";
import OverflowMenu from "../../../components/Menu/OverflowMenu";
import { Dictionary, keyBy } from "lodash";
import Logo from "../../../components/Logo/Logo";
import { ProjectResource } from "../../../client/resources/projectResource";
import { environmentChipList } from "../../../components/Chips/index";
import ToolTip from "../../../components/ToolTip/ToolTip";
import * as cn from "classnames";
import { EnvironmentResource } from "../../../client/resources/environmentResource";
import { RouteComponentProps } from "react-router";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import { DataBaseComponent, DataBaseComponentState } from "../../../components/DataBaseComponent/DataBaseComponent";
import Tag from "../../../components/Tag/Tag";
import AddTagsToTenantDialog from "./AddTagsToTenantDialog";
import Markdown from "components/Markdown/index";
import Note from "components/form/Note/Note";
import { NoResults } from "components/NoResults/NoResults";

const styles = require("./style.less");

interface TenantOverviewState extends DataBaseComponentState {
    tenant?: TenantResource;
    tagSets?: TagSetResource[];
    projects?: Dictionary<ProjectResource>;
    environments?: EnvironmentResource[];
    selectedProjectId?: string;
    projectToRemove?: ProjectResource;
    projectToEdit?: ProjectResource;
}

interface TenantOverviewInternalProps extends RouteComponentProps<{ tenantId: string }> {
    onTenantVariablesFetched: (tenantMissingVariables: TenantMissingVariableResource) => void;
}

class LinkedProjectEnvironmentList extends SimpleList<{ project: ProjectResource, environmentIds: string[] }> { }

export class TenantOverviewInternal extends DataBaseComponent<TenantOverviewInternalProps, TenantOverviewState> {
    tenantId: string = null;
    constructor(props: TenantOverviewInternalProps) {
        super(props);

        this.state = {
            tenant: null
        };

        this.tenantId = this.props.match.params.tenantId;
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const [tenant, tagSets, projects, environments] = await Promise.all([
                repository.Tenants.get(this.tenantId),
                tenantTagsets.getAll(),
                repository.Projects.all(),
                repository.Environments.all()]);
            this.setState({
                tenant,
                tagSets,
                projects: keyBy(projects, p => p.Id),
                environments
            });
        });
    }

    handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            let tenant: TenantResource = {
                ...this.state.tenant,
                // ProjectEnvironments: this.state.model.projectEnvironments
            };
            tenant = await repository.Tenants.save(tenant);
            this.setState({ tenant });

            const variables = await repository.Tenants.missingVariables({ tenantId: tenant.Id }, false);
            this.props.onTenantVariablesFetched(variables.find(t => t.TenantId === tenant.Id));
        });
    }

    renderEnvironmentItems = (project: ProjectResource) => {
        const environmentIds = this.state.tenant.ProjectEnvironments[project.Id];
        if (!environmentIds || !environmentIds.length) {
            return <div className={styles.noEnvironmentsWarning}>
                <ToolTip content={`You will not be able to deploy to ${project.Name} until you connect to one of the environments.`}>
                    <em className={cn("fa fa-warning", styles.noEnvironmentsWarningIcon)} />
                </ToolTip>Not connected to any environments</div>;
        }

        return environmentChipList(this.state.environments, environmentIds);
    }

    renderLinkedProjects(): React.ReactNode {
        if (!this.state.tenant.ProjectEnvironments) {
            return null;
        }

        const items = Object.keys(this.state.tenant.ProjectEnvironments)
            // need to filter out projects that were not returned due to permissions
            .filter(p => !!this.state.projects[p])
            .map(p => ({ project: this.state.projects[p], environmentIds: this.state.tenant.ProjectEnvironments[p] }))
            .sort((a, b) => a.project && b.project && a.project.Name.localeCompare(b.project.Name));

        if (items.length === 0) {
            return null;
        }

        // user may not be able to see some of these projects, if they *can't see any* show a callout
        if (items.filter(i => !!i.project).length === 0) {
            return <div className={styles.noProjectAccess}>
                <Callout type={CalloutType.Information} title={"Insufficient permissions"}>
                    Your Project permissions do not allow you to see any of the Linked Projects.
                </Callout>
            </div>;
        }

        return <LinkedProjectEnvironmentList
            disableListItem={true}
            onFilter={(filter, item) => item.project.Name.toLowerCase().includes(filter.toLowerCase())}
            items={items} onRow={(item) => {
                if (!item.project) {
                    return null;
                }
                return <div className={styles.linkedProject}>
                    <div className={styles.header}>
                        <div className={styles.projectName}>
                            <Logo url={item.project.Links.Logo} />
                            <InternalLink to={routeLinks.project(item.project.Slug).root}>
                                {item.project.Name}
                            </InternalLink>
                        </div>
                        <OverflowMenu menuItems={[
                            OverflowMenu.dialogItem("Edit", <ProjectEnvironmentSelection
                                tenant={this.state.tenant}
                                onUpdated={this.handleUpdatedProjectLink}
                                existingProjectLink={{
                                    projectId: item.project.Id,
                                    environmentIds: item.environmentIds
                                }}
                                excludedProjects={this.state.tenant ? Object.keys(this.state.tenant.ProjectEnvironments) : []} />,
                                { permission: Permission.TenantEdit, tenant: this.tenantId }),
                            OverflowMenu.item("Remove",
                                () => this.setState({ projectToRemove: item.project }),
                                { permission: Permission.TenantEdit, tenant: this.tenantId }),
                            OverflowMenu.navItem(`View Project Template Variables`,
                                routeLinks.project(item.project.Id).variables.projectTemplates,
                                undefined,
                                { permission: Permission.VariableView, wildcard: true }),
                            OverflowMenu.navItem(`View Common Template Variables`,
                                routeLinks.project(item.project.Id).variables.commonTemplates,
                                undefined,
                                { permission: Permission.VariableView, wildcard: true })]} />
                    </div>
                    <div className={styles.details}>
                        <div className={styles.environments}>
                            {this.renderEnvironmentItems(item.project)}
                        </div>
                    </div>
                </div>;
            }
            } />;
    }

    handleUpdatedProjectLink = async (tenant: TenantResource): Promise<boolean> => {
        return await this.doBusyTask(async () => {
            this.setState({ tenant });

            const variables = await repository.Tenants.missingVariables({ tenantId: tenant.Id }, false);
            this.props.onTenantVariablesFetched(variables.find(t => t.TenantId === tenant.Id));
            return true;
        });
    }

    handleRemoveProjectLink = async () => {
        return await this.doBusyTask(async () => {
            const projectId = this.state.projectToRemove.Id;
            this.setState({ projectToRemove: null });

            let tenant = await repository.Tenants.get(this.state.tenant.Id);
            delete tenant.ProjectEnvironments[projectId];

            tenant = await repository.Tenants.save(tenant);
            return await this.handleUpdatedProjectLink(tenant);
        });
    }

    connectProjectButton() {
        if (!this.state.tenant) {
            return null;
        }
        return <OpenDialogButton label="Connect Project"
            permission={{ permission: Permission.TenantEdit, tenant: this.tenantId }}
            type={ActionButtonType.Primary}>
            <ProjectEnvironmentSelection
                tenant={this.state.tenant}
                onUpdated={(tenant: TenantResource) => this.setState({ tenant })}
                excludedProjects={this.state.tenant ? Object.keys(this.state.tenant.ProjectEnvironments) : []} />
        </OpenDialogButton>;
    }

    linkedProjectMessage() {
        const projectCount = Object.keys(this.state.tenant.ProjectEnvironments).length;
        if (projectCount === 0) {
            return <div className={styles.notConnectedMessage}>
                <div>No projects are connected to this tenant.</div>
                <NoResults />
            </div>;
        }

        return <div><div>{projectCount} project{projectCount > 1 ? "s" : ""} can deploy to this tenant.</div>
        </div>;
    }

    renderTenantTagsSection() {
        if (!this.state.tenant) {
            return null;
        }
        const groupedTenantTags = tenantTagsets.groupAndOrderByTagSet(this.state.tenant.TenantTags, this.state.tagSets);

        return <div>
            <h4>Tag Sets</h4>
            {groupedTenantTags.map(groupedTenantTag => {
                const tagSet = this.state.tagSets.find(ts => ts.Name === groupedTenantTag.name);
                return <div key={groupedTenantTag.name} className={styles.tagSetContainer}>
                    {tagSet.Description ?
                        <ToolTip content={<Markdown markup={tagSet.Description} />}>
                            {this.tagSetName(groupedTenantTag.name)}
                        </ToolTip>
                        :
                        this.tagSetName(groupedTenantTag.name)
                    }
                    <div>
                        {groupedTenantTag.tags
                            .map(canonicalTagName => tagSet.Tags.find(t => t.CanonicalTagName === canonicalTagName))
                            .sort((a, b) => a.SortOrder - b.SortOrder)
                            .map(tag => {
                                return <Tag tagName={tag.Name}
                                    tagColor={tag.Color}
                                    key={tag.Name}
                                    description={tag.Description} />;
                            })}
                    </div>
                </div>;
            })}
            <OpenDialogButton label="Manage Tags">
                <AddTagsToTenantDialog
                    tenant={this.state.tenant}
                    onUpdated={(tenant: TenantResource) => this.setState({ tenant })}
                    tagSets={this.state.tagSets} />
            </OpenDialogButton>
        </div>;
    }

    render() {
        return <PaperLayout
            title="Overview"
            busy={this.state.busy}
            errors={this.state.errors}
            sectionControl={this.connectProjectButton()}>
            <SidebarLayout sideBar={this.renderTenantTagsSection()}>
                <DeleteDialog
                    title={"Unlink Tenant from Project"}
                    open={!!this.state.projectToRemove}
                    onClose={() => this.setState({projectToRemove: null})}
                    deleteButtonLabel="Remove"
                    onDeleteClick={this.handleRemoveProjectLink}
                    renderContent={() => this.state.projectToRemove &&
                    <p>
                        Are you sure you want to unlink <b>{this.state.tenant.Name}</b> from <b>{this.state.projectToRemove.Name}</b>?
                    </p>}
                />
                {this.state.tenant && <Section sectionHeader="Projects" headingType={SectionHeadingType.Heading4}>
                    {this.linkedProjectMessage()}
                    {this.renderLinkedProjects()}
                </Section>}
            </SidebarLayout>
        </PaperLayout>;
    }

    private tagSetName(name: string) {
        return <strong>{name}</strong>;
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        onTenantVariablesFetched: (tenantMissingVariables: TenantMissingVariableResource) => {
            dispatch(tenantsActions.tenantMissingVariablesFetched(tenantMissingVariables));
        },
    };
};

export default connect<{}, {}, any>(
    null,
    mapDispatchToProps
)(TenantOverviewInternal);
