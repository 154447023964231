import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
import { TopicsContainer, LearnMoreResources } from "./CommonViews";

export const CommonInfrastructureAccountOverview: React.StatelessComponent<{}> = props => {
    return <OverviewContainer>
        <OverviewHighlightText>
            <p><ExternalLink href="OnboardingAccountsLearnMore">Accounts</ExternalLink> help you to centralise account details used during your deployments,
                including things like username/password, tokens, Azure and AWS credentials and SSH key pairs.</p>
        </OverviewHighlightText>
    </OverviewContainer>;
};

export const CommonInfrastructureAccountTopics: React.StatelessComponent<{}> = props => {
    return <TopicsContainer>
        <LearnMoreResources>
            <div><ExternalLink href="OnboardingAccountsLearnMore">Accounts</ExternalLink></div>
        </LearnMoreResources>
    </TopicsContainer>;
};