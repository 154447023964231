import * as React from "react";
import { UserResource } from "client/resources";
import * as cn from "classnames";
import Popover from "material-ui/Popover";
import Menu from "material-ui/Menu";
import { Avatar } from "components/Avatar/Avatar";
import ExternalLink from "../Navigation/ExternalLink";
import MenuItem from "material-ui/MenuItem";
import { repository } from "clientInstance";
import { secondaryText, primaryDarkest, primary } from "theme/colors";
const styles = require("./style.less");
import Divider from "../Divider/Divider";
import InternalLink from "../Navigation/InternalLink/InternalLink";
import routeLinks from "../../routeLinks";
import { isSpaceNotFound, SpaceContext } from "../StandardLayout/SpaceLoader";
import HelpOutlineIcon from "material-ui/svg-icons/action/help-outline";
import { Note } from "components/form";
import * as MediaQuery from "react-responsive";
import { drawerBreakpointWidth } from "components/Drawer/DrawerWrapperLayout";
import { CommonNeedHelpInformation } from "components/Drawer/PageHelpRegistry/Registrations/CommonComponents/CommonViews";

interface UserAccountMenuState {
    isMenuOpen: boolean;
    version: string;
    versionHasLongTermSupport: boolean;
}

interface UserAccountMenuProps {
    currentUser: UserResource;
    spaceContext: SpaceContext;
}

export default class UserAccountMenu extends React.Component<UserAccountMenuProps, UserAccountMenuState> {
    private menuButton: HTMLElement;

    constructor(props: UserAccountMenuProps) {
        super(props);
        this.state = {
            isMenuOpen: false,
            version: "",
            versionHasLongTermSupport: false
        };
    }

    componentDidMount() {
        const serverInformation = repository.getServerInformation();
        const version = serverInformation.version;
        const versionHasLongTermSupport = serverInformation.versionHasLongTermSupport;
        this.setState({ version, versionHasLongTermSupport });
    }

    render() {
        const meLink = isSpaceNotFound(this.props.spaceContext)
            ? routeLinks.withoutSpace().currentUser.me // Redirect back to `/` (no space prefix) to ensure we get redirected to an appropriate space context first
            : routeLinks.currentUser.me;
        return <div>
            <a className={cn(styles.button)}
                onClick={this.handleTouchTap}
                href="#"
                ref={this.setRef}>
                <Avatar avatarLink={this.props.currentUser && this.props.currentUser.Links && this.props.currentUser.Links.Avatar}
                    isService={this.props.currentUser && this.props.currentUser.IsService}
                    size={24}
                />
                <span className={cn(styles.userName, "hide-sm")}>
                    {this.props.currentUser.DisplayName}
                </span>
                <em className={cn("fa fa-caret-down", styles.icon)} />
            </a>
            <Popover
                open={this.state.isMenuOpen}
                anchorEl={this.menuButton}
                onRequestClose={this.handleRequestClose}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                targetOrigin={{ horizontal: "right", vertical: "top" }}>
                <Menu>
                    <MenuItem style={{ color: secondaryText, fontSize: "0.875rem" }} disabled={true}>
                        {<ExternalLink href={"https://octopus.com/downloads/" + this.state.version}>v{this.state.version}{this.state.versionHasLongTermSupport ? " LTS" : ""}</ExternalLink>}
                    </MenuItem>
                    <Divider />
                    <InternalLink to={routeLinks.currentUser.signOut} onClick={this.handleRequestClose}><MenuItem primaryText="Sign Out" /></InternalLink>
                    <InternalLink to={meLink} onClick={this.handleRequestClose}><MenuItem primaryText="Profile" /></InternalLink>
                    <MediaQuery minWidth={drawerBreakpointWidth}>
                        {(matches: boolean) => {
                            if (matches) {
                                return <>
                                    <Divider />
                                    <MenuItem style={{ color: secondaryText, fontSize: "0.875rem", whiteSpace: "normal", width: "15rem", lineHeight: "1rem" }} disabled={true}>
                                        <h4>Need help?</h4>
                                        <CommonNeedHelpInformation />
                                    </MenuItem>
                                </>;
                            } else {
                                return <>
                                    <Divider />
                                    <ExternalLink href="DocumentationHome" showIcon={false}>
                                        <MenuItem style={{ color: primary }} onClick={this.handleRequestClose} primaryText="Documentation" />
                                    </ExternalLink>
                                    <ExternalLink href={window.location.origin + window.location.pathname + "/../swaggerui/"} showIcon={false}>
                                        <MenuItem style={{ color: primary }} onClick={this.handleRequestClose} primaryText="API Documentation" />
                                    </ExternalLink>
                                    <ExternalLink href="HelpGeneral" showIcon={false}>
                                        <MenuItem style={{ color: primary }} onClick={this.handleRequestClose} primaryText="Support" />
                                    </ExternalLink>
                                    <ExternalLink href="TrainingVideos" showIcon={false}>
                                        <MenuItem style={{ color: primary }} onClick={this.handleRequestClose} primaryText="Training Videos" />
                                    </ExternalLink>
                                    <ExternalLink href="HelpMakeSuggestion" showIcon={false}>
                                        <MenuItem style={{ color: primary }} onClick={this.handleRequestClose} primaryText="Suggestions" />
                                    </ExternalLink>
                                    <ExternalLink href="https://octopus.com/downloads" showIcon={false}>
                                        <MenuItem style={{ color: primary }} onClick={this.handleRequestClose} primaryText="Downloads" />
                                    </ExternalLink>
                                </>;
                            }
                        }}
                    </MediaQuery>
                </Menu>
            </Popover>
        </div>;
    }

    private setRef = (el: HTMLElement) => {
        this.menuButton = el;
    }

    private handleTouchTap = (event: any) => {
        event.preventDefault();

        this.setState({
            isMenuOpen: !this.state.isMenuOpen
        });
    }

    private handleRequestClose = () => {
        this.setState({
            isMenuOpen: false,
        });
    }
}