import * as React from "react";
import { values } from "lodash";
import { CommunicationStyle } from "client/resources";
import { orderBy } from "lodash";

export { CommunicationStyle };

export interface DisplayOrder {
    displayOrder: number;
}

export interface CategoryDefinition extends DisplayOrder {
    category: string;
    title: React.ReactNode;
    help?: React.ReactNode;
}

export type TentacleType = CommunicationStyle.TentacleActive | CommunicationStyle.TentaclePassive;

export type MachineTypeRegistration = {
    communicationStyle: CommunicationStyle.Ssh | TentacleType;
    discoverable: boolean;
};

export type CategorizedMachineRegistration = MachineTypeRegistration & CategorizedEndpointRegistration;
export type SimpleMachineRegistration = MachineTypeRegistration & SimpleEndpointRegistration;
export type MachineRegistration = SimpleMachineRegistration | CategorizedMachineRegistration;
export type DeploymentTargetRegistration = CategorizedEndpointRegistration | SimpleEndpointRegistration;

export enum EndpointSelectionScope {
    Worker = "Worker",
    DeploymentTarget = "Deployment Target"
}

export enum EndpointRegistrationKey {
    CloudRegion = "CloudRegion",
    TentaclePassiveWindows = "TentaclePassiveWindows",
    TentaclePassiveLinux = "TentaclePassiveLinux",
    TentacleActiveWindows = "TentacleActiveWindows",
    TentacleActiveLinux = "TentacleActiveLinux",
    Ssh = "Ssh",
    OfflineDrop = "OfflineDrop",
    AzureWebApp = "AzureWebApp",
    Ftp = "Ftp",
    AzureCloudService = "AzureCloudService",
    AzureServiceFabricCluster = "AzureServiceFabricCluster",
    AzureVmExtension = "AzureVmExtension",
    Kubernetes = "Kubernetes"
}

interface RenderRegistrationNavigationProps {
    onNavigate?: () => void;
}
export interface RenderRegistrationCardProps {
    category: CategoryDefinition;
    registration: CategorizedEndpointRegistration;
    getNavigationProps: (props?: RenderRegistrationNavigationProps) => RenderRegistrationNavigationProps;
    scope: EndpointSelectionScope;

}

export interface CategorizedEndpointRegistration extends SimpleEndpointRegistration {
    categories: CategoryDefinition[];
    renderCard: (props: RenderRegistrationCardProps) => React.ReactElement<any>;
}

export interface SimpleEndpointRegistration extends DisplayOrder {
    key: EndpointRegistrationKey;
    name: string;
    communicationStyle?: CommunicationStyle;
    renderDialogView?: (renderProps: {className: string}) => React.ReactElement<any>;
}

export interface CategorizedEndpointResult {
    category: CategoryDefinition;
    endpoints: CategorizedEndpointRegistration[];
}

export type EndpointRegistration = CategorizedEndpointRegistration | SimpleEndpointRegistration;

class EndpointRegistry {
    private deploymentTargets: Record<string, DeploymentTargetRegistration> = {};
    private categories: Record<string, CategoryDefinition> = {};
    private machines: Record<string, MachineRegistration> = {};

    registerCategory(category: CategoryDefinition) {
        if (!this.categories.hasOwnProperty(category.category)) {
            this.categories[category.category] = category;
        }
    }

    registerCategories(categories: CategoryDefinition[]) {
        for (const category of categories) {
            this.registerCategory(category);
        }
    }

    getCategory(name: string) {
        return this.categories[name];
    }

    getAllCategories() {
        return orderBy(values(this.categories), ["displayOrder", "category"]);
    }

    getDeploymentTarget(key: EndpointRegistrationKey) {
        return this.deploymentTargets[key];
    }

    getMachine(key: EndpointRegistrationKey) {
        return this.machines[key];
    }

    getEndpoint(key: EndpointRegistrationKey) {
        return this.getDeploymentTarget(key) || this.getMachine(key);
    }

    registerEndpoint(registration: DeploymentTargetRegistration | MachineRegistration) {
        if (this.isCategorizedEndpoint(registration)) {
            this.registerCategories(registration.categories);
        }

        if (this.isMachineRegistration(registration)) {
            this.machines[registration.key] = registration;
        } else {
            this.deploymentTargets[registration.key] = registration;
        }
    }

    getAllRegistrations(): EndpointRegistration[] {
        return [...this.getAllMachines(), ...this.getAllDeploymentTargets()];
    }

    getAllMachines(): MachineRegistration[] {
        return values(this.machines);
    }

    getAllDeploymentTargets(): DeploymentTargetRegistration[] {
        return values(this.deploymentTargets);
    }

    isMachineRegistration(item: EndpointRegistration): item is MachineRegistration {
        switch (item.communicationStyle) {
            case CommunicationStyle.TentacleActive:
            case CommunicationStyle.TentaclePassive:
            case CommunicationStyle.Ssh:
                return true;
            default:
                return false;
        }
    }

    isCategorizedEndpoint(item: EndpointRegistration): item is CategorizedEndpointRegistration {
        const endpoint = item as CategorizedEndpointRegistration;
        return endpoint.categories !== undefined;
    }

    categorizeEndpoints(endpoints: EndpointRegistration[]): Record<string, CategorizedEndpointResult> {
        const categorizedEndoints = endpoints.filter(this.isCategorizedEndpoint);

        return categorizedEndoints.reduce((prev: Record<string, CategorizedEndpointResult> , current: CategorizedEndpointRegistration) => {
            const result = {...prev};
            current.categories.forEach(x => (
                result[x.category] = {
                    category: x,
                    endpoints: [ ...((prev[x.category] && prev[x.category].endpoints) || []), ...[current]]
                }));
            return result;
        }, {});
    }
}

const registry = new EndpointRegistry();
export default registry;