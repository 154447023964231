import * as React from "react";
import { useEffect, useMemo } from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import { Theme } from "theme";
import ToolTip from "components/ToolTip";
import * as cn from "classnames";
import { keyBy } from "lodash";
import { TabItemProps } from "components/Tabs/TabItem";

const styles = (theme: Theme) => createStyles({
    warning: {
        color: theme.palette.status.danger,
        paddingLeft: "0.25rem"
    }
});

const TabLabel: React.FC<{ label: React.ReactNode }> = ({ label }) => (
    <span>{label}</span>
);

interface WarningTabLabelProps extends WithStyles<typeof styles> {
    label: React.ReactNode;
    warning: string;
}

const WarningTabLabel: React.FC<WarningTabLabelProps> = ({ label, warning, classes }) => (
    <div>
        <span>{label}</span>
        <ToolTip content={warning}><em className={cn("fa", "fa-warning", classes.warning)} /></ToolTip>&nbsp;
    </div>
);

const EnhancedWarningTabLabel = withStyles(styles)(WarningTabLabel);
export type RenderTabCallback = (props: RenderTabProps) => React.ReactNode;

export interface RenderTabProps {
    label: React.ReactNode;
    value: string;
}

const convertTab = (tab: React.ReactElement<any>, fallbackValue: string, renderTab: RenderTabCallback) => {
    const { label, warning, value = fallbackValue, children, onActive }: TabItemProps = tab.props;
    const tabLabel =  warning ? <EnhancedWarningTabLabel label={label} warning={warning}/> : <TabLabel label={label}/>;

    const control = renderTab({value, label: tabLabel });

    return {
        content: children,
        value,
        control,
        onActive
    };
};

export const useTabs = (children: React.ReactNode, value: string, renderTab: RenderTabCallback) => {
    const {tabs, lookup} = useMemo(() => {
        const validElements = React.Children.toArray(children).filter(React.isValidElement);
        const converted = validElements.map((tab, index) => convertTab(tab, index.toString(), renderTab));
        return { lookup: keyBy(converted, (t) => t.value), tabs: converted.map(x => x.control)};
    }, [children]);

    const { content, onActive } = lookup[value] || { content: null, onActive: null };

    useEffect(() => {
        if (onActive) {
            onActive();
        }
    }, [value]);

    return {
        tabs,
        value,
        content
    };
};
