import { DeploymentTargetResource, TenantResource, EnvironmentResource, ProxyResource, CertificateConfigurationResource, AccountResource, Permission, TenantedDeploymentMode, Resource } from "client/resources";
import BaseMachineSettingsLayout, { BaseMachineSettingsProps, BaseMachineSettingsState, MachineSettingsRouteParams } from "areas/infrastructure/components/BaseMachineSettings/BaseMachineSettings";
import { MapStateToProps, connect } from "react-redux";
import { Dispatch, Action } from "redux";
import { RouteComponentProps } from "react-router";
import GlobalState from "globalState";
import routeLinks from "routeLinks";
import MachineIconHelper from "utils/MachineIconHelper/MachineIconHelper";
import { machineActions } from "../../reducers/machines";
import { repository } from "clientInstance";
import EndpointsHelper, { EndpointCommunicationStyle } from "utils/EndpointsHelper/EndpointsHelper";
import PermissionCheck, { isAllowed } from "components/PermissionCheck/PermissionCheck";
import * as React from "react";
import { FormSectionHeading, ExpandableFormSection, Note } from "components/form";
import { EnvironmentMultiSelect, RoleMultiSelect } from "components/MultiSelect";
import Summary, { SummaryNode } from "components/form/Sections/Summary";
import { environmentChipList, RoleChip } from "components/Chips";
import TenantedDeploymentParticipationSelector from "components/TenantedDeploymentParticipationSelector";
import { AdvancedTenantsAndTenantTagsSelector } from "components/AdvancedTenantSelector";
import CommonSummaryHelper from "utils/CommonSummaryHelper/CommonSummaryHelper";
import * as URI from "urijs";
import endpointRegistry from "../MachineSettings/Endpoints/endpointRegistry";
import { BreadcrumbProps } from "components/PaperLayout/PaperLayout";

interface DeploymentTargetSettingsProps extends BaseMachineSettingsProps<DeploymentTargetResource> {}

interface DeploymentTargetSettingsState extends BaseMachineSettingsState<DeploymentTargetResource> {
    tenants: TenantResource[];
    machineRoles: string[];
    environments: EnvironmentResource[];
    proxies: ProxyResource[];
    globalCertificate: CertificateConfigurationResource;
    accounts: AccountResource[];
    unavailableEnvironmentIds: string[];
    unavailableTenantIds: string[];
    communicationStyles: EndpointCommunicationStyle[];
}

class DeploymentTargetSettingsLayoutInternal extends BaseMachineSettingsLayout<DeploymentTargetSettingsProps, DeploymentTargetSettingsState, DeploymentTargetResource> {
    constructor(props: DeploymentTargetSettingsProps) {
        super(props);
    }

    protected initialState(): DeploymentTargetSettingsState {
        return {
            deleted: false,
            newId: null,
            machinePolicies: null,
            machinePolicy: null,
            tenants: null,
            machineRoles: null,
            environments: null,
            proxies: null,
            globalCertificate: null,
            accounts: null,
            workerPools: [],
            unavailableEnvironmentIds: [],
            unavailableTenantIds: [],
            communicationStyles: EndpointsHelper.communicationStyles,
        };
    }
    protected enableDisablePermission(): Permission { return Permission.MachineEdit; }
    protected createPermission(): Permission { return Permission.MachineCreate; }
    protected editPermission(): Permission { return Permission.MachineEdit; }
    protected deletePermission(): Permission { return Permission.MachineDelete; }

    protected machineLink(machineId: string): string { return routeLinks.infrastructure.machine(machineId).root; }

    protected async loadData(): Promise<Pick<DeploymentTargetSettingsState, any>> {
        const tenantsPromise = repository.Tenants.all();
        const machineRolesPromise = repository.MachineRoles.all();
        const environmentsPromise = repository.Environments.all();
        const machinePoliciesPromise = repository.MachinePolicies.all();
        const proxiesPromise = repository.Proxies.all();
        const globalCertificatePromise = isAllowed({permission: Permission.MachineEdit, wildcard: true}) ? repository.CertificateConfiguration.global() : Promise.resolve(null);
        const accountsPromise = repository.Accounts.all();
        const workerPoolsPromise = repository.WorkerPools.all();

        return {
            tenants: await tenantsPromise,
            machineRoles: await machineRolesPromise,
            environments: await environmentsPromise,
            machinePolicies: await machinePoliciesPromise,
            proxies: await proxiesPromise,
            globalCertificate: await globalCertificatePromise,
            accounts: await accountsPromise,
            workerPools: await workerPoolsPromise
        };
    }

    protected configureNewMachine(): DeploymentTargetResource {
        const query = URI(this.props.location.search).search(true);
        const environmentId = query.environment;
        return {
            ...super.configureNewMachine(),
            TenantedDeploymentParticipation: TenantedDeploymentMode.Untenanted,
            Roles: [] as any[],
            EnvironmentIds: environmentId ? [environmentId] : [],
            TenantIds: [] as string[],
            TenantTags: [] as any[],
        };
    }

    protected renderTypeSpecificComponents(): JSX.Element {
        return <div>
            <FormSectionHeading title="Deployment"/>

            <ExpandableFormSection
                errorKey="EnvironmentIds"
                title="Environments"
                summary={this.environmentsSummary()}
                help={"Choose at least one environment for the deployment target."}>
                <EnvironmentMultiSelect
                    items={this.state.environments}
                    onChange={EnvironmentIds => this.setModelState({EnvironmentIds})}
                    value={this.state.model.EnvironmentIds}/>
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey="Roles"
                title="Target Roles"
                summary={this.rolesSummary()}
                help={"Choose at least one role that this deployment target will provide."}>
                <RoleMultiSelect
                    onChange={Roles => this.setModelState({Roles})}
                    value={this.state.model.Roles}
                    canAdd={true}
                    label="Roles (type to add new)"
                    items={this.state.machineRoles}/>
                <Note>Target roles allow you to "tag" deployment targets with a specific keyword which can be used in your deployments.</Note>
            </ExpandableFormSection>
        </div>;
    }

    protected renderTenantComponent(): JSX.Element {
        if (!(this.props.isMultiTenancyEnabled || this.state.cleanModel.TenantedDeploymentParticipation !== TenantedDeploymentMode.Untenanted)) {
            return null;
        }

        return <PermissionCheck permission={Permission.TenantView} tenant="*">
                <FormSectionHeading title="Restrictions"/>
                <ExpandableFormSection
                    errorKey="TenantedDeploymentParticipation"
                    title="Tenanted Deployments"
                    summary={this.tenantDeploymentModeSummary()}
                    help={"Choose the kind of deployments where this deployment target should be included."}>
                    <TenantedDeploymentParticipationSelector
                        tenantMode={this.state.model.TenantedDeploymentParticipation}
                        resourceTypeLabel="deployment target"
                        onChange={x => this.setModelState({TenantedDeploymentParticipation: x as TenantedDeploymentMode})}
                    />
                </ExpandableFormSection>
                {this.state.model.TenantedDeploymentParticipation !== TenantedDeploymentMode.Untenanted &&
                <ExpandableFormSection
                    errorKey="Tenants"
                    title="Associated Tenants"
                    summary={this.tenantSummary()}
                    help={"Choose tenants this deployment target should be associated with."}>
                    <AdvancedTenantsAndTenantTagsSelector
                        tenants={this.state.tenants}
                        selectedTenantIds={this.state.model.TenantIds}
                        selectedTenantTags={this.state.model.TenantTags}
                        doBusyTask={this.doBusyTask}
                        onChange={(TenantIds, TenantTags) => this.setModelState({TenantIds, TenantTags})}
                        showPreviewButton={true}
                    />
                </ExpandableFormSection>}
            </PermissionCheck>;
    }

    private tenantDeploymentModeSummary(): SummaryNode {
        return CommonSummaryHelper.tenantDeploymentModeSummary(
            this.state.model.TenantedDeploymentParticipation,
            this.state.model.TenantIds,
            this.state.model.TenantTags);
    }

    private tenantSummary() {
        return CommonSummaryHelper.tenantSummary(
            this.state.model.TenantIds,
            this.state.model.TenantTags,
            this.state.tenants);
    }

    private environmentsSummary() {
        return this.state.model.EnvironmentIds && this.state.model.EnvironmentIds.length > 0
            ? Summary.summary(environmentChipList(this.state.environments, this.state.model.EnvironmentIds))
            : Summary.placeholder("No environments");
    }

    private rolesSummary() {
        return this.state.model.Roles && this.state.model.Roles.length > 0
            ? Summary.summary(this.state.model.Roles
                .map(role => <RoleChip role={role} key={"role-" + role}/>))
            : Summary.placeholder("No roles");
    }
}

const mapStateToProps = (state: GlobalState, props: RouteComponentProps<MachineSettingsRouteParams>) => {
    const query = URI(props.location.search).search(true);
    const machineId = props.match && props.match.params ? props.match.params.machineId : null;

    const breadcrumbs: BreadcrumbProps = machineId ? {} : {
        breadcrumbPath: routeLinks.infrastructure.machines.new(query.environment),
        breadcrumbTitle: "New Deployment Target"
    };

    return {
        breadcrumbs,
        rootLink: routeLinks.infrastructure.machines.root,
        repository: repository.Machines,
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>) => {
    return {
        onMachineSaved: (machine: DeploymentTargetResource) => {
            const filename = MachineIconHelper.machineIconFilename(machine);
            dispatch(machineActions.machineSaved({
                id: machine.Id,
                name: machine.Name,
                machineIconFilename: filename,
                machineType: EndpointsHelper.getFriendlyName(machine.Endpoint.CommunicationStyle),
                isDisabled: machine.IsDisabled,
            }));
        }
    };
};

const DeploymentTargetSettingsLayout = connect<{}, {}, RouteComponentProps<MachineSettingsRouteParams>>(
    mapStateToProps,
    mapDispatchToProps
)(DeploymentTargetSettingsLayoutInternal);

export default DeploymentTargetSettingsLayout;
