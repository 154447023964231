import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonInfrastructureMachinePolicyOverview, CommonInfrastructureMachinePolicyTopics } from "../../CommonComponents/CommonInfrastructureMachinePolicyViews";

export const InfrastructureMachinePolicyRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.infrastructure.machinePolicy,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonInfrastructureMachinePolicyOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonInfrastructureMachinePolicyTopics />;
    }
};
