import * as React from "react";
import BaseComponent from "components/BaseComponent";
import CustomSaveDialogLayout from "components/DialogLayout/Custom/CustomSaveDialogLayout";
import {Errors} from "components/DataBaseComponent/DataBaseComponent";
import CodeEditor, {TextFormat} from "components/CodeEditor/CodeEditor";
import {DataContext, MetadataTypeCollection} from "client/resources/dynamicFormResources";
import {repository} from "clientInstance";
import CopyToClipboard from "components/CopyToClipboardButton";
import { CustomSaveDialogActions, CustomSaveDialogTitleBar } from "components/DialogLayout/Custom";
import { CustomDialogActions, CustomFlexDialogContent } from "components/DialogLayout/Custom";
import { CustomDialog, RenderProps } from "components/Dialog/CustomDialog";

interface SourceCodeDialogProps extends RenderProps {
    title?: string;
    template: string;
    textFormat?: TextFormat;
}

interface TemplateSourceCodeDialogProps extends SourceCodeDialogProps {
    metadata: string;
    saveDone(newTemplate: string, metadata: {Metadata: MetadataTypeCollection, Values: DataContext}): any;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}

interface TemplateSourceCodeDialogState {
    template: string;
    metadata: {Metadata: MetadataTypeCollection, Values: DataContext};
    errors?: Errors;
}

class TemplateSourceCodeDialog extends BaseComponent<TemplateSourceCodeDialogProps, TemplateSourceCodeDialogState> {
    private editor?: CodeEditor;

    constructor(props: TemplateSourceCodeDialogProps) {
        super(props);
        this.state = {
            errors: null,
            metadata: null,
            template: this.props.template
        };
    }

    getMetadata(template: string): Promise<{Metadata: MetadataTypeCollection, Values: DataContext}> {
        return repository.CloudTemplates.getMetadata(template, this.props.metadata);
    }

    componentWillReceiveProps(newprops: TemplateSourceCodeDialogProps) {
        if (!newprops.open) {
            this.setState({errors: null, template: newprops.template});
        }
    }

    async save() {
        try {
            const metadata = this.props.metadata ? await this.getMetadata(this.state.template) : null;
            this.props.saveDone(this.state.template, metadata);
            return true;
        } catch (err) {
            this.setState({errors: {message: err.ErrorMessage, details: err.Errors ? err.Errors.map((x: string) => x.toString()) : null, fieldErrors: {}}});
            return false;
        }
    }

    onFocusedEditorEscapePressed() {
        if (this.editor) {
            this.editor.blur();
        }
    }

    render() {
        return (
            <CustomDialog open={this.props.open} close={this.props.close} render={(props) => (
                <CustomSaveDialogLayout
                {...props}
                renderTitle={() => <CustomSaveDialogTitleBar title={this.props.title ? this.props.title : "Edit Source"} />}
                errors={this.state.errors}
                onSaveClick={() => this.save()}
                renderActions={(renderProps: any) => (
                <CustomDialogActions actions={
                    <CustomSaveDialogActions
                        close={renderProps.close}
                        onSaveClick={renderProps.onSaveClick}
                        savePermission={renderProps.savePermission}/>}
                    additionalActions={<CopyToClipboard value={this.state.template}/>}
                />
                )}
                busy={null}
                renderContent={(renderProps: any) => (
                    <CustomFlexDialogContent>
                        <CodeEditor value={this.state.template}
                            ref={e => this.editor = e}
                            language={this.props.textFormat || TextFormat.JSON}
                            allowFullScreen={false}
                            onChange={(template) => { this.setState({template}); }}
                            autoFocus={this.props.template == null || this.props.template === undefined || this.props.template.length === 0}
                            onEscPressed={() => this.onFocusedEditorEscapePressed()}
                        />
                    </CustomFlexDialogContent>
                )}/>
            )}/>
        );
    }
}

export default TemplateSourceCodeDialog;