import * as React from "react";
import { repository, client } from "clientInstance";
import * as tenantTagsets from "components/tenantTagsets";
import {
    SubscriptionResource,
    SubscriptionType,
    TimeSpanString,
    TeamResource,
    EnvironmentResource,
    ServerTimezoneResource,
    UserResource,
    ProjectResource,
    EventCategoryResource,
    EventGroupResource,
    DocumentTypeResource,
    TenantResource,
    EventNotificationSubscription,
    EventNotificationSubscriptionFilter,
    ServerConfigurationResource,
    isExistingSubscriptionResource,
    EventAgentResource,
    SmtpIsConfiguredResource,
    ProjectGroupResource,
} from "client/resources";
import {
    Text,
    ExpandableFormSection,
    Summary,
    FormSectionHeading,
    UnstructuredFormSection,
    required,
    Note,
    Checkbox,
    Select,
    RadioButton,
} from "components/form";
import { cloneDeep } from "lodash";
import EventFilterSummary from "components/EventFilter/EventFilterSummary";
import EventFilter, { EventFilterSelections } from "components/EventFilter";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import { RouteComponentProps } from "react-router";
import TimeSpanHelper from "utils/TimeSpanHelper";
import TimeSpanSelector from "components/form/TimeSpanSelector/TimeSpanSelector";
import ExternalLink from "../../../../components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import Callout, { CalloutType } from "components/Callout/Callout";
import TeamMultiSelect from "components/MultiSelect/TeamMultiSelect";
import * as URI from "urijs";
import OverflowMenu from "components/Menu/OverflowMenu";
import buildValueList from "components/EventFilter/buildValueList";
import { TagIndex } from "components/tenantTagsets";
import { arrayValueFromQueryString } from "utils/ParseHelper/ParseHelper";
import Permission from "client/resources/permission";
import StringHelper from "utils/StringHelper";
import routeLinks from "../../../../routeLinks";
import { TeamChip } from "components/Chips";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { EmailPriority } from "client/resources/emailPriority";
import RadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import { NewSubscriptionResource } from "client/resources/subscriptionResource";
import FormPage from "components/FormPage/FormPage";
import { FormBaseComponent, FormBaseComponentState } from "components/FormBaseComponent/FormBaseComponent";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import {SmtpNeedsConfigurationCallout} from "areas/configuration/components/Smtp/SmtpNeedsConfigurationCallout";

interface SubscriptionProps extends RouteComponentProps<SubscriptionRouteParams> {
    create?: boolean;
}

interface SubscriptionRouteParams {
    subscriptionId: string;
}

interface LookupData {
    environments: EnvironmentResource[];
    teams: TeamResource[];
    users: UserResource[];
    projects: ProjectResource[];
    projectGroups: ProjectGroupResource[];
    eventCategories: EventCategoryResource[];
    eventGroups: EventGroupResource[];
    eventAgents: EventAgentResource[];
    documentTypes: DocumentTypeResource[];
    tagIndex: TagIndex;
    tenants: TenantResource[];
    timezones: ServerTimezoneResource[];
    serverUri?: string;
    smtpIsConfigured?: SmtpIsConfiguredResource;
}

type Model = SubscriptionResource | NewSubscriptionResource;

interface SubscriptionData {
    subscription: SubscriptionResource | null;
    lookupData: LookupData;
    filterSelections: EventFilterSelections;
}

const SubscriptionFormPage = FormPage<SubscriptionData>();

const SubscriptionPage: React.SFC<SubscriptionProps> = (props) => {
    return <SubscriptionFormPage
        title={props.create ? "Create Subscription" : StringHelper.ellipsis}
        load={async () => {
            const environments = repository.Environments.all();
            const teams = repository.Teams.all();
            const users = isAllowed({ permission: Permission.UserView }) ? repository.Users.all() : Promise.resolve<UserResource[]>([]);
            const projects = isAllowed({ permission: Permission.ProjectView, wildcard: true })
                ? repository.Projects.all()
                : Promise.resolve<ProjectResource[]>([]);
            const projectGroups = isAllowed({ permission: Permission.ProjectGroupView, projectGroup: "*" })
                ? repository.ProjectGroups.all()
                : Promise.resolve<ProjectGroupResource[]>([]);
            const eventCategories = repository.Events.categories({});
            const eventGroups = repository.Events.groups({});
            const eventAgents = repository.Events.eventAgents();
            const documentTypes = repository.Events.documentTypes({});
            const timezones = repository.ServerStatus.getTimezones();
            const smtpIsConfiguredPromise = repository.SmtpConfiguration.IsConfigured();
            const serverConfigurationPromise: Promise<ServerConfigurationResource | null> = isAllowed({ permission: Permission.AdministerSystem })
                ? repository.ServerConfiguration.get()
                : Promise.resolve(null);
            const tenants = repository.Tenants.all();
            const tagIndex = tenantTagsets.getTagIndex();
            const subscription = loadSubscription();

            const serverConfiguration = await serverConfigurationPromise;

            const serverUri = serverConfiguration && serverConfiguration.ServerUri ? serverConfiguration.ServerUri : undefined;

            const lookupData: LookupData = {
                environments: await environments,
                teams: await teams,
                users: await users,
                projects: await projects,
                projectGroups: await projectGroups,
                eventCategories: await eventCategories,
                eventGroups: await eventGroups,
                eventAgents: await eventAgents,
                documentTypes: await documentTypes,
                tenants: await tenants,
                tagIndex: await tagIndex,
                timezones: await timezones,
                smtpIsConfigured: await smtpIsConfiguredPromise,
                serverUri
            };

            return {
                subscription: await subscription,
                lookupData,
                filterSelections: extractFilterParams()
            };
        }}
        render={(data: SubscriptionData) => {
            return <SubscriptionForm
                {...data}
            />;
        }}
    />;

    function extractFilterParams(): EventFilterSelections {
        const query = URI(props.location.search).search(true) as any;
        return {
            Users: arrayValueFromQueryString(query.users),
            Projects: arrayValueFromQueryString(query.projects),
            ProjectGroups: arrayValueFromQueryString(query.projectGroups),
            Environments: arrayValueFromQueryString(query.environments),
            EventGroups: arrayValueFromQueryString(query.eventGroups),
            EventCategories: arrayValueFromQueryString(query.eventCategories),
            EventAgents: arrayValueFromQueryString(query.eventAgents),
            Tenants: arrayValueFromQueryString(query.tenants),
            Tags: arrayValueFromQueryString(query.tags),
            DocumentTypes: arrayValueFromQueryString(query.documentTypes)
        };
    }

    async function loadSubscription(): Promise<SubscriptionResource | null> {
        if (props.create) {
            return null;
        } else {
            return await repository.Subscriptions.get(props.match.params.subscriptionId);
        }
    }
};

interface SubscriptionFormState extends FormBaseComponentState<Model> {
    deleted: boolean;
    newId?: string;
}

class SubscriptionForm extends FormBaseComponent<SubscriptionData, SubscriptionFormState, Model> {
    constructor(props: SubscriptionData) {
        super(props);
        const initialModel: Model = props.subscription || this.createNewSubscription();
        this.state = {
            deleted: false,
            model: initialModel,
            cleanModel: cloneDeep(initialModel)
        };
    }

    render() {
        const title = isExistingSubscriptionResource(this.state.model)
            ? this.state.model.Name
            : "Create Subscription";

        const overFlowActions = [];
        if (isExistingSubscriptionResource(this.state.model)) {
            const existingSubscriptionResource: SubscriptionResource = this.state.model;
            const model = this.state.model;
            overFlowActions.push(OverflowMenu.item(existingSubscriptionResource.IsDisabled
                ? "Enable"
                : "Disable",
                () => this.handleEnabledToggle(model),
                { permission: Permission.SubscriptionEdit }));
            overFlowActions.push(OverflowMenu.deleteItemDefault("subscription",
                () => this.handleDeleteConfirm(existingSubscriptionResource),
                { permission: Permission.SubscriptionDelete }));
            overFlowActions.push([OverflowMenu.navItem("Audit Trail",
                routeLinks.configuration.eventsRegardingAny([existingSubscriptionResource.Id]), null, {
                    permission: Permission.EventView,
                    wildcard: true
                })]);
        }

        const saveText: string = this.state.newId
            ? "Subscription created"
            : "Subscription details updated";

        return <FormPaperLayout
            title={title}
            breadcrumbTitle={"Subscriptions"}
            breadcrumbPath={routeLinks.configuration.subscriptions.root}
            busy={this.state.busy}
            errors={this.state.errors}
            model={this.state.model}
            cleanModel={this.state.cleanModel}
            savePermission={{ permission: !isExistingSubscriptionResource(this.state.model) ? Permission.SubscriptionCreate : Permission.SubscriptionEdit }}
            onSaveClick={this.handleSaveClick}
            saveText={saveText}
            expandAllOnMount={!isExistingSubscriptionResource(this.state.model)}
            overFlowActions={overFlowActions}
        >
            {this.state.deleted && <InternalRedirect to={routeLinks.configuration.subscriptions.root} />}
            {this.state.newId && <InternalRedirect to={routeLinks.configuration.subscription(this.state.newId)} />}
            <div>
                {this.state.cleanModel.IsDisabled && <UnstructuredFormSection stretchContent={true}>
                    <Callout type={CalloutType.Warning} title={"This subscription is currently disabled"} />
                </UnstructuredFormSection>}

                <ExpandableFormSection
                    errorKey="Name"
                    title="Name"
                    focusOnExpandAll
                    summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your subscription")}
                    help="Apply filters to this subscription.">
                    <Text
                        value={this.state.model.Name}
                        onChange={Name => this.setModelState({ Name })}
                        label="Name"
                        validate={required("Please enter a subscription name")}
                        error={this.getFieldError("Name")}
                        autoFocus={true}
                    />
                </ExpandableFormSection>

                {<ExpandableFormSection
                    errorKey="IsDisabled"
                    title="Enabled"
                    summary={this.state.model.IsDisabled ? Summary.summary("No") : Summary.default("Yes")}
                    help="Disable a subscription to prevent any notifications from being sent.">
                    <Checkbox
                        value={!this.state.model.IsDisabled}
                        onChange={IsDisabled => this.setModelState({ IsDisabled: !IsDisabled })}
                        label="Enabled"
                    />
                </ExpandableFormSection>}

                <ExpandableFormSection
                    errorKey="Event Filters"
                    title="Event Filters"
                    summary={this.eventFilterSummary()}
                    help="Add filters to narrow the scope of events.">
                    <EventFilter
                        doBusyTask={this.doBusyTask}
                        documentTypes={this.props.lookupData.documentTypes}
                        environments={this.props.lookupData.environments}
                        eventCategories={this.props.lookupData.eventCategories}
                        eventGroups={this.props.lookupData.eventGroups}
                        eventAgents={this.props.lookupData.eventAgents}
                        projects={this.props.lookupData.projects}
                        projectGroups={this.props.lookupData.projectGroups}
                        tenants={this.props.lookupData.tenants}
                        users={this.props.lookupData.users}
                        selectedDocumentTypes={this.state.model.EventNotificationSubscription.Filter.DocumentTypes}
                        selectedEnvironments={this.state.model.EventNotificationSubscription.Filter.Environments}
                        selectedEventCategories={this.state.model.EventNotificationSubscription.Filter.EventCategories}
                        selectedEventGroups={this.state.model.EventNotificationSubscription.Filter.EventGroups}
                        selectedEventAgents={this.state.model.EventNotificationSubscription.Filter.EventAgents}
                        selectedProjects={this.state.model.EventNotificationSubscription.Filter.Projects}
                        selectedProjectGroups={this.state.model.EventNotificationSubscription.Filter.ProjectGroups}
                        selectedTenants={this.state.model.EventNotificationSubscription.Filter.Tenants}
                        selectedTenantTags={this.state.model.EventNotificationSubscription.Filter.Tags}
                        selectedUsers={this.state.model.EventNotificationSubscription.Filter.Users}
                        onChangeDocumentTypes={(x) => this.setEventNotificationSubscriptionFilter({ DocumentTypes: x })}
                        onChangeEventCategories={(x) => this.setEventNotificationSubscriptionFilter({ EventCategories: x })}
                        onChangeEventGroups={(x) => this.setEventNotificationSubscriptionFilter({ EventGroups: x })}
                        onChangeEventAgents={(x) => this.setEventNotificationSubscriptionFilter({ EventAgents: x })}
                        onChangeEnvironments={(x) => this.setEventNotificationSubscriptionFilter({ Environments: x })}
                        onChangeProjects={(x) => this.setEventNotificationSubscriptionFilter({ Projects: x })}
                        onChangeProjectGroups={(x) => this.setEventNotificationSubscriptionFilter({ ProjectGroups: x })}
                        onChangeTenants={(x) => this.setEventNotificationSubscriptionFilter({ Tenants: x })}
                        onChangeTenantTags={(x) => this.setEventNotificationSubscriptionFilter({ Tags: x })}
                        onChangeUsers={(x) => this.setEventNotificationSubscriptionFilter({ Users: x })}
                    />
                    <Note>
                        Any events matching these filters will be included in the e-mail digest or sent to the webhook
                        destination configured below.
                    </Note>
                    <Callout title="Avoid combining machine-event filters with other filters" type={CalloutType.Information}>
                        Machine-related events do not include information about projects or
                        environments. Combining machine-event filters with other filters is not advised, as it may cause your subscription to not trigger.
                    </Callout>
                </ExpandableFormSection>
                <FormSectionHeading title="Email Notifications" />
                <SmtpNeedsConfigurationCallout
                    configured={this.props.lookupData.smtpIsConfigured.IsConfigured}
                    hasPermissions={isAllowed({ permission: Permission.ConfigureServer })}/>
                <ExpandableFormSection
                    errorKey="EmailTeams"
                    title="Email Teams"
                    summary={this.emailTeamsSummary()}
                    help="Select the team(s) that will receive an email of events.">
                    <TeamMultiSelect
                        items={this.props.lookupData.teams}
                        onChange={x => this.setEventNotificationSubscription({ EmailTeams: x })}
                        value={this.state.model.EventNotificationSubscription.EmailTeams} />
                    <Note>Each member of these teams will receive an email with events they have permission to view.</Note>
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey="EmailFrequencyPeriod"
                    title="Email Frequency"
                    summary={this.emailFrequencySummary()}
                    help="Select the frequency of the subscription emails.">
                    <TimeSpanSelector
                        value={this.state.model.EventNotificationSubscription.EmailFrequencyPeriod}
                        onChange={x => this.setEventNotificationSubscription({ EmailFrequencyPeriod: x })}
                    />
                    <Note>Emails will be sent periodically, including a digest of events that have occurred.</Note>
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey="EmailTimezones"
                    title="Email Timezone"
                    summary={this.emailTimezoneSummary()}
                    help="Select the timezone for date/times shown in emails.">
                    <Select
                        value={this.state.model.EventNotificationSubscription.EmailShowDatesInTimeZoneId}
                        allowClear={true}
                        onChange={x => this.setEventNotificationSubscription({ EmailShowDatesInTimeZoneId: x })}
                        items={this.props.lookupData.timezones.map(pg => ({ value: pg.Id, text: pg.Name }))}
                        label="Select timezone" />
                    <Note>Any date/times in emails will be shown using this timezone.</Note>
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey="EmailPriority"
                    title="Email Priority"
                    summary={this.emailPrioritySummary()}
                    help="Select the priority of the emails.">
                    <RadioButtonGroup
                        value={this.state.model.EventNotificationSubscription.EmailPriority}
                        onChange={(x: EmailPriority) => this.setEventNotificationSubscription({ EmailPriority: x })}>
                        <RadioButton value={EmailPriority.Low} label="Low" />
                        <RadioButton value={EmailPriority.Normal} label="Normal" />
                        <RadioButton value={EmailPriority.High} label="High" />
                    </RadioButtonGroup>
                </ExpandableFormSection>

                <UnstructuredFormSection>
                    {this.props.lookupData.serverUri
                        ? <Note>
                            Emails will include a deep-link to your Octopus audit screen (with filters matching the selected
                            events). The base url that will be used is: <ExternalLink
                                href={this.props.lookupData.serverUri}>{this.props.lookupData.serverUri}</ExternalLink>.
                        </Note>
                        : <Note>
                            This email can include a deep-link to your Octopus audit screen (with filters matching the
                            selected
                            events). To enable this feature, simply set a url in your <InternalLink
                                to={routeLinks.configuration.nodes.config}>Octopus Server's
                            configuration settings</InternalLink>.
                        </Note>}
                </UnstructuredFormSection>

                <FormSectionHeading title="Webhook Notifications" />

                <ExpandableFormSection
                    errorKey="EventNotificationSubscription.WebhookURI"
                    title="Payload URL"
                    summary={this.webhookPayloadUrlSummary()}
                    help="Enter the server endpoint that will receive the webhook payload.">
                    <Text
                        value={this.state.model.EventNotificationSubscription.WebhookURI}
                        onChange={x => this.setEventNotificationSubscription({ WebhookURI: x })}
                        label="Payload url"
                        error={this.getFieldError("EventNotificationSubscription.WebhookURI")}
                    />
                    <Note>
                        The payload will be delivered in the body of a POST request using an <code>application/json</code> content type,
                        contained in a parameter called "Payload". Each payload will contain a single event.
                    </Note>
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey="WebhookHeaderKey"
                    title="Header"
                    focusOnExpandAll
                    summary={this.state.model.EventNotificationSubscription.WebhookHeaderKey
                        ? Summary.summary(this.state.model.EventNotificationSubscription.WebhookHeaderKey)
                        : Summary.placeholder("Optionally, enter a header key/value for your webhook")}
                    help="Optionally, add a header key/value for the webhook request.">
                    <Text
                        value={this.state.model.EventNotificationSubscription.WebhookHeaderKey}
                        onChange={x => this.setEventNotificationSubscription({ WebhookHeaderKey: x })}
                        label="Header Key"
                        error={this.getFieldError("WebhookHeaderKey")}
                    />
                    <Text
                        value={this.state.model.EventNotificationSubscription.WebhookHeaderValue}
                        onChange={x => this.setEventNotificationSubscription({ WebhookHeaderValue: x })}
                        label="Header Value"
                        error={this.getFieldError("WebhookHeaderValue")}
                    />
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey="WebhookTeams"
                    title="Limited Team Scope"
                    summary={this.webhookTeamsSummary()}
                    help="Select any team(s) that you wish to limit the scope of this webhook to.">
                    <TeamMultiSelect
                        items={this.props.lookupData.teams}
                        onChange={x => this.setEventNotificationSubscription({ WebhookTeams: x })}
                        value={this.state.model.EventNotificationSubscription.WebhookTeams} />
                    <Note>
                        By default, webhook requests will be sent with a global scope (all events will be sent). You may narrow the security scope
                        to one or more teams. The subscription will then be limited to the events that the given team(s) can view. The team(s) must
                        have at least EventView permissions.
                    </Note>
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey="WebhookTimeout"
                    title="Timeout"
                    summary={this.webhookTimeoutSummary()}
                    help="Select the timeout when sending the webhook payload.">
                    <TimeSpanSelector
                        value={this.state.model.EventNotificationSubscription.WebhookTimeout}
                        onChange={x => this.setEventNotificationSubscription({ WebhookTimeout: x })}
                        granularity={TimeSpanSelector.SecondGranularity}
                    />
                    <Note>The request to send the payload will time out after the time specified.</Note>
                </ExpandableFormSection>

            </div>
        </FormPaperLayout>;
    }

    private createNewSubscription() {
        const filterSelections = this.props.filterSelections;
        // See the note in the EventFilter's "save" method for the reasoning behind this "hack".
        let selectedDocumentTypes: any = [];
        if (filterSelections.DocumentTypes) {
            selectedDocumentTypes = filterSelections.DocumentTypes.map(x => {
                return decodeURIComponent(x);
            });
        }
        const newSubscription: NewSubscriptionResource = {
            Name: "",
            SpaceId: client.spaceId,
            Type: SubscriptionType.Event,
            IsDisabled: false,
            EventNotificationSubscription: {
                Filter: {
                    Users: filterSelections.Users,
                    Projects: filterSelections.Projects,
                    ProjectGroups: filterSelections.ProjectGroups,
                    Environments: filterSelections.Environments,
                    EventGroups: filterSelections.EventGroups,
                    EventCategories: filterSelections.EventCategories,
                    EventAgents: filterSelections.EventAgents,
                    Tenants: filterSelections.Tenants,
                    Tags: filterSelections.Tags,
                    DocumentTypes: selectedDocumentTypes,
                },
                EmailTeams: [],
                EmailFrequencyPeriod: TimeSpanString.OneHour,
                EmailShowDatesInTimeZoneId: getEmailShowDatesInTimeZoneId(this.props.lookupData.timezones),
                EmailPriority: EmailPriority.Normal,
                WebhookURI: "",
                WebhookTeams: [],
                WebhookTimeout: TimeSpanString.TenSeconds,
                WebhookHeaderKey: null,
                WebhookHeaderValue: null,
            }
        };
        return newSubscription;

        function getEmailShowDatesInTimeZoneId(timezones: ServerTimezoneResource[]) {
            const serverLocalTimezones = timezones.filter((tz: any) => {
                return tz.IsLocal === true;
            });
            return (serverLocalTimezones.length > 0 ? serverLocalTimezones[0].Id : "");
        }
    }

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const isNew = !isExistingSubscriptionResource(this.state.model);
            const result = await repository.Subscriptions.save(this.state.model);
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
                newId: isNew ? result.Id : null
            });
        });
    }

    private handleDeleteConfirm = async (model: SubscriptionResource) => {
        await repository.Subscriptions.del(model);
        this.setState({ deleted: true });
        return true;
    }

    private handleEnabledToggle = async (model: SubscriptionResource) => {
        const isDisabled = !model.IsDisabled; // Toggle the current value.
        model.IsDisabled = isDisabled;
        await this.doBusyTask(async () => {
            const isNew = model.Id == null;
            const result = await repository.Subscriptions.save(model);
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
                newId: isNew ? result.Id : null
            });
        });
    }

    private eventFilterSummary() {
        return Summary.summary(
            <EventFilterSummary
                key="eventFilterSummary"
                documentTypes={this.props.lookupData.documentTypes}
                environments={this.props.lookupData.environments}
                eventCategories={this.props.lookupData.eventCategories}
                eventGroups={this.props.lookupData.eventGroups}
                eventAgents={this.props.lookupData.eventAgents}
                projects={this.props.lookupData.projects}
                projectGroups={this.props.lookupData.projectGroups}
                tenants={this.props.lookupData.tenants}
                tagIndex={this.props.lookupData.tagIndex}
                users={this.props.lookupData.users}
                selectedDocumentTypes={this.state.model.EventNotificationSubscription.Filter.DocumentTypes}
                selectedEnvironments={this.state.model.EventNotificationSubscription.Filter.Environments}
                selectedEventCategories={this.state.model.EventNotificationSubscription.Filter.EventCategories}
                selectedEventGroups={this.state.model.EventNotificationSubscription.Filter.EventGroups}
                selectedEventAgents={this.state.model.EventNotificationSubscription.Filter.EventAgents}
                selectedProjects={this.state.model.EventNotificationSubscription.Filter.Projects}
                selectedProjectGroups={this.state.model.EventNotificationSubscription.Filter.ProjectGroups}
                selectedTenants={this.state.model.EventNotificationSubscription.Filter.Tenants}
                selectedTenantTags={this.state.model.EventNotificationSubscription.Filter.Tags}
                selectedUsers={this.state.model.EventNotificationSubscription.Filter.Users}
            />
        );
    }

    private setEventNotificationSubscription<K extends keyof EventNotificationSubscription>(state: Pick<EventNotificationSubscription, K>) {
        this.setChildState2("model", "EventNotificationSubscription", state);
    }

    private setEventNotificationSubscriptionFilter<K extends keyof EventNotificationSubscriptionFilter>(state: Pick<EventNotificationSubscriptionFilter, K>) {
        this.setChildState3("model", "EventNotificationSubscription", "Filter", state);
    }

    private emailTeamsSummary() {
        return this.teamsSummary(this.state.model.EventNotificationSubscription.EmailTeams);
    }

    private emailFrequencySummary() {
        return Summary.summary(TimeSpanHelper.timeSpanTextToHumanReadableString(this.state.model.EventNotificationSubscription.EmailFrequencyPeriod));
    }

    private emailTimezoneSummary() {
        return this.state.model.EventNotificationSubscription.EmailShowDatesInTimeZoneId
            ? Summary.summary(this.state.model.EventNotificationSubscription.EmailShowDatesInTimeZoneId)
            : Summary.placeholder("No timezone selected");
    }

    private emailPrioritySummary() {
        const val = this.state.model.EventNotificationSubscription.EmailPriority || EmailPriority.Normal;
        return val === EmailPriority.Normal ? Summary.default("Normal") : Summary.summary(val);
    }

    private webhookPayloadUrlSummary() {
        return this.state.model.EventNotificationSubscription.WebhookURI
            ? Summary.summary(this.state.model.EventNotificationSubscription.WebhookURI)
            : Summary.placeholder("No payload url assigned");
    }

    private webhookTeamsSummary() {
        return this.teamsSummary(this.state.model.EventNotificationSubscription.WebhookTeams);
    }

    private webhookTimeoutSummary() {
        return Summary.summary(TimeSpanHelper.timeSpanTextToHumanReadableString(this.state.model.EventNotificationSubscription.WebhookTimeout));
    }

    private teamsSummary(selectedTeamIds: string[]) {
        if (selectedTeamIds.length > 0) {
            const teamChips = this.props.lookupData.teams
                .filter(t => selectedTeamIds.includes(t.Id))
                .map(t => <TeamChip key={t.Id} team={t} />);
            return Summary.summary(<div>Limited to teams {buildValueList(teamChips)}</div>);
        } else {
            return Summary.placeholder("No teams selected");
        }
    }
}

export default SubscriptionPage;