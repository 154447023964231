import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
import { TopicsContainer, LearnMoreResources } from "./CommonViews";
import InternalLink from "components/Navigation/InternalLink";
import routeLinks from "routeLinks";

export const CommonInfrastructureProxyOverview: React.StatelessComponent<{}> = props => {
    return <OverviewContainer>
        <OverviewHighlightText>
            <p>
                <ExternalLink href="ProxySupport">Machine proxies</ExternalLink> allow you to specify a proxy server for Octopus to use when communicating with a Tentacle
                or SSH Target, you can also specify a proxy server when a Tentacle and the Octopus Server make web requests to other servers.
            </p>
            <p>
                To configure a proxy for the Octopus Server, modify the
                <InternalLink to={routeLinks.configuration.setting("server-proxy")}> web request proxy</InternalLink>. Learn more about
                <ExternalLink href="ProxySupport"> proxies</ExternalLink>.
            </p>
        </OverviewHighlightText>
    </OverviewContainer >;
};

export const CommonInfrastructureProxyTopics: React.StatelessComponent<{}> = props => {
    return <TopicsContainer>
        <LearnMoreResources>
            <div><ExternalLink href="ProxySupport">Proxy Support</ExternalLink></div>
        </LearnMoreResources>
    </TopicsContainer>;
};