import * as React from "react";
import {ActionButton, ActionButtonType} from "components/Button/ActionButton";
import {
    DialogLayoutDispatchProps,
    DialogLayout, DialogLayoutCommonProps
} from "components/DialogLayout/DialogLayout";
import {DialogLayoutConnect} from "components/Dialog/DialogLayoutConnect";
import { isAllowed, PermissionCheckProps } from "../PermissionCheck/PermissionCheck";
import FormComponent from "../FormComponent/FormComponent";
const styles = require("./style.less");

interface SaveDialogLayoutProps extends DialogLayoutCommonProps {
    saveButtonDisabled?: boolean;
    savePermission?: PermissionCheckProps;
    saveButtonLabel?: string;
    busyButtonLabel?: string;
    cancelButtonLabel?: string;
    hideSave?: boolean;
    onSaveClick(): Promise<boolean>;
}

class SaveDialogLayoutInternal extends React.Component<SaveDialogLayoutProps & DialogLayoutDispatchProps> {
    static defaultProps: Partial<SaveDialogLayoutProps> = {
        hideSave: false
    };

    saveClick = async () => {
        const result = await this.props.onSaveClick();
        if (result) {
            this.props.close();
        }
    }

    submitForm = async () => {
        if (!this.isSaveButtonDisabled()) {
            await this.saveClick();
        }
    }

    render() {

        const {
            children,
            ...other
        } = this.props;

        const save = this.props.hideSave ? null : <ActionButton key="Save"
                                   type={ActionButtonType.Save}
                                   label={this.props.saveButtonLabel || "Save"}
                                   busyLabel={this.props.busyButtonLabel || "Saving..."}
                                   onClick={this.saveClick}
                                   disabled={this.isSaveButtonDisabled()}/>;
        const cancel = <ActionButton key="Cancel"
                                     label={this.props.cancelButtonLabel || "Cancel"}
                                     disabled={this.props.busy}
                                     onClick={() => this.props.close()}/>;
        return<DialogLayout actions={[cancel, save]} {...other} closeDialog={this.props.close}>
            <FormComponent onFormSubmit={this.submitForm} className={styles.dialogWrapper}>
                {children}
            </FormComponent>
        </DialogLayout>;
    }
    private isSaveButtonDisabled(): boolean {
        const disabledDueToPermission = !!this.props.savePermission ? !isAllowed(this.props.savePermission) : false;
        return !!(this.props.saveButtonDisabled || disabledDueToPermission || this.props.busy);
    }
}

const SaveDialogLayout = DialogLayoutConnect.to<SaveDialogLayoutProps>(SaveDialogLayoutInternal);
SaveDialogLayout.displayName = "SaveDialogLayout";
export default SaveDialogLayout;
