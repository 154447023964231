import * as React from "react";
import ExternalLink from "components/Navigation/ExternalLink";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import { BreadcrumbProps } from "components/PaperLayout/PaperLayout";
import Loading from "components/Loading/Loading";
import { repository } from "clientInstance";
import { MachineResource } from "client/resources";
import { RouteComponentProps } from "react-router";
import {
    Text,
    ExpandableFormSection,
    Summary,
    required,
    Note,
    Select,
} from "components/form";
import {
    CommunicationStyle,
    ProxyResource,
    CertificateConfigurationResource,
    ListeningTentacleEndpointResource,
    SshEndpointResource,
} from "client/resources";
import EndpointsHelper, {EndpointCommunicationStyle} from "utils/EndpointsHelper/EndpointsHelper";
import {BooleanRadioButtonGroup} from "components/form/RadioButton/RadioButtonGroup";
import RadioButton from "components/form/RadioButton/RadioButton";
import ParseHelper from "utils/ParseHelper";
import {NavigationButton} from "components/Button";
import CommonSummaryHelper from "utils/CommonSummaryHelper";
import * as URI from "urijs";
import Permission from "client/resources/permission";
import routeLinks from "routeLinks";
import ServerThumbprint from "components/ServerThumbprint/ServerThumbprint";
import {LocationDescriptorObject} from "history";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import GlobalState from "globalState";
import { connect } from "react-redux";
import endpointRegistry, { EndpointRegistrationKey } from "../MachineSettings/Endpoints/endpointRegistry";
import { WindowsListeningTentacleCallout, LinuxListeningTentacleCallout  } from "areas/infrastructure/components/TentacleCallout";

interface DiscoveryEndpoint {
    key?: EndpointRegistrationKey;
    communicationStyle?: CommunicationStyle;
    host?: string;
    port?: number;
    proxyId?: string;
}

interface MachineDiscoveryProps extends RouteComponentProps<MachineDiscoveryParams> {
    breadcrumbs?: BreadcrumbProps;
    title: string;
    machineTypeDescription: string;
    createLink: string;
    helpText: JSX.Element;
    discover(host: string, port: number, type: any, proxyId: string): Promise<MachineResource>;
}

interface MachineDiscoveryParams {
    environmentId: string;
    workerPoolId: string;
    key: EndpointRegistrationKey;
}

interface MachineDiscoveryState extends OptionalFormBaseComponentState<DiscoveryEndpoint> {
    communicationStyles: EndpointCommunicationStyle[];
    proxies: ProxyResource[];
    globalCertificate: CertificateConfigurationResource;
    octopusVersion: string;
    shouldUseProxy: boolean;
    isDataLoaded: boolean;
    redirectTo?: LocationDescriptorObject;
    open: boolean;
    anchor?: any;
}

class MachineDiscoveryLayoutInternal extends FormBaseComponent<MachineDiscoveryProps, MachineDiscoveryState, DiscoveryEndpoint> {
    private environmentId: string;
    private workerPoolId: string;

    constructor(props: MachineDiscoveryProps) {
        super(props);
        this.environmentId = this.props.match && this.props.match.params ? this.props.match.params.environmentId : null;
        this.workerPoolId = this.props.match && this.props.match.params ? this.props.match.params.workerPoolId : null;
        const key = this.props.match && this.props.match.params ? this.props.match.params.key : null;
        const communicationStyle = key ? endpointRegistry.getEndpoint(key) && endpointRegistry.getEndpoint(key).communicationStyle : null;
        this.state = {
            communicationStyles: EndpointsHelper.communicationStyles,
            proxies: null,
            globalCertificate: null,
            octopusVersion: repository.getServerInformation().version,
            shouldUseProxy: false,
            model: {
                key,
                communicationStyle,
                port: this.props.match && this.props.match.params ? EndpointsHelper.getDefaultPort(communicationStyle) : null
            },
            cleanModel: {},
            isDataLoaded: false,
            open: false,
        };
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const proxiesPromise = repository.Proxies.all();
            const globalCertificatePromise = repository.CertificateConfiguration.global();
            this.setState({
                proxies: await proxiesPromise,
                globalCertificate: await globalCertificatePromise,
                isDataLoaded: true
            });
        });
    }

    render() {
        const { breadcrumbs = {} } = this.props;
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} />;
        }

        if (!this.state.isDataLoaded) {
            return <Loading
                title={this.props.title}
                busy={this.state.busy}
                errors={this.state.errors} />;
        }

        const queryParams: {[k: string]: any} = {
            type: this.state.model.communicationStyle
        };
        if (this.environmentId) {
            queryParams.environment = this.environmentId;
        }
        if (this.workerPoolId) {
            queryParams.workerPool = this.workerPoolId;
        }

        const newQS = new URI().search(queryParams).search();

        const enterDetailsButton = EndpointsHelper.canDiscover(this.state.model.communicationStyle) &&
            <NavigationButton
                label="Enter details manually"
                href={this.props.createLink + newQS} />;

        return <FormPaperLayout
            title={this.props.title}
            {...breadcrumbs}
            busy={this.state.busy}
            errors={this.state.errors}
            model={this.state.model}
            cleanModel={this.state.cleanModel}
            disableDirtyFormChecking={true}
            savePermission={{permission: Permission.MachineCreate, environment: "*", tenant: "*"}}
            onSaveClick={async () => this.handleNextAction()}
            hideSectionControls={
                !(EndpointsHelper.canDiscover(this.state.model.communicationStyle)
                    || (this.state.model.communicationStyle && !EndpointsHelper.isPollingTentacle(this.state.model.communicationStyle)))
            }
            saveButtonLabel={"Next"}
            saveButtonBusyLabel={EndpointsHelper.canDiscover(this.state.model.communicationStyle) ? "Discovering" : "Saving"}
            secondaryAction={enterDetailsButton}
            expandAllOnMount={true}
            overFlowActions={null}
        >

            {this.state.model.key === EndpointRegistrationKey.TentaclePassiveWindows && (
                <WindowsListeningTentacleCallout thumbprint={<ServerThumbprint thumbprint={this.state.globalCertificate.Thumbprint}/>}/>
            )}
            {this.state.model.key === EndpointRegistrationKey.TentaclePassiveLinux && (
                <LinuxListeningTentacleCallout thumbprint={<ServerThumbprint thumbprint={this.state.globalCertificate.Thumbprint}/>}/>
            )}

            {EndpointsHelper.canDiscover(this.state.model.communicationStyle) &&
            <div>
                <ExpandableFormSection
                    errorKey="host"
                    title="Hostname"
                    summary={this.state.model.host ? Summary.summary(this.state.model.host) : Summary.placeholder("No host")}
                    help={<span>Enter the DNS name or IP of the {this.props.machineTypeDescription} to discover, eg <code>example.com</code>, <code>10.0.1.23</code>.</span>}>
                    <Text label="Hostname"
                        value={this.state.model.host}
                        onChange={host => this.setModelState({host})}
                        validate={required("Please enter a hostname")}
                        error={this.getFieldError("host")}
                        type="url"
                        autoFocus={EndpointsHelper.canDiscover(this.state.model.communicationStyle)}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Port"
                    title="Port"
                    summary={CommonSummaryHelper.portSummary(this.state.model.port)}
                    help="Enter the port on which the endpoint is listening">
                    <Text label="Port"
                          value={this.state.model.port ? this.state.model.port.toString() : null}
                          onChange={(x) => this.setModelState({port: ParseHelper.safeParseInt(x, null)})}
                          type="number"/>
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="proxyId"
                    title="Proxy"
                    summary={CommonSummaryHelper.resourceSummary(this.state.model.proxyId, this.state.proxies, "proxy")}
                    help={"Select whether to use a proxy to connect to this " + this.props.machineTypeDescription + "."}>
                    <BooleanRadioButtonGroup
                        label="Connection method"
                        onChange={shouldUseProxy => {
                            const discoveryEndpoint = this.state.model;
                            discoveryEndpoint.proxyId = null;
                            this.setState({
                                shouldUseProxy,
                                model: discoveryEndpoint,
                            });
                        }}
                        value={this.state.shouldUseProxy}>
                        <RadioButton value={false} label={"Connect to this " + this.props.machineTypeDescription + " directly"} isDefault={true}/>
                        <RadioButton value={true} label={"Connect to this " + this.props.machineTypeDescription + " through a proxy server"}/>
                    </BooleanRadioButtonGroup>
                    {this.state.shouldUseProxy &&
                    <Select
                        label="Proxy"
                        onChange={proxyId => this.setModelState({proxyId})}
                        value={this.state.model.proxyId}
                        items={this.state.proxies.map(p => ({value: p.Id, text: p.Name}))}/>}
                </ExpandableFormSection>
            </div>}
        </FormPaperLayout>;
    }

    private async handleNextAction() {
        return this.doBusyTask(async () => {
            const queryParams: {[k: string]: any} = {
                type: this.state.model.communicationStyle
            };
            if (this.environmentId) {
                queryParams.environment = this.environmentId;
            }
            if (this.workerPoolId) {
                queryParams.workerPool = this.workerPoolId;
            }
            queryParams["type"] = this.state.model.communicationStyle;
            const endpoint = this.state.model;
            if (EndpointsHelper.canDiscover(endpoint.communicationStyle)) {
                const discoveredMachine = await this.props.discover(endpoint.host, endpoint.port, endpoint.communicationStyle, endpoint.proxyId);
                if (discoveredMachine.Endpoint.CommunicationStyle === CommunicationStyle.TentaclePassive) {
                    const tentacleEndpoint = discoveredMachine.Endpoint as ListeningTentacleEndpointResource;
                    queryParams["uri"] = tentacleEndpoint.Uri;
                    queryParams["thumbprint"] = tentacleEndpoint.Thumbprint;
                    queryParams["proxyId"] = tentacleEndpoint.ProxyId;
                } else if (discoveredMachine.Endpoint.CommunicationStyle === CommunicationStyle.Ssh) {
                    const sshEndpoint = discoveredMachine.Endpoint as SshEndpointResource;
                    queryParams["uri"] = sshEndpoint.Uri;
                    queryParams["host"] = sshEndpoint.Host;
                    queryParams["port"] = sshEndpoint.Port;
                    queryParams["fingerprint"] = sshEndpoint.Fingerprint;
                    queryParams["proxyId"] = sshEndpoint.ProxyId;
                }
            }
            const newQS = new URI().search(queryParams).search();
            this.setState({redirectTo: {
                pathname: this.props.createLink,
                search: newQS
            }});
        });
    }
}

const mapStateToPropsForDeploymentTarget = (state: GlobalState, props: RouteComponentProps<MachineDiscoveryParams>) => {
    const registration = endpointRegistry.getMachine(props.match.params.key);

    return {
        title: `Create ${registration ? registration.name : "deployment target"}`,
        breadcrumbs: {
            breadcrumbPath: routeLinks.infrastructure.machines.new(props.match.params.environmentId),
            breadcrumbTitle: "New Deployment Target"
        },
        machineTypeDescription: "deployment target",
        createLink: routeLinks.infrastructure.machines.create(),
        helpText: <Note>
            Learn more about <ExternalLink href="DeploymentTargets">deployment
            targets</ExternalLink>.
        </Note>,
        discover: (host: string, port: number, type: any, proxyId: string) => repository.Machines.discover(host, port, type, proxyId)
    };
};

const mapStateToPropsForWorkerMachines = (state: GlobalState, props: RouteComponentProps<MachineDiscoveryParams>) => {
    const registration = endpointRegistry.getMachine(props.match.params.key);

    return {
        title: `Create ${registration ? registration.name : "worker"}`,
        breadcrumbs: {
            breadcrumbPath: routeLinks.infrastructure.workerMachines.new(props.match.params.workerPoolId),
            breadcrumbTitle: "New Worker"
        },
        machineTypeDescription: "worker",
        createLink: routeLinks.infrastructure.workerMachines.create(),
        discover: (host: string, port: number, type: any, proxyId: string) => repository.Workers.discover(host, port, type, proxyId)
    };
};

const mapDispatchToProps = () => {
    return {};
};

export const DeploymentTargetDiscoveryLayout = connect<{}, {}, MachineDiscoveryProps>(
    mapStateToPropsForDeploymentTarget,
    mapDispatchToProps
)(MachineDiscoveryLayoutInternal);

export const WorkerMachineDiscoveryLayout = connect<{}, {}, MachineDiscoveryProps>(
    mapStateToPropsForWorkerMachines,
    mapDispatchToProps
)(MachineDiscoveryLayoutInternal);
