import * as React from "react";
import {RemoveItemsList} from "../RemoveItemsList/RemoveItemsList";
import {TextInput} from "../form/Text/Text";
import {Text} from "components/form";
import ActionButton from "../Button/ActionButton";
import {BoundFieldProps} from "../Actions/pluginRegistry";
import { Omit } from "recompose";
const styles = require("./style.less");
import * as cn from "classnames";
import {VariableLookupText} from "components/form/VariableLookupText";
import { noOp } from "utils/noOp";

export interface KeyValuePair {
    key: string;
    value: string;
}

class KeyValueRemoveItemsList extends RemoveItemsList<KeyValuePair> { }

export interface KeyValueEditListProps {
    items: () => KeyValuePair[];
    name: string;
    keyLabel: string;
    keyHintText?: string;
    keyMultiline?: boolean;
    keyRowsMax?: number;
    valueLabel: string;
    valueHintText?: string;
    valueMultiline?: boolean;
    valueRowsMax?: number;
    reverseLayout?: boolean;
    separator: string;
    hideBindOnKey?: boolean;
    onChange: (items: KeyValuePair[]) => void;
}

export interface KeyValueEditListState {
    source: KeyValuePair[];
}

export class KeyValueEditList extends React.PureComponent<KeyValueEditListProps & BoundFieldProps, KeyValueEditListState> {
    adding = false;

    constructor(props: KeyValueEditListProps & BoundFieldProps) {
        super(props);
        this.state = {
            source: props.items()
        };
    }

    handleRemoveRow = (item: any) => {
        const data = this.state.source;
        data.splice(data.indexOf(item), 1);
        this.invokeOnChange(data);
    }

    handleRowRef = (idx: number) => (input: TextInput | null) => {
        if (input && this.adding && idx === 0) {
            input.focus();
            this.adding = false;
        }
    }

    handleKeyChange = (idx: number) => (val: any) => {
        const data = this.state.source;
        data[idx].key = val;
        this.invokeOnChange(data);
    }

    handleValueChange = (idx: number) => (val: any) => {
        const data = this.state.source;
        data[idx].value = val;
        this.invokeOnChange(data);
    }

    invokeOnChange = (data: KeyValuePair[]) => {
        this.props.onChange([...data]);
    }

    handleAddClick = () => {
        this.adding = true;
        this.setState((prev) => ({...prev, source: [...prev.source, { key: "", value: ""}]}));
    }

    renderRow = (item: any, idx: number) => {
        let fields = [<div className={cn(styles.textControl, styles.textControlKey)} key="key">
            {this.props.hideBindOnKey
                ? <Text textInputRef={this.props.reverseLayout ? noOp : this.handleRowRef(idx)}
                        value={item.key}
                        onChange={this.handleKeyChange(idx)}
                        label={this.props.keyLabel}
                        hintText={this.props.keyHintText}
                        multiLine={this.props.keyMultiline}
                        rowsMax={this.props.keyRowsMax} />
                : <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    textInputRef={this.props.reverseLayout ? noOp : this.handleRowRef(idx)}
                    value={item.key}
                    onChange={this.handleKeyChange(idx)}
                    label={this.props.keyLabel}
                    hintText={this.props.keyHintText}
                    multiLine={this.props.keyMultiline}
                    rowsMax={this.props.keyRowsMax}/>
            }
        </div>,
            <span className={styles.separator} key="seperator">{this.props.separator}</span>,
            <div className={styles.textControl} key="value">
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    key="value"
                    textInputRef={this.props.reverseLayout ? this.handleRowRef(idx) : noOp}
                    value={item.value}
                    onChange={this.handleValueChange(idx)}
                    label={this.props.valueLabel}
                    hintText={this.props.valueHintText}
                    multiLine={this.props.valueMultiline}
                    rowsMax={this.props.valueRowsMax}/>
            </div>];

        if (this.props.reverseLayout) {
            fields = fields.reverse();
        }

        return <div key={"KVI-" + idx} className={styles.itemContainer}>
            {fields}
        </div>;
    }

    render() {
        const actionButton = <ActionButton key="Add"
                                           label={`Add ${this.props.name}`}
                                           onClick={this.handleAddClick}/>;
        return <KeyValueRemoveItemsList listActions={[actionButton]}
                                        data={this.state.source}
                                        onRemoveRow={this.handleRemoveRow}
                                        onRow={this.renderRow}
                                        clearButtonToolTip={`Remove ${this.props.name}`}/>;
    }
}

export type StringKeyValueEditListProps = Omit<KeyValueEditListProps, "items" | "onChange"> & { items: string;  onChange(items: string): void; };

function stringToKeyValues(value: string): KeyValuePair[] {
    if (value === null || value === undefined) {
        return [];
    }

    try {
        const source = JSON.parse(value || "{}");
        return Object.keys(source).reduce((arr, key) => {
            arr.push({key, value: source[key]});
            return arr;
        }, []);
    } catch (e) {
        return [];
    }
}

function keyValuesToHash(values: KeyValuePair[]) {
    return values.reduce((idx: any, item) => {
        idx[item.key] = item.value;
        return idx;
    }, {});
}

const StringKeyValueEditList: React.SFC<StringKeyValueEditListProps & BoundFieldProps> = ({items, onChange, ...rest}) => (
    <KeyValueEditList
        items={() => stringToKeyValues(items)}
        onChange={(values) => onChange(JSON.stringify(keyValuesToHash(values)))}
        {...rest} />
);

export default StringKeyValueEditList;