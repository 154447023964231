import {OctopusError} from "client/resources";
import { getType } from "typesafe-actions";
import { Reducer } from "redux";
import { ErrorAction, clearUnhandledErrors, raiseUnhandledError} from "./actions";
import { UnhandledError } from "../UnhandledError";

export type UnhandledErrorState = null | UnhandledError;

const getStoredError = (state: UnhandledErrorState, error: Error, id: string | number | null = null) => {
    if (error instanceof OctopusError) {
        return {
            id: id || error.StatusCode,
            message: error.ErrorMessage,
            details: error.Errors,
            originalError: error
        };
    }

    if (error instanceof Error) {
        const details = error.stack.split(" at ");
        return {
            id: id || details[0],
            message: error.message,
            details,
            originalError: error
        };
    }

    return {
        id: id || "unknown",
        message: JSON.stringify(error),
        details: null,
        originalError: null
    };
};

const currentUnhandledErrorReducer: Reducer<UnhandledErrorState, ErrorAction> = (state = null, action) => {
    switch (action.type) {
        case getType(raiseUnhandledError):
            const { error, id } = action.payload;
            return getStoredError(state, error, id);
        case getType(clearUnhandledErrors):
            return null;
    }
    return state;
};

export default currentUnhandledErrorReducer;