import Onboarding from "./Onboarding";
import * as React from "react";
import {
    EnvironmentResource,
    EnvironmentsSummaryResource,
    MachineModelHealthStatus,
    TenantResource,
    Permission,
    CommunicationStyle,
} from "client/resources";
import { repository } from "clientInstance";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { NavigationButton } from "components/Button";
import { RouteComponentProps } from "react-router";
import InfrastructureLayout from "../InfrastructureLayout";
import PermissionCheck from "../../../../components/PermissionCheck/PermissionCheck";
import OverflowMenu from "components/Menu/OverflowMenu";
import { ActionButtonType } from "components/Button";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import EnvironmentsSorter from "./EnvironmentsSorter";
import EnvironmentSummarySection from "./EnvironmentSummarySection";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import ExpansionButtons from "components/form/Sections/ExpansionButtons";
import * as tenantTagsets from "components/tenantTagsets";
import { TagIndex } from "components/tenantTagsets";
import AddEnvironmentsDialog from "./AddEnvironmentsDialog";
import AdvancedFilterLayout, { FilterSection, AdvancedFilterCheckbox } from "components/AdvancedFilterLayout";
import EnvironmentSummaryMachineFilter from "./EnvironmentSummaryMachineFilter";
import { isEqual } from "lodash";
import {
    TenantMultiSelect,
    RoleMultiSelect,
    TenantTagMultiSelect,
    EnvironmentMultiSelect,
    MachineModelHealthStatusMultiSelect,
    EndpointCommunicationStyleMultiSelect,
} from "components/MultiSelect";
import { connect } from "react-redux";
import { machineActions } from "../../reducers/machines";
import MachineHealthStatusHelper from "utils/MachineHealthStatusHelper";
import { Section } from "components/Section/Section";
import { arrayValueFromQueryString } from "utils/ParseHelper/ParseHelper";
import ActionList from "components/ActionList/ActionList";
import routeLinks from "routeLinks";
import { EnvironmentSummaryArgs } from "client/repositories/environmentRepository";
import RequestRaceConditioner from "utils/RequestRaceConditioner";
import { AdvancedFilterTextInput } from "components/AdvancedFilterLayout/Text/AdvancedFilterTextInput";
import { QueryStringFilters } from "components/QueryStringFilters/QueryStringFilters";
import { EnvironmentSummaryMachineQuery } from "areas/infrastructure/components/EnvironmentsLayout/EnvironmentSummaryMachineQuery";
import { FeatureToggle, Feature } from "components/FeatureToggle";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import NumberedPagingBar from "components/PagingBaseComponent/NumberedPagingBar";
import MachineFilter from "areas/infrastructure/components/MachinesLayout/MachineFilter";
import { NoResults } from "components/NoResults/NoResults";
import EndpointsHelper from "utils/EndpointsHelper/EndpointsHelper";
import ConfirmTentacleUpgradePanel from "../MachinesLayout/ConfirmTentacleUpgradePanel";

export interface EnvironmentsRouteParams {
    ids: string;
}

interface EnvironmentLayoutProps extends RouteComponentProps<EnvironmentsRouteParams> {
    onClearMachine?(): void;
}

interface EnvironmentLayoutState extends DataBaseComponentState {
    environmentsSummary: EnvironmentsSummaryResource;
    filter: EnvironmentSummaryMachineFilter;
    queryFilter?: EnvironmentSummaryMachineFilter;
    isSearching?: boolean;
    environments: EnvironmentResource[];
    machineRoles: string[];
    tenants: TenantResource[];
    tagIndex: TagIndex;
    showOnboarding: boolean;
    redirectToTaskId?: string;
    currentPageIndex: number;
    redirectToEnvironmentId?: string;
}

class FilterLayout extends AdvancedFilterLayout<EnvironmentSummaryMachineFilter> { }

const EnvironmentQueryStringFilters = QueryStringFilters.For<EnvironmentSummaryMachineFilter, EnvironmentSummaryMachineQuery>();

const PageSize = 20;

class EnvironmentsLayoutInternal extends DataBaseComponent<EnvironmentLayoutProps, EnvironmentLayoutState> {
    private machineHealthStatuses = MachineHealthStatusHelper.getMachineModelHealthStatusResources();
    private communicationStyles = EndpointsHelper.getCommunicationStyleResources();
    private requestRaceConditioner = new RequestRaceConditioner();

    constructor(props: EnvironmentLayoutProps) {
        super(props);

        this.state = ({
            environmentsSummary: null,
            filter: createEmptyFilter(),
            isSearching: false,
            machineRoles: null,
            environments: null,
            tenants: null,
            tagIndex: null,
            showOnboarding: false,
            currentPageIndex: 0,
        });
    }

    async componentDidMount() {
        await this.doBusyTask(() => this.loadLookupData());
        await this.refreshEnvironmentSummaryData();

        // Clear currentMachine (to avoid seeing old machine data when switching machines).
        this.props.onClearMachine();
    }

    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={routeLinks.task(this.state.redirectToTaskId).root} push={true} />;
        }
        if (this.state.redirectToEnvironmentId) {
            return <InternalRedirect to={routeLinks.infrastructure.environment(this.state.redirectToEnvironmentId)} push={true} />;
        }

        const actions = [
            this.state.environments && this.state.environments.length > 0 &&
            <PermissionCheck permission={Permission.MachineCreate} environment="*" tenant="*">
                <NavigationButton href={routeLinks.infrastructure.machines.new()} label="Add deployment target" />
            </PermissionCheck>,
            <PermissionCheck permission={Permission.EnvironmentCreate} environment="*">
                <OpenDialogButton label="Add Environment" type={ActionButtonType.Primary}>
                    <AddEnvironmentsDialog
                        saveDone={async env => {
                            this.setState({ redirectToEnvironmentId: env.Id });
                        }} />
                </OpenDialogButton>
            </PermissionCheck>,
            this.state.environments && this.state.environments.length > 0 && <PermissionCheck permission={Permission.EnvironmentEdit} environment="*">
                <OverflowMenu menuItems={[
                    OverflowMenu.dialogItem("Reorder", <EnvironmentsSorter saveDone={this.refreshEnvironmentSummaryData} />)
                ]} />
            </PermissionCheck>
        ];

        const actionSection = <ActionList actions={actions} />;

        let environmentExpanders: React.ReactNode = null;
        const environmentSummaries = this.state.environmentsSummary && this.state.environmentsSummary.EnvironmentSummaries;
        if (environmentSummaries) {
            const start = this.state.currentPageIndex === 0 ? 0 : this.state.currentPageIndex * PageSize;
            const end = start + PageSize;
            environmentExpanders = environmentSummaries
                .slice(start, end)
                .map((environmentsSummary) => {
                    // Do not confuse partialName and machinePartialName filters here or it breaks the environment name filtering.
                    const machineFilter: MachineFilter = {
                        partialName: this.state.filter.machinePartialName,
                        environmentIds: this.state.filter.environmentIds,
                        workerPoolIds: null,
                        roles: this.state.filter.roles,
                        isDisabled: this.state.filter.isDisabled,
                        healthStatuses: this.state.filter.healthStatuses,
                        commStyles: this.state.filter.commStyles,
                        tenantIds: this.state.filter.tenantIds,
                        tenantTags: this.state.filter.tenantTags,
                    };
                    return <EnvironmentSummarySection
                        key={environmentsSummary.Environment.Id}
                        environmentSummary={environmentsSummary}
                        filter={machineFilter}
                        tenants={this.state.tenants}
                        tagIndex={this.state.tagIndex} />;
                });
        }
        if (this.state.environmentsSummary && this.state.environmentsSummary.EnvironmentSummaries.length === 0) {
            environmentExpanders = <Section><NoResults /></Section>;
        }

        const filterSections: FilterSection[] = [{
            render: <div>
                <AdvancedFilterCheckbox
                    label="Disabled only"
                    value={this.state.filter.isDisabled}
                    onChange={x => {
                        this.setFilterState({ isDisabled: x }, () => {
                            this.onFilterChange();
                        });
                    }}
                />
                <AdvancedFilterCheckbox
                    label="Hide empty environments"
                    value={this.state.filter.hideEmptyEnvironments}
                    onChange={x => {
                        this.setFilterState({ hideEmptyEnvironments: x }, () => {
                            this.onFilterChange();
                        });
                    }}
                />
                <AdvancedFilterTextInput
                    fieldName={"deployment target"}
                    value={this.state.filter.machinePartialName}
                    onChange={x => {
                        this.setFilterState({ machinePartialName: x }, () => {
                            this.onFilterChange();
                        });
                    }}
                />
                <EnvironmentMultiSelect
                    items={this.state.environments}
                    value={this.state.filter.environmentIds}
                    onChange={x => {
                        this.setFilterState({ environmentIds: x }, () => {
                            this.onFilterChange();
                        });
                    }}
                />
                <RoleMultiSelect
                    items={this.state.machineRoles ? this.state.machineRoles : []}
                    value={this.state.filter.roles}
                    onChange={x => {
                        this.setFilterState({ roles: x }, () => {
                            this.onFilterChange();
                        });
                    }}
                />
                <MachineModelHealthStatusMultiSelect
                    items={this.machineHealthStatuses}
                    value={this.state.filter.healthStatuses}
                    onChange={x => {
                        this.setFilterState({ healthStatuses: x }, () => {
                            this.onFilterChange();
                        });
                    }}
                />
                <EndpointCommunicationStyleMultiSelect
                    items={this.communicationStyles}
                    value={this.state.filter.commStyles}
                    onChange={x => {
                        this.setFilterState({ commStyles: x }, () => {
                            this.onFilterChange();
                        });
                    }}
                />
                <FeatureToggle feature={Feature.MultiTenancy}>
                    <PermissionCheck permission={Permission.TenantView} tenant="*">
                        <TenantMultiSelect
                            value={this.state.filter.tenantIds}
                            items={this.state.tenants}
                            onChange={x => {
                                this.setFilterState({ tenantIds: x }, () => {
                                    this.onFilterChange();
                                });
                            }}
                        />
                        <TenantTagMultiSelect
                            value={this.state.filter.tenantTags}
                            doBusyTask={this.doBusyTask}
                            onChange={x => {
                                this.setFilterState({ tenantTags: x }, () => {
                                    this.onFilterChange();
                                });
                            }}
                        />
                    </PermissionCheck>
                </FeatureToggle>
            </div>
        }];

        const tentacleUpgradesRequiredWarning = environmentSummaries &&
            environmentSummaries.length > 0 &&
            environmentSummaries.find(x => x.TentacleUpgradesRequired === true) &&
            <ConfirmTentacleUpgradePanel doBusyTask={this.doBusyTask}
                calloutDescriptionElement={<p>One or more deployment targets are running old versions of the Tentacle deployment agent and can be upgraded.</p>}
                onTentacleUpgradeComplete={taskId => {
                    this.setState({ redirectToTaskId: taskId });
                }}
            />;

        return <InfrastructureLayout {...this.props}>
            <EnvironmentQueryStringFilters
                filter={this.state.filter}
                getQuery={this.queryFromFilter}
                getFilter={this.getFilter}
                onFilterChange={filter => this.setState({ filter, queryFilter: filter }, () => this.onFilterChange())} />
            <PaperLayout
                busy={this.state.busy}
                errors={this.state.errors}
                title="Environments"
                sectionControl={actionSection}>
                {this.state.showOnboarding && <Onboarding />}
                {environmentSummaries && !this.state.showOnboarding && <div>
                    {tentacleUpgradesRequiredWarning}
                    <FilterLayout filterSections={filterSections}
                        filter={this.state.filter}
                        queryFilter={this.state.queryFilter}
                        defaultFilter={createEmptyFilter()}
                        initiallyShowFilter={this.isFiltering()}
                        additionalHeaderFilters={[<FilterSearchBox
                            hintText={"Search environments..."}
                            value={this.state.filter.partialName}
                            onChange={x => {
                                this.setFilterState({ partialName: x }, () => {
                                    this.onFilterChange();
                                });
                            }}
                            autoFocus={true}
                        />]}
                        onFilterReset={(filter: EnvironmentSummaryMachineFilter) => {
                            this.setState({ filter }, () => {
                                this.onFilterChange();
                                const location = { ...this.props.history, search: null as any };
                                this.props.history.replace(location);
                            });
                        }}
                        renderContent={() => <div>
                            {this.state.environmentsSummary && this.state.environmentsSummary.EnvironmentSummaries.length > 0 && <ExpansionButtons />}
                            {environmentExpanders}
                            {this.state.environmentsSummary && this.state.environmentsSummary.EnvironmentSummaries.length > 0 &&
                                <NumberedPagingBar currentPageIndex={this.state.currentPageIndex}
                                    totalItems={this.state.environmentsSummary.EnvironmentSummaries.length}
                                    pageSize={PageSize}
                                    onPageSelected={(_, currentPageIndex) => this.setState({ currentPageIndex })}
                                />}
                        </div>}
                    />
                </div>}
            </PaperLayout>
        </InfrastructureLayout>;
    }

    private setFilterState<K extends keyof EnvironmentSummaryMachineFilter>(state: Pick<EnvironmentSummaryMachineFilter, K>, callback?: () => void) {
        this.setState(prev => ({
            filter: { ...prev.filter as object, ...state as object }
        }), callback);
    }

    private isFiltering() {
        return !isEqual(this.state.filter, createEmptyFilter());
    }

    private onFilterChange() {
        this.setState({ isSearching: true }, async () => {
            await this.refreshEnvironmentSummaryData();
            this.setState({ isSearching: false });
        });
    }

    private queryFromFilter = (filter: EnvironmentSummaryMachineFilter): EnvironmentSummaryMachineQuery => {
        const query: EnvironmentSummaryMachineQuery = {
            partialName: filter.partialName,
            machinePartialName: filter.machinePartialName,
            environmentIds: filter.environmentIds,
            healthStatuses: filter.healthStatuses,
            commStyles: filter.commStyles,
            roles: filter.roles,
            isDisabled: filter.isDisabled ? "true" : undefined,
            tenantIds: filter.tenantIds,
            tenantTags: filter.tenantTags,
            hideEmptyEnvironments: filter.hideEmptyEnvironments ? "true" : undefined
        };

        return query;
    }

    private getFilter = (query: EnvironmentSummaryMachineQuery): EnvironmentSummaryMachineFilter => {
        const filter: EnvironmentSummaryMachineFilter = {
            ...createEmptyFilter(),
            partialName: query.partialName,
            machinePartialName: query.machinePartialName,
            environmentIds: arrayValueFromQueryString(query.environmentIds) || [],
            healthStatuses: arrayValueFromQueryString(query.healthStatuses) as MachineModelHealthStatus[] || [],
            commStyles: arrayValueFromQueryString(query.commStyles) as CommunicationStyle[] || [],
            roles: arrayValueFromQueryString(query.roles) || [],
            isDisabled: query.isDisabled === "true",
            tenantIds: arrayValueFromQueryString(query.tenantIds) || [],
            tenantTags: arrayValueFromQueryString(query.tenantTags) || [], // Expecting canonical tag names
            hideEmptyEnvironments: query.hideEmptyEnvironments === "true",
        };

        return filter;
    }

    private refreshEnvironmentSummaryData = async () => {
        await this.doBusyTask(() => this.loadEnvironmentSummaries());
    }

    private async loadEnvironmentSummaries() {
        const filter = this.state.filter;
        const args: Partial<EnvironmentSummaryArgs> = {};
        if (filter.roles && filter.roles.length) {
            args.roles = filter.roles.join(",");
        }
        if (filter.isDisabled) {
            args.isDisabled = true;
        }
        if (filter.environmentIds && filter.environmentIds.length) {
            args.ids = filter.environmentIds.join(",");
        }
        if (filter.healthStatuses && filter.healthStatuses.length) {
            args.healthStatuses = filter.healthStatuses.join(",");
        }
        if (filter.commStyles && filter.commStyles.length) {
            args.commStyles = filter.commStyles.join(",");
        }
        if (filter.healthStatuses && filter.healthStatuses.length) {
            args.healthStatuses = filter.healthStatuses.join(",");
        }
        if (filter.tenantIds && filter.tenantIds.length) {
            args.tenantIds = filter.tenantIds.join(",");
        }
        if (filter.partialName && filter.partialName !== "") {
            args.partialName = filter.partialName;
        }
        if (filter.machinePartialName && filter.machinePartialName !== "") {
            args.machinePartialName = filter.machinePartialName;
        }
        if (filter.tenantTags && filter.tenantTags.length && this.state.tagIndex) {
            args.tenantTags = filter.tenantTags.map(name => this.state.tagIndex[name].Id).join(",");
        }
        if (filter.hideEmptyEnvironments) {
            args.hideEmptyEnvironments = true;
        }
        await this.requestRaceConditioner.avoidStaleResponsesForRequest(repository.Environments.summary(args), (response) => {
            this.setState({
                environmentsSummary: response as EnvironmentsSummaryResource,
                currentPageIndex: 0
            });
        });
    }

    private async loadLookupData() {
        const environmentsPromise = repository.Environments.all();
        const machineRolesPromise = repository.MachineRoles.all();
        const tenantsPromise = repository.Tenants.all();

        this.setState({
            environments: await environmentsPromise,
            machineRoles: await machineRolesPromise,
            tenants: await tenantsPromise,
            tagIndex: await tenantTagsets.getTagIndex(),
            showOnboarding: (await repository.Environments.list({ take: 0 })).TotalResults === 0,
        });
    }
}

function createEmptyFilter(): EnvironmentSummaryMachineFilter {
    return {
        partialName: undefined,
        environmentIds: [],
        machinePartialName: undefined,
        roles: [],
        isDisabled: false,
        tenantIds: [],
        tenantTags: [],
        healthStatuses: [],
        commStyles: [],
        hideEmptyEnvironments: false,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        onClearMachine: () => {
            dispatch(machineActions.machineCleared());
        },
    };
};

const EnvironmentsLayout = connect<{}, {}, EnvironmentLayoutProps>(
    null,
    mapDispatchToProps
)(EnvironmentsLayoutInternal);

export default EnvironmentsLayout;
