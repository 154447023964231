import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonWorkerOverview, CommonWorkerTopics } from "../../CommonComponents/CommonWorkerViews";

export const InfrastructureWorkersRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.infrastructure.workerMachines.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonWorkerOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonWorkerTopics />;
    }
};
