import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
import { TopicsContainer, LearnMoreResources } from "./CommonViews";

export const CommonConfigurationRoleOverview: React.StatelessComponent<{}> = props => {
    return <OverviewContainer>
        <OverviewHighlightText>
            <p><ExternalLink href="UserRoles">User roles</ExternalLink> and group permissions play a major part in the Octopus security model. These roles are assigned to teams and they dictate what the members of those teams can do in Octopus.</p>
            <p>Octopus comes with a common set of built-in roles, and you can create custom user roles to suit your needs.</p>
        </OverviewHighlightText>
    </OverviewContainer>;
};

export const CommonConfigurationRoleTopics: React.StatelessComponent<{}> = props => {
    return <TopicsContainer>
        <LearnMoreResources>
            <div><ExternalLink href="UserRoles">User Roles</ExternalLink></div>
        </LearnMoreResources>
    </TopicsContainer>;
};