import * as React from "react";
import {
    Note, UnstructuredFormSection
} from "components/form";
import {Callout, CalloutType} from "components/Callout";
import ExternalLink from "components/Navigation/ExternalLink";
import { TentacleCalloutHeading } from "./TentacleCalloutHeading";

const styles = require("./styles.less");
export const LinuxListeningTentacleCallout: React.SFC<{thumbprint: React.ReactNode}> = ({thumbprint}) => (
    <UnstructuredFormSection stretchContent={true}>
        <Callout type={CalloutType.Information} title={"Installation Instructions"}>
            <TentacleCalloutHeading/>
            <div>
                <p>Linux Tentacle is in early access. For getting started instructions please visit the <ExternalLink href="LinuxListeningTentacle">Linux Tentacle documentation</ExternalLink>.</p>
                {thumbprint}
            </div>

        </Callout>
    </UnstructuredFormSection>
);

export default LinuxListeningTentacleCallout;