import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import {
    CommonDeploymentTargetOverview,
    CommonDeploymentTargetTopics,
} from "../../CommonComponents/CommonDeploymentTargetViews";

export const InfrastructureDeploymentTargetsFilteredRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.infrastructure.machines.filtered,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonDeploymentTargetOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonDeploymentTargetTopics />;
    }
};
