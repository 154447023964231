import * as React from "react";

import {
    Frame,
    FullScreenDialogFrame,
    LargeDialogFrame,
    MediumDialogFrame,
    SmallDialogFrame
} from "./CustomDialogFrames";

export const KnownFrames: Record<string, Frame> = {
    Small: SmallDialogFrame,
    Medium: MediumDialogFrame,
    Large: LargeDialogFrame,
    Full: FullScreenDialogFrame
};

interface ToggleFrameRenderProps {
    toggleFrame: () => void;
    isInitialFrame: boolean;
    frame: Frame;
}

export interface ToggleFrameProps {
    initialFrame: Frame;
    alternateFrame: Frame;
    render: (renderProps: ToggleFrameRenderProps) => React.ReactNode;
}

export interface ToggleFrameState {
    current: Frame;
}

export class ToggleFrame extends React.Component<ToggleFrameProps, ToggleFrameState> {
    constructor(props: ToggleFrameProps) {
        super(props);
        this.state = {
            current: props.initialFrame
        };
    }

    render() {
        return this.props.render({
            frame: this.state.current,
            isInitialFrame: this.state.current === this.props.initialFrame,
            toggleFrame: this.toggle
        });
    }

    toggle = () => this.setState({
        current: this.state.current === this.props.initialFrame ? this.props.alternateFrame : this.props.initialFrame
    })
}

export default ToggleFrame;