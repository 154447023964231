import { WorkerPoolResource } from "../../../../../client/resources/index";
import * as React from "react";
import {
    AzureServiceFabricClusterEndpointResource,
} from "client/resources";
import {
    ExpandableFormSection,
    Summary,
    Note,
} from "components/form";
import ExternalLink from "components/Navigation/ExternalLink";
import RadioButton from "components/form/RadioButton/RadioButton";
import { DoBusyTask } from "components/DataBaseComponent/DataBaseComponent";
import Select from "components/form/Select/Select";
import CommonSummaryHelper from "utils/CommonSummaryHelper/CommonSummaryHelper";
import { VariableLookupText } from "components/form/VariableLookupText";
import { BoundStringRadioButtonGroup } from "components/form/RadioButton/RadioButtonGroup";
import { BoundSensitive } from "components/form/Sensitive/Sensitive";
import { SummaryNode } from "components/form/Sections/ExpandableFormSection";
import { AzureServiceFabricSecurityMode, AADCredentialType } from "client/resources/endpointResource";
import CertificateSelect from "components/form/CertificateSelect/CertificateSelect";
import { SensitiveValue } from "client/resources/propertyValueResource";
import AzureCategory from "./AzureCategoryDefinition";
import { CategorizedEndpointRegistration, CommunicationStyle, EndpointRegistrationKey } from "./endpointRegistry";
import { EndpointThumbnail } from "./EndpointThumbnail";
import EndpointCard from "./EndpointCard";

interface AzureServiceFabricClusterEndpointProps {
    doBusyTask: DoBusyTask;
    busy: Promise<any> | boolean;
    endpoint: AzureServiceFabricClusterEndpointResource;
    workerPools: WorkerPoolResource[];
    getFieldError(field: string): string;
    onChange(newValue: AzureServiceFabricClusterEndpointResource): void;
}

// tslint:disable-next-line:no-empty-interface
interface AzureServiceFabricClusterEndpointState {
}

class AzureServiceFabricClusterEndpoint extends React.Component<AzureServiceFabricClusterEndpointProps, AzureServiceFabricClusterEndpointState> {

    constructor(props: AzureServiceFabricClusterEndpointProps) {
        super(props);
        this.state = {
        };
    }

    render() {
        return <div>
            <ExpandableFormSection
                errorKey="Octopus.Action.ServiceFabric.ConnectionEndpoint"
                title="Connection Endpoint"
                summary={this.endpointSummary()}
                help={"Enter the connection endpoint of your Service Fabric cluster."}>
                <VariableLookupText
                    localNames={null}
                    projectId={null}
                    value={this.props.endpoint.ConnectionEndpoint}
                    onChange={(x) => {
                        const endpoint = this.props.endpoint;
                        endpoint.ConnectionEndpoint = x;
                        this.props.onChange(endpoint);
                    }}
                    error={this.props.getFieldError("ConnectionEndpoint")}
                    label="Connection endpoint" />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey={null}
                title="Security Mode"
                summary={this.securityModeSummary()}
                help={"Select the security mode use to connect to your Service Fabric cluster."}>
                <BoundStringRadioButtonGroup
                    variableLookup={{
                        localNames: null,
                        projectId: null
                    }}
                    resetValue={AzureServiceFabricSecurityMode.Unsecure}
                    value={this.props.endpoint.SecurityMode}
                    onChange={(x) => {
                        const endpoint = this.props.endpoint;
                        endpoint.SecurityMode = x as AzureServiceFabricSecurityMode;

                        // When they change this mode, clear data from any of the other fields to avoid potentially confusing validation messages.
                        endpoint.ServerCertThumbprint = null;
                        endpoint.ClientCertVariable = null;
                        endpoint.AadClientCredentialSecret = null;
                        endpoint.AadUserCredentialUsername = null;
                        endpoint.AadUserCredentialPassword = null;

                        this.props.onChange(endpoint);
                    }}
                    label="Security mode">
                    <RadioButton value={AzureServiceFabricSecurityMode.Unsecure} label="Unsecure" isDefault/>
                    <RadioButton value={AzureServiceFabricSecurityMode.SecureClientCertificate} label="Secure Client Certificate"/>
                    <Note>To learn about client certificate authentication, please see the <ExternalLink href="ServiceFabricDeployingAppsAuthClientCerts">Connecting
                        Securely with Client Certificates documentation</ExternalLink>.</Note>
                    <RadioButton value={AzureServiceFabricSecurityMode.SecureAzureAD} label="Secure Azure Active Directory (AAD)"/>
                    <Note>To learn about AAD authentication, please see the <ExternalLink href="ServiceFabricDeployingAppsAuthAzureAd">Connecting Securely with
                        Azure Active Directory documentation</ExternalLink>.</Note>
                </BoundStringRadioButtonGroup>

                {(this.props.endpoint.SecurityMode !== AzureServiceFabricSecurityMode.Unsecure) && <div>
                    <VariableLookupText
                        localNames={null}
                        projectId={null}
                        value={this.props.endpoint.ServerCertThumbprint}
                        onChange={(x) => {
                            const endpoint = this.props.endpoint;
                            endpoint.ServerCertThumbprint = x;
                            this.props.onChange(endpoint);
                        }}
                        error={this.props.getFieldError("ServerCertThumbprint")}
                        label="Server certificate thumbprint" />
                    <Note>The server certificate thumbprint used to communicate with the secure cluster.</Note>
                </div>}

                {(this.props.endpoint.SecurityMode === AzureServiceFabricSecurityMode.SecureClientCertificate) && <div>
                    <CertificateSelect
                        allowClear={true}
                        value={this.props.endpoint.ClientCertVariable}
                        error={this.props.getFieldError("ClientCertVariable")}
                        onChange={(x) => {
                            const endpoint = this.props.endpoint;
                            endpoint.ClientCertVariable = x;
                            this.props.onChange(endpoint);
                        }}
                        doBusyTask={this.props.doBusyTask}
                    />
                    <Note>The client certificate used to communicate with the secure cluster.</Note>

                    <VariableLookupText
                        localNames={null}
                        projectId={null}
                        value={this.props.endpoint.CertificateStoreLocation}
                        onChange={(x) => {
                            const endpoint = this.props.endpoint;
                            endpoint.CertificateStoreLocation = x;
                            this.props.onChange(endpoint);
                        }}
                        error={this.props.getFieldError("CertificateStoreLocation")}
                        label="Optionally, override the default certificate store location" />
                    <Note>This defaults to "LocalMachine"</Note>

                    <VariableLookupText
                        localNames={null}
                        projectId={null}
                        value={this.props.endpoint.CertificateStoreName}
                        onChange={(x) => {
                            const endpoint = this.props.endpoint;
                            endpoint.CertificateStoreName = x;
                            this.props.onChange(endpoint);
                        }}
                        error={this.props.getFieldError("CertificateStoreName")}
                        label="Optionally, override the default certificate store name" />
                    <Note>This defaults to "MY"</Note>
                </div>}

                {(this.props.endpoint.SecurityMode === AzureServiceFabricSecurityMode.SecureAzureAD) && <div>
                    {/*NOTE: mark.siedle - ClientCredential is not currently supported on Azure. Uncomment this when they do add support for it.*/}
                    {false && <div>
                        <BoundStringRadioButtonGroup
                            variableLookup={{
                                localNames: null,
                                projectId: null
                            }}
                            resetValue={AADCredentialType.ClientCredential}
                            value={this.props.endpoint.AadCredentialType}
                            onChange={(x) => {
                                const endpoint = this.props.endpoint;
                                endpoint.AadCredentialType = x as AADCredentialType;
                                this.props.onChange(endpoint);
                            }}
                            label="Credential type">
                            <RadioButton value={AADCredentialType.ClientCredential} label="Client credential" isDefault/>
                            <RadioButton value={AADCredentialType.UserCredential} label="User credential"/>
                        </BoundStringRadioButtonGroup>
                        <Note>The credential type to use for AAD authentication.</Note>
                    </div>}

                    {(this.props.endpoint.AadCredentialType === AADCredentialType.ClientCredential) && <div>
                        <VariableLookupText
                            localNames={null}
                            projectId={null}
                            value={this.props.endpoint.AadClientCredentialSecret}
                            onChange={(x) => {
                                const endpoint = this.props.endpoint;
                                endpoint.AadClientCredentialSecret = x;
                                this.props.onChange(endpoint);
                            }}
                            error={this.props.getFieldError("AadClientCredentialSecret")}
                            label="Client application secret" />
                        <Note>The client application secret used to communicate with the secure cluster.</Note>
                    </div>}

                    {(this.props.endpoint.AadCredentialType === AADCredentialType.UserCredential) && <div>
                        <VariableLookupText
                            localNames={null}
                            projectId={null}
                            value={this.props.endpoint.AadUserCredentialUsername}
                            onChange={(x) => {
                                const endpoint = this.props.endpoint;
                                endpoint.AadUserCredentialUsername = x;
                                this.props.onChange(endpoint);
                            }}
                            error={this.props.getFieldError("AadUserCredentialUsername")}
                            label="Username" />
                        <Note>The Azure AD user's username used to communicate with the secure cluster.</Note>
                        <BoundSensitive
                            variableLookup={{
                                localNames: null,
                                projectId: null
                            }}
                            resetValue={""}
                            value={this.props.endpoint.AadUserCredentialPassword as any}
                            onChange={(x) => {
                                const endpoint = this.props.endpoint;
                                endpoint.AadUserCredentialPassword = x as SensitiveValue;
                                this.props.onChange(endpoint);
                            }}
                            error={this.props.getFieldError("AadUserCredentialPassword")}
                            label="Password" />
                        <Note>The Azure AD user's password used to communicate with the secure cluster.</Note>
                    </div>}
                </div>}

            </ExpandableFormSection>

            {this.props.workerPools.length > 1 &&
                <ExpandableFormSection
                    errorKey={"DefaultWorkerPool"}
                    title="Worker Pool"
                    summary={this.props.endpoint.DefaultWorkerPoolId ? CommonSummaryHelper.resourceSummary(this.props.endpoint.DefaultWorkerPoolId, this.props.workerPools, "worker pool") : Summary.placeholder("No pool selected - default pool")}
                    help="Select a default pool for this target (optional).">
                    <Select
                        label={"Select a default pool"}
                        items={this.props.workerPools.map((e) => ({ value: e.Id, text: e.Name }))}
                        value={this.props.endpoint.DefaultWorkerPoolId}
                        allowFilter={true}
                        allowClear={true}
                        onChange={x => this.props.onChange({ ...this.props.endpoint, DefaultWorkerPoolId: x })}
                    />
                </ExpandableFormSection>}
        </div>;
    }

    private endpointSummary(): SummaryNode {
        if (this.props.endpoint.ConnectionEndpoint) {
            return Summary.summary(this.props.endpoint.ConnectionEndpoint);
        }
        return Summary.placeholder("The connection endpoint has not been set");
    }

    private securityModeSummary(): SummaryNode {
        if (this.props.endpoint.SecurityMode === AzureServiceFabricSecurityMode.Unsecure) {
            return Summary.default("Unsecure");
        }
        const summary = [];
        const thumbprint = this.props.endpoint.ServerCertThumbprint;
        if (this.props.endpoint.SecurityMode === AzureServiceFabricSecurityMode.SecureClientCertificate) {
            summary.push(<span>Security mode is <strong>Client Certificate</strong></span>);
            this.contributeCertificateThumbprintSummary(thumbprint, summary);
            if (!this.props.endpoint.ClientCertVariable) {
                summary.push(<span>,  the client certificate has <strong>not</strong> been set</span>);
            } else {
                summary.push(<span>,  the client certificate has been set</span>);
            }
            return Summary.summary(React.Children.toArray(summary));
        }
        if (this.props.endpoint.SecurityMode === AzureServiceFabricSecurityMode.SecureAzureAD) {
            summary.push(<span>Security mode is <strong>Azure Active Directory</strong></span>);
            this.contributeCertificateThumbprintSummary(thumbprint, summary);
            if (!this.props.endpoint.AadUserCredentialUsername) {
                summary.push(<span>,  the AAD credentials have <strong>not</strong> been set</span>);
            } else {
                summary.push(<span>,  the AAD credentials have been set</span>);
            }
            return Summary.summary(React.Children.toArray(summary));
        }
        return Summary.placeholder("Security mode has not been set");
    }

    private contributeCertificateThumbprintSummary(thumbprint: string, summary: any[]) {
        if (!thumbprint) {
            summary.push(<span>,  the certificate thumbprint has <strong>not</strong> been set</span>);
        } else {
            summary.push(<span>,  the certificate thumbprint has been set</span>);
        }
    }
}

export default AzureServiceFabricClusterEndpoint;

const azureServiceFabricClusterEndpointImage = require("./azure-service-fabric.svg");
const azureServiceFabricClusterEndpointRegistration: CategorizedEndpointRegistration = {
    key: EndpointRegistrationKey.AzureServiceFabricCluster,
    displayOrder: 20,
    categories: [ AzureCategory ],
    name: "Service Fabric Cluster",
    communicationStyle: CommunicationStyle.AzureServiceFabricCluster,
    renderCard: ({registration, category, getNavigationProps}) => (
        <EndpointCard
            logo={<EndpointThumbnail src={azureServiceFabricClusterEndpointImage} alt={registration.name} />}
            header={registration.name}
            description="Connect to an existing Service Fabric Cluster (supports both Azure and on-prem)."
            {...getNavigationProps()}
        />
    )
};

export { azureServiceFabricClusterEndpointRegistration };