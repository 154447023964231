import * as React from "react";
import * as _ from "lodash";
import pluginRegistry, {ActionEditProps} from "../pluginRegistry";
import {BaseComponent} from "components/BaseComponent/BaseComponent";
import {repository} from "clientInstance";
import {ActionSummaryProps} from "../actionSummaryProps";
import {
    ActionExecutionLocation, GetPrimaryPackageReference, InitialisePrimaryPackageReference,
    SetPrimaryPackageReference
} from "../../../client/resources";
import ExpanderSectionHeading from "components/form/Sections/FormSectionHeading";
import FeedResource, {FeedType} from "client/resources/feedResource";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import CommonSummaryHelper from "utils/CommonSummaryHelper/CommonSummaryHelper";
import PackageSelector from "components/PackageSelector/PackageSelector";
import PackageDownloadOptions from "components/PackageDownloadOptions/PackageDownloadOptions";
import { VariableLookupText } from "components/form/VariableLookupText";
import Note from "components/form/Note/Note";
import Summary from "components/form/Sections/Summary";
import { BoundStringCheckbox } from "components/form/Checkbox/StringCheckbox";
import routeLinks from "../../../routeLinks";
import {getFeedName} from "../getFeedName";
import Roles from "../Roles";
import { TargetRoles } from "areas/projects/components/DeploymentProcess/ActionDetails";
import { Callout, CalloutType } from "components/Callout/Callout";

interface DeployJavaPackageSummaryState {
    feedName: string;
}

class DeployJavaPackageActionSummary extends BaseComponent<ActionSummaryProps, DeployJavaPackageSummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
        this.state = {feedName: null};
    }

    async componentDidMount() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        if (pkg) {
            /* there was a validation bug that allowed saving of steps to not pick a package */
            this.setState({feedName: await getFeedName(pkg.FeedId)});
        }
    }

    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        return !pkg /* there was a validation bug that allowed saving of steps to not pick a package */
        ? <Callout type={CalloutType.Warning} title="Misconfigured step">
            Package was not selected or cannot be found. Please review this step and ensure a valid package is selected.
        </Callout>
        : <div>
            Deploy a Java package <strong>{pkg.PackageId} </strong>
            from {this.state.feedName ? <strong>{this.state.feedName}</strong> : <em>{pkg.FeedId}</em>}
            {this.props.targetRolesAsCSV && <span> to deployment targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} /></span>}
        </div>;
    }
}

interface DeployJavaPackageProperties {
    "Octopus.Action.Package.TransferPath": string;
    "Octopus.Action.JavaArchive.DeployExploded": string;
    "Octopus.Action.Package.UseCustomInstallationDirectory": string;
    "Octopus.Action.Package.CustomInstallationDirectory": string;
    "Octopus.Action.Package.CustomPackageFileName": string;
    "Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment": string;
    "Octopus.Action.Package.CustomInstallationDirectoryPurgeExclusions": string;
}

interface DeployJavaPackageActionEditState {
    feeds: FeedResource[];
}

export class DeployJavaPackageActionEdit extends BaseComponent<ActionEditProps<DeployJavaPackageProperties>, DeployJavaPackageActionEditState> {
    constructor(props: ActionEditProps<DeployJavaPackageProperties>) {
        super(props);

        this.state = {
            feeds: []
        };
    }

    deploymentSummary() {
        const properties = this.props.properties;
        return Summary.summary(<span>
            Deploying the
            {properties["Octopus.Action.JavaArchive.DeployExploded"] === "True" &&
            <span>&nbsp;exploded</span>}
            &nbsp;package
            {properties["Octopus.Action.Package.CustomPackageFileName"] &&
            <span>&nbsp;named <strong>{properties["Octopus.Action.Package.CustomPackageFileName"]}</strong></span>}
            {properties["Octopus.Action.Package.UseCustomInstallationDirectory"] === "True" &&
            <span>&nbsp;to <strong>{properties["Octopus.Action.Package.CustomInstallationDirectory"]}</strong></span>}
            {properties["Octopus.Action.Package.UseCustomInstallationDirectory"] === "False" &&
            <span>&nbsp;to the default location</span>}
            {properties["Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment"] === "True" &&
            properties["Octopus.Action.Package.UseCustomInstallationDirectory"] === "True" &&
            <span>
                &nbsp;purging the destination
                {properties["Octopus.Action.Package.CustomInstallationDirectoryPurgeExclusions"] &&
                <span>&nbsp;with exclusions</span>}
            </span>}
        </span>);
    }

    async componentDidMount() {
        await this.loadFeeds((feeds) => this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, feeds)));
        await this.props.doBusyTask(async () => {

            const properties: any = {};

            if (this.props.properties["Octopus.Action.Package.CustomInstallationDirectory"]) {
                properties["Octopus.Action.Package.UseCustomInstallationDirectory"] = "True";
            } else if (this.props.properties["Octopus.Action.Package.UseCustomInstallationDirectory"] !== "True" &&
                this.props.properties["Octopus.Action.Package.UseCustomInstallationDirectory"] !== "False") {
                properties["Octopus.Action.Package.UseCustomInstallationDirectory"] = "False";
            }

            if (this.props.properties["Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment"] !== "True" &&
                this.props.properties["Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment"] !== "False") {
                properties["Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment"] = "False";
            }

            if (!this.props.properties["Octopus.Action.JavaArchive.DeployExploded"]) {
                properties["Octopus.Action.JavaArchive.DeployExploded"] = "False";
            }

            this.props.setProperties(properties, true);
        });
    }

    render() {
        // The package is initialized in componentDidMount, but render gets called before the update is propagated
        if (!this.props.packages || this.props.packages.length === 0) {
            return null;
        }

        const properties = this.props.properties;
        const pkg = GetPrimaryPackageReference(this.props.packages);
        const feed = _.find(this.state.feeds, f => f.Id === pkg.FeedId);

        const help = this.state.feeds.length > 0
            ? <span>
                This step is used to deploy a Java archive (.jar, .war, .ear) to one or more machines. You can configure the
                remote machines to deploy to in the <InternalLink to={routeLinks.infrastructure.root} openInSelf={false}>Infrastructure</InternalLink> tab.
            </span>
            : <span>Choose the package you wish to deploy</span>;
        const deploymentErrorKey = "Octopus.Action.JavaArchive.DeployExploded|"
            + "Octopus.Action.Package.CustomPackageFileName|"
            + "Octopus.Action.Package.UseCustomInstallationDirectory|"
            + "Octopus.Action.Package.CustomInstallationDirectory|"
            + "Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment|"
            + "Octopus.Action.Package.CustomInstallationDirectoryPurgeExclusions";

        return <div>
            <ExpanderSectionHeading title="Package Details"/>
            <ExpandableFormSection
                errorKey="Octopus.Action.Package.PackageId|Octopus.Action.Package.FeedId"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Package"
                summary={CommonSummaryHelper.packageSummary(pkg, this.state.feeds)}
                help={help}>
                <PackageSelector
                    packageId={pkg.PackageId}
                    feedId={pkg.FeedId}
                    onPackageIdChange={packageId => this.props.setPackages(SetPrimaryPackageReference({PackageId: packageId}, this.props.packages))}
                    onFeedIdChange={feedId => this.props.setPackages(SetPrimaryPackageReference({FeedId: feedId}, this.props.packages))}
                    packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                    feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                    projectId={this.props.projectId}
                    feeds={this.state.feeds}
                    localNames={this.props.localNames}
                    feedType={[FeedType.Nuget, FeedType.BuiltIn, FeedType.Maven]}
                    refreshFeeds={this.loadFeeds} />
                <PackageDownloadOptions
                    packageAcquisitionLocation={pkg.AcquisitionLocation}
                    onPackageAcquisitionLocationChanged={acquisitionLocation =>
                        this.props.setPackages(SetPrimaryPackageReference({AcquisitionLocation: acquisitionLocation}, this.props.packages))}
                    feed={feed}
                    projectId={this.props.projectId}
                    localNames={this.props.localNames}/>
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey={deploymentErrorKey}
                isExpandedByDefault={this.props.expandedByDefault}
                title="Deployment"
                summary={this.deploymentSummary()}
                help={<span>Choose how and where the package will be deployed.</span>}>
                <BoundStringCheckbox
                    variableLookup={{
                        localNames: this.props.localNames,
                        projectId: this.props.projectId
                    }}
                    resetValue={"False"}
                    value={properties["Octopus.Action.JavaArchive.DeployExploded"]}
                    onChange={(x) => this.props.setProperties({["Octopus.Action.JavaArchive.DeployExploded"]: x})}
                    label="Deploy the extracted package"
                    note={<span> If selected, the package will be deployed extracted.</span>} />
                <Note>
                    <p>
                        Note: The package is always extracted as part of the deployment process, to allow features such
                        as substituting variables in files. By default
                        the package is re-created before deploying to the destination. If the option above is selected
                        it remains extracted.
                    </p>
                </Note>
                {properties["Octopus.Action.JavaArchive.DeployExploded"] === "False" &&
                <div>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        value={properties["Octopus.Action.Package.CustomPackageFileName"]}
                        onChange={(x) => this.props.setProperties({["Octopus.Action.Package.CustomPackageFileName"]: x})}
                        label="Deployed package file name" />
                    <Note>
                        The optional filename of the copied package. If left blank, the original filename from the feed
                        will be retained.
                    </Note>
                </div>}
                <BoundStringCheckbox
                    variableLookup={{
                        localNames: this.props.localNames,
                        projectId: this.props.projectId
                    }}
                    resetValue={"False"}
                    value={properties["Octopus.Action.Package.UseCustomInstallationDirectory"]}
                    onChange={(x) => {
                        this.props.setProperties({["Octopus.Action.Package.UseCustomInstallationDirectory"]: x});
                        if (properties["Octopus.Action.Package.UseCustomInstallationDirectory"] !== "True") {
                            this.props.setProperties({["Octopus.Action.Package.CustomInstallationDirectory"]: ""});
                        }
                    }}
                    label="Use custom deployment directory"
                    note={<span>By default the package will be deployed to the target's application directory. This options allows
                    setting a custom deployment directory.</span>} />
                {properties["Octopus.Action.Package.UseCustomInstallationDirectory"] === "True" &&
                <div>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        value={properties["Octopus.Action.Package.CustomInstallationDirectory"]}
                        onChange={(x) => this.props.setProperties({["Octopus.Action.Package.CustomInstallationDirectory"]: x})}
                        label="Deploy Directory" />
                    <Note>
                        The installed package will be copied to this location on the remote machine.
                    </Note>
                    <BoundStringCheckbox
                        variableLookup={{
                            localNames: this.props.localNames,
                            projectId: this.props.projectId
                        }}
                        resetValue={"False"}
                        value={properties["Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment"]}
                        onChange={(x) => this.props.setProperties({["Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment"]: x})}
                        label="Purge" />
                    <Note>
                        Before the installed package is copied, all files in this location will be removed.
                    </Note>
                    {properties["Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment"] === "True" &&
                    <div>
                        <VariableLookupText
                            localNames={this.props.localNames}
                            projectId={this.props.projectId}
                            value={properties["Octopus.Action.Package.CustomInstallationDirectoryPurgeExclusions"]}
                            onChange={(x) => this.props.setProperties({["Octopus.Action.Package.CustomInstallationDirectoryPurgeExclusions"]: x})}
                            label="Exclude from purge"
                            multiLine={true}
                            rows={5} />
                        <Note>
                            A newline-separated list of file or directory names, relative to the installation directory,
                            to leave when it is purged.
                            To exclude a whole directory, specify it by name without a wildcard.
                            Extended wildcard syntax is supported. E.g., <em>appsettings.config</em>, <em>Config</em>,
                            <em>Config\*.config</em>, <em>**\*.config</em>
                        </Note>
                    </div>}
                </div>}
            </ExpandableFormSection>
        </div>;
    }

    private loadFeeds = (callback?: (feeds: FeedResource[]) => void) => {
        return this.props.doBusyTask(async () => {
            this.setState({ feeds: await repository.Feeds.all() }, () => callback && callback(this.state.feeds));
        });
    }
}

pluginRegistry.registerDeploymentAction({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.JavaArchive",
    summary: (properties, targetRolesAsCSV, packages) =>
        <DeployJavaPackageActionSummary
            properties={properties}
            targetRolesAsCSV={targetRolesAsCSV}
            packages={packages}/>,
    edit: DeployJavaPackageActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => true,
    features: {
        optional: ["Octopus.Features.CustomScripts",
            "Octopus.Features.JsonConfigurationVariables",
            "Octopus.Features.SubstituteInFiles"],
        initial: ["Octopus.Features.SubstituteInFiles"]
    }
});
