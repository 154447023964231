import { getType, createAction, ActionType } from "typesafe-actions";

export const dialogActions = {
    toggle: createAction("DIALOG/TOGGLE", (resolve) => (name: string) => resolve(name)),
    open: createAction("DIALOG/OPEN", (resolve) => (name: string) => resolve(name)),
    close: createAction("DIALOG/CLOSE", (resolve) => (name: string) => resolve(name))
};

export type DialogAction = ActionType<typeof dialogActions>;
export interface DialogState {
    [name: string]: boolean;
}
const INITIAL_STATE: DialogState = {};

const toggleDialog = (state: DialogState, name: string) => {
    if (state.hasOwnProperty(name)) {
        return {...state, [name]: !state[name]};
    }
    return openDialog(state, name);
};

const openDialog = (state: DialogState, name: string) => {
    return {...state, [name]: true };
};

const closeDialog = (state: DialogState, name: string) => {
    if (!state.hasOwnProperty(name)) {
        return state;
    }

    return {...state, [name]: false };
};

export const dialogReducer = (state = INITIAL_STATE, action: DialogAction) => {
    switch (action.type) {
        case getType(dialogActions.toggle):
            return toggleDialog(state, action.payload);
        case getType(dialogActions.close):
            return closeDialog(state, action.payload);
        case getType(dialogActions.open):
            return openDialog(state, action.payload);
    }
    return state;
};

export const isDialogOpen = (name: string) => (state: DialogState) => {
    return state && state.hasOwnProperty(name) && state[name];
};