import * as React from "react";
import {
    ListeningTentacleEndpointResource,
    ProxyResource,
} from "client/resources";
import {
    Text,
    ExpandableFormSection,
    Summary,
    Select,
} from "components/form";
import CommonSummaryHelper from "utils/CommonSummaryHelper";
import {BooleanRadioButtonGroup} from "components/form/RadioButton/RadioButtonGroup";
import RadioButton from "components/form/RadioButton/RadioButton";
import { CategorizedMachineRegistration, CommunicationStyle, EndpointRegistrationKey } from "./endpointRegistry";
import WindowsCategory from "./WindowsCategoryDefinition";
import LinuxCategory from "./LinuxCategoryDefinition";
import { EndpointThumbnail } from "./EndpointThumbnail";
import { EndpointCard } from "./EndpointCard";
import TentacleThumbprint from "./TentacleThumbprint";

interface TentaclePassiveEndpointProps {
    endpoint: ListeningTentacleEndpointResource;
    serverThumbprint: string;
    proxies: ProxyResource[];
    onChange(newValue: ListeningTentacleEndpointResource): void;
}

interface TentaclePassiveEndpointState {
    shouldUseProxy: boolean;
}

class TentaclePassiveEndpoint extends React.Component<TentaclePassiveEndpointProps, TentaclePassiveEndpointState> {

    constructor(props: TentaclePassiveEndpointProps) {
        super(props);
        this.state = {
            shouldUseProxy: !!props.endpoint.ProxyId,
        };
    }

    render() {
        return <div>
            <TentacleThumbprint
                endpoint={this.props.endpoint}
                serverThumbprint={this.props.serverThumbprint}
                onChange={(x) => {
                    const thumbprint = x.Thumbprint;
                    const endpoint = this.props.endpoint;
                    endpoint.Thumbprint = thumbprint;
                    this.props.onChange(endpoint);
                }}
            />
            <ExpandableFormSection
                errorKey="Uri"
                title="Tentacle URL"
                focusOnExpandAll
                summary={this.props.endpoint.Uri ? Summary.summary(this.props.endpoint.Uri) : Summary.placeholder("No uri specified")}
                help="The network address at which the Tentacle can be reached.">
                <Text label="Tentacle URL"
                    hintText="https://example:10933"
                    value={this.props.endpoint.Uri}
                    onChange={(x) => {
                        const endpoint = this.props.endpoint;
                        endpoint.Uri = x;
                        this.props.onChange(endpoint);
                    }}
                    type="url" />
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="Proxy"
                title="Proxy"
                summary={CommonSummaryHelper.resourceSummary(this.props.endpoint.ProxyId, this.props.proxies, "proxy")}
                help="Select whether to use a proxy to connect to this Tentacle.">
                <BooleanRadioButtonGroup
                    label="Connection method"
                    onChange={shouldUseProxy => {
                        this.setState({shouldUseProxy});
                        const endpoint = this.props.endpoint;
                        endpoint.ProxyId = null;
                        this.props.onChange(endpoint);
                    }}
                    value={this.state.shouldUseProxy}>
                    <RadioButton value={false} label="Connect to this Tentacle directly" isDefault={true} />
                    <RadioButton value={true} label="Connect to this Tentacle through a proxy server" />
                </BooleanRadioButtonGroup>
                {this.state.shouldUseProxy &&
                <Select
                    label="The proxy to use to communicate with this Tentacle."
                    onChange={(x) => {
                        const endpoint = this.props.endpoint;
                        endpoint.ProxyId = x;
                        this.props.onChange(endpoint);
                    }}
                    value={this.props.endpoint.ProxyId}
                    items={this.props.proxies.map(p => ({value: p.Id, text: p.Name}))}/>}
            </ExpandableFormSection>
        </div>;
    }
}

export default TentaclePassiveEndpoint;

const passiveTentacleEndpointImage = require("./listening-target.svg");
export const passiveTentacleWindowsEndpointRegistration: CategorizedMachineRegistration = {
    key: EndpointRegistrationKey.TentaclePassiveWindows,
    displayOrder: 10,
    categories: [ WindowsCategory ],
    name: "Listening Tentacle",
    communicationStyle: CommunicationStyle.TentaclePassive,
    discoverable: true,
    renderCard: ({registration, category, getNavigationProps}) => (
        <EndpointCard
            logo={<EndpointThumbnail src={passiveTentacleEndpointImage} alt={registration.name} />}
            header={registration.name}
            description="A Listening target will passively listen for tasks to perform from the Octopus Server."
            {...getNavigationProps()}
        />
    )
};

export const passiveTentacleLinuxEndpointRegistration: CategorizedMachineRegistration = {
    key: EndpointRegistrationKey.TentaclePassiveLinux,
    displayOrder: 10,
    categories: [ LinuxCategory ],
    name: "Listening Tentacle",
    communicationStyle: CommunicationStyle.TentaclePassive,
    discoverable: true,
    renderCard: ({registration, category, getNavigationProps}) => (
        <EndpointCard
            logo={<EndpointThumbnail src={passiveTentacleEndpointImage} alt={registration.name} />}
            header={registration.name}
            isEarlyAccess={true}
            description="A Listening target will passively listen for tasks to perform from the Octopus Server."
            {...getNavigationProps()}
        />
    )
};
