import { pageId } from "utils/pageId";

export default () => {
    return {
        root: pageId("Library"),
        feeds: pageId("Library.Feeds", "External Feeds"),
        feedCreate: pageId("Library.FeedsNew", "New External Feed"),
        feed: {
            root: pageId("Library.Feed", "External Feed"),
            test: pageId("Library.FeedTest", "Test External Feed"),
            redirect: pageId("Library.FeedRedirect", "Redirect External Feed"),
        },
        stepTemplates: {
            root: pageId("Library.StepTemplates", "Library Step Templates"),
            builtIn: pageId("Library.StepTemplatesBuiltIn", "Built-in Step Templates"),
            community: pageId("Library.StepTemplatesCommunity", "Community Step Templates"),
            installedStep: pageId("Library.StepTemplatesInstalledStep", "Step Templates Installation"),
            new: pageId("Library.StepTemplateNew", "New Step Template"),
            communityTemplate: {
                root: pageId("Library.StepTemplatesCommunityTemplate", "Community Step Template"),
                details: pageId("Library.StepTemplatesCommunityTemplateDetails", "Community Step Template Details"),
            }
        },
        stepTemplate: {
            root: pageId("Library.StepTemplate", "Step Template"),
            usage: pageId("Library.StepTemplateUsage", "Step Template Usage"),
            run: pageId("Library.StepTemplateRun", "Run Step Template"),
        },
        lifecycles: pageId("Library.Lifecycles", "Lifecycles"),
        lifecyclesCreate: pageId("Library.LifecyclesNew", "New Lifecycle"),
        lifecycle: pageId("Library.Lifecycle", "Lifecycle"),
        certificates: {
            root: pageId("Library.Certificates", "Certificates"),
            archive: pageId("Library.CertificatesArchive", "Certificates Archive"),
            create: pageId("Library.CertificatesNew", "New Certificate"),
        },
        certificate: pageId("Library.Certificate", "Certificate"),
        variableSets: {
            root: pageId("Library.VariableSets", "Variable Sets"),
            create: pageId("Library.VariableSetsNew", "New Variable Set"),
        },
        variableSet: pageId("Library.VariableSet", "Variable Set"),
        tagSets: {
            root: pageId("Library.TenantTagSets", "Tenant Tag Sets"),
            create: pageId("Library.TenantTagSetsNew", "New Tenant Tag Set"),
        },
        tagSet: pageId("Library.TenantTagSet", "Tenant Tag Set"),
        builtInRepository: {
            root: pageId("Library.BuiltInRepository", "Packages"),
            package: pageId("Library.BuiltInRepositoryPackage", "Package Details"),
            versions: pageId("Library.BuiltInRepositoryPackageVersions", "Package Versions"),
        },
        scriptModules: {
            root: pageId("Library.ScriptModules", "Script Modules"),
            create: pageId("Library.ScriptModulesNew", "New Script Module"),
        },
        scriptModule: pageId("Library.ScriptModule", "Script Module"),
    };
};