import * as React from "react";
import { Navbar } from "components/Navbar";
import ErrorPanel from "components/ErrorPanel";
import RootRoutes from "../RootRoutes";
import GettingStartedFooter from "../GettingStarted/GettingStartedFooter";
import { UnhandledError } from "components/UnhandledError";
import SystemMessagesBanner from "components/SystemMessagesBanner/SystemMessagesBanner";
import Sticky from "components/Sticky/Sticky";
import { client } from "../../clientInstance";
import SpaceLoader, { isSpecificSpaceContext } from "./SpaceLoader";
import { zIndexStickies } from "theme";

interface StandardLayoutProps {
    versionText: string;
    unhandledError: UnhandledError;
    onErrorClose(): void;
}

export default class StandardLayoutInternal extends React.Component<StandardLayoutProps> {
    render() {
        const serverInfo = client.tryGetServerInformation();
        const version = serverInfo ? serverInfo.version : undefined;
        return <div>
            <SpaceLoader
                render={(spaceContext) => <React.Fragment>
                    <Sticky innerZ={zIndexStickies}>
                        <div id="toppanel">
                            <Navbar spaceContext={spaceContext} />
                            {this.props.unhandledError &&
                                <ErrorPanel message={`An unexpected error occurred in Octopus v${version}: ${this.props.unhandledError.message}`}
                                    details={this.props.unhandledError.details}
                                    fullWidth={true}
                                    canClose={true}
                                    onErrorClose={this.onErrorClose} />}
                            <SystemMessagesBanner />
                        </div>
                    </Sticky>

                    <RootRoutes spaceContext={spaceContext} />
                    {isSpecificSpaceContext(spaceContext) && <GettingStartedFooter />}
                </React.Fragment>
                } />
        </div>;
    }

    private onErrorClose = () => {
        this.props.onErrorClose();
    }
}