import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import { RouteComponentProps } from "react-router";
import { ProjectRouteParams } from "areas/projects/components/ProjectLayout";
import {
    ProjectResource,
    EnvironmentResource,
    DeploymentTargetResource,
    ChannelResource,
    LifecycleResource,
    DeploymentProcessResource,
    ResourceCollection,
    TenantResource,
    Permission
} from "client/resources";
import { repository } from "clientInstance";
import { flatMap } from "lodash";
import { VariableSetResource, ScopeValues } from "client/resources/variableSetResource";
import VariableDisplayer from "areas/variables/VariableDisplayer/VariableDisplayer";
import { VariableResource } from "client/resources/variableResource";
import { SFC } from "react";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { SimpleList } from "components/List";
import { Select } from "components/form";
import { Item } from "components/form/Select/Select";
import Checkbox from "components/form/Checkbox/Checkbox";
import { convertVariableResourcesToVariablesWithSource } from "areas/variables/convertVariableResourcesToVariablesWithSource";
const styles = require("./style.less");
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import SectionNote from "components/SectionNote/SectionNote";

interface VariableListProps {
    variables: VariableResource[];
}

class VariableList extends SimpleList<VariableResource> { }

const Variables: SFC<VariableListProps> = props => {
    const onRow = (variable: VariableResource) => [<b>{variable.Name}</b>, variable.Value];
    return <VariableList items={props.variables} onRow={onRow} empty={<span>No variables have been added</span>} />;
};

Variables.displayName = "Variables";

const emptyScope: ScopeValues = {
    Actions: [],
    TenantTags: [],
    Roles: [],
    Channels: [],
    Machines: [],
    Environments: []
};

interface VariablePreviewState extends DataBaseComponentState {
    environmentId?: string;
    targetId?: string;
    channelId?: string;
    tenantId?: string;
    actionId?: string;
    role?: string;
    machineId?: string;
    project?: ProjectResource;
    environments?: Item[];
    targets?: DeploymentTargetResource[];
    channels?: Item[];
    tenants?: Item[];
    actions?: Item[];
    roles?: Item[];
    machines?: Item[];
    variables?: VariableSetResource;
    showOctopus: boolean;
}

export default class VariablePreview extends DataBaseComponent<
    RouteComponentProps<ProjectRouteParams>,
    VariablePreviewState
    > {
    constructor(props: RouteComponentProps<ProjectRouteParams>) {
        super(props);
        this.state = {
            showOctopus: false
        };
    }

    async componentDidMount() {
        await this.loadData();
    }

    render() {
        return (
            <PaperLayout
                title={"Variable Preview"}
                busy={this.state.busy}
                fullWidth={true}
                errors={this.state.errors}>
                <SectionNote>
                    Variable preview gives you an insight in to the final value a variable will have when it is involved in a deployment.
                    It evaluates variable substitution syntax and variable scoping. There are some limitations as some aspects of
                    variable evaluation require an actual deployment, for example output variables. Note in particular that your
                    results will be limited by the Octopus Permissions you have.
                </SectionNote>
                {this.state.variables && (
                    <div className={styles.scopeRow}>
                        {this.state.channels && this.state.channels.length > 1 &&
                            <Select
                                label="Channel"
                                items={this.state.channels}
                                allowClear={true}
                                value={this.state.channelId}
                                onChange={this.handleChannelChanged}
                            />
                        }
                        <Select
                            label="Environment"
                            items={this.state.environments}
                            allowClear={true}
                            value={this.state.environmentId}
                            onChange={this.handleEnvironmentChanged}
                        />
                        <Select
                            label="Role"
                            items={this.state.roles}
                            allowClear={true}
                            value={this.state.role}
                            onChange={this.handleRoleChanged}
                        />
                        <Select
                            label="Deployment target"
                            items={this.state.machines}
                            allowClear={true}
                            value={this.state.machineId}
                            onChange={this.handleMachineChanged}
                        />
                        <Select
                            label="Step"
                            items={this.state.actions}
                            allowClear={true}
                            value={this.state.actionId}
                            onChange={this.handleActionChanged}
                        />
                        {this.state.tenants && this.state.tenants.length > 0 &&
                            <Select
                                label="Tenant"
                                items={this.state.tenants}
                                allowClear={true}
                                value={this.state.tenantId}
                                onChange={this.handleTenantChanged}
                            />
                        }
                        <div className={styles.showAll}>
                            <Checkbox
                                label="Show Octopus variables"
                                value={this.state.showOctopus}
                                onChange={this.handleShowOctopusChanged}
                            />
                        </div>
                    </div>
                )}
                <VariableDisplayer
                    availableScopes={emptyScope}
                    isProjectScoped={true}
                    variableSections={[this.getVariables()]}
                    doBusyTask={this.doBusyTask}
                    hideSource={true}
                    hideScope={true}
                    hideAdvancedFilters={true}
                />
            </PaperLayout>
        );
    }

    private async loadEnvironmentsFromLifecycle(lifecycle: LifecycleResource) {
        const environmentIds = flatMap(lifecycle.Phases, phase => {
            return phase.AutomaticDeploymentTargets.concat(phase.OptionalDeploymentTargets);
        });

        const args = environmentIds.length === 0 ? undefined : { ids: environmentIds };
        const environments = await (isAllowed({ permission: Permission.EnvironmentView, environment: "*" })
            ? repository.Environments.all(args)
            : Promise.resolve<EnvironmentResource[]>([]));

        return environments;
    }

    private async loadData() {
        await this.doBusyTask(async () => {
            const project = await repository.Projects.get(this.props.match.params.projectSlug);

            const [deploymentProcess, channels, lifecycle, tenants, roles] = await Promise.all<
                DeploymentProcessResource,
                ResourceCollection<ChannelResource>,
                LifecycleResource,
                TenantResource[],
                string[]
                >([
                    repository.DeploymentProcesses.get(project.DeploymentProcessId),
                    repository.Projects.getChannels(project, 0, 1000),
                    repository.Lifecycles.get(project.LifecycleId),
                    isAllowed({ permission: Permission.TenantView, tenant: "*" })
                        ? repository.Tenants.all({ projectId: project.Id })
                        : Promise.resolve<TenantResource[]>([]),
                    repository.MachineRoles.all()
                ]);

            const environments = await this.loadEnvironmentsFromLifecycle(lifecycle);

            const actions = flatMap(deploymentProcess.Steps, s => s.Actions.map(a => ({ text: a.Name, value: a.Id })));

            const variables = await repository.Variables.preview(
                project.Id,
                null,
                null,
                null,
                null,
                null,
                null
            );

            this.setState({
                project,
                environments: environments.map(e => ({ text: e.Name, value: e.Id })),
                channels: channels.Items.map(c => ({ text: c.Name, value: c.Id })),
                tenants: tenants.map(t => ({ text: t.Name, value: t.Id })),
                roles: roles.map(r => ({ text: r, value: r })),
                machines: [],
                actions,
                variables
            });
        });
    }

    private loadMachinesForEnvironment = async (environmentId: string, role: string) => {
        if (!environmentId || !isAllowed({ permission: Permission.MachineView, wildcard: true })) {
            return [];
        }
        const machines = await repository.Machines.list({ environmentIds: environmentId, roles: role, take: 999 });
        return machines.Items.map(m => ({ text: m.Name, value: m.Id }));
    }

    private handleEnvironmentChanged = async (environmentId: string) => {
        await this.doBusyTask(async () => {
            const machines = await this.loadMachinesForEnvironment(environmentId, this.state.role);
            const machine = machines.find(m => m.value === this.state.machineId);
            const machineId = machine ? machine.value : null;
            this.setState({
                environmentId,
                machines,
                machineId
            }, () => this.loadVariables());
        });
    }

    private handleRoleChanged = async (role: string) => {
        await this.doBusyTask(async () => {
            const machines = await this.loadMachinesForEnvironment(this.state.environmentId, role);
            const machine = machines.find(m => m.value === this.state.machineId);
            const machineId = machine ? machine.value : null;
            this.setState({
                role,
                machines,
                machineId
            }, () => this.loadVariables());
        });
    }

    private handleMachineChanged = (machineId: string) => {
        this.setState({ machineId }, () => this.loadVariables());
    }

    private handleChannelChanged = async (channelId: string) => {
        await this.doBusyTask(async () => {
            const channel = await repository.Channels.get(channelId);
            const lifecycle = await repository.Lifecycles.get(channel.LifecycleId);
            const environments = await this.loadEnvironmentsFromLifecycle(lifecycle);
            // clear the existing environment selection if it's no longer available in the drop down
            const environmentId = (environments.some(x => x.Id === this.state.environmentId)) ? this.state.environmentId : null;
            this.setState({
                environments: environments.map(e => ({ text: e.Name, value: e.Id })),
                channelId,
                environmentId
            }, () => this.loadVariables());
        });
    }

    private handleTenantChanged = (tenantId: string) => {
        this.setState({ tenantId }, () => this.loadVariables());
    }

    private handleActionChanged = (actionId: string) => {
        this.setState({ actionId }, () => this.loadVariables());
    }

    private handleShowOctopusChanged = (showOctopus: boolean) => {
        this.setState({ showOctopus });
    }

    private getVariables = () => {
        if (!this.state.variables) {
            return [];
        }
        const source = {
            projectName: this.state.project.Name,
            projectId: this.state.project.Id
        };
        return convertVariableResourcesToVariablesWithSource(this.state.variables.Variables.filter(v => this.state.showOctopus || !v.Name.startsWith("Octopus.")), source);
    }

    private loadVariables = async () => {
        await this.doBusyTask(async () => {
            const variables = await repository.Variables.preview(
                this.state.project.Id,
                this.state.actionId,
                this.state.environmentId,
                this.state.role,
                this.state.machineId,
                this.state.channelId,
                this.state.tenantId
            );
            this.setState({
                variables
            });
        });
    }
}
