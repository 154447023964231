import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonLibraryScriptModuleOverview, CommonLibraryScriptModuleTopics } from "../../CommonComponents/CommonLibraryScriptModuleViews";

export const LibraryScriptModulesNewRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.library.scriptModules.create,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryScriptModuleOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryScriptModuleTopics />;
    }
};
