import * as React from "react";
import {BaseComponent} from "components/BaseComponent/BaseComponent";
import {repository} from "clientInstance";
import {AzureWebSiteSlot} from "client/resources";
import Note from "components/form/Note/Note";
import ParseHelper from "utils/ParseHelper/ParseHelper";
import { Select } from "components/form";
import BusyRefreshContainer from "components/BusyRefreshContainer";
import { DoBusyTask } from "components/DataBaseComponent/DataBaseComponent";
import {Icon, default as IconButton} from "components/IconButton/IconButton";
import InputWithActions from "components/InputWithActions";

interface AzureWebAppSlotSelectorProps {
    webAppName: string;
    resourceGroupName: string;
    webAppSlotName: string;
    accountId: string;
    webAppSlotNameError: string;
    doBusyTask: DoBusyTask;
    onWebAppSlotNameChanged(value: string): void;
}

interface AzureWebAppSlotSelectorState {
    reset: {
        name: string;
    };
    slots: AzureWebSiteSlot[];
    slotItems: Array<{ value: string, text: string }>;
    selectedSlotIndex: string;
    busy: boolean; //TODO: move busy back out into props and use a HOC/Render prop component to manage this state
}

const toggleBusy = (value?: boolean) => (prev: AzureWebAppSlotSelectorState, props: AzureWebAppSlotSelectorProps) => ({...prev, busy: value ? value : !prev.busy});

class AzureWebAppSlotSelector extends BaseComponent<AzureWebAppSlotSelectorProps, AzureWebAppSlotSelectorState> {
    constructor(props: AzureWebAppSlotSelectorProps) {
        super(props);
        this.state = {
            reset: {
                name: null
            },
            slots: [],
            slotItems: [],
            selectedSlotIndex: null,
            busy: false
        };
    }

    async componentDidMount() {
        await this.getWebSlots(this.props.accountId, this.props.resourceGroupName, this.props.webAppName);
    }

    async componentWillReceiveProps(newprops: AzureWebAppSlotSelectorProps) {
        if (newprops.accountId !== this.props.accountId || newprops.webAppName !== this.props.webAppName || newprops.resourceGroupName !== this.props.resourceGroupName) {
            await this.getWebSlots(newprops.accountId, newprops.resourceGroupName, newprops.webAppName);
        }
    }

    render() {
        return <div>
            <BusyRefreshContainer busy={this.state.busy}>
                <InputWithActions input={
                        <Select
                            value={this.state.selectedSlotIndex}
                            onChange={this.handleSelectedSlotChanged}
                            items={this.state.slotItems}
                            label="Web App Slot"
                            allowClear={true}
                        />
                    }
                    actions={<IconButton disabled={this.state.busy} onClick={() => this.getWebSlots(this.props.accountId, this.props.resourceGroupName, this.props.webAppName)} toolTipContent="Refresh" icon={Icon.Refresh}/>}
                />
            </BusyRefreshContainer>
            <Note>The name of your Azure Web App Slot.</Note>
        </div>;
    }

    private handleSelectedSlotChanged = (value: string) => {
        const index = ParseHelper.safeParseInt(value, null);
        const selectedSlot = index !== null ? this.state.slots[index] : null;
        this.props.onWebAppSlotNameChanged(selectedSlot ? selectedSlot.Name : null);
        this.setState({selectedSlotIndex: index !== null ? index.toString() : null});
    }

    private async getWebSlots(accountId: string, resourceGroupName: string, siteName: string) {
        this.setState(toggleBusy(true));
        try {
            await this.props.doBusyTask(async () => {
                if (!(accountId && siteName && resourceGroupName)) {
                    this.setState({
                        slots: [],
                        slotItems: [],
                        selectedSlotIndex: null
                    });
                    return;
                }

                const account = await repository.Accounts.get(accountId);
                const slots = await repository.Accounts.getWebSiteSlots(account, resourceGroupName, siteName);
                let selectedSlotIndex: string = null;
                if (this.props) {
                    const selectedSlot = slots.find(s => s.Name === this.props.webAppSlotName
                        && s.ResourceGroupName === resourceGroupName
                        && s.Site === siteName);
                    if (selectedSlot) {
                        const index = slots.indexOf(selectedSlot);
                        selectedSlotIndex = index < 0 ? null : index.toString();
                    }
                }

                const slotItems = slots.map((slot, index) => {
                    return {
                        value: index.toString(),
                        text: slot.Name
                    };
                });

                this.setState({
                    slots,
                    slotItems,
                    selectedSlotIndex
                });
            });
        } finally {
            this.setState(toggleBusy(false));
        }
    }
}

export default AzureWebAppSlotSelector;