import { MachinesLayoutProps, MachinesLayoutState, BaseMachinesLayout } from "./BaseMachinesLayout";
import { repository } from "clientInstance";
import { EnvironmentsSummaryResource, EnvironmentResource, TenantResource, Permission, SummaryResource } from "client/resources";
import { TagIndex } from "components/tenantTagsets";
import * as tenantTagsets from "components/tenantTagsets";
import { EnvironmentSummaryArgs } from "client/repositories/environmentRepository";
import * as React from "react";
import { PermissionCheck } from "components/PermissionCheck";
import { NavigationButton, NavigationButtonType } from "components/Button";
import routeLinks from "routeLinks";
import { cloneDeep } from "lodash";
import OnboardingDeploymentTargets, { OnboardingTextForDeploymentTargets } from "../EnvironmentsLayout/OnboardingDeploymentTargets";
import Section from "components/Section";
import { EnvironmentMultiSelect, RoleMultiSelect, TenantMultiSelect, TenantTagMultiSelect } from "components/MultiSelect";
import { FeatureToggle, Feature } from "components/FeatureToggle";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { machineActions } from "../../reducers/machines";
import GlobalState from "globalState";
import DeploymentTargetsSummarySection from "./DeploymentTargetsSummarySection";
import ExternalLink from "components/Navigation/ExternalLink";
import { Note } from "components/form";
import { NoResults } from "components/NoResults/NoResults";

const { machineCleared } = machineActions;

// tslint:disable-next-line:no-empty-interface
interface DeploymentTargetsLayoutProps extends MachinesLayoutProps {}

interface DeploymentTargetsLayoutState extends MachinesLayoutState {
    environmentsSummary: EnvironmentsSummaryResource;
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    tagIndex: TagIndex;
    machineRoles: string[];
}
class DeploymentTargetsLayoutInternal extends BaseMachinesLayout<DeploymentTargetsLayoutProps, DeploymentTargetsLayoutState> {

    protected initialState(): DeploymentTargetsLayoutState {
        return {
            ...this.baseInitialState(),
            environmentsSummary: null,
            environments: null,
            tenants: null,
            tagIndex: null,
            machineRoles: null,
            hasMachines: false
        };
    }

    protected async loadLookupData(): Promise<void> {
        const environmentsPromise = repository.Environments.all();
        const machineRolesPromise = repository.MachineRoles.all();
        const tenantsPromise = repository.Tenants.all();

        this.setState({
            environments: await environmentsPromise,
            machineRoles: await machineRolesPromise,
            tenants: await tenantsPromise,
            tagIndex: await tenantTagsets.getTagIndex(),
            hasMachines: (await repository.Machines.list({ take: 0 })).TotalResults > 0,
        });
    }

    protected async loadSummaries(): Promise<void> {
        const filter = this.state.filter;
        const args: Partial<EnvironmentSummaryArgs> = {};

        if (filter.roles && filter.roles.length) {
            args.roles = filter.roles.join(",");
        }

        if (filter.isDisabled) {
            args.isDisabled = true;
        }

        if (filter.environmentIds && filter.environmentIds.length) {
            args.ids = filter.environmentIds.join(",");
        }

        if (filter.healthStatuses && filter.healthStatuses.length) {
            args.healthStatuses = filter.healthStatuses.join(",");
        }

        if (filter.commStyles && filter.commStyles.length) {
            args.commStyles = filter.commStyles.join(",");
        }

        if (filter.healthStatuses && filter.healthStatuses.length) {
            args.healthStatuses = filter.healthStatuses.join(",");
        }

        if (filter.tenantIds && filter.tenantIds.length) {
            args.tenantIds = filter.tenantIds.join(",");
        }

        if (filter.partialName && filter.partialName !== "") {
            args.machinePartialName = filter.partialName;
        }

        if (filter.tenantTags && filter.tenantTags.length && this.state.tagIndex) {
            args.tenantTags = filter.tenantTags.map(name => this.state.tagIndex[name].Id).join(",");
        }

        await this.requestRaceConditioner.avoidStaleResponsesForRequest(repository.Environments.summary(args), (response) => {
            this.setState({
                environmentsSummary: response as EnvironmentsSummaryResource,
            });
        });
    }

    protected getSummaries(): SummaryResource {
        return this.state.environments && this.state.environmentsSummary;
    }

    protected extraFilters(): React.ReactNode[] {
        return [
            <EnvironmentMultiSelect key="filterEnvironment"
                items={this.state.environments}
                value={this.state.filter.environmentIds}
                onChange={x => {
                    this.setFilterState({environmentIds: x}, this.onFilterChange);
                }}
            />,
            <RoleMultiSelect key="filterRole"
                items={this.state.machineRoles ? this.state.machineRoles : []}
                value={this.state.filter.roles}
                onChange={x => {
                    this.setFilterState({roles: x}, this.onFilterChange);
                }}
            />,
            <FeatureToggle feature={Feature.MultiTenancy} key="filterMultiTenancy">
                <PermissionCheck permission={Permission.TenantView} tenant="*">
                    <TenantMultiSelect
                        value={this.state.filter.tenantIds}
                        items={this.state.tenants}
                        onChange={x => {
                            this.setFilterState({tenantIds: x}, this.onFilterChange);
                        }}
                    />
                    <TenantTagMultiSelect
                        value={this.state.filter.tenantTags}
                        doBusyTask={this.doBusyTask}
                        onChange={x => {
                            this.setFilterState({tenantTags: x}, this.onFilterChange);
                        }}
                    />
                </PermissionCheck>
            </FeatureToggle>
        ];
    }

    protected getActions(): JSX.Element[] {
        return [
            <PermissionCheck permission={Permission.MachineCreate} environment="*" tenant="*" key="perms">
                <NavigationButton
                    href={routeLinks.infrastructure.machines.new()}
                    label="Add deployment target"
                    type={NavigationButtonType.Primary}
                />
            </PermissionCheck>,
        ];
    }

    protected renderOnboarding(): JSX.Element {
        return <OnboardingDeploymentTargets />;
    }

    protected renderMachinesExpander(): React.ReactNode {
        let machinesExpander: React.ReactNode = null;
        const environmentsSummaries = this.state.environments && this.state.environmentsSummary;
        if (environmentsSummaries) {
            machinesExpander = <DeploymentTargetsSummarySection
                key="allMachines"
                environmentsSummary={environmentsSummaries}
                filter={cloneDeep(this.state.filter)}
                tenants={this.state.tenants}
                tagIndex={this.state.tagIndex}
                environments={this.state.environments}
            />;
        }

        if (this.state.environmentsSummary && this.state.environmentsSummary.EnvironmentSummaries.length === 0) {
            machinesExpander = <Section><NoResults /></Section>;
        }

        return machinesExpander;
    }

}

const mapStateToProps = (state: GlobalState) => {
    return {
        title: "Deployment Targets",
        itemDescriptions: "deployment targets"
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onClearMachine: () => {
            dispatch(machineCleared());
        }
    };
};

const DeploymentTargetsLayout = withRouter(connect<{}, {}, MachinesLayoutProps>(
    mapStateToProps,
    mapDispatchToProps
)(DeploymentTargetsLayoutInternal));

export default DeploymentTargetsLayout;
