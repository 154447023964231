import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
const image = require("../../Images/Release.svg");
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
import { TopicsContainer, LearnMoreResources } from "./CommonViews";
import ImageWithPlaceholder from "components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";

export const CommonProjectReleaseOverview: React.StatelessComponent<{}> = props => {
    return <OverviewContainer>
        <OverviewHighlightText>
            <p><ExternalLink href="OnboardingReleasesLearnMore">Releases</ExternalLink> are a snapshot of your deployment process, configuration variables, and software packages.

                A release is deployed to the environments defined in the project's lifecycle.</p>
            <ImageWithPlaceholder src={image} alt={"Environments"} />
        </OverviewHighlightText>
    </OverviewContainer>;
};

export const CommonProjectReleaseTopics: React.StatelessComponent<{}> = props => {
    return <TopicsContainer>
        <LearnMoreResources>
            <div><ExternalLink href="OnboardingReleasesLearnMore">Deploying Releases</ExternalLink></div>
        </LearnMoreResources>
    </TopicsContainer>;
};