import * as React from "react";
import { commandLinePackagingInstruction } from "../../Instructions";
import {
    CommonCommandLineTextForOctoExe,
    CommonCommandLineTextForOctoExeCodeSample,
    CommonCommandLineTextForOctopackJs,
    CommonCommandLineTextForOctopackJsCodeSample,
} from "../CommonRegistrationText";

export const CommandLinePackaging: React.SFC<{}> = () => (
    <div>
        <p>
            When packaging Node.js applications from command line, we recommend using <i>Octopack.js</i>.
        </p>
        {CommonCommandLineTextForOctopackJs}
        {CommonCommandLineTextForOctopackJsCodeSample}
        <p>
            Alternatively, <i>Octo.exe</i> can also be used to package your build output.
        </p>
        {CommonCommandLineTextForOctoExe}
        {CommonCommandLineTextForOctoExeCodeSample}
    </div>
);

export default commandLinePackagingInstruction(() => (<CommandLinePackaging/>));