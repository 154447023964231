import * as React from "react";
import {flatten, groupBy} from "lodash";
import {RouteComponentProps} from "react-router";
import {ProjectRouteParams} from "areas/projects/components/ProjectLayout/ProjectLayout";
import FormBaseComponent, {OptionalFormBaseComponentState} from "components/FormBaseComponent/FormBaseComponent";
import {repository} from "clientInstance";
import {ProjectResource} from "client/resources/projectResource";
import {TenantResource} from "client/resources/tenantResource";
import {TenantVariableResource} from "client/resources/tenantVariableResource";
import {VariableType} from "client/resources/variableResource";
import {LibraryVariableSetResource} from "client/resources/libraryVariableSetResource";
import {ControlType} from "client/resources";
import PaperLayout from "components/PaperLayout/PaperLayout";
import {ScopeValues, VariableSetResource} from "client/resources/variableSetResource";
import VariableDisplayer, {ValueWithSource} from "areas/variables/VariableDisplayer/VariableDisplayer";
import mergeScopeValues from "areas/variables/MergeScopeValues";
import {convertVariableResourcesToVariablesWithSource} from "areas/variables/convertVariableResourcesToVariablesWithSource";
import convertPropertyValueResourceToString from "components/convertPropertyValueResourceToString";
import {VariableWithSource} from "../../../../variables/VariableDisplayer";
import {ValueSource} from "../../../../variables/SourceLink/SourceLink";
import {default as groupVariablesByName} from "../../../../variables/groupVariablesByName";

interface Model {
    project: ProjectResource;
    projectVariableSet: VariableSetResource;
    libraryVariableSets: LibraryVariableSetWithVariables[];
    tenants: TenantResource[];
    tenantVariables: TenantVariableResource[];
}

interface LibraryVariableSetWithVariables {
    variableSet: VariableSetResource;
    libraryVariableSet: LibraryVariableSetResource;
}

export default class AllVariables extends FormBaseComponent<RouteComponentProps<ProjectRouteParams>, OptionalFormBaseComponentState<Model>, Model> {
    constructor(props: RouteComponentProps<ProjectRouteParams>) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        await this.doBusyTask(() => this.loadVariables());
    }

    // TODO: We should show a warning if the variable does not have a value
    render() {
        return <PaperLayout
            busy={this.state.busy}
            errors={this.state.errors}
            fullWidth={true}
            title={"All Variables"}
        >
            <VariableDisplayer
                availableScopes={this.getAvailableScopes()}
                isProjectScoped={true}
                variableSections={[this.getVariables()]}
                doBusyTask={this.doBusyTask}
            />
        </PaperLayout>;
    }

    private async loadVariables() {
        const project = await repository.Projects.get(this.props.match.params.projectSlug);
        const projectId = project.Id;
        const tenants = repository.Tenants.all({projectId});
        const tenantVariables = repository.TenantVariables.all({projectId});

        const projectVariables = this.getProjectVariableSet(project);
        const libraryVariableSets = this.getLibraryVariableSetVariables(project);

        this.setState({
            model: {
                project,
                projectVariableSet: await projectVariables,
                libraryVariableSets: await libraryVariableSets,
                tenants: await tenants,
                tenantVariables: await tenantVariables
            }
        });
    }

    private async getProjectVariableSet(project: ProjectResource): Promise<VariableSetResource> {
        return await repository.Variables.get(project.VariableSetId);
    }

    private async getLibraryVariableSetVariables(project: ProjectResource): Promise<LibraryVariableSetWithVariables[]> {
        const libraryVariableSets = await repository.LibraryVariableSets.all({ids: project.IncludedLibraryVariableSetIds});
        return await Promise.all(libraryVariableSets.map(async libraryVariableSet => {
            return {
                variableSet: (await repository.Variables.get(libraryVariableSet.VariableSetId)),
                libraryVariableSet
            };
        }));
    }

    private getVariables(): ReadonlyArray<VariableWithSource> {
        if (!this.state.model) {
            return [];
        }
        return [
            ...this.buildProjectVariables(),
            ...this.buildLibraryVariableSetVariables(),
            ...this.buildTenantLibraryVariables(),
            ...this.buildTenantProjectVariables()
        ];
    }

    private buildProjectVariables(): ReadonlyArray<VariableWithSource> {
        const source = {
            projectName: this.state.model.project.Name,
            projectId: this.state.model.project.Id
        };

        return convertVariableResourcesToVariablesWithSource(this.state.model.projectVariableSet.Variables, source);
    }

    private buildLibraryVariableSetVariables(): ReadonlyArray<VariableWithSource> {
        return flatten(this.state.model.libraryVariableSets.map(set => {
            const source = {
                variableSetName: set.libraryVariableSet.Name,
                variableSetId: set.libraryVariableSet.Id
            };
            return convertVariableResourcesToVariablesWithSource(set.variableSet.Variables, source);
        }));
    }

    private buildTenantLibraryVariables(): ReadonlyArray<VariableWithSource> {
        const libraryVariableSetIds = this.state.model.project.IncludedLibraryVariableSetIds;
        const tenants = this.state.model.tenants;
        const namedValues =  flatten(this.state.model.tenantVariables.map(getAllLibrarySetVariablesForTenant));
        const groupedByNameValues = groupVariablesByName(namedValues, namedValue => namedValue.name);
        return Object.keys(groupedByNameValues).map(name => ({ name, values: groupedByNameValues[name].map(nv => nv.value)}));

        function getAllLibrarySetVariablesForTenant(tenantVariables: TenantVariableResource): Array<{name: string, value: ValueWithSource}> {
            const tenant = tenants.find(t => t.Id === tenantVariables.TenantId);
            const libraryVariablesLookup = tenantVariables.LibraryVariables;
            const source: ValueSource = {
                tenantId: tenant.Id,
                tenantName: tenant.Name,
                type: "library"
            };

            return flatten(libraryVariableSetIds.map(variableSetId => {
                const projectLibraryVariables = libraryVariablesLookup[variableSetId];
                const templates = projectLibraryVariables.Templates;
                const values = projectLibraryVariables.Variables;

                return templates.map(varTemplate => {
                    return {
                        name: varTemplate.Name,
                        value: {
                            type: getVariableTypeFromDisplaySettings(varTemplate.DisplaySettings["Octopus.ControlType"]),
                            scope: {},
                            value: convertPropertyValueResourceToString(values[varTemplate.Id] || varTemplate.DefaultValue),
                            source,
                            isPrompted: false
                        }
                    };
                });
            }));
        }
    }

    private buildTenantProjectVariables(): ReadonlyArray<VariableWithSource> {
        const tenants = this.state.model.tenants;
        const projectId = this.state.model.project.Id;
        const namedValues = flatten(this.state.model.tenantVariables.map(getAllProjectVariablesForTenant));

        const groupedByNameValues = groupVariablesByName(namedValues, namedValue => namedValue.name);
        return Object.keys(groupedByNameValues).map(name => ({ name, values: groupedByNameValues[name].map(nv => nv.value)}));

        function getAllProjectVariablesForTenant(tenantVariables: TenantVariableResource): Array<{name: string, value: ValueWithSource}> {
            const tenant = tenants.find(t => t.Id === tenantVariables.TenantId);
            const projectVariables = tenantVariables.ProjectVariables[projectId];
            const source: ValueSource = {
                tenantId: tenant.Id,
                tenantName: tenant.Name,
                type: "project"
            };

            return flatten(projectVariables.Templates.map(template => {
                return Object.keys(projectVariables.Variables).map(environmentId => {
                    const environmentValues = projectVariables.Variables[environmentId];
                    return {
                        name: template.Name,
                        value: {
                            type: getVariableTypeFromDisplaySettings(template.DisplaySettings["Octopus.ControlType"]),
                            scope: {
                                Environment: [environmentId]
                            },
                            value: convertPropertyValueResourceToString(environmentValues[template.Id] || template.DefaultValue),
                            source,
                            isPrompted: false
                        }
                    };
                });
            }));
        }
    }

    private getAvailableScopes(): ScopeValues {
        const allScopeValues: ScopeValues[] = this.state.model ? [
            this.state.model.projectVariableSet.ScopeValues,
            ...this.state.model.libraryVariableSets.map(set => set.variableSet.ScopeValues)
        ] : [];
        return mergeScopeValues(allScopeValues);
    }
}

function getVariableTypeFromDisplaySettings(type?: ControlType): VariableType {
    switch (type) {
        case ControlType.Sensitive:
            return VariableType.Sensitive;
        case ControlType.Certificate:
            return VariableType.Certificate;
        case ControlType.AmazonWebServicesAccount:
            return VariableType.AmazonWebServicesAccount;
        case ControlType.AzureAccount:
            return VariableType.AzureAccount;
        default:
            return VariableType.String;
    }
}
