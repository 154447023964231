import { WorkerMachineResource, Permission, WorkerPoolResource, WorkerPoolsSummaryResource } from "client/resources";
import BaseMachineSettingsLayout, { BaseMachineSettingsProps, BaseMachineSettingsState, MachineSettingsRouteParams } from "../BaseMachineSettings/BaseMachineSettings";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import { RouteComponentProps } from "react-router";
import GlobalState from "globalState";
import MachineIconHelper from "utils/MachineIconHelper/MachineIconHelper";
import { machineActions } from "../../reducers/machines";
import routeLinks from "routeLinks";
import { repository } from "clientInstance";
import EndpointsHelper from "utils/EndpointsHelper/EndpointsHelper";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import * as React from "react";
import { FormSectionHeading, ExpandableFormSection } from "components/form";
import { WorkerPoolMultiSelect } from "components/MultiSelect";
import Summary from "components/form/Sections/Summary";
import { workerPoolChipList } from "components/Chips";
import * as URI from "urijs";
import ExternalLink from "components/Navigation/ExternalLink";
import Callout, { CalloutType } from "components/Callout";
import endpointRegistry from "../MachineSettings/Endpoints/endpointRegistry";
import { BreadcrumbProps } from "components/PaperLayout/PaperLayout";

interface WorkerMachineSettingsProps extends BaseMachineSettingsProps<WorkerMachineResource> {}

interface WorkerMachineSettingsState extends BaseMachineSettingsState<WorkerMachineResource> {
    workerPoolSummaries: WorkerPoolsSummaryResource;
}

class WorkerMachineSettingsLayoutInternal extends BaseMachineSettingsLayout<WorkerMachineSettingsProps, WorkerMachineSettingsState, WorkerMachineResource> {
    constructor(props: WorkerMachineSettingsProps) {
        super(props);
    }

    protected initialState(): WorkerMachineSettingsState {
        return {
            deleted: false,
            newId: null,
            machinePolicies: null,
            machinePolicy: null,
            workerPools: null,
            workerPoolSummaries: null,
            proxies: null,
            globalCertificate: null,
            accounts: null,
            communicationStyles: EndpointsHelper.communicationStyles,
        };
    }

    protected enableDisablePermission(): Permission { return Permission.WorkerEdit; }
    protected createPermission(): Permission { return Permission.WorkerEdit; }
    protected editPermission(): Permission { return Permission.WorkerEdit; }
    protected deletePermission(): Permission { return Permission.WorkerEdit; }
    protected machineLink(machineId: string): string { return routeLinks.infrastructure.workerMachine(machineId).root; }

    protected async loadData(): Promise<Pick<WorkerMachineSettingsState, any>> {
        const machinePoliciesPromise = repository.MachinePolicies.all();
        const workerPoolsPromise = repository.WorkerPools.all();
        const workerPoolsSummariesPromise = repository.WorkerPools.summary();
        const proxiesPromise = repository.Proxies.all();
        const globalCertificatePromise = isAllowed({permission: Permission.MachineEdit, wildcard: true}) ? repository.CertificateConfiguration.global() : Promise.resolve(null);
        const accountsPromise = repository.Accounts.all();

        return {
            machinePolicies: await machinePoliciesPromise,
            workerPools: await workerPoolsPromise,
            workerPoolSummaries: await workerPoolsSummariesPromise,
            proxies: await proxiesPromise,
            globalCertificate: await globalCertificatePromise,
            accounts: await accountsPromise
        };
    }

    protected configureNewMachine(): WorkerMachineResource {
        const query = URI(this.props.location.search).search(true);
        const workerPoolIds = query.workerPool;
        return {
            ...super.configureNewMachine(),
            WorkerPoolIds: workerPoolIds ? [workerPoolIds] : [],
        };
    }

    protected renderTypeSpecificComponents(): JSX.Element {
        return <div>
            <FormSectionHeading title="Workers"/>

            <ExpandableFormSection
                errorKey="WorkerPoolIds"
                title="Worker Pool"
                summary={this.workerPoolsSummary()}
                help={"Choose at least one worker pool for the machine."}>
                <WorkerPoolMultiSelect
                    items={this.state.workerPools}
                    onChange={WorkerPoolIds => this.setModelState({WorkerPoolIds})}
                    value={this.state.model.WorkerPoolIds} />

                {this.selectedPoolsChangesBuiltinSetting() &&
                    <Callout type={CalloutType.Warning} title={"Built-in Worker will be disabled"}>
                        Adding Workers to the default pool turns off the Built-in Worker and changes where some steps are run.
                        Learn about the <ExternalLink href="AddingWorkerToDefaultPool">Built-in Worker</ExternalLink>.
                    </Callout>}
            </ExpandableFormSection>
        </div>;
    }

    private selectedPoolsChangesBuiltinSetting(): boolean {
        if (this.state.workerPoolSummaries == null) {
            return false;
        }
        const defaultPoolSummary = this.state.workerPoolSummaries.WorkerPoolSummaries.filter(wp => wp.WorkerPool.IsDefault)[0];
        return defaultPoolSummary.TotalMachines === 0 && this.state.model.WorkerPoolIds.includes(defaultPoolSummary.WorkerPool.Id);
    }

    private workerPoolsSummary() {
        return this.state.model.WorkerPoolIds && this.state.model.WorkerPoolIds.length > 0
            ? Summary.summary(workerPoolChipList(this.state.workerPools, this.state.model.WorkerPoolIds))
            : Summary.placeholder("No worker pools");
    }
}

const mapStateToProps = (state: GlobalState, props: RouteComponentProps<MachineSettingsRouteParams>) => {

    const query = URI(props.location.search).search(true);
    const type = query.type;
    const registration = endpointRegistry.getEndpoint(type);
    const machineId = props.match && props.match.params ? props.match.params.machineId : null;

    const breadcrumbs: BreadcrumbProps = machineId ? {} : {
        breadcrumbPath: routeLinks.infrastructure.workerMachines.new(query.workerPoolId),
        breadcrumbTitle: "New Worker"
    };

    return {
        breadcrumbs,
        itemDescription: registration ? registration.name : "worker",
        rootLink: routeLinks.infrastructure.workerMachines.root,
        repository: repository.Workers
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: RouteComponentProps<MachineSettingsRouteParams>) => {
    return {
        onMachineSaved: (machine: WorkerMachineResource) => {
            const filename = MachineIconHelper.machineIconFilename(machine);
            dispatch(machineActions.machineSaved({
                id: machine.Id,
                name: machine.Name,
                machineType: EndpointsHelper.getFriendlyName(machine.Endpoint.CommunicationStyle),
                machineIconFilename: filename,
                isDisabled: machine.IsDisabled,
            }));
        }
    };
};

const WorkerMachineSettingsLayout = connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkerMachineSettingsLayoutInternal);

export default WorkerMachineSettingsLayout;