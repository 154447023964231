import * as React from "react";
import { Card, CardContent } from "@material-ui/core";
const styles = require("./style.less");
import { FormSectionBase, FormSectionBaseProps } from "components/form/Sections/FormSectionBase";
import * as cn from "classnames";

interface UnstructuredFormSectionProps extends FormSectionBaseProps {
    className?: string;
    stretchContent?: boolean;
}

class UnstructuredFormSection extends FormSectionBase<UnstructuredFormSectionProps> {
    render() {
        return <Card
            className={styles.formNonExpander}>
                <CardContent className={cn(this.props.stretchContent ? styles.stretchContent : null, this.props.className ? this.props.className : styles.unstructuredFormContent)} >
                    {this.props.children}
                </CardContent>
        </Card>;
    }
}

export default UnstructuredFormSection;