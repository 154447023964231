import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonConfigurationNodesOverview, CommonConfigurationNodesTopics } from "../../CommonComponents/CommonConfigurationNodesViews";

export const ConfigurationNodesConfigRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.nodes.config,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationNodesOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationNodesTopics />;
    }
};
