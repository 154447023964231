import * as React from "react";
import { flatten, flatMap, groupBy, zip, sum } from "lodash";
import {ScopeValues} from "client/resources/variableSetResource";
import {VariableFilter, VariableQuery} from "areas/variables/VariableFilter";
import {VariableType} from "client/resources/variableResource";
import ScrollTable, {CellAligner} from "components/ScrollTable/ScrollTable";
import VariableEditorHeadings from "areas/variables/VariableEditorHeadings/VariableEditorHeadings";
import VariableDescriptionIcon from "areas/variables/VariableDescriptionIcon/VariableDescriptionIcon";
import {
    containsFilter, createEmptyFilter, FilterableValue, filterVariableGroups,
    matchesFilter
} from "areas/variables/VariableFilter/VariableFilter";
import ReadonlyText from "components/ReadonlyText/ReadonlyText";
import {FocusManagedVariableScope} from "areas/variables/VariableScope/VariableScope";
import ToolTipMessages from "components/ToolTipMessages";
const styles = require("./style.less");
import getVariablesMessages, {
    AllVariableMessages, VariableMessages,
    ValueMessages
} from "areas/variables/VariableMessages/VariableMessages";
import {
    default as VariableFilterLayout,
    VariableFilterLayoutProps
} from "areas/variables/VariableFilterLayout/VariableFilterLayout";
import {AdvancedFilterTextInput} from "components/AdvancedFilterLayout";
import * as tenantTagsets from "components/tenantTagsets";
import {TagIndex} from "components/tenantTagsets";
import SourceLink, {getSourceLinkName, ValueSource} from "areas/variables/SourceLink/SourceLink";
import VariableCell from "areas/variables/VariableCell/VariableCell";
import ReadonlySensitive from "components/ReadonlySensitive/ReadonlySensitive";
import ReadonlyCertificate from "components/ReadonlyCertificate";
import ReadonlyAccount from "components/ReadonlyAccount";
import {DoBusyTask} from "components/DataBaseComponent/DataBaseComponent";
import * as certificates from "components/certificates";
import {CertificateIndex} from "components/certificates";
import RateReviewIcon from "material-ui/svg-icons/maps/rate-review";
import ToolTip from "components/ToolTip/ToolTip";
import VariableNameAndDescriptionCell from "areas/variables/VariableNameAndDescriptionCell/VariableNameAndDescriptionCell";
import {BorderCss} from "utils/BorderCss/BorderCss";
import {compareScopes, compareValues} from "areas/variables/VariableSorting/sortVariables";
import {
    isLibraryVariableSetSource, isProjectVariableSource,
    isTenantProjectVariableSource
} from "areas/variables/SourceLink";
import { arrayValueFromQueryString } from "utils/ParseHelper/ParseHelper";
import { QueryStringFilters } from "components/QueryStringFilters/QueryStringFilters";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch, Action } from "redux";
import { fetchAllAccounts } from "areas/infrastructure/reducers/accounts";
import GlobalState from "globalState";
import {secondaryText} from "theme/colors";
import { AccountIcon } from "areas/infrastructure/components/AccountsLayout/AccountIcons";
import {AccountType} from "../../../client/resources";
import groupVariablesByName from "../groupVariablesByName";

interface VariableDisplayerProps {
    availableScopes: ScopeValues;
    isProjectScoped: boolean; // disables Step and Channel filtering if not project scoped
    variableSections: ReadonlyArray<ReadonlyArray<VariableWithSource>>;
    hideSource?: boolean;
    hideScope?: boolean;
    isTenanted?: boolean; // will be overridden in here, if there is tenant related variable scoping
    alwaysShowCheckboxFilters?: boolean;
    hideAdvancedFilters?: boolean;
    doBusyTask: DoBusyTask;
    additionalFilter?: AdditionalFilter;
    sectionHeader?: {
        renderSectionHeader: (sectionIndex: number, cellAligner: CellAligner) => React.ReactNode;
        sectionHeaderRowHeight: number;
    };
    shouldHideSectionContent?(sectionIndex: number): boolean;
    onLoad?(): void;
}

interface VariableDisplayerState {
    filter: VariableDisplayerFilter;
    queryFilter?: VariableDisplayerFilter;
    tagIndex?: TagIndex;
    certificateIndex?: CertificateIndex;
    relativeColumnWidths: ReadonlyArray<number>;
    measuredScopeCellWidth: number | undefined;
}

export interface AdditionalFilter {
    value: string;
    fieldName: string;
    onValueChanged(value: string): void;
}

interface VariableDisplayerFilter extends VariableFilter {
    source: string;
    additional?: string;
}

interface VariableDisplayerQuery extends VariableQuery {
    source?: string;
}

export interface ValueWithSource extends FilterableValue {
    source: ValueSource;
}

export interface VariableWithSource {
    name: string;
    values: ReadonlyArray<ValueWithSource>;
}

const rowHeight = 48;

interface FilteredVariable {
    name: string;
    variableMessages: VariableMessages;
    values: ReadonlyArray<FilteredValue>;
}

interface FilteredValue extends ValueWithSource {
    messages: ValueMessages;
}

type Row = VariableRowRenderer | number; // number is for a section heading, and represents the index of the section

interface VariableRowRenderer {
    height: number;
    render(cellAligner: CellAligner,
           isVisible: boolean,
           isDisplayingFullWidth: boolean,
           borderStyle: BorderCss,
           columnWidthsInPercent: ReadonlyArray<number>): React.ReactNode;
}

const FilterLayout: React.SFC<VariableFilterLayoutProps<VariableDisplayerFilter>> = (props) => VariableFilterLayout<VariableDisplayerFilter>(props);

const VariableQueryStringFilters = QueryStringFilters.For<VariableDisplayerFilter, VariableDisplayerQuery>();

export class VariableDisplayer extends React.Component<VariableDisplayerProps, VariableDisplayerState> {
    constructor(props: VariableDisplayerProps) {
        super(props);
        this.state = {
            filter: {...createEmptyFilter(), source: "", additional: ""},
            relativeColumnWidths: this.getRelativeColumnWidths(props.hideScope, props.hideSource),
            measuredScopeCellWidth: undefined
        };
    }

    async componentDidMount() {
        if (this.props.onLoad) {
            this.props.onLoad();
        }

        await this.props.doBusyTask(async () => {
            const tagIndex =  tenantTagsets.getTagIndex();
            const certificateIndex = certificates.getCertificateIndex();
            this.setState({tagIndex: await tagIndex, certificateIndex: await certificateIndex});
        });
    }

    render() {

        const variableSections = this.props.variableSections.map(variables => mergeAndSortVariables(variables, this.props.availableScopes));
        const variableMessages = variableSections.map((variables => getVariablesMessages(variables, v => v.name, v => v.values)));
        const filteredVariableSections = variableSections.map((variables, index) => {
            return this.getFilteredVariables(variables, variableMessages[index], index);
        });

        const allVariableMessages: AllVariableMessages = this.combineMessages(variableMessages);

        return [
            <VariableQueryStringFilters
                key="queryStringFilters"
                filter={this.state.filter}
                getQuery={this.queryFromFilters}
                getFilter={this.getFilter}
                onFilterChange={filter => this.setState({ filter, queryFilter: filter })} />,
            <FilterLayout
                key="filterLayout"
                filter={{...this.state.filter, additional: this.props.additionalFilter ? this.props.additionalFilter.value : ""}}
                queryFilter={this.state.queryFilter}
                availableScopes={this.props.availableScopes}
                defaultFilter={{...createEmptyFilter(), source: "", additional: ""}}
                messages={allVariableMessages}
                onFilterChanged={filter => {
                    this.setState({ filter });
                    if (this.props.additionalFilter) {
                        this.props.additionalFilter.onValueChanged(filter.additional);
                    }
                }}
                isProjectScoped={this.props.isProjectScoped}
                alwaysShowCheckboxFilters={this.props.alwaysShowCheckboxFilters}
                hideAdvancedFilters={this.props.hideAdvancedFilters}
                isTenanted={this.isTenantedOrHasTenantScopingOnVariables(variableSections)}
                doBusyTask={this.props.doBusyTask}
                extraFilters={[!this.props.hideSource &&
                    <AdvancedFilterTextInput
                        key="sourceFilter"
                        fieldName={"source"}
                        value={this.state.filter.source}
                        onChange={(source) => this.setState({filter: {...this.state.filter, source}})} />,
                    this.props.additionalFilter && <AdvancedFilterTextInput
                        key="additionalFilter"
                        fieldName={this.props.additionalFilter.fieldName}
                        value={this.props.additionalFilter.value}
                        onChange={this.props.additionalFilter.onValueChanged} />
                ]}
                renderContent={filterPanelIsVisible => this.renderContent(filteredVariableSections, !filterPanelIsVisible)} />
            ];
    }

    private getRelativeColumnWidths(hideScope: boolean, hideSource: boolean) {
        const scopeAndSourceHidden = hideScope && hideSource;
        const scopeOrSourceHidden = hideScope || hideSource;

        if (scopeAndSourceHidden) {
            return [1, 4];
        } else if (scopeOrSourceHidden) {
            return [1, 4, 1];
        } else {
            return [3, 5, 3, 2];
        }
    }

    private isTenantedOrHasTenantScopingOnVariables(variableSections: ReadonlyArray<ReadonlyArray<VariableWithSource>>) {
        // if we were told true then just show it, otherwise check for TenantTags
        const variables = flatten(flatten(variableSections.map(s => [...s])).filter((v: any) => !!v.variables).map((v: any) => v.variables));
        const tenantTagsExist = variables.some((v: any) => !!v.scope.TenantTag && v.scope.TenantTag.length > 0);
        return this.props.isTenanted || tenantTagsExist;
    }

    private renderContent(variableSections: ReadonlyArray<ReadonlyArray<FilteredVariable>>, isDisplayingFullWidth: boolean) {
        const orderedRows: ReadonlyArray<Row> = flatten(variableSections.map((section, index) => {
            return [
                index,
                ...flatMap<FilteredVariable, VariableRowRenderer>(section, variable => {
                    return [...this.getVariableRowRenderers(variable)];
                })
            ];
        }));

        const rows: ReadonlyArray<Row> = this.props.sectionHeader
            ? orderedRows
            : orderedRows.filter(r => !isSectionHeaderRow(r));

        return <ScrollTable relativeColumnWidths={this.state.relativeColumnWidths}
            minimumColumnWidthsInPx={[150, 150, 200, 150]}
            onColumnWidthsChanged={relativeColumnWidths => this.setState({relativeColumnWidths})}
            rowCount={rows.length}
            overscanRowCount={10}
            rowHeight={(index) => this.getHeightForRow(rows[index])}
            shouldVirtualize={sum(this.props.variableSections.map(variables => sum(variables.map(v => v.values.length)))) > 100}
            headers={({cellAligner, borderStyle, columnWidthsInPercent}) => {
                return [
                    <div style={{borderBottom: borderStyle.borderCssString, width: "100%"}}>
                        <VariableEditorHeadings
                            isDisplayedFullWidth={isDisplayingFullWidth}
                            columnWidths={columnWidthsInPercent}
                            onWidthMeasured={(index, width) => {
                                if (index === 2) {
                                    this.setState({measuredScopeCellWidth: width});
                                }
                            }}
                            cellAligner={cellAligner}
                            cells={[
                                <span>Name</span>,
                                <span>Value</span>,
                                this.props.hideScope ? null : <span>Scope</span>,
                                this.props.hideSource ? null : <span>Source</span>
                            ].filter(c => !!c)}
                        />
                    </div>
                ];
            }}
            rowRenderer={({cellAligner, index, isVisible, columnWidthsInPercent, borderStyle}) => {
                const row = rows[index];
                if (isSectionHeaderRow(row)) {
                    return <div style={{width: "100%", borderBottom: borderStyle.borderCssString}}>
                        {this.props.sectionHeader.renderSectionHeader(row, cellAligner)}
                    </div>;
                }
                return row.render(cellAligner, isVisible, isDisplayingFullWidth, borderStyle, columnWidthsInPercent);
            }}
        />;
    }

    private getVariableRowRenderers(variable: FilteredVariable): ReadonlyArray<VariableRowRenderer> {
        return variable.values.map((value, index) => {
            return {
                height: rowHeight,
                render: (cellAligner: CellAligner,
                         isVisible: boolean,
                         isDisplayingFullWidth: boolean,
                         borderStyle: BorderCss) => {
                    return <div key={index} style={{height: rowHeight, borderBottom: borderStyle.borderCssString}}>
                        {cellAligner([
                            <VariableNameAndDescriptionCell
                                name={this.renderNameCell(variable.name, index, variable.variableMessages)}
                                description={value.description ? <VariableDescriptionIcon description={value.description} /> : undefined}
                            />,
                            <VariableCell>
                                <div className={styles.value}>
                                    {this.renderPromptedVariableValueIcon(value)}
                                    {value.type === VariableType.Sensitive && <ReadonlySensitive hasValue={true} monoSpacedFont={true} />}
                                    {value.type === VariableType.String && <ReadonlyText text={value.value} monoSpacedFont={true} />}
                                    {value.type === VariableType.Certificate &&
                                        <ReadonlyCertificate
                                            certificateIndex={this.state.certificateIndex}
                                            certificate={value.value}/>}
                                    {value.type === VariableType.AmazonWebServicesAccount &&
                                        <ReadonlyAccount accountId={value.value} renderIcon={() => <AccountIcon accountType={AccountType.AmazonWebServicesAccount}/>}
                                    />}
                                    {value.type === VariableType.AzureAccount &&
                                        <ReadonlyAccount accountId={value.value} renderIcon={() => <AccountIcon accountType={AccountType.AzureServicePrincipal}/>}
                                    />}
                                </div>
                            </VariableCell>,
                            this.props.hideScope ? null : <FocusManagedVariableScope
                                scope={value.scope}
                                availableScopes={this.props.availableScopes}
                                tagIndex={this.state.tagIndex ? this.state.tagIndex : {}}
                                minHeight={rowHeight}
                                showClickIndicator={false}
                                containerWidth={this.state.measuredScopeCellWidth}
                            />,
                            this.props.hideSource ? null : <VariableCell><SourceLink source={value.source}/></VariableCell>
                        ].filter(c => !!c))}
                    </div>;
                }
            };
        });
    }

    private renderPromptedVariableValueIcon(v: FilteredValue) {
        return v.isPrompted && <div className={styles.promptedVariablePositionContainer}>
            <div className={styles.promptedVariableIconSizeContainer}>
                <ToolTip content="You will be prompted for a value during a deployment">
                    <RateReviewIcon className={styles.promptedVariableIcon}/>
                </ToolTip>
            </div>
        </div>;
    }

    private renderNameCell(name: string, index: number, variableMessages: VariableMessages) {
        const allWarningMessages = variableMessages.variableWarningMessages;
        return <VariableCell className={styles.nameCellContent}>
            {index === 0 ? <ReadonlyText text={name} monoSpacedFont={true} /> : <div className={styles.spacer}/>}
            <ToolTipMessages warningMessages={allWarningMessages} />
        </VariableCell>;
    }

    private getFilteredVariables(variables: ReadonlyArray<VariableWithSource>,
                                 messages: AllVariableMessages,
                                 variableSectionIndex: any): ReadonlyArray<FilteredVariable> {
        const variablesWithMessages = zip<VariableWithSource | VariableMessages>(variables, messages.variableMessages).map(gz => {
            const variable = gz[0] as VariableWithSource;
            const variableMessages = gz[1] as VariableMessages;
            const filteredVariables = zip<ValueWithSource | ValueMessages>(variable.values, variableMessages.valuesMessages).map(vz => {
                const value = vz[0] as ValueWithSource;
                const valueMessages = vz[1] as ValueMessages;
                return {
                    ...value,
                    messages: valueMessages
                };
            }).filter(v => {
                const matchesSourceFilter = containsFilter(getSourceLinkName(v.source), this.state.filter.source);
                const shouldHideSectionContent = this.props.shouldHideSectionContent ? this.props.shouldHideSectionContent(variableSectionIndex) : false;
                return matchesFilter(v, variableMessages, v.messages, this.state.filter) && matchesSourceFilter && !shouldHideSectionContent;
            });
            return {
                name: variable.name,
                variableMessages,
                values: filteredVariables
            };
        });

        return filterVariableGroups(variablesWithMessages,
            messages,
            this.state.filter,
            g => g.name)
            .filter(g => g.matchesFilter)
            .map(g => g.group);
    }

    private getHeightForRow(row: Row) {
        if (isSectionHeaderRow(row)) {
            return this.props.sectionHeader.sectionHeaderRowHeight;
        }
        return row.height;
    }

    private queryFromFilters = (filter: VariableDisplayerFilter): VariableDisplayerQuery => {
        const query: VariableDisplayerQuery = {
            name: filter.name,
            value: filter.value,
            description: filter.description,
            source: filter.source,
            filterEmptyValues: filter.filterEmptyValues ? "true" : undefined,
            filterDuplicateNames: filter.filterDuplicateNames ? "true" : undefined,
            filterNonPrintableCharacters: filter.filterNonPrintableCharacters ? "true" : undefined,
            filterVariableSubstitutionSyntax: filter.filterVariableSubstitutionSyntax ? "true" : undefined,
            environment: [...filter.scope.Environment],
            machine: [...filter.scope.Machine],
            role: [...filter.scope.Role],
            action: [...filter.scope.Action],
            channel: [...filter.scope.Channel],
            tenantTag: [...filter.scope.TenantTag]
        };

        return query;
    }

    private getFilter = (query: VariableDisplayerQuery): VariableDisplayerFilter => {
        const filter: VariableDisplayerFilter = {
            name: query.name || "",
            value: query.value || "",
            description: query.description || "",
            source: query.source || "",
            filterEmptyValues: query.filterEmptyValues === "true",
            filterDuplicateNames: query.filterDuplicateNames === "true",
            filterNonPrintableCharacters: query.filterNonPrintableCharacters === "true",
            filterVariableSubstitutionSyntax: query.filterVariableSubstitutionSyntax === "true",
            scope: {
                Environment: arrayValueFromQueryString(query.environment),
                Machine: arrayValueFromQueryString(query.machine),
                Role: arrayValueFromQueryString(query.role),
                Action: arrayValueFromQueryString(query.action),
                Channel: arrayValueFromQueryString(query.channel),
                TenantTag: arrayValueFromQueryString(query.tenantTag)
            }
        };

        return filter;
    }

    private combineMessages(variableMessages: AllVariableMessages[]) {
        return variableMessages.reduce((p, c) => {
            return {
                duplicateVariableNames: [...p.duplicateVariableNames, ...c.duplicateVariableNames],
                variableMessages: [...p.variableMessages, ...c.variableMessages]
            };
        }, {
            duplicateVariableNames: [],
            variableMessages: []
        });
    }
}

function isSectionHeaderRow(row: Row): row is number {
    return typeof row === "number";
}

function mergeAndSortVariables(variables: ReadonlyArray<VariableWithSource>, availableScopes: ScopeValues): ReadonlyArray<VariableWithSource> {

    const groupedByName = groupVariablesByName(variables, v => v.name);
    const merged = Object.keys(groupedByName).map(name => ({name, values: flatten(groupedByName[name].map(v => [...v.values]))}));

    return merged.sort(compareVariablesName)
                .map(v => ({name: v.name, values: [...v.values].sort(compareValuesWithSource)}));

    function compareVariablesName(l: VariableWithSource, r: VariableWithSource): number {
        return l.name.toLowerCase().localeCompare(r.name.toLowerCase());
    }

    function compareValuesWithSource(l: ValueWithSource, r: ValueWithSource) {
        return compareScopes(l.scope, r.scope, availableScopes)
            || compareSources(l.source, r.source)
            || compareValues(l, r);
    }
}

export function compareSources(l: ValueSource, r: ValueSource) {
    return compareSourceType(l, r)
        || compareSourceName(l, r);
}

function compareSourceType(l: ValueSource, r: ValueSource) {
    return getSourceTypeOrder(l) - getSourceTypeOrder(r);
}

function getSourceTypeOrder(source: ValueSource) {
    if (isProjectVariableSource(source)) {
        return 1;
    } else if (isLibraryVariableSetSource(source)) {
        return 2;
    } else if (isTenantProjectVariableSource(source)) {
        return 3;
    } else {
        // Tenant library variable set case
        return 4;
    }
}

function compareSourceName(l: ValueSource, r: ValueSource) {
    return getSourceLinkName(l).localeCompare(getSourceLinkName(r));
}

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>) => bindActionCreators({onLoad: fetchAllAccounts}, dispatch);

const ConnectedVariableDisplayer = connect<{}, {}, VariableDisplayerProps>(null, mapDispatchToProps)(VariableDisplayer);

export default ConnectedVariableDisplayer;