import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
import { TopicsContainer, LearnMoreResources } from "./CommonViews";

export const CommonLibraryVariableSetOverview: React.StatelessComponent<{}> = props => {
    return <OverviewContainer>
        <OverviewHighlightText>
            <p>
                <ExternalLink href="LibraryVariableSets">Variable sets</ExternalLink> are collections of variables that can be be shared between multiple projects.
            </p>
        </OverviewHighlightText>
    </OverviewContainer>;
};

export const CommonLibraryVariableSetTopics: React.StatelessComponent<{}> = props => {
    return <TopicsContainer>
        <LearnMoreResources>
            <div><ExternalLink href="LibraryVariableSets">Variable Sets</ExternalLink></div>
        </LearnMoreResources>
    </TopicsContainer>;
};