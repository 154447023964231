import * as React from "react";
import {repository} from "clientInstance";
import {
    SmtpConfigurationResource,
} from "client/resources";
import {
    Text,
    ExpandableFormSection,
    Summary,
    Sensitive,
    Checkbox,
    required,
} from "components/form";
import FormBaseComponent, {OptionalFormBaseComponentState} from "components/FormBaseComponent";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import {RouteComponentProps} from "react-router";
import {cloneDeep} from "lodash";
import ParseHelper from "utils/ParseHelper";
import {ActionButton, ActionButtonType} from "components/Button";
const styles = require("./style.less");
import Dialog from "components/Dialog/Dialog";
import {connect} from "react-redux";
import {openDialog} from "components/Dialog/reducers/dialog";
import GlobalState from "globalState";
import SendTestEmail from "areas/configuration/components/Smtp/SendEmail";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import Markdown from "components/Markdown";

interface SmtpProps extends RouteComponentProps<any> {
    create?: boolean;
}

interface SmtpState extends OptionalFormBaseComponentState<SmtpConfigurationResource> {
    showPasswordChange: boolean;
    testEmailAddress?: string;
}

interface DispatchProps {
    openTestEmailDialog(): void;
}

interface StateProps {
    testEmailDialogOpen: boolean;
}

type Props = SmtpProps & StateProps & DispatchProps;

class SmtpInternal extends FormBaseComponent<Props, SmtpState, SmtpConfigurationResource> {

    constructor(props: Props) {
        super(props);
        this.state = {
            showPasswordChange: false,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const smtpConfiguration = await repository.SmtpConfiguration.get();
            this.setState({
                model: smtpConfiguration,
                cleanModel: cloneDeep(smtpConfiguration)
            });
        });
    }

    render() {

        const title = "SMTP Configuration";
        const saveText = "SMTP details updated";
        const saveButton = <PermissionCheck permission={Permission.ConfigureServer}>
            <ActionButton
                type={ActionButtonType.Secondary}
                label="Save and Test"
                busyLabel="Saving..."
                disabled={this.state.busy}
                onClick={() => this.handleSaveClick(true)}
            />
        </PermissionCheck>;

        const body = this.state.model &&
            <div className={styles.fullWidth}>
                <ExpandableFormSection
                    errorKey="SmtpHost"
                    title="SMTP Host"
                    focusOnExpandAll
                    summary={this.state.model.SmtpHost
                        ? Summary.summary(this.state.model.SmtpHost)
                        : Summary.placeholder("No DNS hostname specified")}
                    help="Enter the DNS hostname for your SMTP server.">
                    <Text
                        value={this.state.model.SmtpHost}
                        onChange={SmtpHost => this.setModelState({SmtpHost})}
                        label="SMTP host"
                        autoFocus={true}
                    />
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey="SmtpPort"
                    title="SMTP Port"
                    summary={this.state.model.SmtpPort
                        ? Summary.summary(this.state.model.SmtpPort)
                        : Summary.placeholder("No port specified")}
                    help="Enter the TCP port for your SMTP server.">
                    <Text
                        value={this.state.model.SmtpPort === null ? "" : this.state.model.SmtpPort.toString()}
                        onChange={(x) => this.setModelState({SmtpPort: ParseHelper.safeParseInt(x, null)})}
                        label="SMTP port"
                        validate={required("Please enter an SMTP port")}
                    />
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey="EnableSsl"
                    title="Use SSL/TLS"
                    summary={this.state.model.EnableSsl
                        ? Summary.summary("Yes")
                        : Summary.default("No")}
                    help={
                        <Markdown markup="
This option controls whether or not Octopus enforces using an SSL/TLS-wrapped connection.
If this is not selected, SSL/TLS will still be used if your email server supports the `STARTTLS` extension, but it is not mandatory"
                        />}>
                    <Checkbox
                        value={this.state.model.EnableSsl}
                        onChange={EnableSsl => this.setModelState({EnableSsl})}
                        label="Use SSL/TLS"
                        className={styles.rememberMe}
                    />
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey="SendEmailFrom"
                    title="From Address"
                    summary={this.state.model.SendEmailFrom
                        ? Summary.summary(this.state.model.SendEmailFrom)
                        : Summary.placeholder("No from address specified")}
                    help="All emails will be sent 'From' this address.">
                    <Text
                        value={this.state.model.SendEmailFrom}
                        onChange={SendEmailFrom => this.setModelState({SendEmailFrom})}
                        label="From address"
                    />
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey="Credentials"
                    title="Credentials"
                    summary={this.state.model.SmtpLogin ? Summary.summary(`Credentials have been entered, username is ${this.state.model.SmtpLogin}`)
                        : Summary.placeholder("Add authentication details if your SMTP server requires authentication")}
                    help="Leave blank if your SMTP server does not require authentication.">
                    <Text
                        value={this.state.model.SmtpLogin}
                        onChange={SmtpLogin => this.setModelState({SmtpLogin})}
                        label="SMTP login"
                    />
                    <br/>
                    <Sensitive
                            value={this.state.model.SmtpPassword}
                            onChange={SmtpPassword => this.setModelState({SmtpPassword})}
                            label="SMTP password"
                    />
                </ExpandableFormSection>
            </div>;

        return <FormPaperLayout
            title={title}
            busy={this.state.busy}
            errors={this.state.errors}
            model={this.state.model}
            cleanModel={this.state.cleanModel}
            savePermission={{permission: [Permission.AdministerSystem, Permission.ConfigureServer]}}
            onSaveClick={() => this.handleSaveClick(false)}
            saveText={saveText}
            secondaryAction={saveButton}
            expandAllOnMount={this.props.create}>
            <Dialog open={this.props.testEmailDialogOpen || false}>
                <SendTestEmail/>
            </Dialog>
            {body}
        </FormPaperLayout>;
    }

    private handleSaveClick = async (testSend: boolean) => {
        await this.doBusyTask(async () => {
            const result = await repository.SmtpConfiguration.modify(this.state.model);
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
            });
            if (testSend) {
                this.props.openTestEmailDialog();
            }
        });
    }
}

const mapStateToProps = (state: GlobalState) => {
    return {
        testEmailDialogOpen: state.dialogs && state.dialogs["testEmail"]
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        openTestEmailDialog: () => dispatch(openDialog("testEmail"))
    };
};

const Smtp = connect<StateProps, DispatchProps, SmtpProps>(
    mapStateToProps,
    mapDispatchToProps
)(SmtpInternal);

export default Smtp;
