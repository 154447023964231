import * as React from "react";
import * as H from "history";
import ActionList from "components/ActionList/ActionList";
import AdvancedFilterLayout from "components/AdvancedFilterLayout/AdvancedFilterLayout";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import InfrastructureLayout from "../InfrastructureLayout";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import MachineFilter from "./MachineFilter";
import MachineHealthStatusHelper from "utils/MachineHealthStatusHelper";
import PaperLayout from "components/PaperLayout/PaperLayout";
import RequestRaceConditioner from "utils/RequestRaceConditioner";
import routeLinks from "../../../../routeLinks";
import { AdvancedFilterCheckbox, FilterSection } from "components/AdvancedFilterLayout";
import { arrayValueFromQueryString } from "../../../../utils/ParseHelper/ParseHelper";
import { Dictionary, isEqual } from "lodash";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { MachineQuery } from "areas/infrastructure/components/MachinesLayout/MachineQuery";
import { QueryStringFilters } from "components/QueryStringFilters/QueryStringFilters";
import { RouteComponentProps } from "react-router";
import { repository, client } from "clientInstance";
import {
    MachineModelHealthStatus,
    ResourceCollection,
    CommunicationStyle,
    TaskState,
    TaskName,
    TaskResource,
    MachineResource,
    SummaryResource,
} from "client/resources";
import {
    EndpointCommunicationStyleMultiSelect,
    MachineModelHealthStatusMultiSelect,
} from "components/MultiSelect";
import EndpointsHelper from "utils/EndpointsHelper/EndpointsHelper";
import ConfirmTentacleUpgradePanel from "./ConfirmTentacleUpgradePanel";

export interface MachinesLayoutProps extends RouteComponentProps<any> {
    title: string;
    itemDescriptions: string;
    onClearMachine?(): void;
}

export interface MachinesLayoutState extends DataBaseComponentState {
    machinesResponse: ResourceCollection<MachineResource>;
    machineHealthStatusFastLookup?: Dictionary<ResourceCollection<MachineResource>>;
    currentPageIndex: number; // This has a custom endpoint, so we manage our own paging implementation in List/onLoadMore.
    expanded: boolean; // Need to know if we're currently expanded so we can choose to reload when the filter changes or not.
    healthStatusFilters: string[];
    isDisabledFilter: boolean;
    filter: MachineFilter;
    queryFilter?: MachineFilter;
    isSearching?: boolean;
    redirectToTaskId?: string;
    hasMachines: boolean;
}

export class MachineFilterLayout extends AdvancedFilterLayout<MachineFilter> { }

const MachineQueryStringFilters = QueryStringFilters.For<MachineFilter, MachineQuery>();

export abstract class BaseMachinesLayout<Props extends MachinesLayoutProps, State extends MachinesLayoutState> extends DataBaseComponent<Props, State> {
    protected machineHealthStatuses = MachineHealthStatusHelper.getMachineModelHealthStatusResources();
    protected communicationStyles = EndpointsHelper.getCommunicationStyleResources();
    protected requestRaceConditioner = new RequestRaceConditioner();

    constructor(props: Props) {
        super(props);
        this.state = this.initialState();
    }

    async componentDidMount() {
        await this.doBusyTask(() => this.loadLookupData());
        await this.refreshSummaryData();

        // Clear currentMachine (to avoid seeing old machine data when switching machines).
        this.props.onClearMachine();
    }

    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={routeLinks.task(this.state.redirectToTaskId).root} push={true} />;
        }

        const actionSection = <ActionList actions={this.getActions()} />;
        const searchHintText = "Search " + this.props.itemDescriptions + "...";
        const summaries = this.getSummaries();

        const tentacleUpgradesRequiredWarning = summaries &&
            summaries.TentacleUpgradesRequired === true &&
            <ConfirmTentacleUpgradePanel doBusyTask={this.doBusyTask}
                calloutDescriptionElement={<p>One or more {this.props.itemDescriptions} are running old versions of the Tentacle agent and can be upgraded.</p>}
                onTentacleUpgradeComplete={taskId => {
                    this.setState({ redirectToTaskId: taskId });
                }}
            />;

        return <InfrastructureLayout {...this.props}>
            <MachineQueryStringFilters
                filter={this.state.filter}
                getQuery={this.queryFromFilter}
                getFilter={this.getFilter}
                onFilterChange={filter => this.setState({ filter, queryFilter: filter }, () => this.onFilterChange())} />
            <PaperLayout
                title={this.props.title}
                busy={this.state.busy}
                errors={this.state.errors}
                sectionControl={actionSection}
            >
                {summaries && this.state.hasMachines && this.renderPageSummary()}
                {summaries && !this.state.hasMachines && this.renderOnboarding()}
                {tentacleUpgradesRequiredWarning}
                {this.state.hasMachines && <MachineFilterLayout
                    filterSections={this.getFilterSections()}
                    filter={this.state.filter}
                    queryFilter={this.state.queryFilter}
                    defaultFilter={this.createEmptyFilter()}
                    initiallyShowFilter={this.isFiltering()}
                    additionalHeaderFilters={[<FilterSearchBox
                        hintText={searchHintText}
                        value={this.state.filter.partialName}
                        onChange={x => {
                            this.setFilterState({ partialName: x }, this.onFilterChange);
                        }}
                        autoFocus={true}
                    />]}
                    onFilterReset={(filter: MachineFilter) => {
                        this.setState({ filter }, () => {
                            this.onFilterChange();
                            const location = { ...this.props.history as H.History, search: null as any };
                            this.props.history.replace(location);
                        });
                    }}
                    renderContent={() => this.renderMachinesExpander()}
                />}
            </PaperLayout>
        </InfrastructureLayout>;
    }

    protected abstract initialState(): State;
    protected abstract loadLookupData(): Promise<void>;
    protected abstract loadSummaries(): Promise<void>;
    protected abstract getSummaries(): SummaryResource;

    protected abstract getActions(): JSX.Element[];
    protected abstract renderOnboarding(): JSX.Element;
    protected abstract renderMachinesExpander(): React.ReactNode;

    protected renderPageSummary(): JSX.Element {
        return null;
    }

    protected baseInitialState(): MachinesLayoutState {
        return {
            machinesResponse: null,
            currentPageIndex: 0,
            expanded: true,
            healthStatusFilters: [],
            isDisabledFilter: false,
            machineHealthStatusFastLookup: {},
            filter: this.createEmptyFilter(),
            isSearching: false,
            hasMachines: false,
        };
    }

    protected getFilterSections() {
        const filters: React.ReactNode[] = [
            <AdvancedFilterCheckbox key="filterDisabled"
                label="Disabled only"
                value={this.state.filter.isDisabled}
                onChange={x => {
                    this.setFilterState({ isDisabled: x }, () => {
                        this.onFilterChange();
                    });
                }}
            />
        ];

        filters.push(this.extraFilters());

        filters.push(<MachineModelHealthStatusMultiSelect key="filterStatus"
            items={this.machineHealthStatuses}
            value={this.state.filter.healthStatuses}
            onChange={x => {
                this.setFilterState({ healthStatuses: x }, () => {
                    this.onFilterChange();
                });
            }}
        />);

        filters.push(<EndpointCommunicationStyleMultiSelect key="filterCommunication"
            items={this.communicationStyles}
            value={this.state.filter.commStyles}
            onChange={x => {
                this.setFilterState({ commStyles: x }, () => {
                    this.onFilterChange();
                });
            }}
        />);

        const filterSections: FilterSection[] = [{
            render: <div>
                {filters}
            </div>
        }];

        return filterSections;
    }

    protected extraFilters(): React.ReactNode[] {
        return [];
    }

    protected createEmptyFilter(): MachineFilter {
        return {
            partialName: undefined,
            environmentIds: [],
            roles: [],
            workerPoolIds: [],
            isDisabled: false,
            tenantIds: [],
            tenantTags: [],
            healthStatuses: [],
            commStyles: [],
        };
    }

    protected setFilterState<K extends keyof MachineFilter>(state: Pick<MachineFilter, K>, callback?: () => void) {
        this.setState(prev => ({
            filter: { ...prev.filter as object, ...state as object }
        }), callback);
    }

    protected isFiltering() {
        return !isEqual(this.state.filter, this.createEmptyFilter());
    }

    protected onFilterChange() {
        this.setState({ isSearching: true }, async () => {
            await this.refreshSummaryData();
            this.setState({ isSearching: false });
        });
    }

    private queryFromFilter = (filter: MachineFilter): MachineQuery => {
        const query: MachineQuery = {
            partialName: filter.partialName,
            environmentIds: filter.environmentIds,
            healthStatuses: filter.healthStatuses,
            commStyles: filter.commStyles,
            roles: filter.roles,
            workerPoolIds: filter.workerPoolIds,
            isDisabled: filter.isDisabled ? "true" : undefined,
            tenantIds: filter.tenantIds,
            tenantTags: filter.tenantTags
        };

        return query;
    }

    private getFilter = (query: MachineQuery): MachineFilter => {
        const filter: MachineFilter = {
            ...this.createEmptyFilter(),
            partialName: query.partialName,
            environmentIds: arrayValueFromQueryString(query.environmentIds) || [],
            healthStatuses: arrayValueFromQueryString(query.healthStatuses) as MachineModelHealthStatus[] || [],
            commStyles: arrayValueFromQueryString(query.commStyles) as CommunicationStyle[] || [],
            roles: arrayValueFromQueryString(query.roles) || [],
            isDisabled: query.isDisabled === "true",
            tenantIds: arrayValueFromQueryString(query.tenantIds) || [],
            tenantTags: arrayValueFromQueryString(query.tenantTags) || [], // Expecting canonical tag names.
        };

        return filter;
    }

    private refreshSummaryData = async () => {
        await this.doBusyTask(() => this.loadSummaries());
    }
}
