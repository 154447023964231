import IconButtonList from "../../IconButtonList/IconButtonList";
import InputWithActions from "../../InputWithActions";
import * as React from "react";
import FormFieldProps from "../FormFieldProps";
import ToolTip, {ToolTipPosition} from "components/ToolTip";
import { Note } from "components/form";
const styles = require("./style.less");
import Text from "components/form/Text/Text";
import IconButton, {Icon} from "components/IconButton/IconButton";
import { } from "components/IconButtonList/IconButtonList";
import { noOp } from "utils/noOp";
const chooseImage = require("./choose-image.svg");

export interface LogoEditorSettings {
    file?: File;
    reset: boolean;
}

interface LogoEditorProps extends FormFieldProps<LogoEditorSettings> {
    autoFocus?: boolean;
    type?: string;
    hintText?: string;
    error?: string;
    onValidate?(value: string): void;
}

export default class LogoEditor extends React.Component<LogoEditorProps, {}> {
    input: any;
    constructor(props: LogoEditorProps) {
        super(props);
    }

    handleFileChange = (e: any) => {
        if (e.target.files.length < 1) {
            this.onValidate("No file selected");
            return;
        }

        const file = e.target.files[0];
        const ValidImageTypes = ["image/gif", "image/jpeg", "image/png"];
        if (ValidImageTypes.indexOf(file.type) < 0) {
            this.onValidate("Selected file was not an image");
            return;
        }

        this.onValidate("");
        const value: LogoEditorSettings = {
            file,
            reset: false
        };
        this.props.onChange(value);
    }
    onValidate(message: string) {
        if (this.props.onValidate) {
            this.props.onValidate(message);
        }
    }
    useDefault = () => {
        const value: LogoEditorSettings = {
            file: null,
            reset: true
        };
        this.props.onChange(value);
    }

    clear = () => {
        const value: LogoEditorSettings = {
            file: null,
            reset: false
        };
        this.props.onChange(value);
    }

    chooseFile = () => {
        this.input.click();
    }

    render() {
        const {
            value,
            error,
            hintText
        } = this.props;

        const v = (value == null || (value.file == null && !value.reset))
            ? "Current logo"
                : (value.file ?  value.file.name : "Default logo");
        const removeFromScreen = {
                cursor: "pointer",
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                width: "100%",
                opacity: 0,
        };
//TODO Use choose-image.svg rather than Image
        return (
            <div>
                <div className={styles.logoContainer}>
                    <InputWithActions
                        input={
                            <Text
                                type="text"
                                onChange={noOp}
                                hintText={hintText}
                                value={v}
                                error={error}
                                onClick={this.chooseFile} />
                        }
                        actions={
                            <IconButtonList buttons={
                                [
                                    <IconButton onClick={this.chooseFile} icon={chooseImage} toolTipContent="Choose image" />,
                                    <IconButton onClick={this.useDefault} icon={Icon.UseDefaultImage} toolTipContent="Use default image" />
                                ]}
                            />}
                    />
                    <input type="file"
                        style={removeFromScreen as any}
                        onChange={this.handleFileChange}
                        ref={(input) => { this.input = input; }}/>
                </div>
                <Note>A transparent PNG, no larger than 100x100 pixels, is recommended. JPG and gif are also suitable.</Note>
            </div>
        );
    }
}