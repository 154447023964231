import * as React from "react";
import pageHelpRegistry, { CategorizedPageHelpRegistration } from "./Registry/pageHelpRegistry";
import * as cn from "classnames";
import { withRegistrations, RegistrationProps } from "./Registry/withRegistrations";
import { compose } from "recompose";
import IPageWrapper from "utils/pageId";
import Section from "components/Section";
import { DefaultOverview, DefaultTopics, DefaultGettingStarted } from "./Registrations/CommonComponents/DefaultViews";

interface PageHelpViewProps {
    registrations: CategorizedPageHelpRegistration[];
    className?: string;
}

const PageHelpOverviewView: React.SFC<PageHelpViewProps> = ({ registrations = [], className }) => {
    if (!registrations || registrations.length === 0) {
        return <DefaultOverview />;
    }
    return <div className={cn(className)}>
        {registrations.map(x => {
            const element = x.renderOverview({
                registration: x,
            });
            return element
                ? React.cloneElement(element, { key: x.page.Id })
                : <DefaultOverview key={x.page.Id} />;
        })}
    </div>;
};

const PageHelpTopicsView: React.SFC<PageHelpViewProps> = ({ registrations = [], className }) => {
    if (!registrations || registrations.length === 0) {
        return <><DefaultTopics /><DefaultGettingStarted /></>;
    }
    return <div className={cn(className)}>
        {registrations.map((x, index) => {
            const element = x.renderTopics({
                registration: x,
            });
            const elementToReturn = element
                ? React.cloneElement(element, { key: x.page.Id })
                : <DefaultTopics />;
            return <div key={index}>{elementToReturn}<DefaultGettingStarted /></div>;
        })}
    </div>;
};

class PageHelpRegistrationsOverviewView extends React.Component<RegistrationProps & PageProps> {
    render() {
        const { registrations, pageId } = this.props;
        const pageRegistrations = pageId ? registrations.filter(x => x.page.Id === pageId.Id) : null;
        return <PageHelpOverviewView registrations={pageRegistrations} />;
    }
}

class PageHelpRegistrationsTopicsView extends React.Component<RegistrationProps & PageProps> {
    render() {
        const { registrations, pageId } = this.props;
        const pageRegistrations = pageId ? registrations.filter(x => x.page.Id === pageId.Id) : null;
        return <PageHelpTopicsView registrations={pageRegistrations} />;
    }
}

interface PageProps {
    pageId: IPageWrapper;
}

type PageHelpSelectorProps = PageProps & RegistrationProps;

export const PageHelpOverviewSelector: React.SFC<PageHelpSelectorProps> = ({ registrations, pageId }) => {
    return <Section>
        <PageHelpRegistrationsOverviewView
            registrations={registrations}
            pageId={pageId}
        />
    </Section>;
};

export const PageHelpTopicsSelector: React.SFC<PageHelpSelectorProps> = ({ registrations, pageId }) => {
    return <Section>
        <PageHelpRegistrationsTopicsView
            registrations={registrations}
            pageId={pageId}
        />
    </Section>;
};

const withKnownPageHelpRegistrations = withRegistrations(() => pageHelpRegistry.getAllRegistrations());

type EnhancedSelectorProps = PageProps & Partial<RegistrationProps>;

const forCommonPageHelpOverview = compose<PageHelpSelectorProps, EnhancedSelectorProps>(
    withKnownPageHelpRegistrations,
);

const CommonPageHelpOverviewSelector = forCommonPageHelpOverview(PageHelpOverviewSelector);
const CommonPageHelpTopicsSelector = forCommonPageHelpOverview(PageHelpTopicsSelector);

export default PageHelpOverviewSelector;

export {
    withKnownPageHelpRegistrations,
    CommonPageHelpOverviewSelector,
    CommonPageHelpTopicsSelector,
};