import * as React from "react";
import * as Clipboard from "clipboard";
import Tooltip from "components/ToolTip";
import IconButton from "material-ui/IconButton";
import CopyIcon from "material-ui/svg-icons/content/content-copy";
import { FocusContextConsumer } from "components/FocusContext";
const styles = require("./style.less");

interface CopyToClipboardInternalState {
    showConfirmation: boolean;
}

interface CopyToClipboardInternalProps extends CopyToClipboardProps {
    focusArea?: React.ReactElement;
}

export class CopyToClipboardInternal extends React.Component<CopyToClipboardInternalProps, CopyToClipboardInternalState> {
    private clipboard: Clipboard;
    private hideTimeoutId: number;
    private element: HTMLElement;

    constructor(props: CopyToClipboardInternalProps) {
        super(props);
        this.state = { showConfirmation: false };
    }

    setupClipboard = () => {
        this.clipboard = new Clipboard(this.element, {
            text: () => this.props.value,
            ...((this.props.focusArea && {container: this.props.focusArea}) || {})
        });
        this.clipboard.on("success", () => this.onCopy());
    }

    teardownClipboard = () => {
        if (this.clipboard) {
            this.clipboard.destroy();
            this.clipboard = null;
        }
    }

    componentDidMount() {
        this.setupClipboard();
    }

    componentDidUpdate(prevProps: CopyToClipboardInternalProps, prevState: CopyToClipboardInternalState) {
        this.teardownClipboard();
        this.setupClipboard();
    }

    shouldComponentUpdate(nextProps: CopyToClipboardInternalProps, nextState: CopyToClipboardInternalState) {
        //We don't have to re-render on props.value since it's never used as part of rendering
        //the value will still be copied appropriately though.
        return nextProps.focusArea !== this.props.focusArea ||
        nextState.showConfirmation !== this.state.showConfirmation;
    }

    componentWillUnmount() {
        this.teardownClipboard();
        if (this.hideTimeoutId) {
            window.clearTimeout(this.hideTimeoutId);
        }
    }

    render() {
        const button = <span>
            <IconButton className={styles.defaultIconButton}>
                <CopyIcon />
            </IconButton>
        </span>;

        return (
            <span ref={this.setRef}>
                {this.state.showConfirmation && <Tooltip
                    content="Copied!"
                    trigger="manual"
                    open={true}
                >
                    {button}
                </Tooltip>}
                {!this.state.showConfirmation && <Tooltip
                    content="Copy to clipboard"
                >
                    {button}
                </Tooltip>}
            </span>
        );
    }

    private setRef = (el: HTMLElement) => {
        this.element = el;
    }

    private onCopy() {
        this.setState({ showConfirmation: true });
        if (this.hideTimeoutId) {
            window.clearTimeout(this.hideTimeoutId);
        }
        this.hideTimeoutId = window.setTimeout(() => this.onHide(), 3500);
    }

    private onHide() {
        this.setState({ showConfirmation: false });
        this.hideTimeoutId = null;
    }
}

interface CopyToClipboardProps {
    value: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = (props) => (
    <FocusContextConsumer>
        {({current}) => <CopyToClipboardInternal focusArea={current} {...props}/>}
    </FocusContextConsumer>
);

export default CopyToClipboard;