import * as React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "theme";
import { useCallback, useMemo } from "react";
import { useTabs, RenderTabCallback } from "./useTabs";

const useStyles = makeStyles<Theme, ControlledTabComponentProps>((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary
    },
    rootTab: {
        minWidth: "7.5rem", // Needed for DrawerWrapperLayout.
    },
    tabsContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    }
}));

export interface ControlledTabComponentProps {
    value: string;
    afterTabsElement?: JSX.Element;
    onChange: (value: string) => void;
    renderTab?: RenderTabCallback;
}

const ControlledTabsContainer: React.FC<ControlledTabComponentProps> = (props) => {
    const classes = useStyles(props);

    const renderTab = useMemo(() => {
        const defaultTabRender: RenderTabCallback = (renderProps) => (<Tab className={classes.rootTab} {...renderProps} key={renderProps.value} />);
        return props.renderTab || defaultTabRender;
    }, [props.renderTab]);

    const { tabs, content, value } = useTabs(props.children, props.value, renderTab);

    const handleTabChange = useCallback((event: React.ChangeEvent<{}>, tabValue: string) => {
        if (props.onChange) {
            props.onChange(tabValue);
        }
    }, [props.onChange]);

    return (
        <React.Fragment>
            <div className={classes.tabsContainer}>
                <Tabs className={classes.root} value={value} onChange={handleTabChange}>
                    {tabs}
                </Tabs>
                {props.afterTabsElement}
            </div>
            {content}
        </React.Fragment>
    );
};

export default ControlledTabsContainer;
