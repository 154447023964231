import * as React from "react";
import {ActionPlugin, ActionEditProps} from "../pluginRegistry";
import {BaseComponent} from "components/BaseComponent/BaseComponent";
import {repository} from "clientInstance";
import {ActionSummaryProps} from "../actionSummaryProps";
import {ActionExecutionLocation} from "../../../client/resources/actionExecutionLocation";
import {ScriptActionEdit, ScriptProperties} from "../script/scriptAction";
import AwsLoginComponent, {AWSScriptProperties} from "./awsLoginComponent";
import {VariableLookupText} from "components/form/VariableLookupText";
import {AccountResource} from "../../../client/resources";
import FeedResource from "../../../client/resources/feedResource";
import ExpandableFormSection from "../../form/Sections/ExpandableFormSection";
import {Summary} from "../../form";
import {TargetRoles} from "../../../areas/projects/components/DeploymentProcess/ActionDetails";
import Note from "../../form/Note/Note";
import ExternalLink from "../../Navigation/ExternalLink/ExternalLink";
import {ScriptPackageProperties} from "../script/ScriptPackageReferenceDialog";
import {SupportedLanguage} from "../../ScriptingLanguageSelector/ScriptingLanguageSelector";

export interface AWSScriptActionEditState {
    accounts: AccountResource[];
    feeds: FeedResource[];
}

interface AWSRunScriptProperties extends AWSScriptProperties, ScriptProperties {

}

class AWSRunScriptActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        return <div>
            Log into AWS and run a script with the AWS CLI.
        </div>;
    }
}

export class AWSRunScriptActionEdit extends BaseComponent<ActionEditProps<AWSRunScriptProperties, ScriptPackageProperties>, AWSScriptActionEditState> {
    constructor(props: ActionEditProps<AWSRunScriptProperties, ScriptPackageProperties>) {
        super(props);
        this.state = {
            feeds: [],
            accounts: []
        };
    }

    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            this.setState({
                feeds: await repository.Feeds.all(),
                accounts: await repository.Accounts.all(),
            });

            if (!this.props.properties["Octopus.Action.Aws.AssumeRole"]) {
                this.props.setProperties({["Octopus.Action.Aws.AssumeRole"]: "False"}, true);
            }

            if (!this.props.properties["Octopus.Action.AwsAccount.UseInstanceRole"]) {
                this.props.setProperties({["Octopus.Action.AwsAccount.UseInstanceRole"]: "False"}, true);
            }
        });
    }

    render() {
        return <div>
            <AwsLoginComponent
                projectId={this.props.projectId}
                properties={this.props.properties}
                packages={this.props.packages}
                plugin={this.props.plugin}
                setProperties={this.props.setProperties}
                setPackages={this.props.setPackages}
                doBusyTask={this.props.doBusyTask}
                busy={this.props.busy}
                getFieldError={this.props.getFieldError}
                errors={this.props.errors}
                expandedByDefault={this.props.expandedByDefault}/>
            <ExpandableFormSection
                errorKey="Octopus.Action.Aws.Region"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Region"
                summary={this.regionSummary()}
                help={"Specify the default region"}>
                <VariableLookupText localNames={this.props.localNames}
                                    projectId={this.props.projectId}
                                    value={this.props.properties["Octopus.Action.Aws.Region"]}
                                    label="Region"
                                    onChange={(val) => this.props.setProperties({["Octopus.Action.Aws.Region"]: val})}
                                    error={this.props.getFieldError("Octopus.Action.Aws.Region")}
                />
                <Note>
                    View the <ExternalLink href="AWSRegions">AWS Regions and Endpoints</ExternalLink> documentation
                    for a current list of the available region codes.
                </Note>
            </ExpandableFormSection>
            <ScriptActionEdit
                plugin={this.props.plugin}
                projectId={this.props.projectId}
                packages={this.props.packages}
                localNames={this.props.localNames}
                properties={this.props.properties}
                setProperties={this.props.setProperties}
                setPackages={this.props.setPackages}
                doBusyTask={this.props.doBusyTask}
                busy={this.props.busy}
                getFieldError={this.props.getFieldError}
                supportedLanguages={SupportedLanguage.PowerShell}
                errors={this.props.errors}
                expandedByDefault={this.props.expandedByDefault}/>
        </div>;
    }

    private regionSummary() {
        const properties = this.props.properties;

        if (properties["Octopus.Action.Aws.Region"]) {
            return Summary.summary(<span>
                Using <strong>{properties["Octopus.Action.Aws.Region"]}</strong> as the default region
                </span>);
        }

        return Summary.placeholder("Specify the default AWS region");
    }
}

export default (): ActionPlugin => ({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.AwsRunScript",
    summary: (properties, targetRolesAsCSV) => <AWSRunScriptActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    edit: AWSRunScriptActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => false,
    features: {
        optional: ["Octopus.Features.JsonConfigurationVariables",
            "Octopus.Features.SubstituteInFiles"],
    }
});