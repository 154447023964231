import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonLibraryTenantTagSetOverview, CommonLibraryTenantTagSetTopics } from "../../CommonComponents/CommonLibraryTenantTagSetViews";

export const LibraryTenantTagSetRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.library.tagSet,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryTenantTagSetOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryTenantTagSetTopics />;
    }
};
