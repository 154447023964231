import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import Popover from "components/Popover/Popover";
import FilterSearchBox from "components/FilterSearchBox";
import { repository } from "clientInstance";
import { keyBy, sortBy } from "lodash";
const styles = require("./projectSwitcher.less");
import routeLinks from "../../routeLinks";
import { Section } from "components/Section/Section";
import { ProjectGroupResource, Permission } from "client/resources";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import VirtualListWithKeyboard from "components/VirtualListWithKeyboard/VirtualListWithKeyboard";
import SwitcherLink from "./SwitcherLink";
import { FocusableComponent } from "../VirtualListWithKeyboard/FocusableComponent";

interface Project {
    Id: string;
    Name: string;
    Slug: string;
    Group: string;
}

interface ProjectSwitcherProps {
    open: boolean;
    onRequestClose: () => void;
    anchorEl: HTMLElement;
}

interface ProjectSwitcherState extends DataBaseComponentState {
    filter: string;
    projects: Project[];
    filteredList: Project[];
    redirectTo?: string;
}

const VirtualList = VirtualListWithKeyboard<Project>();

export default class ProjectSwitcher extends DataBaseComponent<ProjectSwitcherProps, ProjectSwitcherState> {

    static getDerivedStateFromProps(props: ProjectSwitcherProps, state: ProjectSwitcherState) {
        if (!props.open) {
            return { filter: "" };
        }
    }

    private searchRef: any;
    private virtualList: FocusableComponent;
    private updatePopoverPosition: () => void;

    constructor(props: ProjectSwitcherProps) {
        super(props);

        this.state = {
            filter: "",
            projects: [],
            filteredList: [],
        };
    }

    async componentDidMount() {
        await this.doBusyTask(() => this.refreshProjects());
    }

    async componentDidUpdate(prevProps: ProjectSwitcherProps, prevState: ProjectSwitcherState) {
        if (prevState.filter !== this.state.filter) {
            await this.refreshProjects();
        }
    }

    render() {
        const filteredList = this.filterList(this.state.filter);

        return <Popover
            getUpdatePosition={(update) => this.updatePopoverPosition = update}
            style={{ overflowY: "hidden" }}
            open={this.props.open}
            anchorEl={this.props.anchorEl}
            onClose={this.onRequestClose}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            transformOrigin={{ horizontal: "left", vertical: "top" }}>
            <div className={styles.container} onKeyDown={this.onKeyEsc}>
                {this.state.projects.length > 0 && <Section>
                    <FilterSearchBox
                        innerRef={this.setSearchRef}
                        autoFocus={true}
                        value={this.state.filter}
                        hintText="Jump to project..."
                        onChange={this.handleFilterChanged}
                        fullWidth={true}
                        onKeyDown={this.onArrowDown}
                        containerClassName={styles.filterFieldContainer}
                    />
                </Section>}
                <div className={styles.menuContainer}>
                    <VirtualList
                        multiSelectRef={el => this.virtualList = el}
                        items={filteredList}
                        empty={<div className={styles.empty}>There are no projects yet!</div>}
                        renderItem={item => ({
                            primaryText: item.Name,
                            secondaryText: item.Group
                        })}
                        containerElement={item => <SwitcherLink to={routeLinks.project(item.Slug).root} />}
                        onSelected={this.onRequestClose}
                        onResized={() => {
                            // When the content's size changes, we re-render so that the
                            // popover can re-position itself based on the new `VirtualList` size
                            if (this.updatePopoverPosition) {
                                this.updatePopoverPosition();
                            }
                        }}
                        onBlur={() => this.searchRef.focus()}
                    />
                </div>
            </div>
        </Popover>;
    }

    private setSearchRef = (el: any) => {
        this.searchRef = el;
    }

    private onRequestClose = () => {
        this.props.onRequestClose();
    }

    private filterList(filter: string): Project[] {
        const matchesFilter = (n: string) => n.toLowerCase().includes(filter.toLowerCase());

        return (filter.length > 0
            ? this.state.projects.filter(p => matchesFilter(p.Name) || (p.Group ? matchesFilter(p.Group) : false))
            : this.state.projects);
    }

    private refreshProjects = async () => {
        const projectResources = await repository.Projects.all();
        let projectGroupMap: { [id: string]: ProjectGroupResource } = null;
        if (isAllowed({ permission: Permission.ProjectGroupView, projectGroup: "*" })) {
            const groups = await repository.ProjectGroups.all();

            if (groups.length > 1) {
                projectGroupMap = keyBy(groups, "Id");
            }
        }

        const sortedProjects = sortBy(projectResources, p => p.Name.toLowerCase());
        const projectGroup = (gId: string) => {
            return projectGroupMap.hasOwnProperty(gId) ? projectGroupMap[gId].Name : null;
        };

        const projects = sortedProjects.map(p => ({
            Id: p.Id,
            Name: p.Name,
            Slug: p.Slug,
            Group: projectGroupMap ? projectGroup(p.ProjectGroupId) : null
        }));

        this.setState({ projects });
    }

    private onKeyEsc = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Escape") {
            this.onRequestClose();
        }
    }

    private onArrowDown = (event: KeyboardEvent) => {
        if (event.key === "ArrowDown" || event.key === "Tab") {
            if (this.filterList(this.state.filter).length === 0) {
                return;
            }

            this.virtualList.focus();
            event.preventDefault();
        }
    }

    private handleFilterChanged = (filter: string) => {
        this.setState({ filter });
    }
}
