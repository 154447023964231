import * as React from "react";
import * as cn from "classnames";
const formStyles = require("./style.less");

interface NoteProps {
    style?: any;
    checkboxNote?: boolean;
    className?: string;
    heading?: string;
}

const Note: React.StatelessComponent<NoteProps> = props => {
    const customStyle =  props.checkboxNote ? {...props.style, display: "block"} : props.style;
    return <div>
        <div className={formStyles.header}>{props.heading}</div>
        <div className={cn(formStyles.note, props.className)} style={customStyle}>{props.children}</div>
    </div>;
};

export default Note;