import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "../../DataBaseComponent";
import OkDialogLayout from "../../DialogLayout/OkDialogLayout";
import { required, Text } from "components/form";
import PackageSelector from "../../PackageSelector/PackageSelector";
import FeedResource, { feedTypeSupportsExtraction } from "../../../client/resources/feedResource";
import { BoundStringCheckbox } from "components/form/Checkbox/StringCheckbox";
import { PackageReference } from "../../../client/resources/packageReference";
import PackageDownloadOptions from "../../PackageDownloadOptions/PackageDownloadOptions";
import * as _ from "lodash";
import { RunOn } from "../../../areas/projects/components/DeploymentProcess/ActionDetails";
import Callout, { CalloutType } from "components/Callout";
import isBound from "../../form/BoundField/isBound";
import ExternalLink from "../../Navigation/ExternalLink/ExternalLink";
import Note from "../../form/Note/Note";
import {PackageAcquisitionLocation} from "../../../client/resources/packageAcquisitionLocation";

interface PackageReferenceProps {
    packageReference: ScriptPackageReference;
    runOn?: RunOn;
    feeds: FeedResource[];
    localNames: string[];
    projectId: string;
    onChange(PackageReference: ScriptPackageReference): boolean;
    refreshFeeds(): Promise<any>;
}

interface ScriptPackageReferenceState extends DataBaseComponentState {
    packageReferenceId: string;
    name: string;
    packageId: string;
    feedId: string;
    extract: string;
    acquisitionLocation: string;
    originalName: string;
    originalPackageId: string;
    showNameDefaultedMessage: boolean;
    showNameChangeWarning: boolean;
}

export interface ScriptPackageReference extends PackageReference<ScriptPackageProperties> {
}

export interface ScriptPackageProperties {
    "Extract": string;
}

export class ScriptPackageReferenceDialog extends DataBaseComponent<PackageReferenceProps, ScriptPackageReferenceState> {

    constructor(props: PackageReferenceProps) {
        super(props);

        this.state = {
            packageReferenceId: this.props.packageReference.Id,
            name: this.props.packageReference.Name,
            packageId: this.props.packageReference.PackageId,
            feedId: this.props.packageReference.FeedId,
            extract: this.props.packageReference.Properties["Extract"],
            acquisitionLocation: this.props.packageReference.AcquisitionLocation,
            originalName: this.props.packageReference.Name,
            originalPackageId: this.props.packageReference.PackageId,
            showNameDefaultedMessage: false,
            showNameChangeWarning: false
        };
    }

    render() {
        const feed = _.find(this.props.feeds, f => f.Id === this.state.feedId);
        const showExtract = this.shouldShowExtractOption(this.state.feedId);

        return <OkDialogLayout
            onOkClick={this.save}
            busy={this.state.busy}
            errors={this.state.errors}
            title="Reference a Package">
            {this.props.packageReference && <div>
                <PackageSelector
                    packageId={this.state.packageId}
                    feedId={this.state.feedId}
                    onPackageIdChange={packageId => this.onPackageIdChange(packageId)}
                    onFeedIdChange={feedId => this.onFeedIdChange(feedId)}
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    feeds={this.props.feeds}
                    refreshFeeds={this.props.refreshFeeds} />
                {this.state.showNameChangeWarning && <Callout type={CalloutType.Warning} title={"The name has been changed"}>
                   If you have external dependencies on this field (custom scripts, build-server plugins, etc) these will need to be updated.
                </Callout>}
                <Text
                    label="Name"
                    value={this.state.name}
                    onChange={name => this.onNameChange(name)}
                    validate={required("Please supply a Name")}
                />
                <Note>
                    The name is used to identify the package reference. Learn more about the <ExternalLink href="ScriptStepPackageReferenceName">package name</ExternalLink>.
                </Note>
                <PackageDownloadOptions
                    packageAcquisitionLocation={this.state.acquisitionLocation}
                    onPackageAcquisitionLocationChanged={acquisitionLocation => this.setState({ acquisitionLocation })}
                    runOn={this.props.runOn}
                    showNotAcquiredOption={true}
                    feed={feed}
                    projectId={this.props.projectId}
                    localNames={this.props.localNames} />
                {showExtract && <React.Fragment>
                    <BoundStringCheckbox
                        variableLookup={{
                            localNames: this.props.localNames,
                            projectId: this.props.projectId
                        }}
                        resetValue={this.props.packageReference.Properties["Extract"]}
                        value={this.state.extract}
                        label="Extract package during deployment"
                        onChange={extract => this.setState({ extract })} />
                    <Note>Learn more about <ExternalLink href="ScriptStepPackageReferencesFromCustomScripts">Accessing Package References from a Custom Script</ExternalLink>.</Note>
                </React.Fragment>}
            </div>}
        </OkDialogLayout>;
    }

    save = () => {

        if (!this.state.name) {
            this.setError("Please enter a name");
            return false;
        }

        if (!this.state.packageId) {
            this.setError("Please select a package ID");
            return false;
        }

        if (!this.state.feedId) {
            this.setError("Please select a feed ID");
            return false;
        }

        const packageReference = {
            Id: this.state.packageReferenceId,
            Name: this.state.name,
            PackageId: this.state.packageId,
            FeedId: this.state.feedId,
            AcquisitionLocation: this.state.acquisitionLocation,
            Properties: { Extract: this.state.extract }
        };

        return this.props.onChange(packageReference);
    }

    onPackageIdChange = (packageId: string) => {
        let name = this.state.name;
        let showNameDefaultedMessage = false;
        let showNameChangeWarning = false;

        // If `Name` was the default value (the previous package ID), then update it to the new package ID
        if ((!!packageId && !isBound(packageId) && !(!!this.state.originalName && this.state.originalName !== this.sanitizeName(this.state.originalPackageId)))) {

            name = this.sanitizeName(packageId);
            showNameChangeWarning = this.shouldShowNameChangeWarning(name);
            showNameDefaultedMessage = !showNameChangeWarning; // Showing both the info and the warning gets a bit noisy
        }

        this.setState({
            packageId,
            name,
            showNameDefaultedMessage,
            showNameChangeWarning
        });
    }

    onFeedIdChange = (feedId: string) => {
        this.setState({ feedId });

        // If the feed doesn't support extracting packages, then reset extract to "False"
        if (!this.shouldShowExtractOption(feedId)) {
            this.setState({ extract: "False" });
        }
    }

    onNameChange = (name: string) => {
        this.setState({
            name,
            showNameChangeWarning: this.shouldShowNameChangeWarning(name)
        });
    }

    shouldShowExtractOption = (feedId: string) => {
        const feed = _.find(this.props.feeds, f => f.Id === feedId);

        // Show the option to extract if the feed-type supports it
        // or if the feed is bound to a variable.
        return (!feed || feedTypeSupportsExtraction(feed.FeedType)) && this.state.acquisitionLocation !== PackageAcquisitionLocation.NotAcquired;
    }

    shouldShowNameChangeWarning = (name: string) => {
        // If the name is changing from a previous value, then we'll show a warning regarding external dependencies
        return !!name && !!this.state.originalName && name !== this.state.originalName;
    }

    // Some package ID's are invalid names, so we will try and sanitize them into valid a name
    sanitizeName = (name: string) => {
        if (!name) {
            return name;
        }

        // Container image ID's may contain `/`
        // Maven package ID's will contain `:`
        // We will take everything to the right of the last invalid-char as the name.
        // e.g. hashicorp/http-echo -> http-echo
        // e.g. org.acme:acme-web -> acme-web
        // Of course there may be other invalid characters, but these are two common ones, so we optimize for them.
        // Any others will fail server-side validation.

        const lastInvalidCharIndex = name.search("[/,:]");

        // If no invalid chars found
        if (lastInvalidCharIndex < 0) {
            return name;
        }

        // If the last character is an invalid char?  We'll just clear the name to force them to set it manually.
        if (lastInvalidCharIndex === name.length - 1) {
            return "";
        }

        // Return everything after the last invalid char
        return name.substring(lastInvalidCharIndex + 1);
    }
}
