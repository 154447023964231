export class MetadataTypeCollection {
    Description: string;
    Types: TypeMetadata[];
}

export class TypeMetadata {
    Name: string;
    Properties: PropertyMetadata[];
}

export class PropertyMetadata {
    Name: string;
    Type: string;
    DisplayInfo: DisplayInfo;
}

export class DisplayInfo {
    Required: boolean;
    Sensitive: boolean;
    Label: string;
    Description: string;
    Options: any;
    ListApi: string;
    ReadOnly: boolean;
    ShowCopyToClipboard: boolean;
    PropertyApplicability?: PropertyApplicability;
}

export class MetadataTypeDisplayOptions {
    SelectMode: string;
    Values: any;
}

export enum PropertyApplicabilityMode {
    ApplicableIfHasAnyValue = "ApplicableIfHasAnyValue",
    ApplicableIfHasNoValue = "ApplicableIfHasNoValue",
    ApplicableIfSpecificValue = "ApplicableIfSpecificValue",
    ApplicableIfNotSpecificValue = "ApplicableIfNotSpecificValue"
}

export class PropertyApplicability {
    Mode: PropertyApplicabilityMode;
    DependsOnPropertyName: string;
    DependsOnPropertyValue: any;
}

export type DataContext = { [key: string]: any; };