import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonUserProfileOverview, CommonUserProfileTopics } from "../../CommonComponents/CommonUserProfileViews";

export const UserProfileRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.currentUser.me,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonUserProfileOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonUserProfileTopics />;
    }
};
