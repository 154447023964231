import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {ProjectGroupResource, ProjectResource} from "client/resources";
import {repository} from "clientInstance";
import GlobalState from "globalState";
import {connect} from "react-redux";
import {projectFetched, projectStepsUpdated} from "../../reducers/projectsArea";
import NavigationSidebarLayout, {Navigation, NavItem} from "components/NavigationSidebarLayout/index";
import AreaTitle from "components/AreaTitle";
import BusyIndicator from "components/BusyIndicator/BusyIndicator";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import routeLinks from "../../../../routeLinks";
import Permission from "client/resources/permission";
import PermissionCheck, {isAllowed} from "components/PermissionCheck/PermissionCheck";
import BusyFromPromise from "../../../../components/BusyFromPromise/BusyFromPromise";
import ErrorPanel from "../../../../components/ErrorPanel/ErrorPanel";
import {DataBaseComponent, DataBaseComponentState} from "../../../../components/DataBaseComponent/DataBaseComponent";
import ActionButton, {ActionButtonType} from "components/Button/ActionButton";
import {DisabledChip} from "components/Chips";

const styles = require("./style.less");

export interface ProjectRouteParams {
    projectSlug: string;
}

interface StateProps {
    projectName?: string;
    projectSlug?: string;
    projectDescription?: string;
    projectGroupId?: string;
    projectLogoUrl?: string;
    projectId?: string;
    projectIsDisabled?: boolean;
    numberOfSteps?: number;
    isMultiTenancyEnabled?: boolean;
}

interface DispatchProps {
    onFetchedProject(project: ProjectResource): void;
    onFetchedDeploymentProcessSteps(numberOfSteps: number): void;
}

interface ProjectLayoutProps extends RouteComponentProps<ProjectRouteParams> {
    busy?: Promise<any> | boolean;
}

interface ProjectLayoutState {
    project: ProjectResource;
    projectGroups: ProjectGroupResource[];
}

type Props = ProjectLayoutProps & StateProps & DispatchProps;

class ProjectLayoutInternal extends DataBaseComponent<Props, DataBaseComponentState & ProjectLayoutState> {
    private projectId: string;

    constructor(props: Props) {
        super(props);
        this.state = {
            project: null,
            projectGroups: null
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            if (!this.props.projectId || this.props.projectId !== this.projectId) {
                const project = await repository.Projects.get(this.props.match.params.projectSlug);
                this.projectId = project.Id;
                this.props.onFetchedProject(project);

                // We load all project groups into state, so if the projectGroupId changes in our global
                // state (redux), we have an easy lookup based on id.
                const projectGroups = await repository.ProjectGroups.all();

                const deploymentProcess =
                    isAllowed({ permission: Permission.ProcessView, project: this.projectId, tenant: "*" })
                        ? await repository.DeploymentProcesses.get(project.DeploymentProcessId)
                        : null;

                if (deploymentProcess) {
                    this.props.onFetchedDeploymentProcessSteps(deploymentProcess.Steps.length);
                }
                this.setState({project, projectGroups});
            }
        });
    }

    render() {
        const isUpdatedDataAvailable = this.props.projectId === this.projectId;
        const projectSlug = this.props.match.params.projectSlug;

        if (!isUpdatedDataAvailable || !this.state.project) {
            return (
                <main id="maincontent">
                    {this.areaTitle(true)}
                    {this.renderErrors()}
                </main>);
        }

        return (
            this.state.project && <main id="maincontent">
                {this.areaTitle(false)}
                {this.renderErrors()}
                <NavigationSidebarLayout
                    logoUrl={this.props.projectLogoUrl}
                    name={this.props.projectName}
                    description={this.props.projectDescription}
                    preNavbarComponent={<div>
                        {this.props.projectIsDisabled && <div>
                            <DisabledChip />
                        </div>}
                        {!this.props.projectIsDisabled && this.props.numberOfSteps > 0 &&
                            <PermissionCheck permission={Permission.ReleaseCreate}
                                project={this.props.projectId}
                                tenant="*">
                                <div className={styles.specialActions} key={this.props.numberOfSteps}>
                                    {/*Note: We can't just use a NavigationButton here, because once you click it, it renders a redirect and disappears :) */}
                                    <InternalLink to={routeLinks.project(projectSlug).releaseCreate}>
                                        <ActionButton type={ActionButtonType.CreateRelease} label="Create release" />
                                    </InternalLink>
                                </div>
                            </PermissionCheck>}
                    </div>}
                    navLinks={this.navLinks(projectSlug)} // Uses projectSlug from our route (and NOT this.state.project) to avoid the sidebar refreshing unnecessarily.
                    content={this.props.children} />
            </main>);
    }

    renderErrors() {
        const errors = this.state.errors;
        if (!errors) {
            return null;
        }
        return <ErrorPanel message={errors.message}
            details={errors.details}
            detailLinks={errors.detailLinks}
            helpText={errors.helpText}
            fullException={errors.fullException}
            helpLink={errors.helpLink}
        />;
    }

    private renderBusy(forceBusy: boolean) {
        return <BusyFromPromise promise={this.props.busy || forceBusy}>
            {(busy: boolean) => <BusyIndicator show={busy || forceBusy} />}
        </BusyFromPromise>;
    }

    private areaTitle(forceBusy: boolean) {
        const hasAccessibleProjectGroup = this.state.projectGroups && this.props.projectGroupId && this.state.projectGroups.find((pg) => pg.Id === this.props.projectGroupId);
        return !hasAccessibleProjectGroup
            ? <AreaTitle link={routeLinks.projects.root} title="Projects" busyIndicator={this.renderBusy(forceBusy)} />
            : <AreaTitle
                breadcrumbTitle="Projects"
                breadcrumbPath={routeLinks.projects.root}
                link={routeLinks.projects.filteredByGroup(this.props.projectGroupId)}
                title={hasAccessibleProjectGroup.Name}
                busyIndicator={this.renderBusy(forceBusy)}
            />;
    }

    private navLinks = (projectSlug: string) => {
        const projectLinks = routeLinks.project(projectSlug || ""); // Uses projectSlug from our route (and NOT this.state.project) to avoid the sidebar refreshing unnecessarily.
        const navigationLinks: NavItem[] = [];
        navigationLinks.push(Navigation.navItem("Overview", projectLinks.overview));
        navigationLinks.push(Navigation.navItem("Process", projectLinks.process.root, null, {
            permission: Permission.ProcessView,
            project: this.props.projectId,
            tenant: "*"
        }));
        const variableSubLinks: NavItem[] = [];
        variableSubLinks.push(Navigation.navItem("Project", projectLinks.variables.root, true));
        if (this.props.isMultiTenancyEnabled) {
            variableSubLinks.push(Navigation.navItem("Project Templates", projectLinks.variables.projectTemplates));
            variableSubLinks.push(Navigation.navItem("Common Templates", projectLinks.variables.commonTemplates));
        }
        variableSubLinks.push(Navigation.navItem("Library Sets", projectLinks.variables.library));
        variableSubLinks.push(Navigation.navItem("All", projectLinks.variables.all));
        variableSubLinks.push(Navigation.navItem("Preview", projectLinks.variables.preview));
        navigationLinks.push(Navigation.navGroup("Variables", projectLinks.variables.root, variableSubLinks, {
            permission: Permission.VariableView,
            project: this.props.projectId,
            wildcard: true
        }));
        navigationLinks.push(Navigation.navItem("Triggers", projectLinks.triggers, null, {
            permission: Permission.TriggerView,
            project: this.props.projectId
        }));
        navigationLinks.push(Navigation.navItem("Channels", projectLinks.channels, null, {
            permission: Permission.ProcessView,
            project: this.props.projectId,
            tenant: "*"
        }));
        navigationLinks.push(Navigation.navItem("Releases", projectLinks.releases, null, {
            permission: Permission.ReleaseView,
            project: this.props.projectId,
            tenant: "*",
        }));
        navigationLinks.push(Navigation.navItem("Settings", projectLinks.settings, null, {
            permission: Permission.ProjectView,
            project: this.props.projectId,
            projectGroup: "*",
            tenant: "*",
        }));
        return navigationLinks;
    }
}

const mapStateToProps = (state: GlobalState) => {
    const currentProjectState = !state.projectsArea.currentProject ? {} : {
        projectName: state.projectsArea.currentProject.name,
        projectDescription: state.projectsArea.currentProject.description,
        projectLogoUrl: state.projectsArea.currentProject.logoUrl,
        projectId: state.projectsArea.currentProject.id,
        projectGroupId: state.projectsArea.currentProject.projectGroupId,
        projectIsDisabled: state.projectsArea.currentProject.isDisabled,
        numberOfSteps: state.projectsArea.currentProject.numberOfSteps,
        projectSlug: state.projectsArea.currentProject.slug,
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled
    };
    return currentProjectState;
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onFetchedProject: (project: ProjectResource) => {
            dispatch(projectFetched({
                id: project.Id,
                name: project.Name,
                description: project.Description,
                projectGroupId: project.ProjectGroupId,
                logoUrl: project.Links.Logo,
                isDisabled: project.IsDisabled,
                numberOfSteps: null,
                slug: project.Slug,
            }));
        },
        onFetchedDeploymentProcessSteps: (numberOfSteps: number) => {
            dispatch(projectStepsUpdated(numberOfSteps));
        },
    };
};

const ProjectLayout = connect<StateProps, DispatchProps, ProjectLayoutProps>(
    mapStateToProps,
    mapDispatchToProps
)(ProjectLayoutInternal);

export default withRouter(ProjectLayout);