import isBound from "../../form/BoundField/isBound";
import * as React from "react";
import pluginRegistry, {ActionEditProps} from "../pluginRegistry";
import {BaseComponent} from "components/BaseComponent/BaseComponent";
import Roles from "../Roles";
import {ActionSummaryProps} from "../actionSummaryProps";
import {ActionExecutionLocation} from "../../../client/resources/actionExecutionLocation";
import {repository} from "clientInstance";
import Summary from "components/form/Sections/Summary";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import { withBoundField } from "components/form/BoundField/BoundField";
import AzureServerTargetRolesInfo from "components/Actions/azurePowerShell/AzureServerTargetRolesInfo";
import AzurePowerShellScriptInfo from "components/Actions/azurePowerShell/AzurePowerShellScriptInfo";
import {AccountResource, AccountType} from "client/resources";
import AzurePowerShellAccountInfo from "components/Actions/azurePowerShell/AzurePowerShellAccountInfo";
import { UnstructuredFormSection } from "components/form";
import AccountSelect from "components/form/AccountSelect/AccountSelect";
import {ScriptActionEdit, ScriptProperties} from "components/Actions/script/scriptAction";
import { TargetRoles } from "areas/projects/components/DeploymentProcess/ActionDetails";
import {ScriptPackageProperties} from "../script/ScriptPackageReferenceDialog";
import {SupportedLanguage} from "../../ScriptingLanguageSelector/ScriptingLanguageSelector";

class AzurePowerShellActionSummary extends BaseComponent<ActionSummaryProps, any> {
    render() {
        return <div>
            Run a PowerShell script using an Azure subscription with Azure modules loaded
            {this.props.targetRolesAsCSV && <span> on behalf of targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV}/></span>}
        </div>;
    }
}

interface AzurePowerShellProperties extends ScriptProperties {
    "Octopus.Action.Azure.AccountId": string;
}

interface AzurePowerShellActionEditState {
    accounts: AccountResource[];
    account: AccountResource;
}

const BoundAccountSelect = withBoundField(AccountSelect);

class AzurePowerShellActionEdit extends BaseComponent<ActionEditProps<AzurePowerShellProperties, ScriptPackageProperties>, AzurePowerShellActionEditState> {
    constructor(props: ActionEditProps<AzurePowerShellProperties, ScriptPackageProperties>) {
        super(props);

        this.state = {
            accounts: [],
            account: null
        };
    }

    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            this.setState({
                accounts: await repository.Accounts.all()
            });
        });
    }

    accountSummary() {
        const accountId = this.props.properties["Octopus.Action.Azure.AccountId"];
        if (!accountId) {
            return Summary.placeholder("No account has been selected");
        }
        const account = this.state.accounts.find(a => a.Id === accountId);
        if (account) {
            return Summary.summary(account.Name);
        }

        if (isBound(accountId, false)) {
            return Summary.summary(<span>Account is bound to <strong>{accountId}</strong></span>);
        }
        return Summary.placeholder("No account has been selected");
    }

    render() {
        const properties = this.props.properties;
        const account = this.state.accounts.find(a => a.Id === properties["Octopus.Action.Azure.AccountId"]);

        return <div>
            <UnstructuredFormSection stretchContent={true}>
                {this.props.additionalActions && <AzureServerTargetRolesInfo stepTargetRoles={this.props.additionalActions.stepTargetRoles} isCompatibleWithCloudRegions={true} />}
                <AzurePowerShellScriptInfo actionType={this.props.plugin.actionType}/>
                <AzurePowerShellAccountInfo accountType={account && account.AccountType}/>
            </UnstructuredFormSection>

            <FormSectionHeading title="Azure"/>

            <ExpandableFormSection
                errorKey="Octopus.Action.Azure.AccountId"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Account"
                summary={this.accountSummary()}
                help="Select the Azure account to use to run the script">
                <BoundAccountSelect
                    variableLookup={{
                        localNames: this.props.localNames,
                        projectId: this.props.projectId
                    }}
                    resetValue=""
                    value={this.props.properties["Octopus.Action.Azure.AccountId"]}
                    type={[AccountType.AzureSubscription, AccountType.AzureServicePrincipal]}
                    onChange={(x: string) => this.props.setProperties({["Octopus.Action.Azure.AccountId"]: x})}
                    error={this.props.getFieldError("Octopus.Action.Azure.AccountId")}
                    items={this.state.accounts}
                    onRequestRefresh={this.refreshAccounts} />
            </ExpandableFormSection>

            <ScriptActionEdit
                plugin={this.props.plugin}
                projectId={this.props.projectId}
                localNames={this.props.localNames}
                properties={this.props.properties}
                packages={this.props.packages}
                setProperties={this.props.setProperties}
                setPackages={this.props.setPackages}
                doBusyTask={this.props.doBusyTask}
                busy={this.props.busy}
                getFieldError={this.props.getFieldError}
                errors={this.props.errors}
                expandedByDefault={this.props.expandedByDefault}
                supportedLanguages={SupportedLanguage.PowerShell}/>
        </div>;
    }

    private refreshAccounts = () => {
        return this.props.doBusyTask(async () => {
            this.setState({ accounts: await repository.Accounts.all() });
        });
    }
}

pluginRegistry.registerDeploymentAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.AzurePowerShell",
    summary: (properties, targetRolesAsCSV) => <AzurePowerShellActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    edit: AzurePowerShellActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => false,
    features: {
        optional: ["Octopus.Features.SubstituteInFiles", "Octopus.Features.JsonConfigurationVariables",
            "Octopus.Features.ConfigurationTransforms", "Octopus.Features.ConfigurationVariables" ]}
});