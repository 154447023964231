import * as React from "react";
import LibraryVariableSetResource, { VariableSetContentType } from "client/resources/libraryVariableSetResource";
import { List } from "components/List/List";
import Checkbox from "components/form/Checkbox/Checkbox";
const styles = require("./style.less");
import { repository } from "clientInstance";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import ResourceCollection from "client/resources/resourceCollection";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import Callout, { CalloutType } from "components/Callout/Callout";
import routeLinks from "routeLinks";
import { Note } from "components/form";
import InternalLink from "components/Navigation/InternalLink";
import { PermissionCheck } from "components/PermissionCheck";
import { Permission } from "client/resources";

interface VariableSetSelectorProps {
    selectedVariableSetIds: ReadonlyArray<string>;
    saveVariableSetsSelection(variableSetIds: ReadonlyArray<string>): Promise<void>;
}

interface VariableSetSelectorState extends DataBaseComponentState {
    libraryVariableSets?: ResourceCollection<LibraryVariableSetResource>;
    selectedVariableSetIds: ReadonlyArray<string>;
}

export default class VariableSetSelector extends DataBaseComponent<VariableSetSelectorProps, VariableSetSelectorState> {
    constructor(props: VariableSetSelectorProps) {
        super(props);
        this.state = {
            selectedVariableSetIds: [...this.props.selectedVariableSetIds]
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const libraryVariableSets = await repository.LibraryVariableSets.list({contentType: VariableSetContentType.Variables});
            this.setState({libraryVariableSets});
        });
    }

    render() {
        return <SaveDialogLayout
            title="Select Variable Sets"
            busy={this.state.busy}
            errors={this.state.errors}
            onSaveClick={() => this.doBusyTask(() => this.props.saveVariableSetsSelection(this.state.selectedVariableSetIds))}
        >
            <PermissionCheck permission={Permission.LibraryVariableSetView}>
                <Note>Manage your <InternalLink to={routeLinks.library.variableSets}>library variable sets</InternalLink>.</Note>
            </PermissionCheck>

            {!this.props.selectedVariableSetIds.every(original => this.state.selectedVariableSetIds.some(selected => original === selected)) &&
                <Callout title="You appear to be removing a variable set." type={CalloutType.Warning}>
                    The values for any variable templates that are removed by this action will be permanently deleted from associated Tenants,
                    there is no going back.
                </Callout>}

            {this.state.libraryVariableSets && <LibraryVariableSetsList
                filterHintText="Filter by name or description"
                showPagingInNumberedStyle={true}
                initialData={this.state.libraryVariableSets}
                onFilter={filter}
                filterSearchEnabled={true}
                showFilterWithinSection={false}
                apiSearchParams={["partialName"]}
                onRow={(vs: LibraryVariableSetResource) => <div>
                    <Checkbox value={this.state.selectedVariableSetIds.includes(vs.Id)}
                        label={vs.Name}
                        onChange={(checked) => this.changeSelection(checked, vs.Id)} />
                    {vs.Description && <p className={styles.description}>{vs.Description}</p>}
                </div>}
            />}
        </SaveDialogLayout>;
    }

    private changeSelection(isSelected: boolean, variableSetId: string) {
        if (isSelected) {
            this.setState(prev => ({ selectedVariableSetIds: [...prev.selectedVariableSetIds, variableSetId] }));
        } else {
            this.setState(prev => ({ selectedVariableSetIds: prev.selectedVariableSetIds.filter(id => id !== variableSetId) }));
        }
    }
}

function filter(filterText: string, item: LibraryVariableSetResource) {
    return item.Name.toLowerCase().includes(filterText.toLowerCase())
        || item.Description.toLowerCase().includes(filterText.toLowerCase());
}

class LibraryVariableSetsList extends List<LibraryVariableSetResource> { }