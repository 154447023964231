import * as React from "react";
import TentacleDownloadButton from "areas/infrastructure/components/TentacleDownloadButton";
import {
    Note
} from "components/form";
import ExternalLink from "components/Navigation/ExternalLink";
import { TentacleCalloutHeading } from "./TentacleCalloutHeading";

const styles = require("./styles.less");

export const WindowsPollingTentacleCallout: React.SFC<{}> = () => (
    <div>
        <h3>Installation Instructions</h3>
        <TentacleCalloutHeading />
        <p>During setup, configure the Tentacle in <strong>polling</strong> mode.
        The wizard will then ask for your credentials to connect to the Octopus
            server and establish the connection.</p>
        <Note>
            Learn more about <ExternalLink href="PollingTentacle">configuring Polling
            Tentacles</ExternalLink>.
        </Note>
        <TentacleDownloadButton className={styles.downloadButton}/>
    </div>
);

export default WindowsPollingTentacleCallout;
