import * as React from "react";
import {VariableLookupText} from "../../form/VariableLookupText";
import OkDialogLayout from "../../DialogLayout/OkDialogLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import * as _ from "lodash";
import Note from "../../form/Note/Note";
import {KubernetesWildcardIngressHostRegex, KubernetesNameRegex, KubernetesIngressHostRegex} from "components/Actions/kubernetes/kubernetesValidation";
import isBound from "components/form/BoundField/isBound";
import {ExtendedKeyValueEditList} from "components/EditList/ExtendedKeyValueEditList";
import {IngressRule} from "components/Actions/kubernetes/kubernetesIngressComponent";
import {ServicePort} from "components/Actions/kubernetes/kubernetesServiceComponent";

interface IngressRuleState extends DataBaseComponentState {
    ingressRule: IngressRule;
}

interface IngressRuleProps {
    ingressRule: IngressRule;
    localNames: string[];
    projectId: string;
    standAlone: boolean;
    servicePorts: ServicePort[];
    onAdd(Binding: IngressRule): boolean;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}

class IngressRuleDialog extends DataBaseComponent<IngressRuleProps, IngressRuleState> {
    constructor(props: IngressRuleProps) {
        super(props);
        this.state = {
            ingressRule: null
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            this.setState({
                ingressRule: this.props.ingressRule
            });
        });
    }

    save = () => {
        let valid = true;
        const binding = this.state.ingressRule;

        if (!!binding.host &&
            !!binding.host.trim() &&
            !isBound(binding.host.trim()) &&
            binding.host.indexOf("*") !== -1 &&
            !KubernetesWildcardIngressHostRegex.exec(binding.host.trim())) {
            this.setError("The ingress host must be empty, or a wildcard DNS-1123 subdomain that starts with '*.', followed by a valid DNS subdomain, " +
                "which must consist of lower case alphanumeric characters, '-' or '.' and end with an alphanumeric character(e.g. '*.example.com').", [], {
                ServicePortName: "The ingress host must be empty, or a wildcard DNS-1123 subdomain that starts with '*.', followed by a valid DNS subdomain, " +
                    "which must consist of lower case alphanumeric characters, '-' or '.' and end with an alphanumeric character(e.g. '*.example.com')."
            });
            valid = false;
        }

        if (!!binding.host &&
            !!binding.host.trim() &&
            !isBound(binding.host.trim()) &&
            binding.host.indexOf("*") === -1 &&
            !KubernetesIngressHostRegex.exec(binding.host.trim())) {
            this.setError("The ingress host must be empty, or a a DNS-1123 subdomain must consist of lower case alphanumeric characters, " +
                "'-' or '.', and must start and end with an alphanumeric character. (e.g. example.com)", [], {
                ServicePortName: "The ingress host must be empty, or a a DNS-1123 subdomain must consist of lower case alphanumeric characters, " +
                    "'-' or '.', and must start and end with an alphanumeric character. (e.g. example.com)"
            });
            valid = false;
        }

        if (binding.http.paths.length === 0) {
            this.setError("At least one ingress path must be defined.");
            valid = false;
        }

        binding.http.paths.forEach(p => {
            p.keyError = (!!p.key && (isBound(p.key) || p.key.trim().startsWith("/")))
                ? null
                : "Ingress paths must all start with a forward slash.";
        });

        binding.http.paths.forEach(p => {
            p.valueError = !!p.value &&
                !!p.value.trim() &&
                (isBound(p.value.trim()) ||
                isNaN(parseInt(p.value.trim(), 10)) ||
                (parseInt(p.value.trim(), 10) >= 1 && parseInt(p.value.trim(), 10) <= 65535))
                ? null
                : "Ingress rules must have a service port between 1 and 65535, or a port name.";
        });

        binding.http.paths.forEach(p => {
            p.optionError = !this.props.standAlone ||
            (!!p.option && (isBound(p.option) || KubernetesNameRegex.exec(p.option.trim())))
                ? null
                : "Ingress rules must have a valid service name.";
        });

        if (binding.http.paths.find(p => !!p.keyError || !!p.valueError || !!p.optionError)) {
            this.setError("The ingress rules are incorrectly configured.");
            valid = false;
        }

        if (valid) {
            return this.props.onAdd(binding);
        }

        return valid;
    }

    render() {
        return <OkDialogLayout
            onOkClick={this.save}
            busy={this.state.busy}
            errors={this.state.errors}
            title={"Add Host Rule"}>
            {this.state.ingressRule && <div>
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={this.state.ingressRule.host}
                    error={this.getFieldError("IngressRuleHost")}
                    onChange={x => this.setIngressState({host: x})}
                    label="Host" />
                <Note>
                    An optional value that defines the host that this ingress rule applies to.
                    If left blank, this rule will apply to all hosts.
                </Note>
                {this.props.standAlone
                ? <div>
                    <p>
                    <strong>Paths</strong> <br/>
                    Include the URL path and the service <code>Port</code> this ingress path directs traffic to. The service port can be the service name, or the port number.
                    </p>
                    <ExtendedKeyValueEditList
                    items={() => this.state.ingressRule.http.paths}
                    name="Path"
                    onChange={val => {
                        this.setIngressState({http: {paths: val}});
                        this.repositionDialog();
                    }}
                    valueLabel="Service port"
                    optionLabel="Service name"
                    keyLabel="Path"
                    hideBindOnKey={false}
                    projectId={this.props.projectId}
                    addToTop={true}
                    onAdd={this.repositionDialog}
                    /> </div>
                : <div>
                        <p>
                        <strong>Paths</strong> <br/>
                        Include the URL path and the service <code>Port</code> this ingress path directs traffic to. The service port can be the service name, or the port number.
                        </p>
                        <ExtendedKeyValueEditList
                        items={() => this.state.ingressRule.http.paths}
                        name="Path"
                        onChange={val => {
                            this.setIngressState({http: {paths: val}});
                            this.repositionDialog();
                        }}
                        valueLabel="Service port"
                        keyLabel="Path"
                        hideBindOnKey={false}
                        projectId={this.props.projectId}
                        getValueOptions={searchText => this.getPortOptions(searchText)}
                        addToTop={true}
                        onAdd={this.repositionDialog}
                        />
                    </div>}
            </div>}
        </OkDialogLayout>;
    }

    private getPortOptions = async (searchText: string) => {
        const results = _.chain(this.props.servicePorts)
            .flatMap(p => [p.name, p.port])
            .filter(p => !!p)
            .sort()
            .filter(v => v.toLowerCase().includes(searchText.toLowerCase()))
            .value();
        const itemsToTake = 7;
        return {
            items: results.slice(0, itemsToTake).map(f => ({ Id: f, Name: f })),
            containsAllResults: results.length <= itemsToTake
        };
    }

    private setIngressState<K extends keyof IngressRule>(state: Pick<IngressRule, K>, callback?: () => void) {
        this.setChildState1("ingressRule", state);
    }

    /**
     * https://github.com/mui-org/material-ui/issues/1676
     * https://github.com/mui-org/material-ui/issues/5793
     * When adding or removing items from a list, the dialog needs to be repositioned, otherwise
     * the list may disappear off the screen. A resize event is the commonly suggested workaround.
     */
    private repositionDialog() {
        window.dispatchEvent(new Event("resize"));
    }
}

export default IngressRuleDialog;