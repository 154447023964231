import * as React from "react";
import * as cn from "classnames";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import { isAllowed, PermissionCheckProps } from "components/PermissionCheck/PermissionCheck";
import ToolTip from "components/ToolTip";

const styles = require("./CustomDialogActions.less");

const continueIf = (predicate: () => Promise<boolean>, action: () => void) => {
    return () => predicate().then(x => {
        if (x) {
            action();
        }
        return x;
    });
};

function isButtonDisabled(flag: boolean, savePermission?: PermissionCheckProps) {
    const disabledDueToPermission = !!savePermission ? !isAllowed(savePermission) : false;
    return !!(flag || disabledDueToPermission);
}

export interface CustomDialogActionsProps {
    actions?: React.ReactNode;
    additionalActions?: React.ReactNode;
    stepper?: React.ReactNode;
    className?: string;
}

export const CustomDialogActions: React.SFC<CustomDialogActionsProps> = ({ className, actions, additionalActions }) => {
    return <div className={cn(styles.actions, className)}>
        <div className={styles.additionalActions}>
            {additionalActions}
        </div>
        <div className={styles.primaryActions}>
            {actions}
        </div>
    </div>;
};

interface CustomWizardDialogActionsProps extends CustomDialogActionsProps {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    moveNext: () => void;
    movePrevious: () => void;
    close: () => void;
}

interface WizardStepperProps {
    pageCount: number;
    pageNumber: number;
    selectPage: (index: number) => void;
}

export const WizardStepper: React.SFC<WizardStepperProps> = ({ pageCount, pageNumber, selectPage }) => {
    return (
        <div className={styles.steps}>
            {Array(pageCount).fill(null).map((_, index) => (
                <div onClick={() => selectPage(index)} key={index} className={cn(styles.step, { [styles.active]: index === pageNumber })} />
            ))}
        </div>
    );
};

export const WizardDialogActions: React.SFC<CustomWizardDialogActionsProps> = ({ className, hasNextPage, hasPreviousPage, moveNext, movePrevious, close, ...rest }) => {
    return (
        <div className={cn(styles.wizardActions, className)}>
            <div className={styles.additionalActions}>
                {rest.additionalActions}
                {!hasPreviousPage && <ToolTip key="skip" content="Skip, I know what I'm doing"><ActionButton label="Skip" onClick={close} /></ToolTip>}
                {hasPreviousPage && <ActionButton label="Back" onClick={movePrevious} />}
            </div>
            {rest.stepper}
            <div className={styles.primaryActions}>
                {rest.actions}
                {hasNextPage && <ActionButton type={ActionButtonType.Primary} label="Next" onClick={moveNext} />}
                {!hasNextPage && <ActionButton type={ActionButtonType.Primary} label="Try it now" onClick={close} />}
            </div>
        </div>
    );
};

export interface CustomSaveDialogActionsProps {
    saveButtonLabel?: string;
    cancelButtonLabel?: string;
    saveDisabled?: boolean;
    savePermission?: PermissionCheckProps;
    hideCancel?: boolean;
    onSaveClick(): Promise<boolean>;
    close(): void;
}

export const CustomSaveDialogActions: React.SFC<CustomSaveDialogActionsProps> = ({ cancelButtonLabel = "Cancel", saveButtonLabel = "Save", saveDisabled = false, ...rest }) => {
    return <React.Fragment>
        {!rest.hideCancel && <ActionButton label={cancelButtonLabel} onClick={rest.close} type={ActionButtonType.Secondary} />}
        <ActionButton label={saveButtonLabel} onClick={continueIf(rest.onSaveClick, rest.close)} type={ActionButtonType.Save} disabled={isButtonDisabled(saveDisabled, rest.savePermission)} />
    </React.Fragment>;
};

export interface CustomOkDialogActionProps {
    okButtonLabel?: string;
    okButtonDisabled?: boolean;
    okButtonPermission?: PermissionCheckProps;
    hideCancel?: boolean;
    cancelButtonLabel?: string;
    onOkClick(): any;
}

export default CustomDialogActions;