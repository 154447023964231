import * as React from "react";
import { Card } from "@material-ui/core";
import { CardTitle } from "components/form/Sections";
import styles = require("./style.less");
import Expandable, { ExpandableProps } from "components/Expandable/Expandable";
import OverflowMenu, { MenuItem } from "components/Menu/OverflowMenu";

interface ScopedUserRoleGroupExpanderProps {
    error?: string;
    title: React.ReactNode;
    overflowMenuItems?: Array<MenuItem | MenuItem[]>;
}

type Props = ScopedUserRoleGroupExpanderProps & ExpandableProps;

export class ScopedUserRoleGroupExpanderInternal extends React.Component<Props> {

    renderChild = (child: any): any => {
        if (!child) {
            return child;
        }
        const children = child.props && child.props.children
            ? { children: this.renderChildren(child.props.children) }
            : null;

        const props = children ? { ...child.props, ...children } : child.props;
        return React.isValidElement(child) ? React.cloneElement(child, props) : child;
    }

    renderChildren = (children: any) => {
        return React.Children.map(children, this.renderChild);
    }

    render() {
        const title = <div className={styles.cardTitleContainer}>
            {this.props.title}
            {this.props.overflowMenuItems && <div className={styles.overflowMenuActions}>
                <OverflowMenu menuItems={this.props.overflowMenuItems} />
            </div>}
        </div>;

        return <Card
            className={styles.formNonExpander}>
            <CardTitle title={title}/>
            <div className={styles.cardMedia}>
                {this.renderChildren(this.props.children)}
            </div>
        </Card>;
    }
}

const ScopedUserRoleGroupExpander = Expandable<ScopedUserRoleGroupExpanderProps>(ScopedUserRoleGroupExpanderInternal);
export default ScopedUserRoleGroupExpander;