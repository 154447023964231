import * as React from "react";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import Card from "components/Card";
import DialogTrigger, {DialogTriggerRenderProps} from "components/Dialog/DialogTrigger";
import { white, alert } from "theme/colors";

const styles = require("./EndpointCard.less");

export interface EndpointCardDispatchProps {
    onNavigate?: () => void;
}

export interface EndpointCardDetailProps {
    logo: React.ReactElement<any>;
    header: React.ReactNode;
    description: React.ReactNode;
    buttonLabel?: string;
    isEarlyAccess?: boolean;
}

export type EndpointCardProps = EndpointCardDispatchProps & EndpointCardDetailProps;

export const EndpointCard: React.SFC<EndpointCardProps> = (props) => (
    <Card logo={<div className={styles.logo}>{props.logo}</div>}
        className={styles.card}
        header={props.header}
        content={<div className={styles.description}>{props.description}</div>}
        footerText={props.isEarlyAccess && <span style={{color: white}}>Early Access</span>}
        footerBackgroundColor={props.isEarlyAccess && alert}
        includeContentFade={false} /* Don't set true here, or you see the contentFade onHover */
        onHoverView={<div className={styles.onHoverActions} onClick={props.onNavigate}>
            <ActionButton
                label={props.buttonLabel ? props.buttonLabel : "Add"}
                type={ActionButtonType.Primary}
                onClick={props.onNavigate}
            />
        </div>}
    />
);

export type InformationalEndpointCardProps = EndpointCardDetailProps & {
    renderDialog: (props: DialogTriggerRenderProps) => React.ReactElement<any>;
};

export const InformationalEndpointCard: React.SFC<InformationalEndpointCardProps> = ({buttonLabel= "View Instructions", renderDialog, ...rest}) => (
    <DialogTrigger render={(renderProps) => (
        <React.Fragment>
            <EndpointCard {...rest} onNavigate={renderProps.openDialog} buttonLabel={buttonLabel} />
            {renderDialog(renderProps)}
        </React.Fragment>
    )}/>
);

export type SimpleInformationalEndpointCardProps = EndpointCardDetailProps & {
    renderView: () => React.ReactElement<any>
};

export default EndpointCard;