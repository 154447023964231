import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import ExternalLink from "components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "../../CommonComponents/CommonViews";
import { TopicsContainer, LearnMoreResources } from "../../CommonComponents/CommonViews";

export const InfrastructureOverviewRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.infrastructure.overview,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <OverviewContainer>
            <OverviewHighlightText>
                <p><ExternalLink href="OnboardingInfrastructureLearnMore">Infrastructure</ExternalLink> includes the environments, targets, workers and accounts that you will be deploying to.
                The infrastructure overview will help you keep track of the health status and the types of deployment targets and workers you have.</p>
            </OverviewHighlightText>
        </OverviewContainer>;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <TopicsContainer>
            <LearnMoreResources>
                <div><ExternalLink href="OnboardingInfrastructureLearnMore">Infrastructure</ExternalLink></div>
            </LearnMoreResources>
        </TopicsContainer>;
    }
};
