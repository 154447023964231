import * as React from "react";
import {connect} from "react-redux";
import { Dispatch, Action } from "redux";
import {openDialog} from "components/Dialog/reducers/dialog";
import GlobalState from "globalState";
import { Omit } from "utils/omit";

export interface DispatchProps {
    openDialog(id: string): void;
}

export interface StateProps {
    openDialogs: {[key: string]: boolean};
}

//export type OpenDialogProps = DispatchProps & StateProps;
export interface OpenDialogProps extends DispatchProps, StateProps {}

export class OpenDialogConnect {
    static to<TProps extends OpenDialogProps>(internal: React.ComponentType<TProps>, withRef: boolean = false) {
        type ExternalProps = Omit<TProps, keyof OpenDialogProps>;

        const mapDispatchToProps = (dispatch: Dispatch<Action>, props: TProps): DispatchProps => {
            return {
                openDialog: (key: string) => {
                    dispatch(openDialog(key));
                }
            };
        };

        const mapStateToProps = (state: GlobalState, props: TProps): StateProps => ({
            openDialogs: state.dialogs || {}
        });

        return connect(
            mapStateToProps,
            mapDispatchToProps,
            null,
            { forwardRef: true }
        )(internal as any) as any as React.ComponentType<ExternalProps>;
    }
}