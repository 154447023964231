import MarkdownDescription from "components/MarkdownDescription";
import Onboarding from "./Onboarding";
import * as React from "react";
import { ProjectCard } from "../ProjectCard";
import { ProjectResource, ProjectGroupResource, Permission } from "client/resources";
import AreaTitle from "components/AreaTitle";
import { repository } from "clientInstance";
import AddProjectGroup from "./AddProjectGroup";
import { default as AddProject } from "./AddProject";
import { RouteComponentProps } from "react-router";
import * as URI from "urijs";
import PermissionCheck, { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { Callout, CalloutType } from "components/Callout/Callout";
import Section from "components/Section";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import { ActionButtonType } from "components/Button";
import OverflowMenu from "components/Menu/OverflowMenu";
import ActionList from "components/ActionList/ActionList";
import ActionButton from "components/Button/ActionButton";
const styles = require("./style.less");
import routeLinks from "../../../../routeLinks";
import { groupBy, flatten, isEqual } from "lodash";
import Select from "components/form/Select/Select";
import ComponentRow from "components/ComponentRow/ComponentRow";
import * as MobileDetect from "mobile-detect";
import * as cn from "classnames";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import { IQuery, QueryStringFilters } from "components/QueryStringFilters/QueryStringFilters";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import DrawerWrapperLayout from "components/Drawer/DrawerWrapperLayout";

type ProjectsProps = RouteComponentProps<any>;
type ProjectsByGroup = { [projectGroupId: string]: ProjectResource[] };
type FilterableItem = { Name: string; Description: string };

interface ProjectsFilter {
    projectName: string;
    projectGroupId: string;
}

interface ProjectsState extends DataBaseComponentState {
    projectsByGroup: ProjectsByGroup;
    projectGroups: ProjectGroupResource[];
    filteredProjectGroups: ProjectGroupResource[];
    filter: ProjectsFilter;
    redirectTo?: string;
    hasProjects: boolean;
    showEmptyGroups: boolean;
    isLoaded: boolean;
}

const defaultFilter: ProjectsFilter = {
    projectName: "",
    projectGroupId: ""
};

interface ProjectsQuery extends IQuery {
    projectGroupId?: string;
    projectName?: string;
}

const ProjectsQueryStringFilters = QueryStringFilters.For<ProjectsFilter, ProjectsQuery>();

class Projects extends DataBaseComponent<ProjectsProps, ProjectsState> {
    constructor(props: ProjectsProps) {
        super(props);
        this.state = ({
            projectsByGroup: null,
            projectGroups: null,
            filter: defaultFilter,
            filteredProjectGroups: null,
            hasProjects: false,
            showEmptyGroups: false,
            isLoaded: false,
        });
    }

    async loadProjectGroups() {
        const [projectGroups, projects] = await Promise.all([
            isAllowed({ permission: Permission.ProjectGroupView, projectGroup: "*" }) ? repository.ProjectGroups.all() : [],
            repository.Projects.all()]
        );

        const projectsByGroup = groupBy(projects, p => p.ProjectGroupId);
        projectGroups.forEach(g => {
            if (!projectsByGroup.hasOwnProperty(g.Id)) {
                projectsByGroup[g.Id] = [];
            }
        });
        const hasProjects = projects.length > 0;
        this.setState(
            { projectsByGroup, projectGroups, hasProjects, isLoaded: true },
            () => {
                const newProjectGroupId = this.getNewProjectGroupId();
                this.updateFilteredGroups(newProjectGroupId);
            }
        );
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            await this.loadProjectGroups();
        });
    }

    async componentWillReceiveProps(newProps: RouteComponentProps<void>) {
        if (this.props.location.search !== newProps.location.search) {
            // When a component redirects to itself and the new url differs from the old one only by query string then
            // the component doesn't get unmounted and mounted again. Instead, the changes are passed via props. This means
            // we have to clear the redirectTo property manually as otherwise we will end up in an infinite redirect loop.
            this.setState({ redirectTo: null });
            await this.loadProjectGroups();
        }
    }

    render() {
        const newProjectGroupId = this.getNewProjectGroupId();

        const projectGroupFilterItems = this.state.projectGroups &&
            this.state.projectGroups
                .filter(g => this.showProjectGroup(g, newProjectGroupId))
                .map(g => ({ value: g.Id, text: g.Name }));

        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }

        const matchCount = this.state.filteredProjectGroups && this.state.projectsByGroup &&
            flatten(this.state.filteredProjectGroups.map(g => this.state.projectsByGroup[g.Id]
                .filter(p => this.matches(p, this.state.filter.projectName)))).length;

        const hasFilter = !isEqual(defaultFilter, this.state.filter);
        const matchCountText = hasFilter
            ? <span className={cn(styles.help, styles.info)}>{(matchCount !== 1 ? `${matchCount} projects match` : "1 project matches")}</span>
            : null;

        // Disable autoFocus filtering for mobile (Android has issues and is annoying users).
        const md = new MobileDetect(window.navigator.userAgent);
        const autoFocus = md.isPhoneSized() ? false : true;
        return <main id="maincontent">
            <ProjectsQueryStringFilters
                filter={this.state.filter}
                onFilterChange={filter => this.setState({ filter })}
                getFilter={getFilter}
                getQuery={getQuery}
            />
            <AreaTitle
                link={routeLinks.projects.root}
                title="Projects">
                {this.state.isLoaded &&
                    <div className={styles.actionContainer}>
                        <PermissionCheck permission={Permission.ProjectGroupCreate} projectGroup="*">
                            <OpenDialogButton label="Add Group">
                                <AddProjectGroup
                                    projectGroupCreated={id => this.setState({ redirectTo: `${routeLinks.projects.root}?newProjectGroupId=${id}` })} />
                            </OpenDialogButton>
                        </PermissionCheck>
                        <PermissionCheck permission={Permission.ProjectCreate} projectGroup="*">
                            <OpenDialogButton label="Add Project"
                                type={ActionButtonType.Primary}>
                                <AddProject title="Add New Project"
                                    projectCreated={this.onProjectCreated}
                                    hasProjects={this.state.hasProjects} />
                            </OpenDialogButton>
                        </PermissionCheck>
                    </div>}
            </AreaTitle>
            <DrawerWrapperLayout>
                <PaperLayout title={null}
                    busy={this.state.busy}
                    errors={this.state.errors}
                    fullWidth={true}
                    flatStyle={true}>
                    {this.state.isLoaded && <TransitionAnimation>
                        {!this.state.hasProjects && <Onboarding />}
                        <PermissionCheck permission={Permission.ProjectGroupView} projectGroup="*" wildcard={true}
                            alternate={<Section>
                                <Callout type={CalloutType.Warning} title={"Permission required"}>
                                    You do not have permission to perform this action.
                                    Please contact your Octopus administrator. Missing permission: {Permission.ProjectGroupView}
                                </Callout>
                            </Section>}>
                            {this.state.hasProjects &&
                                <div className={styles.filterHeaderContainer} role="search">
                                    <div className={styles.filterFieldContainer}>
                                        <ComponentRow key="A" className={styles.filter}>
                                            {this.state.projectGroups && this.state.projectGroups.length > 1 && <Select label="Project group"
                                                items={projectGroupFilterItems}
                                                onChange={(projectGroupId) => this.setState(prev => ({ filter: { ...prev.filter, projectGroupId } }),
                                                    () => this.updateFilteredGroups(newProjectGroupId))}
                                                value={this.state.filter.projectGroupId}
                                                allowClear={true}
                                            />}
                                            <div className={styles.filterField}>
                                                <FilterSearchBox
                                                    hintText="Project name or description"
                                                    value={this.state.filter.projectName}
                                                    onChange={(projectName) => this.setState(prev => ({ filter: { ...prev.filter, projectName } }),
                                                        () => this.updateFilteredGroups(newProjectGroupId))}
                                                    autoFocus={autoFocus}
                                                    fullWidth={true}
                                                />
                                            </div>
                                        </ComponentRow>
                                        {matchCountText}
                                    </div>
                                    {this.anyEmptyProjectGroups(this.state.projectsByGroup) &&
                                        <ActionButton label={this.showHideGroupsLabel(this.state.showEmptyGroups)}
                                            onClick={() => this.setState(prevState => ({ showEmptyGroups: !prevState.showEmptyGroups }),
                                                () => this.updateFilteredGroups(newProjectGroupId))}
                                            type={ActionButtonType.Ternary}
                                        />}
                                </div>
                            }
                        </PermissionCheck>
                        {this.groups(newProjectGroupId)}
                    </TransitionAnimation>
                    }
                </PaperLayout>
            </DrawerWrapperLayout>
        </main>;
    }

    private getNewProjectGroupId() {
        const query = URI(this.props.location.search).search(true);
        const newProjectGroupId = query.newProjectGroupId;
        return newProjectGroupId;
    }

    private onProjectCreated = (project: ProjectResource) => {
        this.setState({ redirectTo: routeLinks.project(project).overview });
    }

    private updateFilteredGroups(newProjectGroupId: string) {
        const filteredProjectGroups = this.state.projectGroups
            .filter(projectGroup => this.showProjectGroup(projectGroup, newProjectGroupId))
            .filter(projectGroup => this.state.filter.projectGroupId === "" || projectGroup.Id === this.state.filter.projectGroupId)
            .filter(pg => {
                // if projectName is not specified don't filter, because that will hide groups `!this.state.filter.projectName`
                return !this.state.filter.projectName || this.state.projectsByGroup[pg.Id].some(p => this.matches(p, this.state.filter.projectName));
            });
        this.setState({ filteredProjectGroups });
    }

    private groups(newProjectGroupId: string) {
        return this.state.filteredProjectGroups && this.state.projectsByGroup &&
            this.state.filteredProjectGroups
                .map(projectGroup => {
                    return <div key={projectGroup.Id}>
                        <div className={styles.groupHeader}>
                            <div>{projectGroup.Name}</div>
                            {this.projectGroupActions(projectGroup.Id)}
                        </div>
                        <div className={styles.groupDescription}><MarkdownDescription markup={projectGroup.Description} /></div>
                        <div className={styles.cardList}>
                            {this.state.projectsByGroup[projectGroup.Id]
                                .filter(p => this.matches(p, this.state.filter.projectName))
                                .map((p) => <ProjectCard key={p.Id} project={p} />)}
                        </div>
                    </div>;
                });
    }

    private matches(item: FilterableItem, filter: string) {
        const normalizedFilter = this.normalize(filter);
        return !normalizedFilter || this.normalize(item.Name).includes(normalizedFilter) || this.normalize(item.Description).includes(normalizedFilter);
    }

    private normalize(value: string) {
        return value.toLowerCase();
    }

    private showProjectGroup(projectGroup: ProjectGroupResource, newProjectGroupId: string) {
        return this.state.showEmptyGroups || this.state.projectsByGroup[projectGroup.Id].length || (newProjectGroupId && projectGroup.Id === newProjectGroupId);
    }

    private anyEmptyProjectGroups(projectsByGroup: ProjectsByGroup) {
        return Object.keys(projectsByGroup).find(groupId => projectsByGroup[groupId].length === 0);
    }

    private projectGroupActions(projectGroupId: string) {
        const actions = [];
        if (isAllowed({ permission: Permission.ProjectCreate, projectGroup: projectGroupId })) {
            actions.push(<OpenDialogButton label="Add Project"
                type={ActionButtonType.Secondary}>
                <AddProject title="Add New Project"
                    groupId={projectGroupId}
                    projectCreated={this.onProjectCreated}
                    hasProjects={this.state.hasProjects} />
            </OpenDialogButton>);
        }

        actions.push(<OverflowMenu menuItems={[
            OverflowMenu.navItem("Edit", routeLinks.projectGroup(projectGroupId).root, null, { permission: Permission.ProjectGroupEdit, projectGroup: "*" })
        ]} />);

        return <ActionList actions={actions} />;
    }

    private showHideGroupsLabel(showEmptyGroups: boolean) {
        const action = this.state.showEmptyGroups ? "HIDE" : "SHOW";
        return `${action} EMPTY GROUPS`;
    }
}

function getFilter(query: ProjectsQuery): ProjectsFilter {
    return {
        projectName: query.projectName || "",
        projectGroupId: query.projectGroupId || ""
    };
}

function getQuery(filter: ProjectsFilter): ProjectsQuery {
    return {
        projectName: filter.projectName,
        projectGroupId: filter.projectGroupId
    };
}

export default Projects;
