import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
const image = require("../../Images/Projects.svg");
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
import { TopicsContainer, LearnMoreResources } from "./CommonViews";
import ImageWithPlaceholder from "components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";

export const CommonProjectView: React.StatelessComponent<{}> = props => {
    return <OverviewContainer>
        <OverviewHighlightText>
            <p><ExternalLink href="OnboardingProjectsLearnMore">Projects</ExternalLink> let you manage multiple software applications.
            For each project, you define a deployment process, configuration variables, and the environments the software will be deployed to.</p>
            <ImageWithPlaceholder src={image} alt={"Projects"} />
        </OverviewHighlightText>
    </OverviewContainer>;
};

export const CommonProjectTopics: React.StatelessComponent<{}> = props => {
    return <TopicsContainer>
        <LearnMoreResources>
            <div><ExternalLink href="OnboardingProjectsLearnMore">Projects</ExternalLink></div>
        </LearnMoreResources>
    </TopicsContainer>;
};