import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonInfrastructureProxyOverview, CommonInfrastructureProxyTopics } from "../../CommonComponents/CommonInfrastructureProxyViews";

export const InfrastructureProxyRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.infrastructure.proxy,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonInfrastructureProxyOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonInfrastructureProxyTopics />;
    }
};
