import * as React from "react";
import { ActionPlugin, ActionEditProps, BoundFieldProps } from "../pluginRegistry";
import { ActionSummaryProps } from "../actionSummaryProps";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { repository } from "clientInstance";
import { ActionExecutionLocation } from "client/resources/actionExecutionLocation";
import AwsLoginComponent, { AWSScriptProperties } from "./awsLoginComponent";
import { VariableLookupText } from "components/form/VariableLookupText";
import {
    AccountResource,
    GetPrimaryPackageReference,
    InitialisePrimaryPackageReference,
    SetPrimaryPackageReference
} from "client/resources";
import FeedResource from "client/resources/feedResource";
import { TargetRoles } from "areas/projects/components/DeploymentProcess/ActionDetails";
import CommonSummaryHelper from "utils/CommonSummaryHelper/CommonSummaryHelper";
import PackageSelector from "components/PackageSelector/PackageSelector";
import { curry } from "lodash";
import { BoundRadioButtonGroup } from "components/form/RadioButton/RadioButtonGroup";
import { KeyValueEditList, KeyValuePair  } from "components/EditList/KeyValueEditList";
import StringCheckBox from "components/form/Checkbox/StringCheckbox";
import { ActionButton, ActionButtonType } from "components/Button";
import { identity } from "lodash";
import {
    Summary,
    SummaryNode,
    ExpandableFormSection,
    UnstructuredFormSection,
    Note,
    RadioButton,
    FormSectionHeading,
} from "components/form";
import ExternalLink from "../../Navigation/ExternalLink/ExternalLink";

export interface BucketKeyBehaviourProps {
    bucketKey: string;
    bucketKeyBehaviour: BucketKeyBehaviour;
    bucketKeyPrefix: string;
}

export interface AwsS3PackageUploadEditState {
    accounts: AccountResource[];
    feeds: FeedResource[];
    package?: PackageOptions;
    selections: FileSelection[];
}

export type MetadataItem = KeyValuePair;
export type Tag = KeyValuePair;
export type StorageClass = string;
export type CannedAcl = string;

export interface BucketFileMetadata {
    tags: Tag[];
    metadata: MetadataItem[];
}

export interface BucketFileOptions {
    storageClass: StorageClass;
    cannedAcl: CannedAcl;
}

export enum BucketKeyBehaviour {
    Filename = "Filename",
    Custom = "Custom"
}

export enum TargetMode {
    EntirePackage = "EntirePackage",
    FileSelections = "FileSelections"
}

export enum FileSelectionTypes {
    SingleFile = "SingleFile",
    MultipleFiles = "MultipleFiles"
}

export interface PackageOptions extends BucketFileMetadata, BucketFileOptions, BucketKeyBehaviourProps {}

export interface FileSelection extends BucketFileMetadata, BucketFileOptions {
    type: FileSelectionTypes;
}

/*
We allow the type of selection to be changed. In this case we keep all existing
information for that selection. Just make sure that if a property is added
that it does not exist on any other file selection type which it is not compatibile with.
*/
export interface SingleFileSelection extends FileSelection, BucketKeyBehaviourProps {
    type: FileSelectionTypes.SingleFile;
    performVariableSubstitution: string;
    path: string;
}

export interface MultiFileSelection extends FileSelection {
    type: FileSelectionTypes.MultipleFiles;
    variableSubstitutionPatterns: string;
    bucketKeyPrefix: string;
    pattern: string;
}

export function isMultiFileSelection(selection?: FileSelection): selection is MultiFileSelection {
    return selection && selection.type && selection.type === FileSelectionTypes.MultipleFiles;
}

export function isSingleFileSelection(selection?: FileSelection): selection is SingleFileSelection {
    return selection && selection.type && selection.type === FileSelectionTypes.SingleFile;
}

export interface AwsUploadS3Properties extends AWSScriptProperties {
    "Octopus.Action.Aws.S3.BucketName": string;
    "Octopus.Action.Aws.S3.TargetMode": string;
    "Octopus.Action.Aws.S3.FileSelections": string;
    "Octopus.Action.Aws.S3.PackageOptions": string;
}

export const AwsUploadS3ActionSummary: React.SFC<ActionSummaryProps> = (props) => (
    <div>Upload a package to an AWS S3 bucket.</div>
);

const simpleSummary = curry((
    property: keyof AwsUploadS3Properties,
    render: (value: string) => SummaryNode,
    placeholder: () => SummaryNode,
    properties: AwsUploadS3Properties) =>
        properties[property] ? render(properties[property]) : placeholder()
);

export const bucketSummary = simpleSummary("Octopus.Action.Aws.S3.BucketName",
    (value) => Summary.summary(<span>Using bucket <strong>{value}</strong></span>),
    () => Summary.placeholder("Specify the S3 bucket name")
);

export const regionSummary = simpleSummary("Octopus.Action.Aws.Region",
    (value) => Summary.summary(<span>Using <strong>{value}</strong> as the default region</span>),
    () => Summary.placeholder("Specify the default AWS region")
);

const failWith = (message: string) => {
    throw new Error(message);
};

export const targetModeSummary = simpleSummary("Octopus.Action.Aws.S3.TargetMode",
    (value: TargetMode) => {
        switch (value) {
            case TargetMode.EntirePackage: return Summary.summary(<span>Entire package file (the package will not be extracted)</span>);
            case TargetMode.FileSelections: return Summary.summary(<span>Specific file(s) within the package</span>);
            default: failWith("Unknown package type");
        }
    },
    () => Summary.placeholder("Specify a target type")
);

export interface PackageTargetDetailProps extends Partial<PackageOptions>, FieldErrorProps {
    onChange: (value: Partial<PackageOptions>) => void;
}

export interface FieldErrorProps {
    getFieldError: (field: string) => string;
}

export interface FileSelectionCallbacks<T> extends FieldErrorProps {
    onChange: (value: Partial<T>) => void;
    onChangeType: (value: FileSelectionTypes) => void;
}

export interface SingleFileSelectionDetailProps extends Partial<SingleFileSelection>, FileSelectionCallbacks<SingleFileSelection>, FieldErrorProps {}
export interface MultiFileSelectionDetailProps extends Partial<MultiFileSelection>, FileSelectionCallbacks<MultiFileSelection>, FieldErrorProps {}

export const renderMetadata = (onChange: (value: Partial<BucketFileMetadata>) => void, { projectId, localNames, ...detail }: BucketFileMetadata & BoundFieldProps) => {
    const fieldProps = { projectId, localNames, separator: "=",   keyLabel: "Key", valueLabel: "Value" };

    return [<KeyValueEditList
        {...fieldProps}
        key="metadata"
        name="metadata"
        onChange={(val) => onChange({...detail, metadata: val})}
        items={() => detail.metadata || []}
    />,
    <KeyValueEditList
        {...fieldProps}
        key="tags"
        name="tags"
        onChange={(val) => onChange({...detail, tags: val})}
        items={() => detail.tags || []}
    />];
};

export const CannedAclNote = () => (<Note>
    You can read more about available options regarding canned Acls <ExternalLink href="AwsS3CannedAcl" >here</ExternalLink>
</Note>);

export const PrefixNote = () => (<Note>
    A prefix delimited with forward slash <code>/</code> characters is seen in S3 as folders. <ExternalLink href="AwsS3Prefix" >More examples here</ExternalLink>.
</Note>);

export const CustomBucketKeyNote = () => (<Note>
    A custom key delimited with forward slash <code>/</code> characters is seen in S3 as folders. <ExternalLink href="AwsS3CustomBucketKey" >More examples here</ExternalLink>.
</Note>);

export const StorageClassNote = () => (<Note>
    Read more about S3 storage classes <ExternalLink href="AwsS3StorageClasses" >here</ExternalLink>
</Note>);

export const CannedAclField: React.SFC<BoundFieldProps & { value: string, error?: string, onChange: (val: string) => void }> = (props) => {
    return <VariableLookupText
        {...props}
        label="Canned Acl"
    />;
};

export const StorageClassField: React.SFC<BoundFieldProps & { value: string, error?: string, onChange: (val: string) => void }> = (props) => {
    return <VariableLookupText
        {...props}
        label="Storage Class"
    />;
};

export interface BucketKeyBehaviourEditProps extends BoundFieldProps, Partial<BucketKeyBehaviourProps>, FieldErrorProps {
    onChange: (val: Partial<BucketKeyBehaviourProps>) => void;
}

export const BucketKeyBehaviourEdit: React.SFC<BucketKeyBehaviourEditProps> = ({ onChange, localNames, projectId, getFieldError, ...detail }) => {
    const fieldProps = { localNames, projectId };
    const behaviour = detail.bucketKeyBehaviour || BucketKeyBehaviour.Custom;
    return <React.Fragment>
        <BoundRadioButtonGroup
            resetValue={BucketKeyBehaviour.Custom}
            value={behaviour}
            onChange={(bucketKeyBehaviour: BucketKeyBehaviour) => onChange({bucketKeyBehaviour})}
            error={getFieldError("BucketKeyBehaviour")}
        >
            <RadioButton value={BucketKeyBehaviour.Filename} label="Use filename"/>
            <RadioButton value={BucketKeyBehaviour.Custom} label="Use custom key"/>
        </BoundRadioButtonGroup>
        {behaviour === BucketKeyBehaviour.Custom && <React.Fragment>
                <VariableLookupText
                    {...fieldProps}
                    value={detail.bucketKey}
                    label="Bucket Key"
                    onChange={(bucketKey: string) => onChange({bucketKey})}
                    error={getFieldError("BucketKey")}
                />
                <CustomBucketKeyNote />
            </React.Fragment>
        }
        {behaviour === BucketKeyBehaviour.Filename && <React.Fragment>
                <VariableLookupText
                {...fieldProps}
                value={detail.bucketKeyPrefix}
                label="Bucket Key Prefix"
                onChange={(bucketKeyPrefix: string) => onChange({bucketKeyPrefix})}
                error={getFieldError("BucketKeyPrefix")}
                />
                <PrefixNote />
            </React.Fragment>
        }
    </React.Fragment>;
};

export const S3PackageDetailEdit: React.SFC<PackageTargetDetailProps & BoundFieldProps> = (props) => {
    const {onChange, getFieldError, children, projectId, localNames, metadata, tags, ...detail} = props;
    const fieldProps = { projectId, localNames };
    return  <React.Fragment>
        <BucketKeyBehaviourEdit
            {...fieldProps}
            {...detail}
            onChange={(val: Partial<BucketKeyBehaviourProps>) => onChange({...detail, ...val})}
            getFieldError={getFieldError}
        />
        <CannedAclField
                {...fieldProps}
                value={detail.cannedAcl}
                onChange={(val: string) => onChange({...detail, cannedAcl: val})}
                error={getFieldError("CannedAcl")}
        />
        <CannedAclNote/>
        <StorageClassField
            {...fieldProps}
            value={detail.storageClass}
            onChange={(val: string) => onChange({...detail, storageClass: val})}
            error={getFieldError("StorageClass")}
        />
        <StorageClassNote/>
        {renderMetadata(onChange, {...fieldProps, tags, metadata })}
        </React.Fragment>;
};

export const S3SingleFileDetailEdit: React.SFC<SingleFileSelectionDetailProps & BoundFieldProps> = (props) => {
    const {onChange, onChangeType,  getFieldError, children, metadata, tags, localNames, projectId, ...detail} = props;
    const fieldProps = { projectId, localNames };

    return <React.Fragment>
        <BoundRadioButtonGroup
            resetValue="SingleFile"
            value={detail.type}
            onChange={onChangeType}
            error={getFieldError("Type")}
        >
            <RadioButton value={"SingleFile"} label="Single File"/>
            <RadioButton value={"MultipleFiles"} label="Multiple Files"/>
        </BoundRadioButtonGroup>
        <VariableLookupText
            {...fieldProps}
            value={detail.path}
            label="Path"
            onChange={(val: string) => onChange({...detail, path: val})}
            error={getFieldError("Path")}
        />
        <StringCheckBox
            value={detail.performVariableSubstitution}
            label="Variable Substitution"
            onChange={(val: string) => onChange({...detail, performVariableSubstitution: val})}
        />
        <BucketKeyBehaviourEdit
            {...fieldProps}
            {...detail}
            onChange={(val: Partial<BucketKeyBehaviourProps>) => onChange({...detail, ...val})}
            getFieldError={getFieldError}
        />
        <CannedAclField
                {...fieldProps}
                value={detail.cannedAcl}
                onChange={(val: string) => onChange({...detail, cannedAcl: val})}
                error={getFieldError("CannedAcl")}
        />
        <CannedAclNote/>
        <StorageClassField
            {...fieldProps}
            value={detail.storageClass}
            onChange={(val: string) => onChange({...detail, storageClass: val})}
            error={getFieldError("StorageClass")}
        />
        <StorageClassNote/>
        {renderMetadata(onChange, {...fieldProps, tags, metadata })}
    </React.Fragment>;
};

export const S3MultiFileDetailEdit: React.SFC<MultiFileSelectionDetailProps & BoundFieldProps> = (props) => {
    const {onChange, onChangeType, getFieldError, children, metadata, tags, localNames, projectId,  ...detail} = props;
    const fieldProps = { projectId, localNames };

    if (detail.pattern === undefined) {
        detail.pattern = "**/*";
    }

    return <React.Fragment>
         <BoundRadioButtonGroup
            resetValue="MultipleFiles"
            value={detail.type}
            onChange={onChangeType}
            error={getFieldError("Type")}
        >
            <RadioButton value={"SingleFile"} label="Single File"/>
            <RadioButton value={"MultipleFiles"} label="Multiple Files"/>
        </BoundRadioButtonGroup>
        <VariableLookupText
            {...fieldProps}
                value={detail.pattern}
                label="File Pattern"
                onChange={(val: string) => onChange({...detail, pattern: val})}
                error={getFieldError("Pattern")}
        />
        <VariableLookupText
            {...fieldProps}
                multiLine={true}
                value={detail.variableSubstitutionPatterns}
                label="Variable Substitution File Patterns"
                onChange={(val: string) => onChange({...detail, variableSubstitutionPatterns: val})}
                error={getFieldError("VariableSubstitutionPatterns")}
        />
        <Note>
            A newline-separated list of file names to transform, relative to the package contents. Extended wildcard syntax is supported.
            E.g., <em>Notes.txt</em>, <em>Config\*.json</em>, <em>**\specific-folder\*.config.</em><br/>
            This field supports extended template syntax. Conditional <code>if</code> and <code>unless</code>:
            <pre>{`#{if MyVar}...#{/if}`}</pre>
            Iteration over variable sets or comma-separated values with <code>each</code>:
            <pre>{`#{each mv in MyVar}...#{mv}...#{/each}`}</pre>
        </Note>

        <VariableLookupText
            {...fieldProps}
            value={detail.bucketKeyPrefix}
            label="Bucket Key Prefix"
            onChange={(val: string) => onChange({...detail, bucketKeyPrefix: val})}
            error={getFieldError("BucketKeyPrefix")}
        />
        <PrefixNote />
        <CannedAclField
                {...fieldProps}
                value={detail.cannedAcl}
                onChange={(val: string) => onChange({...detail, cannedAcl: val})}
                error={getFieldError("CannedAcl")}
        />
        <CannedAclNote/>
        <StorageClassField
            {...fieldProps}
            value={detail.storageClass}
            onChange={(val: string) => onChange({...detail, storageClass: val})}
            error={getFieldError("StorageClass")}
        />
        <StorageClassNote/>
        {renderMetadata(onChange, {...fieldProps, tags, metadata })}
    </React.Fragment>;
};

export interface FileSelectionEditProps extends FileSelection {
    onChange: (value: Partial<FileSelection>) => void;
    onChangeType: (type: FileSelectionTypes) => void;
    onRemove: () => void;
    getFieldError: (field: string) => string;
}

export const FileSelectionEdit: React.SFC<FileSelectionEditProps & BoundFieldProps> = ({onChange, onChangeType, onRemove,  getFieldError, ...selection}) => {
    const callbacks = { onChange, onChangeType, getFieldError };
    const removeButton =   <div style={{textAlign: "right"}}>
            <ActionButton type={ActionButtonType.Secondary} onClick={onRemove} label={"Remove File Selection"}/>
        </div>;

    return <React.Fragment>
            {removeButton}
            {isSingleFileSelection(selection) && <S3SingleFileDetailEdit {...selection} {...callbacks}  />}
            {isMultiFileSelection(selection) && <S3MultiFileDetailEdit {...selection} {...callbacks} />}
        </React.Fragment>;
};

export const getNewFileSelection = (): SingleFileSelection => {
    return {
        type: FileSelectionTypes.SingleFile,
        tags: [],
        metadata: [],
        cannedAcl: "",
        path: "",
        storageClass: "STANDARD",
        bucketKey: "",
        bucketKeyPrefix: "",
        bucketKeyBehaviour: BucketKeyBehaviour.Custom,
        performVariableSubstitution: "False"
    };
};

export const fileOptionsSummaryText = (options: BucketFileOptions) => {
    return  [
        options.storageClass && ` on ${options.storageClass} storage`,
        options.cannedAcl && ` with ${options.cannedAcl} permissions`
    ];
};

export function targetOptionsSummary<T>(placeholder: string, summaryCallback: (options: T) => string | React.ReactElement<any>) {
    return (options: T) => {
        if (options === undefined || options === null) {
            return Summary.placeholder(placeholder);
        }
        const summary = summaryCallback(options);

        if (!summary) {
            return Summary.placeholder(placeholder);
        }

        return Summary.summary(<span children={summary} />);
    };
}

const continuedTextTransform = (value: string, prefix?: string) => {
    if (!value) {
        return value;
    }

    const result = `${prefix || ""}${value.replace(/\w/, (val) => val.toLowerCase()).trim()}`;
    return result.length > 0 ? " " + result : result;
};

export const singleFileSelectionSummary = targetOptionsSummary<SingleFileSelection>("Specify file options", (options) => {
    return <React.Fragment key="summary">
        {options.path && ("File " && <strong>{options.path}</strong>)}
        {!options.path && "No file path specified"}
        {options.path && <React.Fragment>
            {bucketKeyBehaviourSummary(options, (val) => continuedTextTransform(val))}
            {fileOptionsSummaryText(options)}
        </React.Fragment>}
    </React.Fragment>;
});

export const packageSummary = targetOptionsSummary<PackageOptions>("Specify file options", (options: PackageOptions) => {
    return <React.Fragment key="summary">
        {bucketKeyBehaviourSummary(options)}
        {options.bucketKey && fileOptionsSummaryText(options)}
    </React.Fragment>;
});

export const bucketKeyBehaviourSummary = (options: BucketKeyBehaviourProps, transform: (value: string) => string = identity) => {
    return <React.Fragment key="bucketKeyBehaviourSummary">
        {options.bucketKeyBehaviour === BucketKeyBehaviour.Custom && <React.Fragment>
            {options.bucketKey && <React.Fragment>{transform("Using bucket key")}<strong> {options.bucketKey}</strong></React.Fragment>}
            {!options.bucketKey && transform("No bucket key provided")}
        </React.Fragment>}
        {options.bucketKeyBehaviour === BucketKeyBehaviour.Filename && <React.Fragment>
            {transform("Using file name as bucket key")}
            {options.bucketKeyPrefix && <React.Fragment> with prefix<strong> {options.bucketKeyPrefix}</strong></React.Fragment>}
        </React.Fragment>}
    </React.Fragment>;
};

export const mutliFileSelectionSummary = targetOptionsSummary<MultiFileSelection>("Specify file options", (options) => {
    return <React.Fragment key="summary">
        {options.pattern && ("Files matching" && <strong>{options.pattern}</strong>)}
        {!options.pattern && "No patterns specified"}
        {fileOptionsSummaryText(options)}
    </React.Fragment>;
});

export const fileSelectionSummary = (selection: FileSelection) => {
     if (isSingleFileSelection(selection)) {
        return  singleFileSelectionSummary(selection);
    } else if (isMultiFileSelection(selection)) {
        return mutliFileSelectionSummary(selection);
    }
};

export const updateFileSelection = (index: number, value: Partial<FileSelection>)  => {
    return (previous: AwsS3PackageUploadEditState, properties: ActionEditProps<AwsUploadS3Properties>) => {
        const patch = { ...previous, selections: [...previous.selections] };
        patch.selections[index] = { ...patch.selections[index], ...value };
        //We close over the state update and push it onto the queue via setProperties.
        //TODO: Invetisgate alternate way to do this, possibly redux since the same action can be handled by multiple reducers
        properties.setProperties({ ["Octopus.Action.Aws.S3.FileSelections"]: JSON.stringify(patch.selections)});
        return patch;
    };
};

export const removeFileSelection = (index: number) => {
    return (previous: AwsS3PackageUploadEditState, properties: ActionEditProps<AwsUploadS3Properties>) => {
        const patch = { ...previous, selections: [...previous.selections] };
        patch.selections.splice(index, 1);
        properties.setProperties({ ["Octopus.Action.Aws.S3.FileSelections"]: JSON.stringify(patch.selections)});
        return patch;
    };
};

export const updatePackageOptions = (value: Partial<PackageOptions>) => {
    return (previous: AwsS3PackageUploadEditState, properties: ActionEditProps<AwsUploadS3Properties>) => {
        const result = { ...previous, package: { ...previous.package, ...value }};
        properties.setProperties({["Octopus.Action.Aws.S3.PackageOptions"]: JSON.stringify(result.package)});
        return result;
    };
};

export const addFileSelection = (value: Partial<FileSelection>) => {
    return (previous: AwsS3PackageUploadEditState, properties: ActionEditProps<AwsUploadS3Properties>) => {
        const patch = { selections: [...previous.selections, value ] };
        properties.setProperties({["Octopus.Action.Aws.S3.FileSelections"]: JSON.stringify(patch.selections)});
        return patch;
    };
};

export class AwsUploadS3ActionEdit extends BaseComponent<ActionEditProps<AwsUploadS3Properties>, AwsS3PackageUploadEditState> {
    constructor(props: ActionEditProps<AwsUploadS3Properties>) {
        super(props);
        this.state = {
            feeds: [],
            accounts: [],
            package: {
                bucketKey: "",
                bucketKeyBehaviour: BucketKeyBehaviour.Custom,
                bucketKeyPrefix: "",
                storageClass: "STANDARD",
                cannedAcl: "",
                metadata: [],
                tags: []
            },
            selections: []
        };
    }

    async componentDidMount() {
        await this.loadFeeds((feeds) => this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, feeds)));
        await this.props.doBusyTask(async () => {
            const accounts = await repository.Accounts.all();

            this.setState((prev) => ({  ...prev,  accounts }));

            if (!this.props.properties["Octopus.Action.Aws.AssumeRole"]) {
                this.props.setProperties({ ["Octopus.Action.Aws.AssumeRole"]: "False" }, true);
            }

            if (!this.props.properties["Octopus.Action.AwsAccount.UseInstanceRole"]) {
                this.props.setProperties({ ["Octopus.Action.AwsAccount.UseInstanceRole"]: "False" }, true);
            }

            if (!this.props.properties["Octopus.Action.Aws.S3.TargetMode"]) {
                this.props.setProperties({ ["Octopus.Action.Aws.S3.TargetMode"]: TargetMode.EntirePackage }, true);
            }

            this.setState((prev, props) => {
                if (props.properties["Octopus.Action.Aws.S3.FileSelections"]) {
                    return { selections: (JSON.parse(props.properties["Octopus.Action.Aws.S3.FileSelections"]) || []) };
                }
            });

            this.setState((prev, props) => {
                if (props.properties["Octopus.Action.Aws.S3.PackageOptions"]) {
                    return { package: JSON.parse(props.properties["Octopus.Action.Aws.S3.PackageOptions"]) || {} };
                }
            });
        });
    }

    render() {
        // The package is initialized in componentDidMount, but render gets called before the update is propagated
        if (!this.props.packages || this.props.packages.length === 0) {
            return null;
        }

        const pkg = GetPrimaryPackageReference(this.props.packages);

        return <div>
            <AwsLoginComponent
                projectId={this.props.projectId}
                properties={this.props.properties}
                packages={this.props.packages}
                plugin={this.props.plugin}
                setProperties={this.props.setProperties}
                setPackages={this.props.setPackages}
                doBusyTask={this.props.doBusyTask}
                busy={this.props.busy}
                getFieldError={this.props.getFieldError}
                errors={this.props.errors}
                expandedByDefault={this.props.expandedByDefault} />
            <ExpandableFormSection
                errorKey="Octopus.Action.Aws.Region"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Region"
                summary={regionSummary(this.props.properties)}
                help={"Specify the default region"}>
                <VariableLookupText localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={this.props.properties["Octopus.Action.Aws.Region"]}
                    label="Region"
                    onChange={(val) => this.props.setProperties({ ["Octopus.Action.Aws.Region"]: val })}
                    error={this.props.getFieldError("Octopus.Action.Aws.Region")}
                />
                <Note>
                    View the <ExternalLink href="AWSRegions">AWS Regions and Endpoints</ExternalLink> documentation
                    for a current list of the available region codes.
                </Note>
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="Octopus.Action.Aws.S3.BucketName"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Bucket"
                summary={bucketSummary(this.props.properties)}
                help={"Specify the bucket name"}>
                <VariableLookupText localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={this.props.properties["Octopus.Action.Aws.S3.BucketName"]}
                    label="Bucket"
                    onChange={(val) => this.props.setProperties({ ["Octopus.Action.Aws.S3.BucketName"]: val })}
                    error={this.props.getFieldError("Octopus.Action.Aws.S3.BucketName")}
                />
            </ExpandableFormSection>
            <FormSectionHeading title="Package" />
            <ExpandableFormSection
                errorKey="Octopus.Action.Package.PackageId|Octopus.Action.Package.FeedId"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Package"
                summary={CommonSummaryHelper.packageSummary(pkg, this.state.feeds)}
                help={"Choose the package or package containing the file(s) to upload."}>
                <PackageSelector
                    packageId={pkg.PackageId}
                    feedId={pkg.FeedId}
                    onPackageIdChange={packageId => this.props.setPackages(SetPrimaryPackageReference({PackageId: packageId}, this.props.packages))}
                    onFeedIdChange={feedId => this.props.setPackages(SetPrimaryPackageReference({FeedId: feedId}, this.props.packages))}
                    packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                    feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                    projectId={this.props.projectId}
                    feeds={this.state.feeds}
                    localNames={this.props.localNames}
                    refreshFeeds={this.loadFeeds} />
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="Octopus.Action.Aws.S3.TargetType"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Target"
                summary={targetModeSummary(this.props.properties)}
                help={"Choose how we should treat this package."}>
                <BoundRadioButtonGroup
                    resetValue={TargetMode.EntirePackage}
                    value={this.props.properties["Octopus.Action.Aws.S3.TargetMode"]}
                    onChange={(x: string) => this.props.setProperties({ ["Octopus.Action.Aws.S3.TargetMode"]: x })}
                    label="Execute using the AWS service role for an EC2 instance">
                    <RadioButton value={TargetMode.EntirePackage}
                        label="Entire package file (the package will not be extracted)" />
                    <RadioButton value={TargetMode.FileSelections}
                        label="Specific file(s) within the package" />
                </BoundRadioButtonGroup>
            </ExpandableFormSection>
            {  this.props.properties["Octopus.Action.Aws.S3.TargetMode"] ===  TargetMode.EntirePackage  &&
                <ExpandableFormSection
                    errorKey="Octopus.Action.Aws.S3.PackageOptions"
                    isExpandedByDefault={this.props.expandedByDefault}
                    summary={packageSummary(this.state.package)}
                    title="Package Options"
                    help="Package options and metadata to use when uploading the entire package to an S3 bucket"
                >
                    <S3PackageDetailEdit {...this.state.package}
                        projectId={this.props.projectId}
                        onChange={(detail) => this.setState(updatePackageOptions(detail))}
                        getFieldError={(field) =>  this.props.getFieldError(`Octopus.Action.Aws.S3.PackageOptions.${field}`)}
                    />
                </ExpandableFormSection>
            }
            {this.props.properties["Octopus.Action.Aws.S3.TargetMode"] === TargetMode.FileSelections && <FormSectionHeading title="Files"   />}
            {this.props.properties["Octopus.Action.Aws.S3.TargetMode"] === TargetMode.FileSelections && this.state.selections.map((selection, index) =>
                <ExpandableFormSection
                    key={`IDX_${index}`}
                    errorKey={`Octopus.Action.Aws.S3.FileSelections[${index}]`}
                    isExpandedByDefault={this.props.expandedByDefault}
                    summary={fileSelectionSummary(selection)}
                    title={`${isSingleFileSelection(selection) ? "Single File" : "Multiple Files"}`}
                    help="Package options and metadata to use when uploading the entire package to an S3 bucket"
                >
                    <FileSelectionEdit {...selection}
                        onChange={value => this.setState(updateFileSelection(index, value))}
                        onChangeType={type => this.setState(updateFileSelection(index, {type}))}
                        onRemove={() => this.setState(removeFileSelection(index))}
                        getFieldError={(field) => this.props.getFieldError(`Octopus.Action.Aws.S3.FileSelections[${index}].${field}`)}
                        projectId={this.props.projectId}
                        localNames={this.props.localNames}
                    />
                </ExpandableFormSection>
            )}
            {this.props.properties["Octopus.Action.Aws.S3.TargetMode"] === TargetMode.FileSelections &&
                <UnstructuredFormSection>
                    <div style={{textAlign: "right"}}>
                        <ActionButton type={ActionButtonType.Secondary}
                            label={`${this.state.selections.length > 0 ? "Add another file selection" : "Add a file selection" }`}
                            onClick={() => this.setState(addFileSelection(getNewFileSelection()))}
                        />
                    </div>
                </UnstructuredFormSection>
            }
        </div>;
    }

    private loadFeeds = (callback?: (feeds: FeedResource[]) => void) => {
        return this.props.doBusyTask(async () => {
            this.setState({ feeds: await repository.Feeds.all() }, () => callback && callback(this.state.feeds));
        });
    }
}

export default (): ActionPlugin => ({
    executionLocation: ActionExecutionLocation.TargetOrServer,
    actionType: "Octopus.AwsUploadS3",
    summary: (properties, targetRolesAsCSV) => <AwsUploadS3ActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    edit: AwsUploadS3ActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => true,
    features: {
        optional: [],
    }
});