import InternalLink from "../Navigation/InternalLink";
import * as React from "react";
import {BaseComponent} from "components/BaseComponent/BaseComponent";
import FeedResource, {FeedType, isOctopusProjectFeed} from "client/resources/feedResource";
import {Callout, CalloutType} from "components/Callout";
import Note from "components/form/Note/Note";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import routeLinks from "../../routeLinks";
import {AutoCompleteOption} from "components/AutoComplete/AutoComplete";
import {repository} from "clientInstance";
import {BoundAutoComplete} from "components/AutoComplete";
import {feedTypeCanSearchEmpty, isContainerImageRegistry} from "../../client/resources/feedResource";
import {BoundSelectWithAddRefresh} from "../form/SelectWithAddRefresh/SelectWithAddRefresh";
import ActionButton, {ActionButtonType} from "../Button";
import * as _ from "lodash";

interface PackageSelectorProps {
    packageId: string;
    feedId: string;
    projectId: string;
    feeds: FeedResource[];
    localNames: string[];
    feedType?: FeedType[];
    autoFocus?: boolean;
    packageIdError?: string;
    feedIdError?: string;
    onPackageIdChange(value: string): void;
    onFeedIdChange(value: string): void;
    refreshFeeds(): Promise<any>;
}

interface PackageSelectorState {
    looksLikeVersion: boolean;
}

export default class PackageSelector extends BaseComponent<PackageSelectorProps, PackageSelectorState> {
    constructor(props: PackageSelectorProps) {
        super(props);

        this.state = {
            looksLikeVersion: false
        };
    }

    componentDidMount() {
        // Default to built-in feed.
        if (!this.props.feedId) {
            const defaultFeedId = this.defaultFeedId(this.possibleFeeds());
            if (defaultFeedId) {
                this.props.onFeedIdChange(defaultFeedId);
            }
        }
    }

    checkIfLooksLikeVersion = () => {
        let looksLikeVersion = false;
        const packageId = this.props.packageId;
        if (packageId) {
            if (packageId.match(/\.[0-9]+\.?$/)) {
                looksLikeVersion = true;
            }
        }
        this.setState({ looksLikeVersion });
    }

    render() {
        const feeds = this.possibleFeeds();

        if (feeds == null || feeds.length === 0) {
            return this.noFeedsWarning();
        }

        const defaultFeedId = this.defaultFeedId(feeds);
        let selectedFeedId = this.props.feedId;
        if (selectedFeedId === null) {
            selectedFeedId = defaultFeedId;
        }
        const selectedFeed: FeedResource = this.getSelectedFeed(feeds);

        return <div>
            <BoundSelectWithAddRefresh
                variableLookup={{
                    localNames: this.props.localNames,
                    projectId: this.props.projectId
                }}
                resetValue={defaultFeedId}
                value={selectedFeedId}
                onChange={this.feedChanged}
                items={feeds.map(f => ({ value: f.Id, text: f.Name }))}
                error={this.props.feedIdError}
                autoFocus={this.props.autoFocus}
                label="Package feed"
                addUrl={`#${routeLinks.library.feeds}`}
                onRequestRefresh={this.props.refreshFeeds}
            />
            <Note>
                The feed containing this package. Learn about <ExternalLink href="DynamicPackageFeeds">Dynamically Selecting Packages at Deployment Time</ExternalLink>.
            </Note>
            <BoundAutoComplete
                variableLookup={{
                    localNames: this.props.localNames,
                    projectId: this.props.projectId
                }}
                resetValue=""
                label="Package ID"
                value={this.props.packageId || ""}
                onChange={this.packageChanged}
                hintText="Enter package ID"
                allowAnyTextValue={true}
                getOptions={searchText => this.getPackageOptions(searchText, selectedFeed)}
                error={this.props.packageIdError}
                showEmpty={selectedFeed && feedTypeCanSearchEmpty(selectedFeed.FeedType)} />
            <Note>
                Enter the ID of the package.
                {selectedFeed && selectedFeed.FeedType === FeedType.Maven && <span> The format of the package ID is <em>Group:Artifact</em> for
                example <em>com.google.guava:guava</em> or <em>junit:junit</em>.</span>}
            </Note>
            {this.state.looksLikeVersion && <Callout type={CalloutType.Information} title="Package version not required">
                Hey there! It looks like you might be including the package version number in this field. You shouldn't include the package version number here, just the package ID.
                </Callout>}
        </div>;
    }

    private possibleFeeds() {
        return this.props.feeds.filter(f =>
            !isOctopusProjectFeed(f.FeedType) && // We will never show the OctopusProject feed when selecting packages
            ((!this.props.feedType || this.props.feedType.length === 0) || this.props.feedType.some(feedType => feedType === f.FeedType)));
    }

    private getPackageOptions = async (searchText: string, feed: FeedResource) => {
        if (feed === null) {
            const items: AutoCompleteOption[] = [];
            return {
                items,
                containsAllResults: true
            };
        }

        const packages = await repository.Feeds.searchPackages(feed, { term: searchText, take: 11, skip: 0 });
        return {
            items: packages.Items.slice(0, 10).map(p => ({ Id: p.Id, Name: p.Id })),
            containsAllResults: packages.Items.length !== 11
        };
    }

    private packageChanged = (packageId: string) => {
        this.props.onPackageIdChange(packageId);
        this.checkIfLooksLikeVersion();
    }

    private feedChanged = (feedId: string) => {
        this.props.onFeedIdChange(feedId);
    }

    private defaultFeedId = (feeds: FeedResource[]): string | null => {
        if (feeds.length === 0) {
            return null;
        }

        const buildtIn = feeds.find(f => f.FeedType === FeedType.BuiltIn);
        if (buildtIn) {
            return buildtIn.Id;
        }

        return feeds[0].Id;
    }

    private getSelectedFeed = (feeds: FeedResource[]) => {
        if (feeds == null || feeds.length === 0) {
            return null;
        }

        if (this.props.feedId) {
            const feed = feeds.find(f => f.Id === this.props.feedId);
            if (feed) {
                return feed;
            }
        }

        return null;
    }

    private noFeedsWarning() {
        const requiresContainerImageRegistryFeed = _.every(this.props.feedType, f => isContainerImageRegistry(f));
        const requiresHelmChartFeed = this.props.feedType.length === 1 && this.props.feedType[0] === FeedType.Helm;

        const feedTypeText = requiresContainerImageRegistryFeed
            ? "Container Image Registry"
            : requiresHelmChartFeed
                ? "Helm Chart Repository"
                : "Package"; // This option should never actually be used, as the built-in feed will always be available

        return <Callout type={CalloutType.Warning} title={`${feedTypeText} Feed required`}>
            A {feedTypeText} feed is required before this step can be configured. Please add an appropriate feed in
            the <InternalLink to={routeLinks.library.feeds} openInSelf={false}>External Feeds</InternalLink> area and try again.
            <div>
                <ActionButton
                    type={ActionButtonType.Ternary}
                    label="Refresh"
                    onClick={this.props.refreshFeeds}/>
            </div>
        </Callout>;
    }
}