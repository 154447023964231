import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
const image = require("../../Images/Channels.svg");
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
import { TopicsContainer, LearnMoreResources } from "./CommonViews";
import ImageWithPlaceholder from "components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";

export const CommonProjectChannelOverview: React.StatelessComponent<{}> = props => {
    return <OverviewContainer>
        <OverviewHighlightText>
            <p><ExternalLink href="Channels">Channels</ExternalLink> allow you to dynamically change the deployment logic and lifecycle of a project based on the release being deployed.</p>
            <ImageWithPlaceholder src={image} alt={"Channels"} />
        </OverviewHighlightText>
    </OverviewContainer>;
};

export const CommonProjectChannelTopics: React.StatelessComponent<{}> = props => {
    return <TopicsContainer>
        <LearnMoreResources>
            <div><ExternalLink href="Channels">Channels</ExternalLink></div>
        </LearnMoreResources>
    </TopicsContainer>;
};