import React = require("react");
import { Note } from "components/form";
import ExternalLink from "components/Navigation/ExternalLink";
import { LearnMoreResources, TopicsContainer, TutorialResources } from "./CommonViews";
import { OverviewContainer, OverviewHighlightText, TermsText } from "./CommonViews";
const HelpImageEnvironments = require("../../Images/Environments.svg");
import ImageWithPlaceholder from "components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";

export const CommonEnvironmentOverview: React.StatelessComponent<{}> = props => {
    return <OverviewContainer>
        <OverviewHighlightText>
            <p>
                An <ExternalLink href="OnboardingEnvironmentsLearnMore">environment</ExternalLink> groups together all the deployment targets used to host your software application, like a web server and a database server.
            </p>
            <p>
                Octopus deploys releases to environments. For instance, from <i>Development</i> to <i>Test</i> and finally into <i>Production</i>.
            </p>
            <ImageWithPlaceholder src={HelpImageEnvironments} alt={"Environments"} />
        </OverviewHighlightText>
        <TermsText>
            <Note heading={"Environments and lifecycles"}>
                Ultimately, environments will be used in a <ExternalLink href="OnboardingLifecycles">lifecycle</ExternalLink> which will define the deployment pipeline for your software.
                The default lifecycle will automatically add your environments as phases, in the order they appear on the environments page.
            </Note>
        </TermsText>
    </OverviewContainer>;
};

export const CommonEnvironmentTopics: React.StatelessComponent<{}> = props => {
    return <TopicsContainer>
        <TutorialResources>
            <div><ExternalLink href="OptimumSetupEnvironments">Optimum Setup - Environments</ExternalLink></div>
        </TutorialResources>
        <LearnMoreResources>
            <div><ExternalLink href="OnboardingEnvironmentsLearnMore">Environments</ExternalLink></div>
        </LearnMoreResources>
    </TopicsContainer>;
};