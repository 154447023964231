/* tslint:disable */
import * as React from "react";
import * as PropTypes from "prop-types";
import DropDownMenu from "./DropDownMenu";
import { secondaryText, divider, danger, primary } from "theme/colors";
import { normal } from "fontWeights";

function getStyles(props: SelectFieldProps, _: any) {
  return {
    root: {
      marginTop: 0,
    },
    label: {
      paddingLeft: 0,
      top: 8,
    },
    floatingLabelStyle: {
      position: "absolute" as "absolute", // Cast needed apparently >< This gives us the floating label.
      fontWeight: normal,
      color: secondaryText,
      lineHeight: "0.75rem",
      transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
      fontSize: "0.75rem",
    },
    hintLabelStyle: {
      position: "absolute" as "absolute",
      fontWeight: normal,
      color: secondaryText,
      lineHeight: "1rem",
      fontSize: "1rem",
      top: "1.5rem",
    },
    icon: {
      right: 0,
      top: 0,
      marginTop: 0,
      fill: secondaryText,
    },
    hideDropDownUnderline: {
      borderTop: "none",
    },
    dropDownMenu: {
      display: "block",
      borderBottom: `1px solid ${divider}`,
    },
    error: {
      color: danger,
    },
    container: {
      position: "relative" as "relative", // Needed for absolute positioned elements.
      margin: "0 0 1rem 0",
    },
  };
}

interface SelectFieldProps {
  floatingLabelText?: string | JSX.Element,
  errorText?: string,
  hintText?: string,
  className?: string,
  allowClear?: boolean,
  autoWidth?: boolean,
  multiple?: boolean,
  children?: any,
  style?: any,
  labelStyle?: any,
  iconStyle?: any,
  id?: string,
  underlineDisabledStyle?: any,
  menuItemStyle?: any,
  selectedMenuItemStyle?: any,
  dropDownMenuProps?: any,
  disabled?: boolean,
  listStyle?: any,
  maxHeight?: number,
  menuStyle?: any,
  onFocus?: any,
  onBlur?: any,
  onChange?: any,
  selectionRenderer?: any,
  value: string,
  type?: any,
  filter?: any,
  autoFocus?: boolean,
}

class SelectField extends React.Component<SelectFieldProps, any> {

  static defaultProps = {
    autoWidth: false,
    disabled: false,
    multiple: false,
    selectedMenuItemStyle: { color: primary },
  };

  static contextTypes = {
    muiTheme: PropTypes.object.isRequired,
  };

  render() {
    const {
      allowClear,
      autoWidth,
      multiple,
      children,
      style,
      labelStyle,
      iconStyle,
      id,
      underlineDisabledStyle,
      menuItemStyle,
      selectedMenuItemStyle,
      dropDownMenuProps,
      disabled,
      listStyle,
      maxHeight,
      menuStyle,
      onFocus,
      onBlur,
      onChange,
      selectionRenderer,
      value,
      type,
      filter,
      autoFocus,
      floatingLabelText,
      errorText,
      hintText,
      ...rest
    } = this.props;

    const styles = getStyles(this.props, this.context);

    const floatingLabelTextElement = floatingLabelText && (
      <div style={styles.floatingLabelStyle}>
        {floatingLabelText}
      </div>
    );

    const hintLabelTextElement = !value && hintText && (
      <div style={styles.hintLabelStyle}>
        {hintText}
      </div>
    );

    const errorTextElement = errorText && (
      <div style={styles.error}>{errorText}</div>
    );

    return (
      <div style={styles.container} className={this.props.className}>
        {floatingLabelTextElement}
        {hintLabelTextElement}
        <DropDownMenu
          allowClear={allowClear}
          disabled={disabled}
          style={Object.assign(styles.dropDownMenu, menuStyle)}
          labelStyle={Object.assign(styles.label, labelStyle)}
          iconStyle={Object.assign(styles.icon, iconStyle)}
          menuItemStyle={menuItemStyle}
          selectedMenuItemStyle={selectedMenuItemStyle}
          underlineStyle={styles.hideDropDownUnderline}
          listStyle={listStyle}
          autoWidth={autoWidth}
          value={value}
          onChange={onChange}
          maxHeight={maxHeight}
          multiple={multiple}
          selectionRenderer={selectionRenderer}
          filter={filter}
          autoFocus={autoFocus}
          onFocus={onFocus}
          onBlur={onBlur}
          id={id}
          {...rest}
          {...dropDownMenuProps}
        >
          {children}
        </DropDownMenu>
        {errorTextElement}
      </div>
    );
  }
}

export default SelectField;
