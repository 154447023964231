import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
import { LearnMoreResources, TopicsContainer } from "./CommonViews";
const HelpImageTenants = require("../../Images/Tenants.svg");
import ImageWithPlaceholder from "components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";

export const CommonTenantOverview: React.StatelessComponent<{}> = props => {
    return <OverviewContainer>
        <OverviewHighlightText>
            <p><ExternalLink href="MultiTenantDeployments">Tenants</ExternalLink> allow you to deploy different instances of your projects to multiple end-customers.</p>
            <p>For example, tenants can be useful if you deploy a new copy of your application every time you sign up a new customer.</p>
            <ImageWithPlaceholder src={HelpImageTenants} alt={"Tenants"} />
        </OverviewHighlightText>
    </OverviewContainer>;
};

export const CommonTenantTopics: React.StatelessComponent<{}> = props => {
    return <TopicsContainer>
        <LearnMoreResources>
            <div><ExternalLink href="MultiTenantDeployments">Multi-tenant Deployments</ExternalLink></div>
        </LearnMoreResources>
    </TopicsContainer>;
};