import * as React from "react";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {
  Text,
  required,
  Note,
  Select,
} from "components/form";
import {AccessRule, AccessType} from "./CertificateImportTypes";

interface PrivateKeyAccessRuleDialogProps {
  rule: AccessRule;
  saveDone(newRule: AccessRule): void;
}

interface PrivateKeyAccessRuleDialogState extends DataBaseComponentState {
  identity: string;
  access: AccessType;
}

class PrivateKeyAccessRuleDialog extends DataBaseComponent<PrivateKeyAccessRuleDialogProps, PrivateKeyAccessRuleDialogState> {
    constructor(props: PrivateKeyAccessRuleDialogProps) {
        super(props);
        this.state = {
          identity: this.props.rule.Identity,
          access: this.props.rule.Access,
        };
    }

    async save() {
        if (this.state.identity) {
            this.props.saveDone({Identity: this.state.identity, Access: this.state.access});
            return true;
        } else {
            this.setState({
                errors: {
                    message: "Identity must be provided",
                    details: ["Please enter an identity"],
                    fieldErrors: {}
                }
            });
            return false;
        }
    }

    render() {
        return <SaveDialogLayout title={this.props.rule.Identity ? "Edit Access Rule" : "Add Access Rule"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={() => this.save()}>
            <Text label="Identity"
                hintText="Enter an identity"
                value={this.state.identity}
                validate={required("Please enter an identity")}
                onChange={(val) => this.setState({identity: val})}
                autoFocus={true}
            />
            <Note>The user or group identity. e.g. <em>DOMAIN\userOrGroup</em></Note>

            <Select
                label="Access"
                value={this.state.access}
                items={Object.values(AccessType).map(item => ({value: item, text: item}))}
                onChange={(val: AccessType) => this.setState({access: val})}
            />
        </SaveDialogLayout>;
    }
}

export default PrivateKeyAccessRuleDialog;