import * as React from "react";
import { DataTable } from "components/DataTable/DataTable";
import { DataTableBody } from "components/DataTable/DataTableBody";
import { DataTableRow } from "components/DataTable/DataTableRow";
import { DataTableRowColumn } from "components/DataTable/DataTableRowColumn";
import { DataTableHeader } from "components/DataTable/DataTableHeader";
import { DataTableHeaderColumn } from "components/DataTable/DataTableHeaderColumn";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import routeLinks from "routeLinks";
import { AccountType } from "client/resources/accountResource";
import { ExpandableFormSection, Summary, FormSectionHeading, UnstructuredFormSection } from "components/form";
import {
    AccountResource, AccountUsageResource, StepUsage, StepUsageEntry,
    ReleaseUsage, ReleaseUsageEntry, LibraryVariableSetUsageEntry, ProjectVariableSetUsage, TargetUsageEntry
} from "client/resources";
import Chip from "components/Chips/Chip";
import ActionButton from "components/Button/ActionButton";
import { ActionButtonType } from "components/Button";
import { repository } from "clientInstance";
import { DoBusyTask } from "components/DataBaseComponent/DataBaseComponent";
import BaseComponent from "components/BaseComponent";

class AccountUsageProps {
    accountType?: AccountType;
    account: AccountResource;
    doBusyTask: DoBusyTask;
    busy: Promise<any> | boolean;
}

interface AccountUsageState {
    accountUsages: AccountUsageResource;
    isAccountUsageLoaded: boolean;
}

enum UsageType {
    DeploymentProcess,
    Release,
    Target,
    LibraryVariableSet,
    ProjectVariableSet,
    ReleaseVariableSet
}

let accountUsageInProjects = new Array<ProjectVariableSetUsage>();
let accountUsageInReleaseSnapshots = new Array<ProjectVariableSetUsage>();

export default class AccountUsage extends BaseComponent<AccountUsageProps, AccountUsageState> {
    constructor(props: AccountUsageProps) {
        super(props);
        this.state = {
            accountUsages: null,
            isAccountUsageLoaded: false,
        };
    }

    loadAccountUsage = async () => {
        await this.props.doBusyTask(async () => {
            const usages = await repository.Accounts.getAccountUsages(this.props.account);
            this.setState({ accountUsages: usages, isAccountUsageLoaded: true });
        });
    }

    render() {
        const usageHeader = <FormSectionHeading title="Usage" key={"headerUsage"} />;
        let usageBody = <div>
            {!this.state.isAccountUsageLoaded &&
            <UnstructuredFormSection>
                <ActionButton
                    type={ActionButtonType.Secondary}
                    label="Load Account Usage"
                    busyLabel="Loading..."
                    disabled={this.props.busy}
                    onClick={() => this.loadAccountUsage()}
                />
            </UnstructuredFormSection>}
        </div>;

        if (this.state.accountUsages) {
            usageBody = this.accountUsage(this.props.accountType, this.state.accountUsages);
        }

        return <div>
            {usageHeader}
            {usageBody}
        </div>;
    }

    accountUsage(accountType: AccountType, accountUsages: AccountUsageResource) {
        switch (accountType) {
            case AccountType.AmazonWebServicesAccount:
                accountUsageInProjects = accountUsages.ProjectVariableSets.filter(x => x.IsCurrentlyBeingUsedInProject === true);
                accountUsageInReleaseSnapshots = accountUsages.ProjectVariableSets.filter(x => x.Releases.length > 0);
                return <div>
                    <ExpandableFormSection
                        key="usageInLibraryVariableSets"
                        errorKey="usageInLibraryVariableSets"
                        title="Library Variable Sets"
                        summary={this.accountUsageSummary(UsageType.LibraryVariableSet, accountUsages)}
                        help={this.accountUsageHelp(UsageType.LibraryVariableSet, accountUsages)}>
                        {this.accountUsageInLibraryVariableSets(accountUsages)}
                    </ExpandableFormSection>
                    <ExpandableFormSection
                        key="usageInProjects"
                        errorKey="usageInProjects"
                        title="Projects"
                        summary={this.accountUsageSummary(UsageType.ProjectVariableSet, accountUsages)}
                        help={this.accountUsageHelp(UsageType.ProjectVariableSet, accountUsages)}>
                        {this.accountUsageInProjects()}
                    </ExpandableFormSection>
                    <ExpandableFormSection
                        key="usageInReleaseSnapshots"
                        errorKey="usageInReleaseSnapshots"
                        title="Releases"
                        summary={this.accountUsageSummary(UsageType.ReleaseVariableSet, accountUsages)}
                        help={this.accountUsageHelp(UsageType.ReleaseVariableSet, accountUsages)}>
                        {this.accountUsageInReleaseSnapshots()}
                    </ExpandableFormSection>
                </div>;
            case AccountType.AzureSubscription:
            case AccountType.AzureServicePrincipal:
                accountUsageInProjects = accountUsages.ProjectVariableSets.filter(x => x.IsCurrentlyBeingUsedInProject === true);
                accountUsageInReleaseSnapshots = accountUsages.ProjectVariableSets.filter(x => x.Releases.length > 0);
                return <div>
                    <ExpandableFormSection
                        key="usageInLibraryVariableSets"
                        errorKey="usageInLibraryVariableSets"
                        title="Library Variable Sets"
                        summary={this.accountUsageSummary(UsageType.LibraryVariableSet, accountUsages)}
                        help={this.accountUsageHelp(UsageType.LibraryVariableSet, accountUsages)}>
                        {this.accountUsageInLibraryVariableSets(accountUsages)}
                    </ExpandableFormSection>
                    <ExpandableFormSection
                        key="usageInProjects"
                        errorKey="usageInProjects"
                        title="Projects"
                        summary={this.accountUsageSummary(UsageType.ProjectVariableSet, accountUsages)}
                        help={this.accountUsageHelp(UsageType.ProjectVariableSet, accountUsages)}>
                        {this.accountUsageInProjects()}
                    </ExpandableFormSection>
                    <ExpandableFormSection
                        key="usageInDeploymentProcesss"
                        errorKey="usageInDeploymentProcesss"
                        title="Deployment Processes"
                        summary={this.accountUsageSummary(UsageType.DeploymentProcess, accountUsages)}
                        help={this.accountUsageHelp(UsageType.DeploymentProcess, accountUsages)}>
                        {this.accountUsageInDeploymentProcess(accountUsages)}
                    </ExpandableFormSection>
                    <ExpandableFormSection
                        key="usageInReleases"
                        errorKey="usageInReleases"
                        title="Releases"
                        summary={this.accountUsageSummary(UsageType.Release, accountUsages)}
                        help={this.accountUsageHelp(UsageType.Release, accountUsages)}>
                        {this.accountUsageInReleases(accountUsages)}
                    </ExpandableFormSection>
                    <ExpandableFormSection
                        key="usageInTargets"
                        errorKey="usageInTargets"
                        title="Targets"
                        summary={this.accountUsageSummary(UsageType.Target, accountUsages)}
                        help={this.accountUsageHelp(UsageType.Target, accountUsages)}>
                        {this.accountUsageInTargets(accountUsages)}
                    </ExpandableFormSection>
                    <ExpandableFormSection
                        key="usageInReleaseSnapshots"
                        errorKey="usageInReleaseSnapshots"
                        title="Release Snapshots"
                        summary={this.accountUsageSummary(UsageType.ReleaseVariableSet, accountUsages)}
                        help={this.accountUsageHelp(UsageType.ReleaseVariableSet, accountUsages)}>
                        {this.accountUsageInReleaseSnapshots()}
                    </ExpandableFormSection>
                </div>;
            case AccountType.SshKeyPair:
            case AccountType.Token:
            case AccountType.UsernamePassword:
                return <div>
                    <ExpandableFormSection
                        key="usageInTargets"
                        errorKey="usageInTargets"
                        title="Targets"
                        summary={this.accountUsageSummary(UsageType.Target, accountUsages)}
                        help={this.accountUsageHelp(UsageType.Target, accountUsages)}>
                        {this.accountUsageInTargets(accountUsages)}
                    </ExpandableFormSection>
                </div>;
        }
    }

    private accountUsageSummary(usageType: UsageType, accountUsages: AccountUsageResource) {
        switch (usageType) {
            case UsageType.DeploymentProcess:
                let steps = 0;
                accountUsages.DeploymentProcesses.forEach((usageEntry: StepUsage) => {
                    steps = steps + usageEntry.Steps.length;
                });
                return steps > 0
                    ? steps > 1
                        ? Summary.summary(<span>This account is being used in <b>{steps}</b> steps</span>)
                        : Summary.summary(<span>This account is being used in <b>one</b> step</span>)
                    : Summary.placeholder("This account is not being used in any steps");
            case UsageType.Release:
                let releases = 0;
                accountUsages.Releases.forEach((usageEntry: ReleaseUsage) => {
                    releases += usageEntry.Releases.length;
                });
                return releases > 0
                    ? releases > 1
                        ? Summary.summary(<span>This account is being used in <b>{releases}</b> releases</span>)
                        : Summary.summary(<span>This account is being used in <b>one</b> release</span>)
                    : Summary.placeholder("This account is not being used in any releases");
            case UsageType.Target:
                return accountUsages.Targets.length > 0
                    ? accountUsages.Targets.length > 1
                        ? Summary.summary(<span>This account is being used in <b>{accountUsages.Targets.length}</b> targets</span>)
                        : Summary.summary(<span>This account is being used in <b>one</b> target</span>)
                    : Summary.placeholder("This account is not being used in any targets");
            case UsageType.LibraryVariableSet:
                return accountUsages.LibraryVariableSets.length > 0
                    ? accountUsages.LibraryVariableSets.length > 1
                        ? Summary.summary(<span>This account is being used in <b>{accountUsages.LibraryVariableSets.length}</b> Library Variable Sets</span>)
                        : Summary.summary(<span>This account is being used in <b>one</b> Library Variable Set</span>)
                    : Summary.placeholder("This account is not being used in any Library Variable Sets");
            case UsageType.ProjectVariableSet:
                return accountUsageInProjects.length > 0
                    ? accountUsageInProjects.length > 1
                        ? Summary.summary(<span>This account is being used in variables in <b>{accountUsageInProjects.length}</b> Projects</span>)
                        : Summary.summary(<span>This account is being used in variables in <b>one</b> Project</span>)
                    : Summary.placeholder("This account is not being used in any variables in Projects");
            case UsageType.ReleaseVariableSet:
                let releaseSnapshots = 0;
                accountUsageInReleaseSnapshots.forEach((usageEntry: ProjectVariableSetUsage) => {
                    releaseSnapshots = releaseSnapshots + usageEntry.Releases.length;
                });
                return releaseSnapshots > 0
                    ? releaseSnapshots > 1
                        ? Summary.summary(<span>This account is being used in variables in <b>{releaseSnapshots}</b> Release snapshots</span>)
                        : Summary.summary(<span>This account is being used in variables in <b>one</b> Release snapshot</span>)
                    : Summary.placeholder("This account is not being used in any variables in Releases snapshots");
        }
    }

    private accountUsageHelp(usageType: UsageType, accountUsages: AccountUsageResource) {
        switch (usageType) {
            case UsageType.DeploymentProcess:
                return accountUsages.DeploymentProcesses.length > 0 ?
                    "This account is being used in the below step(s)" :
                    "This account is not being used in any steps";
            case UsageType.Release:
                return accountUsages.Releases.length > 0 ?
                    "This account is being used in the below release(s)" :
                    "This account is not being used in any releases";
            case UsageType.Target:
                return accountUsages.Targets.length > 0 ?
                    "This account is being used in the below target(s)" :
                    "This account is not being used in any targets";
            case UsageType.LibraryVariableSet:
                return accountUsages.LibraryVariableSets.length > 0 ?
                    "This account is being used in the below Library Variable Set(s)" :
                    "This account is not being used in any Library Variable Sets";
            case UsageType.ProjectVariableSet:
                return accountUsageInProjects.length > 0 ?
                    "This account is being used in variables in the below Project(s)" :
                    "This account is not being used in any variables in Projects";
            case UsageType.ReleaseVariableSet:
                return accountUsageInReleaseSnapshots.length > 0 ?
                    "This account is being used in variables in the below Release(s)" :
                    "This account is not being used in any variables in Releases";
        }
    }

    private accountUsageInDeploymentProcess(accountUsages: AccountUsageResource) {
        return <div>
            {accountUsages.DeploymentProcesses.length > 0 &&
                <DataTable>
                    <DataTableHeader>
                        <DataTableRow>
                            <DataTableHeaderColumn>Project Name</DataTableHeaderColumn>
                            <DataTableHeaderColumn>Step Name(s)</DataTableHeaderColumn>
                        </DataTableRow>
                    </DataTableHeader>
                    <DataTableBody>
                        {accountUsages.DeploymentProcesses.map((usageEntry: StepUsage, idx) => {
                            const rowKey = `AUDP-${usageEntry.ProjectId}`;
                            return <DataTableRow key={rowKey}>
                                <DataTableRowColumn>
                                    {usageEntry.ProjectName}
                                </DataTableRowColumn>
                                <DataTableRowColumn>
                                    {usageEntry.Steps.map((step: StepUsageEntry, i) =>
                                        <Chip to={routeLinks.project(usageEntry.ProjectSlug).process.step(step.StepId)} key={`${step.StepId}-deploymentProcessLink`}>{step.StepName}</Chip>)}
                                </DataTableRowColumn>
                            </DataTableRow>;
                        })}
                    </DataTableBody>
                </DataTable>
            }
        </div>;
    }

    private accountUsageInReleases(accountUsages: AccountUsageResource) {
        return <div>
            {accountUsages.Releases.length > 0 &&
                <DataTable>
                    <DataTableHeader>
                        <DataTableRow>
                            <DataTableHeaderColumn>Project Name</DataTableHeaderColumn>
                            <DataTableHeaderColumn>Release Version(s)</DataTableHeaderColumn>
                        </DataTableRow>
                    </DataTableHeader>
                    <DataTableBody>
                        {accountUsages.Releases.map((usageEntry: ReleaseUsage, idx) => {
                            const rowKey = `AUR-${usageEntry.ProjectId}`;
                            return <DataTableRow key={rowKey}>
                                <DataTableRowColumn>
                                    {usageEntry.ProjectName}
                                </DataTableRowColumn>
                                <DataTableRowColumn>
                                    {usageEntry.Releases.map((release: ReleaseUsageEntry, i) =>
                                        <InternalLink to={routeLinks.release(release.ReleaseId)} key={`${release.ReleaseId}-releaseSnapshotLink`}>
                                            <Chip>{release.ReleaseVersion}</Chip>
                                        </InternalLink>)}
                                </DataTableRowColumn>
                            </DataTableRow>;
                        })}
                    </DataTableBody>
                </DataTable>
            }
        </div>;
    }

    private accountUsageInProjects() {
        return <div>
            {accountUsageInProjects.length > 0 &&
                <DataTable>
                    <DataTableHeader>
                        <DataTableRow>
                            <DataTableHeaderColumn>Project Name</DataTableHeaderColumn>
                        </DataTableRow>
                    </DataTableHeader>
                    <DataTableBody>
                        {accountUsageInProjects.map((usageEntry: ProjectVariableSetUsage, idx) => {
                            const rowKey = `AUP-${usageEntry.ProjectId}`;
                            return <DataTableRow key={rowKey}>
                                <DataTableRowColumn>
                                    <InternalLink to={routeLinks.project(usageEntry.ProjectSlug).variables.root}>
                                        {usageEntry.ProjectName}
                                    </InternalLink>
                                </DataTableRowColumn>
                            </DataTableRow>;
                        })}
                    </DataTableBody>
                </DataTable>
            }
        </div>;
    }

    private accountUsageInLibraryVariableSets(accountUsages: AccountUsageResource) {
        return <div>
            {accountUsages.LibraryVariableSets.length > 0 &&
                <DataTable>
                    <DataTableHeader>
                        <DataTableRow>
                            <DataTableHeaderColumn>Library Variable Set</DataTableHeaderColumn>
                        </DataTableRow>
                    </DataTableHeader>
                    <DataTableBody>
                        {accountUsages.LibraryVariableSets.map((usageEntry: LibraryVariableSetUsageEntry, idx) => {
                            const rowKey = `AULVS-${usageEntry.LibraryVariableSetId}`;
                            return <DataTableRow key={rowKey}>
                                <DataTableRowColumn>
                                    <InternalLink to={routeLinks.library.variableSet(usageEntry.LibraryVariableSetId)}>
                                        {usageEntry.LibraryVariableSetName}
                                    </InternalLink>
                                </DataTableRowColumn>
                            </DataTableRow>;
                        })}
                    </DataTableBody>
                </DataTable>
            }
        </div>;
    }

    private accountUsageInTargets(accountUsages: AccountUsageResource) {
        return <div>
            {accountUsages.Targets.length > 0 &&
                <DataTable>
                    <DataTableHeader>
                        <DataTableRow>
                            <DataTableHeaderColumn>Target Name</DataTableHeaderColumn>
                        </DataTableRow>
                    </DataTableHeader>
                    <DataTableBody>
                        {accountUsages.Targets.map((usageEntry: TargetUsageEntry, idx) => {
                            const rowKey = `AUT-${usageEntry.TargetId}`;
                            return <DataTableRow key={rowKey}>
                                <DataTableRowColumn>
                                    <InternalLink to={routeLinks.infrastructure.machine(usageEntry.TargetId).root}>
                                        {usageEntry.TargetName}
                                    </InternalLink>
                                </DataTableRowColumn>
                            </DataTableRow>;
                        })}
                    </DataTableBody>
                </DataTable>
            }
        </div>;
    }

    private accountUsageInReleaseSnapshots = () => {
        return <div>
            {accountUsageInReleaseSnapshots.length > 0 &&
                <DataTable>
                    <DataTableHeader>
                        <DataTableRow>
                            <DataTableHeaderColumn>Project Name</DataTableHeaderColumn>
                            <DataTableHeaderColumn>Release Version(s)</DataTableHeaderColumn>
                        </DataTableRow>
                    </DataTableHeader>
                    <DataTableBody>
                        {accountUsageInReleaseSnapshots.map((usageEntry: ProjectVariableSetUsage, idx) => {
                            const rowKey = `AURS-${usageEntry.ProjectId}`;
                            return <DataTableRow key={rowKey}>
                                <DataTableRowColumn>
                                    {usageEntry.ProjectName}
                                </DataTableRowColumn>
                                <DataTableRowColumn>
                                    {usageEntry.Releases.map((release: ReleaseUsageEntry, i, arr) =>
                                        <InternalLink to={routeLinks.release(release.ReleaseId)} key={`${rowKey}-${release.ReleaseId}-releaseSnapshotLink`}>
                                            <Chip>{release.ReleaseVersion}</Chip>
                                        </InternalLink>)}
                                </DataTableRowColumn>
                            </DataTableRow>;
                        })}
                    </DataTableBody>
                </DataTable>
            }
        </div>;
    }
}
