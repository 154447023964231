import * as React from "react";
import {OfflineDropEndpointResource} from "client/resources";
import {ExpandableFormSection, Note, Sensitive, StringRadioButtonGroup, Summary, Text} from "components/form";
import {ObfuscatedPlaceholder} from "components/form/Sensitive/Sensitive";
import { CategorizedEndpointRegistration, CommunicationStyle, EndpointRegistrationKey } from "./endpointRegistry";
import { EndpointThumbnail } from "./EndpointThumbnail";
import EndpointCard from "./EndpointCard";
import RadioButton from "../../../../../components/form/RadioButton/RadioButton";
import {OfflineDropDestinationType} from "../../../../../client/resources/offlineDropDestinationResource";
import ExternalLink from "../../../../../components/Navigation/ExternalLink/ExternalLink";

interface OfflineDropEndpointProps {
    endpoint: OfflineDropEndpointResource;
    onChange(newValue: OfflineDropEndpointResource): void;
}

class OfflineDropEndpoint extends React.Component<OfflineDropEndpointProps, undefined> {
    render() {
        return <div>
            <ExpandableFormSection
                title="Destination"
                summary={this.destinationTypeSummary()}
                help="The offline-drop package can be written either as an Octopus Artifact or to a file-system path"
                errorKey="Destination">
                <StringRadioButtonGroup
                    value={this.props.endpoint.Destination.DestinationType}
                    onChange={val => this.onDestinationTypeChange(val as OfflineDropDestinationType)}>
                    <RadioButton value={OfflineDropDestinationType.Artifact} label="Artifact" isDefault />
                    <Note>The offline bundle will be written as a zip file and stored as an <ExternalLink href="Artifacts">Octopus Artifact</ExternalLink></Note>
                    <RadioButton value={OfflineDropDestinationType.FileSystem} label="Drop Folder" />
                    <Note>The offline bundle will be written to a directory</Note>
                </StringRadioButtonGroup>

                {this.props.endpoint.Destination.DestinationType === OfflineDropDestinationType.FileSystem &&
                <React.Fragment>
                    <Text label="Drop folder path"
                          value={this.props.endpoint.Destination.DropFolderPath}
                          hintText="\\\\"
                          onChange={(x) => {
                              const endpoint = this.props.endpoint;
                              endpoint.Destination.DropFolderPath = x;
                              this.props.onChange(endpoint);
                          }}/>
                    <Note>A file-system path to which drop packages will be published. Using a full UNC path is recommended. Packages may contain sensitive data.</Note>
                </React.Fragment>
                }
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="SensitiveVariablesEncryptionPassword"
                title="Sensitive-variables Encryption Password"
                summary={this.encryptionPasswordSummary()}
                help="If your project contains sensitive-variables, they will be encrypted in the drop location.">
                <Sensitive
                    value={this.props.endpoint.SensitiveVariablesEncryptionPassword}
                    onChange={(x) => {
                        const endpoint = this.props.endpoint;
                        endpoint.SensitiveVariablesEncryptionPassword = x;
                        this.props.onChange(endpoint);
                    }}
                    label="Encryption password"
                />
                <Note>
                    The installation script will prompt for this password, and use it for decryption.
                    If your project will not contain sensitive-variables you may leave this value un-set.
                </Note>
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="ApplicationsDirectory"
                title="Applications Directory"
                summary={this.props.endpoint.ApplicationsDirectory
                    ? Summary.summary(this.props.endpoint.ApplicationsDirectory)
                    : Summary.placeholder("No applications directory")}
                help="The directory that packages will be extracted to when run on the deployment target.">
                <Text label="Applications directory"
                    value={this.props.endpoint.ApplicationsDirectory}
                    hintText="C:\Applications"
                    onChange={(x) => {
                        const endpoint = this.props.endpoint;
                        endpoint.ApplicationsDirectory = x;
                        this.props.onChange(endpoint);
                    }}
                />
                <Note>The final application installation path can be set by using the 'Custom Installation Directory' project setting.</Note>
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="OctopusWorkingDirectory"
                title="Working Directory"
                summary={this.props.endpoint.OctopusWorkingDirectory
                    ? Summary.summary(this.props.endpoint.OctopusWorkingDirectory)
                    : Summary.placeholder("No working directory")}
                help="A working directory for Octopus to store reusable needed information such as the deployment journal.">
                <Text label="Applications directory"
                    value={this.props.endpoint.OctopusWorkingDirectory}
                    hintText="C:\Octopus"
                    onChange={(x) => {
                        const endpoint = this.props.endpoint;
                        endpoint.OctopusWorkingDirectory = x;
                        this.props.onChange(endpoint);
                    }}
                />
            </ExpandableFormSection>
        </div>;
    }

    private encryptionPasswordSummary() {
        return this.props.endpoint.SensitiveVariablesEncryptionPassword && this.props.endpoint.SensitiveVariablesEncryptionPassword.HasValue ?
            Summary.summary(ObfuscatedPlaceholder) :
            Summary.placeholder("No encryption password provided");
    }

    private destinationTypeSummary() {
       switch (this.props.endpoint.Destination.DestinationType) {
           case OfflineDropDestinationType.Artifact:
               return Summary.summary("The offline-drop package will be created as an Octopus Artifact and attached to the deployment");
           case OfflineDropDestinationType.FileSystem:
               return Summary.summary(`The offline-drop package will be written to ${this.props.endpoint.Destination.DropFolderPath}`);
       }
    }

    private onDestinationTypeChange(destinationType: OfflineDropDestinationType) {
        const endpoint = this.props.endpoint;
        endpoint.Destination.DestinationType = destinationType;
        if (destinationType === OfflineDropDestinationType.Artifact) {
            endpoint.Destination.DropFolderPath = null;
        }
        if (destinationType === OfflineDropDestinationType.FileSystem) {
            endpoint.Destination.DropFolderPath = "";
        }
        this.props.onChange(endpoint);
    }
}

export default OfflineDropEndpoint;

const offlineDropEndpointImage = require("./offline-package-drop.svg");
const offlineDropEndpointRegistration: CategorizedEndpointRegistration = {
    key: EndpointRegistrationKey.OfflineDrop,
    displayOrder: 10,
    communicationStyle: CommunicationStyle.OfflineDrop,
    name:  "Offline Package Drop",
    categories: [{
        category: "Offline Package Drop",
        title: (<React.Fragment>Let's setup an <strong>Offline Package Drop</strong></React.Fragment>),
        help: <Note>Learn more about <ExternalLink href="OfflinePackageDrop">Offline Package Drops</ExternalLink></Note>,
        displayOrder: 40,
    }],
    renderCard: ({registration, category, getNavigationProps}) => (
        <EndpointCard
            logo={<EndpointThumbnail src={offlineDropEndpointImage} alt={registration.name} />}
            header={registration.name}
            description="Configure a location to drop Offline Deployment Packages."
            {...getNavigationProps()}
        />
    )
};

export { offlineDropEndpointRegistration };