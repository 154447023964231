import * as React from "react";
import { manualPackagingInstruction } from "../../Instructions";
import { CommonManualPackagingTextForNuGet, CommonManualPackagingTextForZip, CommonTextForJavaSample } from "../CommonRegistrationText";

export const ManualPackaging: React.SFC<{}> = () => (
    <div>
        <p>
            When packaging Java applications manually, creating <i>NuGet</i> or <i>Zip</i> packages
            yourself is an easy way to get started.
        </p>
        {CommonTextForJavaSample}
        {CommonManualPackagingTextForNuGet}
        {CommonManualPackagingTextForZip}
    </div>
);

export default manualPackagingInstruction(() => <ManualPackaging />);