import * as React from "react";
import { Reducer, useReducer } from "react";
import { getType, ActionType, createAction } from "typesafe-actions";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import { PackagingRegistration, PackagingInstruction, usePackagingInstructions, usePackagingRegistrations } from "./Registry";
import * as cn from "classnames";
import { combineUseReducers } from "utils/Reducers/combineUseReducers";
import { white } from "theme/colors";
import { ControlledTabsContainer, TabItem } from "components/Tabs";
import { Collapse } from "@material-ui/core";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import usePackagingRegistration from "./Registry/usePackageRegistration";
import Section from "components/Section";

const styles = require("./PackagingInstructionSelector.less");

//#region state
type RegistrationState = string;
type InstructionState = string;

interface PackagingSelectorState {
    selectedRegistration?: RegistrationState;
    selectedInstruction?: InstructionState;
}

const PackagingSelectorActions = {
    selectRegistration: createAction("SELECT/REGISTRATION", (resolve) => (registration: PackagingRegistration) => resolve(registration)),
    selectInstruction: createAction("SELECT/INSTRUCTION", (resolve) => (instruction: PackagingInstruction) => resolve(instruction))
};

type PackagingSelectorAction = ActionType<typeof PackagingSelectorActions>;

const autoSelectInstruction = (current: string, instructions: PackagingInstruction[]) => {
    const sortedInstructions = instructions.sort((a, b) => a.displayOrder - b.displayOrder);
    const firstInstruction = sortedInstructions[0] && sortedInstructions[0].name;
    if (!current) {
        return firstInstruction;
    }
    const found = sortedInstructions.filter(x => current === x.name);
    return found.length > 0 ? found[0].name : firstInstruction;
};

const registrationReducer: Reducer<RegistrationState, PackagingSelectorAction> = (state = null, action) => {
    switch (action.type) {
        case getType(PackagingSelectorActions.selectRegistration):
            return action.payload ? action.payload.id : null;
    }
    return state;
};

const instructionReducer: Reducer<InstructionState, PackagingSelectorAction> = (state = null, action) => {
    switch (action.type) {
        case getType(PackagingSelectorActions.selectRegistration):
            return autoSelectInstruction(state, action.payload && action.payload.instructions);
        case getType(PackagingSelectorActions.selectInstruction):
            return action.payload ? action.payload.name : null;
    }
    return state;
};

const packagingSelectorReducer = combineUseReducers<PackagingSelectorState, PackagingSelectorAction>({
    selectedRegistration: registrationReducer,
    selectedInstruction: instructionReducer
});

//#endregion

export const PackagingGroupTitle: React.SFC<{ className?: string }> = ({ className, children }) => (
    <div className={cn(styles.groupHeading, className)}>
        {children}
    </div>
);

export const PackagingGroupHelp: React.SFC<{ className?: string }> = ({ className, children }) => (
    <div className={cn(styles.groupHelp, className)}>
        {children}
    </div>
);

type ActiveItemButtonProps = { active: boolean, label: string, onClick?: () => void, style?: object, icon?: any; };

const ActiveItemButton: React.SFC<ActiveItemButtonProps> = ({ onClick, label, active, style }) => {
    const labelProps = active ? { color: white } : undefined;
    return (
        <ActionButton
            type={ActionButtonType.Category}
            labelProps={labelProps}
            label={label}
            onClick={onClick}
            className={cn({ [styles.active]: active })}
            style={style}
        />
    );
};

const PackagingActions: React.SFC<{ className?: string }> = ({ children, className }) => (
    <div className={cn(styles.actions, className)}>{children}</div>
);

interface PackagingInstructionViewProps {
    registrationId: string;
    instruction: string;
    onSelect: (instruction: PackagingInstruction) => void;
}

const PackagingInstructionView: React.FC<PackagingInstructionViewProps> = ({ registrationId, onSelect, instruction }) => {
    const instructions = usePackagingInstructions(registrationId).sort((a, b) => a.displayOrder - b.displayOrder);
    const registration = usePackagingRegistration(registrationId);

    const handleSelect = (value: string) => {
        const found = instructions.filter(x => x.name === value);
        if (found.length > 0) {
            onSelect(found[0]);
        }
    };

    return (
        <Collapse in={!!instruction} timeout="auto" unmountOnExit={true}>
            {instruction && <div className={styles.contentContainer}>
                <div className={styles.instructionsIntro}>How would you like to package this <b>{registration.name}</b> application?</div>
                <Section>
                    <ControlledTabsContainer value={instruction} onChange={handleSelect}>
                        {instructions.map(x => (
                            <TabItem label={x.name} value={x.name} key={x.name}>
                                <TransitionAnimation key={`${registrationId}-${instruction}`}>
                                    {x.render()}
                                </TransitionAnimation>
                            </TabItem>
                        ))}
                    </ControlledTabsContainer>
                </Section>
            </div>}
        </Collapse>
    );
};

type PackagingInstructionSelectorProps = {};

export const PackagingInstructionSelector: React.SFC<PackagingInstructionSelectorProps> = (props) => {
    const registrations = usePackagingRegistrations();
    const [state, dispatch] = useReducer(packagingSelectorReducer, { selectedInstruction: null, selectedRegistration: null });

    return (
        <>
            <div>What type of <b>software application</b> do you want to package?</div>
            <Section>
                <PackagingActions className={styles.paperActions}>
                    {registrations.map(x => (
                        <ActiveItemButton
                            label={x.name}
                            onClick={() => dispatch(PackagingSelectorActions.selectRegistration(x))}
                            key={x.id}
                            active={state.selectedRegistration === x.id}
                        />)
                    )}
                </PackagingActions>
            </Section>
            <PackagingInstructionView
                onSelect={(instruction) => dispatch(PackagingSelectorActions.selectInstruction(instruction))}
                instruction={state.selectedInstruction}
                registrationId={state.selectedRegistration} />
        </>
    );
};