import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
import CustomDialog from "components/Dialog/CustomDialog";
import { FundamentalsGuideDialogLayout } from "components/GettingStarted/FundamentalsGuide/FundamentalsGuideDialogLayout";
import { TopicsContainer, LearnMoreResources, SupportResources, NewToOctopusResources, NeedHelpResources } from "./CommonViews";
import { OverviewContainer } from "./CommonViews";

const styles = require("./Registrations.less");

export const DefaultOverview: React.StatelessComponent<{}> = props => {
    return <OverviewContainer>
        <TopicsContainer>
            <NeedHelpResources>
                <p>Search our <ExternalLink href="DocumentationHome">documentation</ExternalLink> or see the resources tab for helpful topics.</p>
            </NeedHelpResources>
        </TopicsContainer>
    </OverviewContainer>;
};

export const DefaultTopics: React.StatelessComponent<{}> = props => {
    return <TopicsContainer>
        <LearnMoreResources>
            <div><ExternalLink href="DocumentationHome">Documentation</ExternalLink></div>
        </LearnMoreResources>
    </TopicsContainer>;
};

interface DefaultGettingStartedState {
    showFundamentals: boolean;
}

export class DefaultGettingStarted extends React.Component<{}, DefaultGettingStartedState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            showFundamentals: false,
        };
    }

    render() {
        return <div className={styles.gettingStarted}>
            <CustomDialog open={this.state.showFundamentals} close={this.closeFundamentalsDialog} render={(renderProps) => (
                <FundamentalsGuideDialogLayout {...renderProps} />
            )} />
            <NewToOctopusResources>
                <div><a href="#" onClick={this.showFundamentalsDialog}>Fundamentals of Deploying</a></div>
                <div><ExternalLink href="GettingStarted">Getting Started Guide</ExternalLink></div>
            </NewToOctopusResources>
            <SupportResources>
                <div><ExternalLink href="Slack">Join the Slack Community</ExternalLink></div>
                <div><ExternalLink href="https://www.youtube.com/playlist?list=PLAGskdGvlaw3-cd9rPiwhwfUo7kDGnOBh">Ask Octopus Training Videos</ExternalLink></div>
                <div><ExternalLink href="HelpMakeSuggestion">Make a Suggestion</ExternalLink></div>
                <div><ExternalLink href="DocumentationHome">Search Documentation</ExternalLink></div>
                <div><ExternalLink href={window.location.origin + window.location.pathname + "/../swaggerui/"}>Search API Documentation</ExternalLink></div>
                <div><ExternalLink href="https://octopus.com/downloads">See Additional Downloads</ExternalLink></div>
            </SupportResources>
        </div>;
    }

    private showFundamentalsDialog = (e: any) => {
        e.preventDefault();
        this.setState({ showFundamentals: true });
    }

    private closeFundamentalsDialog = () => {
        this.setState({ showFundamentals: false });
    }
}