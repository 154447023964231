import * as React from "react";
import MarkdownDescription from "components/MarkdownDescription";
import {
    DataTable,
    DataTableBody,
    DataTableRow,
    DataTableRowColumn
} from "components/DataTable";
import {Callout, CalloutType} from "components/Callout/Callout";
const styles = require("./style.less");
import {PackageModel} from "../packageModel";
import Note from "../../../../../components/form/Note/Note";
import { ReleasePackageMetadataResource, WorkItemLink } from "client/resources";
import DataBaseComponent, { DataBaseComponentState } from "components/DataBaseComponent";
import SimpleExpander from "components/SimpleExpander/SimpleExpander";
import ExternalLink from "components/Navigation/ExternalLink";
import WorkItems from "components/WorkItems/WorkItems";
import CommitDetails from "components/Commits/CommitDetails";

interface PackagesListProps {
    packages: PackageModel[];
    packageMetadata: ReleasePackageMetadataResource[];
}

export default class PackagesList extends DataBaseComponent<PackagesListProps, DataBaseComponentState> {
    constructor(props: PackagesListProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                {this.props.packages && this.props.packages.length > 0
                ? <ul>
                    {this.props.packages.map((pkg, index) =>
                        <li key={index} className={pkg.Notes.Succeeded ? styles.ok : styles.error}>
                            <b>
                                <dfn title={pkg.PackageId + " from feed " + pkg.FeedName}>
                                    {this.renderPackageReference(pkg)} version {pkg.Version}
                                </dfn>
                            </b>
                            {pkg.Notes.Notes && <Note><MarkdownDescription markup={pkg.Notes.Notes}/></Note>}
                            {pkg.Notes.FailureReason && <div className={styles.container}>
                                <Callout title={pkg.Notes.FailureReason} type={CalloutType.Danger}/>
                            </div>}
                            {this.metadataForPackage(pkg)}
                        </li>
                    )}
                </ul>
                : <Note>There are no packages associated with any steps.</Note>}
            </div>
        );
    }

    metadataForPackage = (pkg: PackageModel) => {
        const metadata = this.props.packageMetadata.find(p => p.PackageId === pkg.PackageId && p.Version === pkg.Version);

        if (metadata) {
            return <div className={styles.container}>
            <SimpleExpander title={<div className={styles.metadataTitle}>Metadata</div>} errorKey="metadata">
                <div className={styles.metadataSummary}>
                    <DataTable>
                        <DataTableBody>
                            <DataTableRow>
                                <DataTableRowColumn className={styles.metadataSummaryLabel}>Build Environment</DataTableRowColumn>
                                <DataTableRowColumn>{metadata.BuildEnvironment}</DataTableRowColumn>
                            </DataTableRow>
                            <DataTableRow>
                                <DataTableRowColumn className={styles.metadataSummaryLabel}>Build</DataTableRowColumn>
                                <DataTableRowColumn><ExternalLink href={metadata.BuildUrl}>{metadata.BuildNumber}</ExternalLink></DataTableRowColumn>
                            </DataTableRow>
                            {metadata && metadata.Commits && metadata.Commits.length > 0 &&
                            <DataTableRow>
                                <DataTableRowColumn className={styles.metadataSummaryLabel}>Commits</DataTableRowColumn>
                                <DataTableRowColumn><CommitDetails data={metadata.Commits} /></DataTableRowColumn>
                            </DataTableRow>}
                            <DataTableRow>
                                <DataTableRowColumn className={styles.metadataSummaryLabel}>Work Items</DataTableRowColumn>
                                <DataTableRowColumn><WorkItems data={metadata.WorkItems} /></DataTableRowColumn>
                            </DataTableRow>
                        </DataTableBody>
                    </DataTable>
                </div>
            </SimpleExpander></div>;
        }
        return;
    }

    workItemSummary = (workItems: WorkItemLink[]) => {
        return workItems && workItems.length > 0 ? `(${workItems.length} work items)` : "";
    }

    renderPackageReference = (pkg: PackageModel) => {
    if (pkg.ProjectName) {
        return <React.Fragment>{pkg.ActionName}:{pkg.ProjectName}</React.Fragment>;
    }

    if (pkg.PackageReferenceName) {
        return <React.Fragment>{pkg.ActionName}:{pkg.PackageReferenceName}({pkg.PackageId})</React.Fragment>;
    }

    return <React.Fragment>{pkg.ActionName}:{pkg.PackageId}</React.Fragment>;
    }
}