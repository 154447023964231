import * as React from "react";
import StandardLayoutInternal from "./StandardLayoutInternal";
import { connect } from "react-redux";
import {bindActionCreators} from "redux";
import GlobalState from "globalState";
import { clearUnhandledErrors, currentUnhandledErrorSelector } from "components/UnhandledError/reducers";

interface StandardLayoutDispatchers {
    onErrorClose(): void;
}

const StandardLayout = connect(
    (state: GlobalState) => {
        return {
            unhandledError: currentUnhandledErrorSelector(state)
        };
    },
    (dispatch): StandardLayoutDispatchers => {
        return {
            onErrorClose: bindActionCreators(clearUnhandledErrors, dispatch)
        };
    }
)(StandardLayoutInternal);

export default StandardLayout;