import * as React from "react";
import { LargeDialogFrame, DialogFrameProp } from "./CustomDialogFrames";
import { PermissionCheckProps } from "components/PermissionCheck/PermissionCheck";
import BusyIndicator from "components/BusyIndicator/BusyIndicator";
import BusyFromPromise, { BusyState } from "components/BusyFromPromise/BusyFromPromise";
import ErrorPanel from "components/ErrorPanel/ErrorPanel";
import { Errors } from "components/DataBaseComponent/DataBaseComponent";
import CustomDialogTitleBar from "./CustomDialogTitleBar";
import { RenderProps } from "components/Dialog/CustomDialog";
import { defaultProps } from "recompose";

type CustomSaveDialogLayoutProps = RenderProps & DialogFrameProp & {
    errors: Errors;
    busy: BusyState;
    frame?: React.ComponentType<any>;
    renderTitle?: (props: ActionRenderProps) => React.ReactElement<any>;
    savePermission?: PermissionCheckProps;
    renderActions?: (props: ActionRenderProps) => React.ReactElement<any>;
    renderContent?: (props: ActionRenderProps) => React.ReactElement<any>;
    onSaveClick(): Promise<boolean>;
};

interface ActionRenderProps extends RenderProps {
    savePermission?: PermissionCheckProps;
    onSaveClick(): Promise<boolean>;
}

export const CustomSaveDialogTitleBar = defaultProps({ title: "Edit Details" })(CustomDialogTitleBar);

const CustomSaveDialogLayout: React.SFC<CustomSaveDialogLayoutProps> = ({
    frame: Frame = LargeDialogFrame,
    renderContent,
    busy,
    errors,
    renderTitle = (actionRenderProps: ActionRenderProps) => (<CustomSaveDialogTitleBar />),
    renderActions,
    children,
    ...renderProps
}) => {

    return (
        <Frame>
            {renderTitle && renderTitle(renderProps)}
            {busy && <BusyFromPromise promise={busy}>
                {(isBusy: boolean) => <BusyIndicator show={isBusy} />}
            </BusyFromPromise>}

            {errors && <ErrorPanel message={errors.message} details={errors.details} />}
            {renderContent && renderContent(renderProps)}
            {renderActions && renderActions(renderProps)}
        </Frame>
    );
};

export default CustomSaveDialogLayout;