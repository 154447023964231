import * as React from "react";
import {connect} from "react-redux";
import { Dispatch, Action } from "redux";
import { onExpanderStateChanged } from "components/form/Sections/reducers/expanders";

export interface ControlExpandersProps {
    setExpanderState(key: string, open: boolean): void;
}

// HOC that adds an openExpander prop for direct control over expanders
export function ControlExpanders<Props extends ControlExpandersProps>(Comp: React.ComponentClass<Props>) {
    type ExternalProps = ChangeProperties<Props, Partial<ControlExpandersProps>>;
    const mapDispatchToProps = (dispatch: Dispatch<Action<any>>) => {
        return {
            setExpanderState: (key: string, open: boolean) => dispatch(onExpanderStateChanged(null, key, open))
        };
    };

    const BaseComponent = Comp as  React.ComponentClass<ControlExpandersProps>;

    return connect(
        null,
        mapDispatchToProps
    )(BaseComponent) as any as React.ComponentClass<ExternalProps>;
}