import * as React from "react";
import FormBaseComponent, {OptionalFormBaseComponentState} from "components/FormBaseComponent";
import {connect, MapDispatchToProps } from "react-redux";
import {repository, session} from "clientInstance";
import {TenantResource, MultiTenancyStatusResource} from "client/resources";
import { tenantsActions } from "../tenantsArea";
import {ExpandableFormSection, Summary, SummaryNode} from "../../../components/form";
import FormPaperLayout from "../../../components/FormPaperLayout/FormPaperLayout";
import LogoEditor, {LogoEditorSettings} from "../../../components/form/LogoEditor/LogoEditor";
import Text from "../../../components/form/Text/Text";
import {required} from "../../../components/form/Validators";
import {Redirect, RouteComponentProps} from "react-router";
import Logo from "../../../components/Logo/Logo";
import {saveLogo} from "client/repositories/logoUpload";
import {Permission} from "../../../client/resources/permission";
import OverflowMenu from "components/Menu/OverflowMenu";
import routeLinks from "../../../routeLinks";
import GlobalState from "globalState";
import { bindActionCreators, Dispatch, Action } from "redux";
import InternalRedirect from "../../../components/Navigation/InternalRedirect/InternalRedirect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import { configurationActions } from "../../configuration/reducers/configurationArea";

interface TenantModel {
    name: string;
    logo: LogoEditorSettings;
    tenantTags: string[];
}

interface DispatchProps {
    onSpaceMultiTenancyStatusFetched: (status: MultiTenancyStatusResource) => void;
    onTenantSaved: (tenant: TenantResource) => void;
}

interface TenantSettingsState extends OptionalFormBaseComponentState<TenantModel> {
    tenant: TenantResource;
    deleted: boolean;
}

class TenantSettingsInternal extends FormBaseComponent<DispatchProps & RouteComponentProps<any>, TenantSettingsState, TenantModel> {
    constructor(props: DispatchProps & RouteComponentProps<any>) {
        super(props);

        this.state = {
            tenant: null,
            deleted: false
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const tenantId = this.props.match.params.tenantId;
            const tenant = await repository.Tenants.get(tenantId);
            this.setState({
                tenant,
                model: this.buildModel(tenant),
                cleanModel: this.buildModel(tenant)
            });
        });
    }

    render() {
        const overFlowActions = this.state.tenant
            ? [
                OverflowMenu.deleteItemDefault("tenant", this.handleDeleteConfirm, { permission: Permission.TenantDelete, tenant: this.state.tenant.Id }),
                [OverflowMenu.navItem("Audit Trail",
                    routeLinks.configuration.eventsForTenant(this.state.tenant.Id), null, {
                        permission: Permission.EventView,
                        wildcard: true
                    })]
            ]
            : [];

        if (this.state.deleted) {
            return <InternalRedirect to={routeLinks.tenants} />;
        }
        return (
            <FormPaperLayout
                title="Settings"
                busy={this.state.busy}
                errors={this.state.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={{ permission: Permission.TenantEdit, tenant: "*" }}
                onSaveClick={this.handleSaveClick}
                overFlowActions={overFlowActions}
                saveText="Tenant details updated">
                {this.state.model && <TransitionAnimation>
                    <ExpandableFormSection
                        errorKey="logo"
                        title="Logo"
                        summary={this.logoSummary()}
                        help="Choose an image to use as a tenant logo.">
                        <LogoEditor
                            value={this.state.model.logo}
                            onChange={logo => this.setModelState({ logo })}
                        />
                    </ExpandableFormSection>

                    <ExpandableFormSection
                        errorKey="name"
                        title="Name"
                        focusOnExpandAll
                        summary={this.state.model.name ? Summary.summary(this.state.model.name) : Summary.placeholder("Please enter a name for your tenant")}
                        help="Enter a name for your tenant.">
                        <Text
                            value={this.state.model.name}
                            onChange={name => this.setModelState({ name })}
                            label="Tenant name"
                            validate={required("Please enter a tenant name")}
                            autoFocus={true}
                        />
                    </ExpandableFormSection>
                </TransitionAnimation>}
            </FormPaperLayout>
        );
    }

    handleSaveClick = async () => {
        const model = this.state.model;
        const tenant: TenantResource = {
            ...this.state.tenant,
            Name: model.name,
            TenantTags: model.tenantTags,
        };

        await this.doBusyTask(async () => {
            await saveLogo(this.state.tenant, this.state.model.logo.file, this.state.model.logo.reset);

            const result = await repository.Tenants.save(tenant);
            this.props.onTenantSaved(result);
            this.setState(s => {
                return {
                    model: this.buildModel(result),
                    cleanModel: this.buildModel(result),
                    tenant: result
                };
            });
        });
    }

    buildModel(tenant: TenantResource): TenantModel {

        const model: TenantModel = {
            name: tenant.Name,
            tenantTags: tenant.TenantTags,
            logo: { file: null, reset: false },
        };
        return model;
    }

    logoSummary(): SummaryNode {
        if (!this.state.tenant || this.state.model.logo.reset) {
            return Summary.placeholder("Default logo");
        }
        if (this.state.model.logo.file) {
            return Summary.summary(this.state.model.logo.file.name);
        }

        return Summary.summary(<Logo url={this.state.tenant.Links.Logo} size="2.5em" />);
    }

    private handleDeleteConfirm = async () => {
        const result = await repository.Tenants.del(this.state.tenant);
        const status = await repository.Tenants.status();
        this.props.onSpaceMultiTenancyStatusFetched(status);
        this.setState(state => {
            return {
                model: null,
                cleanModel: null,
                deleted: true
            };
        });
        return true;
    }
}

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>): DispatchProps =>
    bindActionCreators({
        onSpaceMultiTenancyStatusFetched: configurationActions.spaceMultiTenancyStatusFetched,
        onTenantSaved: tenantsActions.tenantSaved
    }, dispatch);

const TenantSettings = connect(
    null,
    mapDispatchToProps
)(TenantSettingsInternal);

export default TenantSettings;