import * as React from "react";
import {ActionEditProps} from "../pluginRegistry";
import {BaseComponent} from "components/BaseComponent/BaseComponent";
import {repository} from "clientInstance";
import {ActionSummaryProps} from "../actionSummaryProps";
import {ActionExecutionLocation} from "../../../client/resources/actionExecutionLocation";
import {ScriptActionEdit, ScriptProperties} from "../script/scriptAction";
import {TargetRoles} from "../../../areas/projects/components/DeploymentProcess/ActionDetails";
import pluginRegistry from "../pluginRegistry";
import {ScriptPackageProperties} from "../script/ScriptPackageReferenceDialog";
import {SupportedLanguage} from "../../ScriptingLanguageSelector/ScriptingLanguageSelector";
import { AccountResource } from "client/resources";

class KubernetesRunScriptActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        return <div>
            Run a script within an environment configured using the Kubernetes target.
        </div>;
    }
}

interface KubernetesRunScriptActionEditState {
    accounts: AccountResource[];
}

class KubernetesRunScriptActionEdit extends BaseComponent<ActionEditProps<ScriptProperties, ScriptPackageProperties>, KubernetesRunScriptActionEditState> {
    constructor(props: ActionEditProps<ScriptProperties, ScriptPackageProperties>) {
        super(props);

    }

    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            this.setState({
                accounts: await repository.Accounts.all()
            });
        });
    }

    render() {
        return <div>
            <ScriptActionEdit
                busy={this.props.busy}
                plugin={this.props.plugin}
                projectId={this.props.projectId}
                localNames={this.props.localNames}
                properties={this.props.properties}
                packages={this.props.packages}
                setProperties={this.props.setProperties}
                setPackages={this.props.setPackages}
                doBusyTask={this.props.doBusyTask}
                getFieldError={this.props.getFieldError}
                supportedLanguages={SupportedLanguage.PowerShellAndBash}
                errors={this.props.errors}
                expandedByDefault={this.props.expandedByDefault}/>
        </div>;
    }
}

pluginRegistry.registerDeploymentAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.KubernetesRunScript",
    summary: (properties, targetRolesAsCSV) => <KubernetesRunScriptActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    canHaveChildren: (step) => true,
    canBeChild: true,
    edit: KubernetesRunScriptActionEdit,
    targetRoleOption: (action) => TargetRoles.Required,
    hasPackages: (action) => true,
    features: {
        optional: ["Octopus.Features.SubstituteInFiles", "Octopus.Features.JsonConfigurationVariables",
            "Octopus.Features.ConfigurationTransforms", "Octopus.Features.ConfigurationVariables" ] }
});