import * as React from "react";
import * as cn from "classnames";
import { History } from "history";
type LocationDescriptor = History.LocationDescriptor;
import Breadcrumb from "components/Breadcrumb";
import BusyFromPromise from "components/BusyFromPromise/BusyFromPromise";
import BusyRefreshIndicator from "components/BusyRefreshIndicator";
import IconButton, { Icon } from "components/IconButton/IconButton";
import GlobalState from "globalState";
import { connect } from "react-redux";
import { toggleDrawer, isDrawerOpen } from "components/Drawer/reducers";
import { Dispatch, Action } from "redux";
import * as MediaQuery from "react-responsive";
import { drawerBreakpointWidth } from "components/Drawer/DrawerWrapperLayout";

const styles = require("./style.less");

interface ConnectedProps {
    isDrawerOpen?: boolean;
}

interface DispatchProps {
    toggleDrawer?(): void;
}

interface SectionTitlePropsInternal {
    title: JSX.Element;
    className?: string;
    sectionControl?: React.ReactNode;
    titleLogo?: JSX.Element;
    breadcrumbTitle?: string; // If specified, this text will display above the title.
    breadcrumbPath?: LocationDescriptor; // If specified, this will link your breadcrumb title.
    breadcrumbChip?: JSX.Element; // If specified, this will put the chip next to the  breadcrumb title.
    busy?: Promise<any> | boolean;
    enableLessIntrusiveLoadingIndicator?: boolean;
}

type SectionTitleProps = SectionTitlePropsInternal & ConnectedProps & DispatchProps;

class SectionTitleInternal extends React.Component<SectionTitleProps, never> {
    render() {
        const props = this.props;
        return <div className={cn(styles.container, props.className)}>
            <div className={styles.titleContainer}>
                <div className={styles.breadcrumbContainer}>
                    <Breadcrumb title={props.breadcrumbTitle} path={props.breadcrumbPath} />
                    {props.breadcrumbChip}
                </div>
                <div className={styles.title}>
                    {props.titleLogo && <div className={styles.logo}>{props.titleLogo}</div>}
                    <h2 className={styles.header}>
                        <span>{props.title}</span>
                        <MediaQuery minWidth={drawerBreakpointWidth}>
                            <div className={cn(styles.helpIconContainer, this.props.isDrawerOpen ? styles.helpIconContainerClosed : null)}>
                                <IconButton
                                    toolTipContent={this.props.isDrawerOpen ? "Hide help" : "Show help"}
                                    onClick={this.props.toggleDrawer}
                                    icon={this.props.isDrawerOpen ? Icon.CloseHelp : Icon.OpenHelp}
                                />
                            </div>
                        </MediaQuery>
                    </h2>
                    {/* We show a less intrusive refresh indicator for screens that have auto-refresh implemented (because the main horizontal loading bar is annoying). */}
                    {props.enableLessIntrusiveLoadingIndicator &&
                        <div className={styles.refreshIndicator}>
                            <BusyFromPromise promise={props.busy}>
                                {(busy: boolean) => {
                                    return busy && props.enableLessIntrusiveLoadingIndicator
                                        ? <div className={styles.refreshContainer}>
                                            <BusyRefreshIndicator show={true} />
                                        </div>
                                        : null;
                                }}
                            </BusyFromPromise>
                        </div>}
                </div>
            </div>
            {props.sectionControl}
        </div>;
    }
}

const mapStateToProps = (state: GlobalState) => {
    return {
        isDrawerOpen: isDrawerOpen(state)
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        toggleDrawer: () => dispatch(toggleDrawer())
    };
};

const SectionTitle = connect<{}, DispatchProps, SectionTitleProps>(
    mapStateToProps,
    mapDispatchToProps
)(SectionTitleInternal);

export default SectionTitle;