import * as React from "react";
import {SFC} from "react";
import {ActivityLogElement} from "client/resources/taskDetailsResource";
import TaskLogLines from "components/TaskLogLines/TaskLogLines";
import {Section} from "components/Section/Section";
import {NavigationButton, NavigationButtonType} from "components/Button/NavigationButton";
import {Callout, CalloutType} from "components/Callout/Callout";
import HALogWarning from "./HALogWarning";
import routeLinks from "routeLinks";
import { noOp } from "utils/noOp";

interface ServerLogsProps {
    logs: ActivityLogElement[];
    isHA: boolean;
}

const ServerLogs: SFC<ServerLogsProps> =
    props => {
        const logs = props.logs;
        return <div>
            <Section sectionHeader="Server Logs">
                <p>
                    The entries below are the most recent warning and error-level events recorded on this
                    Octopus Server node in this session.
                </p>
                <HALogWarning isHA={props.isHA} />
                {logs && logs.length === 0 && <Callout type={CalloutType.Information} title={"No events found"}>
                    No warning or error-level events have been collected in this session.
                </Callout>}
            </Section>
            <Section>
                <TaskLogLines lines={logs} showAdditional={noOp}/>
                <NavigationButton label="View detailed log" href={routeLinks.configuration.diagnostics.logs.root} type={NavigationButtonType.Ternary} />
            </Section>
        </div>;
    };

ServerLogs.displayName = "ServerLogs";

export default ServerLogs;
