import * as React from "react";
import { useReducer } from "react";
import StyleGuideEntry from "../StyleGuideEntry";
import ActionButton from "components/Button";
import ConfirmationDialog from "components/Dialog/ConfirmationDialog";
import NoActionDialog from "components/Dialog/NoActionDialog";
import { dialogActions, isDialogOpen, DialogState, dialogReducer } from "./reducer";
import { sleep1s } from "../helpers/sleep";

const INITIAL_STATE: DialogState = {};

const DialogInlineExample: React.FC = () => {
    const [state, dispatch] = useReducer(dialogReducer, INITIAL_STATE);
    enum KnownDialogs {
        ShowConfirmationDialog = "showConfirmationDialog",
        ShowNoActionDialog = "showNoActionDialog"
    }

    return (
        <React.Fragment>
            <ActionButton
                label="Do some process that may need confirmation"
                onClick={() => dispatch(dialogActions.open(KnownDialogs.ShowConfirmationDialog))}
            />
            <ConfirmationDialog
                title="Confirm Something"
                continueButtonLabel="Continue"
                continueButtonBusyLabel="Saving..."
                open={isDialogOpen(KnownDialogs.ShowConfirmationDialog)(state)}
                onClose={() => dispatch(dialogActions.close(KnownDialogs.ShowConfirmationDialog))}
                onContinueClick={() => sleep1s()}>
                Are you sure?
            </ConfirmationDialog>
            <ActionButton
                label="Do some process that can't continue"
                onClick={() => {
                    dispatch(dialogActions.open(KnownDialogs.ShowNoActionDialog));
                }}
            />

            <NoActionDialog
                title="Stop! Hammertime"
                open={isDialogOpen(KnownDialogs.ShowNoActionDialog)(state)}
                onCloseClick={() => dispatch(dialogActions.close(KnownDialogs.ShowNoActionDialog))}>
                Sorry some sort of precondition has not been met to continue with this action.
            </NoActionDialog>
        </React.Fragment>
    );
};

const DialogsInlineEntry = () => {
    return (
        <StyleGuideEntry heading="Dialogs - Inline" context={<div>
            Inline dialogs are used when none of the criteria for standard dialogs are met. The dialog takes its contents
            completely from the parent's state and does not mutate any state directly. If an action is to be
            taken, the parent registers a callback.
            <span>
                The dialog is opened via the state on the parent and the contents of the dialog are set inside the
                parent's render method. See <code>ConfigureLetsEncrypt</code> for some examples of these dialogs.
            </span>
        </div>}
        >
            <DialogInlineExample/>
        </StyleGuideEntry>
    );
};

export default DialogsInlineEntry;
