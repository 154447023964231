import * as React from "react";
import * as cn from "classnames";
const styles = require("./style.less");

export enum TransitionAnimationType {
    Fade,
}

interface TransitionAnimationProps {
    animationType?: TransitionAnimationType;
    className?: any;
    disableAnimation?: boolean;
}

interface TransitionAnimationState {
    isLoaded: boolean;
}

class TransitionAnimation extends React.Component<TransitionAnimationProps, TransitionAnimationState> {
    constructor(props: TransitionAnimationProps) {
        super(props);
        this.state = { isLoaded: false };
    }

    componentDidMount() {
        this.setState({ isLoaded: true });
    }

    render() {
        return <div className={cn(!this.props.disableAnimation &&
            (this.state.isLoaded
                ? styles.fadeIn
                : styles.notVisible), styles.container, this.props.className)}>
            {this.props.children}
        </div>;
    }
}

export default TransitionAnimation;