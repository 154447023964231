import React = require("react");
import { DefaultTopics } from "./DefaultViews";
import { OverviewContainer, OverviewHighlightText, TermsText, CommonNeedHelpInformation } from "./CommonViews";
import { Note } from "components/form";
import CustomDialog from "components/Dialog/CustomDialog";
import { FundamentalsGuideDialogLayout } from "components/GettingStarted/FundamentalsGuide/FundamentalsGuideDialogLayout";

interface CommonDashboardOverviewState {
    showFundamentals: boolean;
}

export class CommonDashboardOverview extends React.Component<{}, CommonDashboardOverviewState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            showFundamentals: false,
        };
    }

    render() {
        return <OverviewContainer>
            <CustomDialog open={this.state.showFundamentals} close={this.closeFundamentalsDialog} render={(renderProps) => (
                <FundamentalsGuideDialogLayout {...renderProps} />
            )} />

            <OverviewHighlightText>
                <p>The <b>dashboard</b> is where you can see what's happening with your deployments. Use the 'Configure' button to include only the projects or environments you're interested in.</p>
                <TermsText>
                    <Note heading={"New to Octopus?"}>
                        Take a look at the <a href="#" onClick={this.showFundamentalsDialog}>fundamentals</a> of deploying your first application.
                    </Note>
                    <Note heading={"Need Help?"}>
                        <CommonNeedHelpInformation />
                    </Note>
                </TermsText>
            </OverviewHighlightText>
        </OverviewContainer>;
    }

    private showFundamentalsDialog = (e: any) => {
        e.preventDefault();
        this.setState({ showFundamentals: true });
    }

    private closeFundamentalsDialog = () => {
        this.setState({ showFundamentals: false });
    }
}

export const CommonDashboardTopics: React.StatelessComponent<{}> = props => {
    return <DefaultTopics />;
};