import * as React from "react";
import { commandLinePackagingInstruction } from "../../Instructions";
import {
    CommonCommandLineTextForOctoExe,
    CommonCommandLineTextForOctoExeCodeSample,
    CommonCommandLineTextForOctoPack,
} from "../CommonRegistrationText";

export const CommandLinePackaging: React.SFC<{}> = () => (
    <div>
        <p>
            When packaging .NET applications from command line, we recommend using <i>OctoPack</i> or <i>Octo.exe</i>.
        </p>
        {CommonCommandLineTextForOctoPack}
        {CommonCommandLineTextForOctoExe}
        {CommonCommandLineTextForOctoExeCodeSample}
    </div>
);

export default commandLinePackagingInstruction(() => <CommandLinePackaging/>);