
import GlobalState from "globalState";
import { combineReducers, Reducer } from "redux";
import infrastructureArea from "areas/infrastructure/reducers/infrastructureArea";
import libraryArea from "areas/library/reducers/libraryArea";
import projectsArea from "areas/projects/reducers/projectsArea";
import unhandledError from "components/UnhandledError/reducers";
import { tenantsArea } from "../areas/tenants/tenantsArea";
import expanders from "components/form/Sections/reducers/expanders";
import dialogs from "components/Dialog/reducers/dialog";
import { notificationReducer } from "../components/Navbar/notifications.redux";
import { configurationArea } from "areas/configuration/reducers/configurationArea";
import formPaperLayout from "../components/FormPaperLayout/reducers";
import overlayArea from "components/OverlaySteps/reducers/overlayArea";
import onboardingArea from "components/GettingStarted/reducers/onboardingArea";
import pageLayoutArea from "components/PageLayout/reducers/pageLayoutArea";
import drawerArea from "components/Drawer/reducers";
import themes from "components/Theme/reducers";

interface Action {
    type: string;
}

const reducers: Reducer<GlobalState> = combineReducers({
    libraryArea,
    infrastructureArea,
    projectsArea,
    tenantsArea,
    configurationArea,
    expanders,
    dialogs,
    notifications: notificationReducer,
    formPaperLayout,
    unhandledError,
    overlayArea,
    onboardingArea,
    pageLayoutArea,
    drawerArea,
    themes
});

export default reducers;

export { reducers, Action };