import * as React from "react";
import { ErrorInfo } from "react";
import Logger from "client/logger";
import ErrorPanel from "components/ErrorPanel/ErrorPanel";
import { client } from "../../clientInstance";
import * as StackTrace from "stacktrace-js";
import StackFrame = StackTrace.StackFrame;

interface ErrorBoundaryState {
    error?: {
        originalError: Error,
        info: ErrorInfo
        mappedStackTrace?: string
    };
}

interface ErrorBoundProps {
    children: any;
}

export default class ErrorBoundary extends React.Component<ErrorBoundProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundProps) {
        super(props);
        this.state = {};
    }

    async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        Logger.error(error);
        Logger.error(errorInfo);
        this.setState({ error: { originalError: error, info: errorInfo } });
        await this.mapToOriginalSourceCode(error);
    }

    render() {
        if (this.state.error) {
            const serverInfo = client.tryGetServerInformation();
            const version = serverInfo ? serverInfo.version : undefined;
            return <ErrorPanel
                message={`An unexpected error occurred in Octopus v${version}: "${this.state.error.originalError.name}: ${this.state.error.originalError.message}"`}
                details={this.state.error.info.componentStack.split("\n").slice(1)}
                fullException={this.state.error.mappedStackTrace || this.state.error.originalError.stack} />;
        }
        return this.props.children;
    }

    private async mapToOriginalSourceCode(error: Error) {
        const frames: StackFrame[] = await StackTrace.fromError(error);
        const mappedStackTrace = error.stack.split("\n")[0] + "\n\n" + frames.map(this.stringify).join("\n");
        this.setState(prevState => ({ ...prevState, error: { ...prevState.error, mappedStackTrace } }));
    }

    private stringify = (frame: StackFrame) => {
        const normalizedFrame = { ...frame, fileName: this.normalizeFileName(frame.fileName) };
        return `${normalizedFrame.functionName} (${normalizedFrame.fileName}:${normalizedFrame.lineNumber}:${normalizedFrame.columnNumber})`;
    }

    private normalizeFileName(fileName: string) {
        const index = Math.max(fileName.indexOf("/app"), fileName.indexOf("/node_modules"));
        if (index === -1) {
            return fileName;
        }

        return fileName.substr(index);
    }
}