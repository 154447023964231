import * as React from "react";
import { ActionButton, ActionButtonType } from "components/Button/ActionButton";
import {
    DialogLayoutDispatchProps,
    DialogLayout, DialogLayoutCommonProps
} from "components/DialogLayout/DialogLayout";
import { DialogLayoutConnect } from "components/Dialog/DialogLayoutConnect";
import { isAllowed, PermissionCheckProps } from "../PermissionCheck/PermissionCheck";
import FormComponent from "../FormComponent/FormComponent";
const styles = require("./style.less");

interface WizardDialogLayoutProps extends DialogLayoutCommonProps, DialogLayoutDispatchProps {
    canSaveBeforeLastPage?: boolean;
    saveButtonDisabled?: boolean;
    savePermission?: PermissionCheckProps;
    busyButtonLabel?: string;
    onPageChanged(): void; // Warning: Make sure you mutate something in your component's state, inside a doBusyTask to trigger the dialog re-render (see example in AddProject.tsx). TODO: See if there's a nicer way of dealing with this hack.
    onSaveClick(): Promise<boolean>;
}

interface WizardDialogState {
    currentPageIndex: number;
}

class WizardDialogLayoutInternal extends React.Component<WizardDialogLayoutProps & DialogLayoutDispatchProps, WizardDialogState> {
    constructor(props: WizardDialogLayoutProps & DialogLayoutDispatchProps) {
        super(props);
        this.state = {
            currentPageIndex: 0,
        };
    }

    saveClick = async () => {
        const result = await this.props.onSaveClick();
        if (result) {
            this.props.close();
        }
    }

    submitForm = async () => {
        if (!this.isSaveButtonDisabled()) {
            await this.saveClick();
        }
    }

    render() {

        const {
            children,
            ...other
        } = this.props;

        const save = <ActionButton key="Save"
            type={ActionButtonType.Save}
            label="Save"
            busyLabel={this.props.busyButtonLabel || "Saving..."}
            onClick={this.saveClick}
            disabled={this.isSaveButtonDisabled()} />;
        const cancel = <ActionButton key="Cancel"
            label="Cancel"
            disabled={this.props.busy}
            onClick={() => this.props.close()} />;

        let actions = [cancel, save];
        if (React.Children.count(children) > 1) {
            const prev = <ActionButton key="Previous"
                label="Previous"
                disabled={this.props.busy || !this.canPrevious()}
                onClick={() => this.previous()} />;

            const next = <ActionButton key="Next"
                label="Next"
                disabled={this.props.busy || !this.canNext()}
                onClick={() => this.next()} />;

            actions = [cancel, prev, next, save];
        }

        const child = React.Children.toArray(children)[this.state.currentPageIndex];

        return <DialogLayout actions={actions} {...other} closeDialog={this.props.close}>
            <FormComponent onFormSubmit={this.submitForm} className={styles.dialogWrapper}>
                {child}
            </FormComponent>
        </DialogLayout>;
    }

    private isSaveButtonDisabled(): boolean {
        const disabledDueToPermission = !!this.props.savePermission ? !isAllowed(this.props.savePermission) : false;
        return !!(this.props.saveButtonDisabled || disabledDueToPermission || this.props.busy || (!this.props.canSaveBeforeLastPage && this.canNext()));
    }

    private canPrevious = () => {
        return this.state.currentPageIndex > 0;
    }

    private previous = () => {
        if (this.canPrevious()) {
            this.setState({
                currentPageIndex: this.state.currentPageIndex - 1,
            }, () => {
                if (this.props.onPageChanged) {
                    this.props.onPageChanged();
                }
            });
        }
    }

    private canNext = () => {
        return this.state.currentPageIndex < React.Children.count(this.props.children) - 1;
    }

    private next = () => {
        if (this.canNext()) {
            this.setState({
                currentPageIndex: this.state.currentPageIndex + 1,
            }, () => {
                if (this.props.onPageChanged) {
                    this.props.onPageChanged();
                }
            });
        }
    }
}

const WizardDialogLayout = DialogLayoutConnect.to(WizardDialogLayoutInternal);
WizardDialogLayout.displayName = "WizardDialogLayout";
export default WizardDialogLayout;
