import React = require("react");
import { Note } from "components/form";
import ExternalLink from "components/Navigation/ExternalLink";
const styles = require("./Registrations.less");
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
import { TopicsContainer, LearnMoreResources } from "./CommonViews";

export const CommonConfigurationUserOverview: React.StatelessComponent<{}> = props => {
    return <OverviewContainer>
        <OverviewHighlightText>
            <p>Manage permissions by assigning <ExternalLink href="ManagingUsersAndTeams">users</ExternalLink> to teams that are accessible in one space or all spaces (system team).</p>
        </OverviewHighlightText>
    </OverviewContainer>;
};

export const CommonConfigurationUserTopics: React.StatelessComponent<{}> = props => {
    return <TopicsContainer>
        <LearnMoreResources>
            <div><ExternalLink href="ManagingUsersAndTeams">Users and Teams</ExternalLink></div>
        </LearnMoreResources>
    </TopicsContainer>;
};