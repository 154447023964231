import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {SpaceRouteParams} from "../Navbar/SpacesMenu";
import {repository, session} from "../../clientInstance";
import {SpaceResource} from "../../client/resources";
import store from "store";
import {configurationActions} from "areas/configuration/reducers/configurationArea";
import routeLinks from "routeLinks";
import InternalRedirect from "../Navigation/InternalRedirect";
import {connect, MapStateToProps} from "react-redux";
import {Action, Dispatch} from "redux";
import GlobalState from "../../globalState";

export interface SpaceNotFoundContext {
    // If you have access to zero space, they we don't need to prompt users to enter a space context,
    // and they can continue to navigate around because they are already in the "system" context
    isAlsoInSystemContext: boolean;
    missingSpaceId: string; // This id of the space which could not be found
}

type SystemContext = "system";

export type SpaceContext = SpaceResource | SystemContext | SpaceNotFoundContext;

export function isSpecificSpaceContext(spaceContext: SpaceContext): spaceContext is SpaceResource {
    return !!(spaceContext as SpaceResource).Id;
}

export function isSpaceNotFound(spaceContext: SpaceContext): spaceContext is SpaceNotFoundContext {
    return spaceContext !== "system" && !isSpecificSpaceContext(spaceContext);
}

interface SpaceLoaderState {
    redirectTo: string | null;
    currentSpaceContext?: SpaceContext;
}

interface DispatchProps {
    onUserAccessibleSpacesLoaded(spaces: SpaceResource[]): void;
}

interface ReduxStateProps {
    spaces: SpaceResource[] | null; // null indicates that the spaces haven't yet been loaded
}

interface ProvidedProps {
    render(spaceContext: SpaceContext): React.ReactNode;
}

type PropsExceptReduxProps = ProvidedProps & RouteComponentProps<SpaceRouteParams>;

type SpaceLoaderProps = PropsExceptReduxProps & DispatchProps & ReduxStateProps;

class SpaceLoader extends React.Component<SpaceLoaderProps, SpaceLoaderState> {
    constructor(props: SpaceLoaderProps) {
        super(props);
        this.state = {
            redirectTo: null
        };
    }

    async componentWillReceiveProps(nextProps: Readonly<SpaceLoaderProps>, nextContext: any) {
        if (nextProps.spaces && nextProps.spaces !== this.props.spaces) {
            await this.switchToSpace(nextProps.spaces, nextProps.match.params.spaceId, nextProps.location.pathname);
        }
    }

    async componentDidMount() {
        repository.Spaces.subscribeToDataModifications(this.constructor.name, this.loadSpaces);
        return this.loadSpaces();
    }

    componentWillUnmount() {
        repository.Spaces.unsubscribeFromDataModifications(this.constructor.name);
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }

        return this.state.currentSpaceContext ? this.props.render(this.state.currentSpaceContext) : null;
    }

    private loadSpaces = async () => {
        try {
            const spaces = await repository.Users.getSpaces(session.currentUser);
            // This updates redux, which makes this component update as part of componentWillReceiveProps
            this.props.onUserAccessibleSpacesLoaded(spaces);
        } catch (e) {
            console.error(e);
        }
    }

    private async switchToSpace(spaces: SpaceResource[], spaceId: string, pathname: string) {
        try {
            if (spaceId) {
                await this.stepIntoSelectedSpaceId(spaces, spaceId);
            } else {
                const automaticallySelectedSpace = spaces.find(s => s.IsDefault) || spaces.find(() => true); // default to the first space returned from the API
                if (automaticallySelectedSpace) {
                    this.redirectToSpace(automaticallySelectedSpace, pathname);
                } else if (this.state.currentSpaceContext !== "system") {
                    await this.stepIntoNoSpaceSelectedContext();
                    this.setState({ currentSpaceContext: "system" });
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    private redirectToSpace(space: SpaceResource, pathname: string) {
        const redirectTo = routeLinks.space(space.Id) + pathname;
        this.setState({redirectTo});
    }

    private async stepIntoSelectedSpaceId(spaces: SpaceResource[], spaceId: string) {
        const selectedSpace = spaces.find(s => s.Id === spaceId);

        if (selectedSpace) {
            const alreadyInTheSelectedSpace = this.state.currentSpaceContext
                && isSpecificSpaceContext(this.state.currentSpaceContext)
                && selectedSpace.Id === this.state.currentSpaceContext.Id;
            if (!alreadyInTheSelectedSpace) {
                await this.stepIntoSpace(selectedSpace);
            }
        } else {
            await this.stepIntoNoSpaceSelectedContext();
            const isAlsoInSystemContext = spaces.length === 0;
            this.setState({currentSpaceContext: {isAlsoInSystemContext, missingSpaceId: spaceId}});
        }
    }

    private async stepIntoNoSpaceSelectedContext() {
        repository.switchToSystem();
        await this.refreshPermissions();
    }

    private async stepIntoSpace(space: SpaceResource) {
        await repository.switchToSpace(space.Id);

        const refreshPermissions = this.refreshPermissions();

        const status = await repository.Tenants.status();
        store.dispatch(configurationActions.spaceMultiTenancyStatusFetched(status));
        await refreshPermissions;

        this.setState({ currentSpaceContext: space });
    }

    private async refreshPermissions() {
        const permissionSet = await repository.UserPermissions.getAllPermissions(session.currentUser, true);
        session.refreshPermissions(permissionSet);
    }
}

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>): DispatchProps => {
    return {
        onUserAccessibleSpacesLoaded: (spaces: SpaceResource[]) => {
            dispatch(configurationActions.userAccessibleSpacesFetched(spaces));
        }
    };
};

const mapStateToProps: MapStateToProps<ReduxStateProps, PropsExceptReduxProps, GlobalState> = (state) => {
    return {
        spaces: state.configurationArea.spaces ? state.configurationArea.spaces.usersAccessibleSpaces : null
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpaceLoader));