import * as React from "react";
import BusyIndicator from "components/BusyIndicator/BusyIndicator";
import BusyFromPromise, { BusyState } from "components/BusyFromPromise/BusyFromPromise";
import ErrorPanel from "components/ErrorPanel/ErrorPanel";
import { Errors } from "components/DataBaseComponent/DataBaseComponent";
import KeyboardHandler, { Key } from "../KeyboardHandler/KeyboardHandler";
import { DialogContent, DialogActions, DialogTitle } from "@material-ui/core";
import * as cn from "classnames";

const styles = require("./style.less");

export interface DialogLayoutDispatchProps {
    close: () => void;
}

export interface DialogLayoutCommonProps {
    title: string;
    titleIcon?: React.ReactNode;
    extraHeaderContent?: (alignmentClassName: string) => React.ReactNode;
    additionalActions?: React.ReactNode;
    secondaryActions?: React.ReactNode;
    errors: Errors;
    busy: BusyState;
    headerClassName?: string;
}

interface DialogLayoutProps extends DialogLayoutCommonProps {
    actions: React.ReactNode;
    closeDialog(): void;
}

interface DialogLayoutState {
    dialogResizeKey: string;
}

export class DialogLayout extends React.Component<DialogLayoutProps, DialogLayoutState> {

    constructor(props: DialogLayoutProps) {
        super(props);
        this.state = {
            dialogResizeKey: new Date().getDate().toString(),
        };
    }

    render() {
        const keyboardRegistrations = [{ key: Key.Esc, onKeyPressed: this.onEscKeyPressed }];
        return <KeyboardHandler key={this.state.dialogResizeKey}
            registrations={keyboardRegistrations}
            className={styles.dialogLayout}
        >
            <DialogTitle className={cn(this.props.headerClassName ? this.props.headerClassName : styles.headerContainer)}>
                <BusyFromPromise promise={this.props.busy}>
                    {(busy: boolean) => <BusyIndicator show={busy} />}
                </BusyFromPromise>
                <div className={styles.title}>
                    {this.props.titleIcon && <div className={styles.titleIcon}>{this.props.titleIcon}</div>}
                    {this.props.title}
                </div>
                {this.props.extraHeaderContent && this.props.extraHeaderContent(styles.headerContentAlignment)}
            </DialogTitle>
            <DialogContent className={styles.body}>
                {this.props.errors && <ErrorPanel message={this.props.errors.message} details={this.props.errors.details} />}
                {this.props.children}
            </DialogContent>
            <DialogActions className={styles.actions}>
                <div className={styles.secondaryActions}>
                    {this.props.additionalActions}
                </div>
                <div className={styles.primaryActions}>
                    {this.props.actions}
                </div>
            </DialogActions>
        </KeyboardHandler>;
    }

    onEscKeyPressed = () => {
        if (this.props.closeDialog) {
            this.props.closeDialog();
            return true;
        }
        return false;
    }
}