import packagingRegistry from "./packagingRegistry";

import AspNetRegistration from "../Registrations/AspNet";
import AspNetCoreRegistration from "../Registrations/AspNetCore";
import AspNetOtherRegistration from "../Registrations/AspNetOther";
import JavaRegistration from "../Registrations/Java";
import NodeJsRegistration from "../Registrations/NodeJs";
import OtherRegistration from "../Registrations/Other";

packagingRegistry.registerPackaging(AspNetRegistration);
packagingRegistry.registerPackaging(AspNetCoreRegistration);
packagingRegistry.registerPackaging(AspNetOtherRegistration);
packagingRegistry.registerPackaging(JavaRegistration);
packagingRegistry.registerPackaging(NodeJsRegistration);
packagingRegistry.registerPackaging(OtherRegistration);