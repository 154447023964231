import * as React from "react";
import FormPaperLayout from "components/FormPaperLayout/index";
import EnvironmentMultiSelect from "components/MultiSelect/EnvironmentMultiSelect";
import ProjectMultiSelect from "components/MultiSelect/ProjectMultiSelect";
import { BooleanRadioButtonGroup as RadioButtonGroup } from "components/form";
import RadioButton from "components/form/RadioButton/RadioButton";
import {ExpandableFormSection, Summary, SummaryNode} from "components/form";
import { repository } from "clientInstance";
import {
    DashboardConfigurationResource,
    ProjectGroupResource,
    ProjectResource,
    EnvironmentResource,
    TenantResource,
} from "client/resources";
import Text from "components/form/Text/Text";
import TenantTagMultiSelect from "components/MultiSelect/TenantTagMultiSelect";
import { TenantMultiSelect } from "components/MultiSelect";
import { environmentChipList, tenantChipList, projectChipList, projectGroupChipList } from "components/Chips";
import TenantTagsList from "components/TenantTagsList/TenantTagsList";
import FormBaseComponent, { OptionalFormBaseComponentState } from "../../../components/FormBaseComponent/FormBaseComponent";
import Permission from "client/resources/permission";
const styles = require("./style.less");
import { FeatureToggle, Feature } from "components/FeatureToggle";
import { PermissionCheck } from "components/PermissionCheck";
import {ProjectGroupMultiSelect} from "../../../components/MultiSelect/ProjectGroupMultiSelect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";

interface DashboardConfigurationState extends OptionalFormBaseComponentState<DashboardConfigurationModel> {
    projectGroups: ProjectGroupResource[];
    projects: ProjectResource[];
    environments: EnvironmentResource[];
    tenants: TenantResource[];
}

interface DashboardConfigurationModel extends DashboardConfigurationResource {
    includeAllProjectGroups: boolean;
    includeAllProjects: boolean;
    includeAllEnvironments: boolean;
    includeAllTenants: boolean;
}

export const DefaultProjectLimit = "200";
export default class DashboardConfiguration extends FormBaseComponent<any, DashboardConfigurationState, DashboardConfigurationModel> {

    constructor(props: any) {
        super(props);

        this.state = {
            projectGroups: [],
            projects: [],
            environments: [],
            tenants: [],
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const [projectGroups, projects, environments, tenants, dashboardConfiguration] = await Promise.all([
                repository.ProjectGroups.all(),
                repository.Projects.all(),
                repository.Environments.all(),
                repository.Tenants.all(),
                repository.DashboardConfiguration.get()
            ]);

            this.setState({
                model: this.buildModel(dashboardConfiguration),
                cleanModel: this.buildModel(dashboardConfiguration),
                projectGroups,
                projects,
                environments,
                tenants,
            });
        });
    }

    buildModel(resource: DashboardConfigurationResource): DashboardConfigurationModel {
        return {
            ...resource,
            includeAllProjectGroups: !resource.IncludedProjectGroupIds.length,
            includeAllProjects: !resource.IncludedProjectIds.length,
            includeAllEnvironments: !resource.IncludedEnvironmentIds.length,
            includeAllTenants: !resource.IncludedTenantIds.length && !resource.IncludedTenantTags.length
        };
    }

    handleSaveClick = async () => {
        const { includeAllEnvironments, includeAllProjectGroups, includeAllProjects, includeAllTenants, ...dashboardConfig } = this.state.model;

        if (includeAllProjectGroups) {
            dashboardConfig.IncludedProjectGroupIds.length = 0;
        }

        if (includeAllProjects) {
            dashboardConfig.IncludedProjectIds.length = 0;
        }

        if (includeAllEnvironments) {
            dashboardConfig.IncludedEnvironmentIds.length = 0;
        }

        if (includeAllTenants) {
            dashboardConfig.IncludedTenantTags.length = 0;
            dashboardConfig.IncludedTenantIds.length = 0;
        }

        await this.doBusyTask(async () => {
            const resource = await repository.DashboardConfiguration.modify(dashboardConfig);
            this.setState(s => {
                return {
                    submitted: true,
                    model: this.buildModel(resource),
                    cleanModel: this.buildModel(resource)
                };
            });
        });
    }

    environmentsSummary(): SummaryNode {
        return this.state.model.IncludedEnvironmentIds && this.state.model.IncludedEnvironmentIds.length
            ? Summary.summary(<div>Only show {environmentChipList(this.state.environments, this.state.model.IncludedEnvironmentIds)}</div>)
            : Summary.default("All environments are shown");
    }

    projectGroupsSummary(): SummaryNode {
        return this.state.model.IncludedProjectGroupIds && this.state.model.IncludedProjectGroupIds.length
            ? Summary.summary(<div>Only show {projectGroupChipList(this.state.projectGroups, this.state.model.IncludedProjectGroupIds)}</div>)
            : Summary.default("All project groups are shown");
    }

    projectsSummary(): SummaryNode {
        return this.state.model.IncludedProjectIds && this.state.model.IncludedProjectIds.length
            ? Summary.summary(<div>Only show {projectChipList(this.state.projects, this.state.model.IncludedProjectIds)}</div>)
            : Summary.default("All projects are shown");
    }

    maximumProjectSummary(): SummaryNode {
        const limit = this.state.model.ProjectLimit || DefaultProjectLimit;
        const wrapper = this.state.model.ProjectLimit ? Summary.summary : Summary.default;
        return wrapper(`Only ${limit} project${limit === 1 ? "" : "s"} will be shown (plus any with failing deployments).`);
    }

    tenantSummary(): SummaryNode {
        const tenants = this.state.model.IncludedTenantIds || [];
        const tags = this.state.model.IncludedTenantTags || [];
        if (tenants.length === 0 && tags.length === 0) {
            return Summary.default("All tenants are included");
        }

        if (tenants.length === 0) {
            return Summary.summary(<span>Only show deployments for tenants with <TenantTagsList tags={tags} /> tags</span>);
        }

        return Summary.summary(<span>Only show deployments for {tenantChipList(this.state.tenants, tenants)}
            {tags.length === 0 || <span> or any tenants with <TenantTagsList tags={tags} /> tags</span>}
        </span>);
    }

    render() {
        return <FormPaperLayout
            title="Configure"
            className={styles.paper}
            busy={this.state.busy}
            errors={this.state.errors}
            model={this.state.model}
            cleanModel={this.state.cleanModel}
            onSaveClick={this.handleSaveClick}>
            {this.state.model && <TransitionAnimation>
                <ExpandableFormSection
                    errorKey="ProjectLimit"
                    title="Maximum Projects"
                    focusOnExpandAll
                    summary={this.maximumProjectSummary()}
                    help="The maximum number of projects to display on the dashboard.">
                    <Text value={this.convertToString(this.state.model.ProjectLimit)}
                        min={1}
                        type="number"
                        hintText={DefaultProjectLimit}
                        onChange={projectLimit => this.setModelState({ProjectLimit: this.parseToInt(projectLimit)})}
                        autoFocus={true}
                    />
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey="includedProjectGroupIds"
                    title="Project Groups"
                    summary={this.projectGroupsSummary()}
                    help="Filter the dashboard to only show a subset of your project groups.">
                    <RadioButtonGroup onChange={includeAllProjectGroups => this.setModelState({includeAllProjectGroups})}
                        value={this.state.model.includeAllProjectGroups}>
                        <RadioButton value={true} label="All project groups" isDefault={true} />
                        <RadioButton value={false} label="Only selected project groups" />
                    </RadioButtonGroup>
                    {this.state.model.includeAllProjectGroups ||
                        <ProjectGroupMultiSelect
                            onChange={IncludedProjectGroupIds => this.setModelState({IncludedProjectGroupIds})}
                            value={this.state.model.IncludedProjectGroupIds}
                            items={this.state.projectGroups}
                        />
                    }
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey="includedProjectIds"
                    title="Projects"
                    summary={this.projectsSummary()}
                    help="Filter the dashboard to only show a subset of your projects.">
                    <RadioButtonGroup onChange={includeAllProjects => this.setModelState({includeAllProjects})}
                        value={this.state.model.includeAllProjects}>
                        <RadioButton value={true} label="All projects" isDefault={true} />
                        <RadioButton value={false} label="Only selected projects" />
                    </RadioButtonGroup>
                    {this.state.model.includeAllProjects ||
                        <ProjectMultiSelect
                            onChange={IncludedProjectIds => this.setModelState({IncludedProjectIds})}
                            value={this.state.model.IncludedProjectIds}
                            items={this.state.projects}
                        />
                    }
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey="includedEnvironmentIds"
                    title="Environments"
                    summary={this.environmentsSummary()}
                    help="Filter the dashboard to only show a subset of your environments.">
                    <RadioButtonGroup onChange={includeAllEnvironments => this.setModelState({includeAllEnvironments})}
                        value={this.state.model.includeAllEnvironments}>
                        <RadioButton value={true} label="All environments" isDefault={true} />
                        <RadioButton value={false} label="Only selected environments" />
                    </RadioButtonGroup>
                    {this.state.model.includeAllEnvironments ||
                        <EnvironmentMultiSelect
                            items={this.state.environments}
                            onChange={IncludedEnvironmentIds => this.setModelState({IncludedEnvironmentIds})}
                            value={this.state.model.IncludedEnvironmentIds} />
                    }
                </ExpandableFormSection>

                <FeatureToggle feature={Feature.MultiTenancy}>
                    <PermissionCheck permission={Permission.TenantView} tenant="*">
                        <ExpandableFormSection
                            errorKey="Tenants"
                            title="Tenants"
                            summary={this.tenantSummary()}
                            help="Filter the dashboard to only show releases for specific tenants.">
                            <RadioButtonGroup onChange={includeAllTenants => this.setModelState({includeAllTenants})}
                                value={this.state.model.includeAllTenants}>
                                <RadioButton value={true} label="All tenants" isDefault={true} />
                                <RadioButton value={false} label="Only selected tenants" />
                            </RadioButtonGroup>
                            {this.state.model.includeAllTenants ||
                                <div>
                                    <TenantMultiSelect value={this.state.model.IncludedTenantIds}
                                        items={this.state.tenants}
                                        onChange={IncludedTenantIds => this.setModelState({IncludedTenantIds})} />
                                    <TenantTagMultiSelect value={this.state.model.IncludedTenantTags}
                                                        doBusyTask={this.doBusyTask}
                                        onChange={IncludedTenantTags => this.setModelState({IncludedTenantTags})} />
                                </div>
                            }
                        </ExpandableFormSection>
                    </PermissionCheck>
                </FeatureToggle>
            </TransitionAnimation>}
        </FormPaperLayout>;
    }

    private convertToString(value?: number) {
        return value ? value.toString() : "";
    }

    private parseToInt(value?: string) {
        return value ? parseInt(value, 10) : null;
    }
}