import * as React from "react";
import BaseComponent from "components/BaseComponent";
import { repository } from "clientInstance";
import { CertificateResource, ResourceCollection, TagSetResource, EnvironmentResource, TenantResource } from "client/resources";
import List from "components/List";
import CertificateSummary from "./CertificateSummary";
import routeLinks from "../../../routeLinks";
import { xor, uniqBy, values } from "lodash";

interface CertificateListProp {
    certificates?: ResourceCollection<CertificateResource>;
    selectedCertificateId?: string;
    tenantId?: string;
    doBusyTask: (action: () => Promise<any>) => Promise<boolean>;
    onSelected?: (certificate: CertificateResource) => void;
    archived?: boolean;
}

interface CertificateListState {
    certificates: ResourceCollection<CertificateResource>;
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    tenantTags: TagSetResource[];
    selectedId: string;
    busy: boolean;
}

class CertificateList extends List<CertificateResource> {}

export default class CertificateSearch extends BaseComponent<CertificateListProp, CertificateListState> {

    constructor(props: CertificateListProp) {
        super(props);
        this.state = {
            certificates: this.props.certificates,
            environments: [],
            tenants: [],
            tenantTags: [],
            busy: false,
            selectedId: this.props.selectedCertificateId
        };
    }

    async componentDidMount() {
        await this.props.doBusyTask(async () => {

            let certificates = this.state.certificates;
            if (!certificates) {
                certificates = await repository.Certificates.list({ tenant: this.props.tenantId });
            }

            const [tenantTags] = await Promise.all([
                repository.TagSets.all()]);

            this.setState({
                certificates,
                tenantTags
            });
        });
    }

    buildRow = (certificate: CertificateResource) => {
        return [
            <CertificateSummary
                onClick={() => {
                    if (!this.props.onSelected) {
                        return;
                    }
                    this.setState({ selectedId: certificate.Id }, () => {
                        this.props.onSelected(certificate);
                    });
                }}
                showSelection={this.props.onSelected ? true : false}
                selected={certificate.Id === this.state.selectedId}
                key={certificate.Id}
                certificate={certificate}
                environments={values(this.state.environments)}
                tenants={values(this.state.tenants)}
                tenantTags={this.state.tenantTags} />
        ];
    }

    rowClicked = (certificate: CertificateResource) => {
        if (this.props.onSelected) {
            return null;
        } else {
            return routeLinks.library.certificate(certificate.Id);
        }
    }

    applyFilter(filter: string, resource: CertificateResource) {
        return !filter || filter.length === 0 || !resource
            || (resource.Name && resource.Name.toLowerCase().includes(filter.toLowerCase()))
            || (resource.SubjectCommonName && resource.SubjectCommonName.toLowerCase().includes(filter.toLowerCase()))
            || (resource.Thumbprint && resource.Thumbprint.toLowerCase().includes(filter.toLowerCase()));
    }

    render() {
        const additionalRequestParams = new Map<string, any>();
        if (this.props.archived) {
            additionalRequestParams.set("archived", "true");
        }

        // Note: This is wrapped in a <div> on purpose for CSS transition animations.
        return <div>
            {this.state.certificates && <CertificateList initialData={this.state.certificates}
                onRow={this.buildRow}
                onRowRedirectUrl={this.rowClicked}
                onFilter={this.applyFilter}
                filterSearchEnabled={true}
                apiSearchParams={["partialName"]}
                filterHintText="Filter by name, subject, or thumbprint"
                onNewItems={this.loadRelatedCertificateData}
                additionalRequestParams={additionalRequestParams}
            />}
        </div>;
    }

    private loadRelatedCertificateData = async (certificates: CertificateResource[]): Promise<CertificateResource[]> => {
        const environmentIds = xor(certificates.map(c => c.EnvironmentIds).reduce((list, ids) => list.concat(ids), []), this.state.environments.map(x => x.Id));
        const tenantIds = xor(certificates.map(c => c.TenantIds).reduce((list, ids) => list.concat(ids), []), this.state.tenants.map(x => x.Id));

        await this.props.doBusyTask(async () => {
            const [environments, tenants]  = await Promise.all<EnvironmentResource[], TenantResource[]>([
                repository.Environments.all({ ids: environmentIds }),
                repository.Tenants.all({ ids: tenantIds })
            ]);

            this.setState(x => ({
                ...x,
                environments: uniqBy([...x.environments, ...environments], env => env.Id),
                tenants: uniqBy([...x.tenants, ...tenants], tenant => tenant.Id)
            }));
        });

        return certificates;
    }
}
