import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonProjectChannelOverview, CommonProjectChannelTopics } from "../../CommonComponents/CommonProjectChannelViews";

export const ProjectChannelRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().channel,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectChannelOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectChannelTopics />;
    }
};
