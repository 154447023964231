import Resolver, {RouteArgs} from "./resolver";
import Logger from "./logger";
import Ajax from "./ajax";
import RootResource from "./resources/rootResource";
import { SpaceRootResource, OctopusError } from "./resources";
import Environment from "environment";

const apiLocation = "~/api";

export interface ClientConfiguration {
    serverEndpoint: string | null;
}

// The Octopus Client implements the low-level semantics of the Octopus Deploy REST API
class Client {
    public static Create(configuration: ClientConfiguration) {
        const endpoint = normalizeEndpoint(configuration.serverEndpoint);
        Logger.log("Creating Octopus client for endpoint: " + endpoint);

        const resolver = new Resolver(endpoint);
        return new Client(resolver, null, null, null);
    }

    private constructor(private readonly resolver: Resolver,
                        private rootDocument: RootResource | null,
                        public spaceId: string | null,
                        private spaceRootDocument: SpaceRootResource | null) {
        this.resolver = resolver;
        this.rootDocument = rootDocument;
        this.spaceRootDocument = spaceRootDocument;
    }

    resolve = (path: string, uriTemplateParameters?: RouteArgs) => this.resolver.resolve(path, uriTemplateParameters);

    connect(progressCallback: (message: string, error?: OctopusError) => void): Promise<void> {
        progressCallback("Checking your credentials. Please wait...");

        return new Promise((resolve, reject) => {
            if (this.rootDocument) {
                resolve();
                return;
            }

            const attempt = (success: any, fail: any) => {
                this.get(apiLocation)
                    .then((root: RootResource) => {
                        success(root);
                    }, fail);
            };

            const onSuccess = (root: RootResource) => {
                this.rootDocument = root;
                resolve();
            };

            let fails = 0;
            const onFail = (err: any) => {
                if (err.StatusCode !== 503 && fails < 20) {
                    fails++;
                }

                const timeout = fails === 20
                    ? 5000
                    : 1000;

                if ((err.StatusCode === 0 || err.StatusCode === 503) && fails < 20) {
                    if (err.StatusCode === 503) {
                        progressCallback("Octopus Server unavailable.", err);
                    } else if (err.StatusCode === 0) {
                        progressCallback("The Octopus Server does not appear to have started, trying again...", err);
                    }
                } else {
                    progressCallback("Unable to connect to the Octopus Server. Is your server online?", err);
                }
                setTimeout(() => {
                    attempt(onSuccess, onFail);
                }, timeout);
            };

            attempt(onSuccess, onFail);
        });
    }

    async forSpace(spaceId: string): Promise<Client> {
        const spaceRootResource = await this.get<SpaceRootResource>(this.rootDocument.Links["SpaceHome"], {spaceId});
        return new Client(this.resolver, this.rootDocument, spaceId, spaceRootResource);
    }

    forSystem(): Client {
        return new Client(this.resolver, this.rootDocument, null, null);
    }

    async switchToSpace(spaceId: string): Promise<void> {
        this.spaceId = spaceId;
        this.spaceRootDocument = await this.get<SpaceRootResource>(this.rootDocument.Links["SpaceHome"], {spaceId: this.spaceId});
    }

    switchToSystem(): void {
        this.spaceId = null;
        this.spaceRootDocument = null;
    }

    get<TResource>(path: string, args?: RouteArgs): Promise<TResource> {
        const url = this.resolver.resolve(path, this.getArgsWithSpaceId(args));
        return this.dispatchRequest("GET", url) as Promise<TResource>;
    }

    getRaw(path: string, args?: RouteArgs): Promise<string> {
        const url = this.resolver.resolve(path, args);

        return new Promise((resolve, reject) => {
            new Ajax({
                client: this,
                method: "GET",
                error: e => reject(e),
                url,
                raw: true,
                success: data => resolve(data)
            }).execute();
        });
    }

    post<TReturn>(path: string, resource?: any, args?: RouteArgs): Promise<TReturn> {
        const url = this.resolver.resolve(path, this.getArgsWithSpaceId(args));
        return this.dispatchRequest("POST", url, resource) as Promise<TReturn>;
    }

    create<TNewResource, TResource>(path: string, resource: TNewResource, args: RouteArgs): Promise<TResource> {
        const url = this.resolver.resolve(path, args);

        return new Promise((resolve, reject) => {
            this.dispatchRequest("POST", url, resource)
                .then((result: any) => {
                    this.dispatchRequest("GET", this.resolver.resolve(result.Links.Self))
                        .then((result2: TResource) => {
                            resolve(result2);
                        }, reject);
                }, reject);
        });
    }

    update<TResource>(path: string, resource: TResource, args?: RouteArgs): Promise<TResource> {
        const url = this.resolver.resolve(path, args);

        return new Promise((resolve, reject) => {
            this.dispatchRequest("PUT", url, resource)
                .then(() => {
                    this.dispatchRequest("GET", url)
                        .then((result2: TResource) => {
                            resolve(result2);
                        }, reject);
                }, reject);
        });
    }

    del(path: string, resource?: any, args?: RouteArgs) {
        const url = this.resolver.resolve(path, args);
        return this.dispatchRequest("DELETE", url, resource);
    }

    put<TResource>(path: string, resource?: TResource, args?: RouteArgs): Promise<TResource> {
        const url = this.resolver.resolve(path, this.getArgsWithSpaceId(args));
        return this.dispatchRequest("PUT", url, resource) as Promise<TResource>;
    }

    getAntiforgeryToken() {
        if (!this.isConnected()) {
            return null;
        }

        const installationId = this.getGlobalRootDocument().InstallationId;
        if (!installationId) {
            return null;
        }

        // If we have come this far we know we are on a version of Octopus Server which supports anti-forgery tokens
        const antiforgeryCookieName = "Octopus-Csrf-Token_" + installationId;
        const antiforgeryCookies = document.cookie.split(";").filter((c) => {
            return c.trim().indexOf(antiforgeryCookieName) === 0;
        }).map((c) => {
            return c.trim();
        });

        if (antiforgeryCookies && antiforgeryCookies.length === 1) {
            const antiforgeryToken = antiforgeryCookies[0].split("=")[1];
            return antiforgeryToken;
        } else {
            if (Environment.isInDevelopmentMode()) {
                return "FAKE TOKEN USED FOR DEVELOPMENT";
            }
            return null;
        }
    }

    resolveLinkTemplate(link: string, args: any) {
        return this.resolve(this.getLink(link), args);
    }

    getServerInformation() {
        if (!this.isConnected()) {
            throw new Error("The Octopus Client has not connected. THIS SHOULD NOT HAPPEN! Please notify support.");
        }
        return {
            version: this.rootDocument.Version,
            isEarlyAccessProgram: this.rootDocument.IsEarlyAccessProgram,
            versionHasLongTermSupport: this.rootDocument.HasLongTermSupport
        };
    }

    tryGetServerInformation() {
        return this.rootDocument ? {
            version: this.rootDocument.Version,
            isEarlyAccessProgram: this.rootDocument.IsEarlyAccessProgram
        } : null;
    }

    getLink(name: string): string {
        const spaceLinkExists = this.spaceRootDocument && this.spaceRootDocument.Links[name];
        return spaceLinkExists ? this.spaceRootDocument.Links[name] : this.rootDocument.Links[name];
    }

    private dispatchRequest(method: any, url: string, requestBody?: any) {
        return new Promise((resolve, reject) => {
            new Ajax({
                client: this,
                error: e => reject(e),
                method,
                url,
                requestBody,
                success: data => resolve(data),
            }).execute();
        });
    }

    private isConnected() {
        return this.rootDocument !== null;
    }

    private getArgsWithSpaceId(args: RouteArgs) {
        return this.spaceId ? {spaceId: this.spaceId, ...args} : args;
    }

    private getGlobalRootDocument() {
        if (!this.isConnected()) {
            throw new Error("The Octopus Client has not connected.");
        }

        return this.rootDocument;
    }
}

function normalizeEndpoint(endpoint: any) {
    if (endpoint == null || endpoint.length === 0) {
        endpoint = "" + window.location.protocol;
        if (!endpoint.endsWith("//")) {
            endpoint = endpoint + "//";
        }

        endpoint = endpoint + window.location.host;

        let path = window.location.pathname;
        if (!path.startsWith("/")) {
            path = "/" + path;
        }

        if (path.length >= 1) {
            const lastSegmentIndex = path.lastIndexOf("/");
            if (lastSegmentIndex >= 0) {
                path = path.substring(0, lastSegmentIndex + 1);
            }
        }

        endpoint = endpoint + path;
    }
    return endpoint;
}

export default Client;
