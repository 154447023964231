import * as React from "react";
import Dialog from "components/Dialog/Dialog";
import { Toolbar, ToolbarGroup } from "material-ui/Toolbar";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { GettingStartedDetails } from "components/GettingStarted/GettingStartedDetails";
import IconButton from "material-ui/IconButton";
import ClearIcon from "material-ui/svg-icons/content/clear";
import DialogContent from "@material-ui/core/DialogContent";

interface GettingStartedDialogProps {
    open: boolean;
    highlightSection?: string;
    onClose(): void;
    onNavigating(): void;
}

export class GettingStartedDialog extends BaseComponent<GettingStartedDialogProps, {}> {
    styles = {
        dialogRoot: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 0,
        },
        dialogContent: {
            width: "100%",
            marginRight: "1rem", // To allow room for the Close/X button in the top right.
        },
        dialogBody: {
            flex: "1 1 auto"
        }
    };

    render() {
        const close = <Toolbar style={{ backgroundColor: "initial", height: 0 }} noGutter={true}>
            <ToolbarGroup firstChild={true} />
            <ToolbarGroup lastChild={true}>
                <IconButton
                    tooltip="Close"
                    onClick={this.props.onClose}>
                    <ClearIcon />
                </IconButton>
            </ToolbarGroup>
        </Toolbar>;

        return <Dialog
            title={null}
            open={this.props.open}
            onRequestClose={this.props.onClose}
        >
            <DialogContent>
                <div style={this.styles.dialogRoot}>
                    <div style={this.styles.dialogBody}>
                        {close}
                        <div style={this.styles.dialogContent}>
                            <GettingStartedDetails showIntroHeading={true} onNavigating={() => this.props.onNavigating()} highlightSection={this.props.highlightSection} />
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>;
    }
}